import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';

import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { DanhMucQuyTrinhSanXuat } from 'src/app/shared/DanhMucQuyTrinhSanXuat.model';
import { DanhMucQuyTrinhSanXuatService } from 'src/app/shared/DanhMucQuyTrinhSanXuat.service';
import { DanhMucPhanDoanSanXuat } from 'src/app/shared/DanhMucPhanDoanSanXuat.model';
import { DanhMucPhanDoanSanXuatService } from 'src/app/shared/DanhMucPhanDoanSanXuat.service';

import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';
import { ToChucQuyTrinhSanXuat } from 'src/app/shared/ToChucQuyTrinhSanXuat.model';
import { ToChucQuyTrinhSanXuatService } from 'src/app/shared/ToChucQuyTrinhSanXuat.service';
import { ToChucQuyTrinhSanXuatChiTiet } from 'src/app/shared/ToChucQuyTrinhSanXuatChiTiet.model';
import { ToChucQuyTrinhSanXuatChiTietService } from 'src/app/shared/ToChucQuyTrinhSanXuatChiTiet.service';
import { ToChucDetailComponent } from '../to-chuc-detail/to-chuc-detail.component';

@Component({
  selector: 'app-to-chuc-quy-trinh-san-xuat-detail',
  templateUrl: './to-chuc-quy-trinh-san-xuat-detail.component.html',
  styleUrls: ['./to-chuc-quy-trinh-san-xuat-detail.component.css']
})
export class ToChucQuyTrinhSanXuatDetailComponent implements OnInit {

  ToChucQuyTrinhSanXuatChiTietDataSource: MatTableDataSource<any>;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietSort') ToChucQuyTrinhSanXuatChiTietSort: MatSort;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietPaginator') ToChucQuyTrinhSanXuatChiTietPaginator: MatPaginator;

  ToChucQuyTrinhSanXuatChiTietDataSource1: MatTableDataSource<any>;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietSort1') ToChucQuyTrinhSanXuatChiTietSort1: MatSort;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietPaginator1') ToChucQuyTrinhSanXuatChiTietPaginator1: MatPaginator;

  ToChucQuyTrinhSanXuatChiTietDataSource2: MatTableDataSource<any>;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietSort2') ToChucQuyTrinhSanXuatChiTietSort2: MatSort;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietPaginator2') ToChucQuyTrinhSanXuatChiTietPaginator2: MatPaginator;

  ToChucQuyTrinhSanXuatChiTietDataSource3: MatTableDataSource<any>;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietSort3') ToChucQuyTrinhSanXuatChiTietSort3: MatSort;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietPaginator3') ToChucQuyTrinhSanXuatChiTietPaginator3: MatPaginator;

  ToChucQuyTrinhSanXuatChiTietDataSource4: MatTableDataSource<any>;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietSort4') ToChucQuyTrinhSanXuatChiTietSort4: MatSort;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietPaginator4') ToChucQuyTrinhSanXuatChiTietPaginator4: MatPaginator;

  ToChucQuyTrinhSanXuatChiTietDataSource5: MatTableDataSource<any>;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietSort5') ToChucQuyTrinhSanXuatChiTietSort5: MatSort;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietPaginator5') ToChucQuyTrinhSanXuatChiTietPaginator5: MatPaginator;

  ToChucQuyTrinhSanXuatChiTietDataSource6: MatTableDataSource<any>;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietSort6') ToChucQuyTrinhSanXuatChiTietSort6: MatSort;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietPaginator6') ToChucQuyTrinhSanXuatChiTietPaginator6: MatPaginator;

  ToChucQuyTrinhSanXuatChiTietDataSource7: MatTableDataSource<any>;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietSort7') ToChucQuyTrinhSanXuatChiTietSort7: MatSort;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietPaginator7') ToChucQuyTrinhSanXuatChiTietPaginator7: MatPaginator;


  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ToChucQuyTrinhSanXuatDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,
    
    public DanhMucQuyTrinhSanXuatService: DanhMucQuyTrinhSanXuatService,
    public DanhMucPhanDoanSanXuatService: DanhMucPhanDoanSanXuatService,


    public ToChucService: ToChucService,
    public ToChucQuyTrinhSanXuatService: ToChucQuyTrinhSanXuatService,
    public ToChucQuyTrinhSanXuatChiTietService: ToChucQuyTrinhSanXuatChiTietService,
  ) { }

  ngOnInit(): void {
    this.DanhMucQuyTrinhSanXuatGetToList();
    this.DanhMucPhanDoanSanXuatGetToList();
    this.ToChucGetByID();
    this.ToChucQuyTrinhSanXuatChiTietSearchFull();
  }
  ToChucExportHopTacXaByIDToHTMLAsync() {
    this.isShowLoading = true;
    this.DownloadService.baseParameter.ID = this.ToChucQuyTrinhSanXuatService.formData.ParentID;
    this.DownloadService.ExportHopTacXaByIDToHTMLAsync().subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucAdd() {
    this.ToChucService.baseParameter.ID = this.ToChucQuyTrinhSanXuatService.formData.ParentID;
    this.ToChucService.GetByIDAsync().subscribe(
      res => {
        this.ToChucService.formData = res as ToChuc;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: this.ToChucQuyTrinhSanXuatChiTietService.formData.ParentID };
        const dialog = this.dialog.open(ToChucDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
      },
      err => {
      }
    );
  }

  LastUpdatedDateChange(value) {
    this.ToChucQuyTrinhSanXuatService.formData.LastUpdatedDate = new Date(value);
  }

  ToChucQuyTrinhSanXuatChiTietSearchFull() {
    this.ToChucQuyTrinhSanXuatChiTietSearch();
    this.ToChucQuyTrinhSanXuatChiTietSearch1();
    this.ToChucQuyTrinhSanXuatChiTietSearch2();
    this.ToChucQuyTrinhSanXuatChiTietSearch3();
    this.ToChucQuyTrinhSanXuatChiTietSearch4();
    this.ToChucQuyTrinhSanXuatChiTietSearch5();
    this.ToChucQuyTrinhSanXuatChiTietSearch6();
    this.ToChucQuyTrinhSanXuatChiTietSearch7();
  }

  ToChucQuyTrinhSanXuatChiTietSave(element: ToChucQuyTrinhSanXuatChiTiet) {
    this.ToChucQuyTrinhSanXuatChiTietService.formData = element;
    this.ToChucQuyTrinhSanXuatChiTietService.SaveAsync().subscribe(
      res => {
        this.ToChucQuyTrinhSanXuatChiTietSearchFull();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  ToChucQuyTrinhSanXuatChiTietDelete(element: ToChucQuyTrinhSanXuatChiTiet) {
    if (confirm(environment.DeleteConfirm)) {
      this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.ID = element.ID;
      this.ToChucQuyTrinhSanXuatChiTietService.RemoveAsync().subscribe(
        res => {
          this.ToChucQuyTrinhSanXuatChiTietSearchFull();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }

  ToChucQuyTrinhSanXuatChiTietSearch7() {
    this.ToChucQuyTrinhSanXuatChiTietGetToList7();
  }

  ToChucQuyTrinhSanXuatChiTietGetToList7() {
    this.isShowLoading = true;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.ParentID = this.ToChucQuyTrinhSanXuatService.formData.ID;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.DanhMucQuyTrinhSanXuatPhanLoaiID = 7;
    this.ToChucQuyTrinhSanXuatChiTietService.GetByParentID_DanhMucQuyTrinhSanXuatPhanLoaiIDToListAsync().subscribe(
      res => {
        this.ToChucQuyTrinhSanXuatChiTietService.list007 = (res as ToChucQuyTrinhSanXuatChiTiet[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucQuyTrinhSanXuatChiTietDataSource7 = new MatTableDataSource(this.ToChucQuyTrinhSanXuatChiTietService.list007);
        this.ToChucQuyTrinhSanXuatChiTietDataSource7.sort = this.ToChucQuyTrinhSanXuatChiTietSort7;
        this.ToChucQuyTrinhSanXuatChiTietDataSource7.paginator = this.ToChucQuyTrinhSanXuatChiTietPaginator7;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ToChucQuyTrinhSanXuatChiTietSearch6() {
    this.ToChucQuyTrinhSanXuatChiTietGetToList6();
  }

  ToChucQuyTrinhSanXuatChiTietGetToList6() {
    this.isShowLoading = true;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.ParentID = this.ToChucQuyTrinhSanXuatService.formData.ID;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.DanhMucQuyTrinhSanXuatPhanLoaiID = 6;
    this.ToChucQuyTrinhSanXuatChiTietService.GetByParentID_DanhMucQuyTrinhSanXuatPhanLoaiIDToListAsync().subscribe(
      res => {
        this.ToChucQuyTrinhSanXuatChiTietService.list006 = (res as ToChucQuyTrinhSanXuatChiTiet[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucQuyTrinhSanXuatChiTietDataSource6 = new MatTableDataSource(this.ToChucQuyTrinhSanXuatChiTietService.list006);
        this.ToChucQuyTrinhSanXuatChiTietDataSource6.sort = this.ToChucQuyTrinhSanXuatChiTietSort6;
        this.ToChucQuyTrinhSanXuatChiTietDataSource6.paginator = this.ToChucQuyTrinhSanXuatChiTietPaginator6;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ToChucQuyTrinhSanXuatChiTietSearch5() {
    this.ToChucQuyTrinhSanXuatChiTietGetToList5();
  }

  ToChucQuyTrinhSanXuatChiTietGetToList5() {
    this.isShowLoading = true;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.ParentID = this.ToChucQuyTrinhSanXuatService.formData.ID;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.DanhMucQuyTrinhSanXuatPhanLoaiID = 5;
    this.ToChucQuyTrinhSanXuatChiTietService.GetByParentID_DanhMucQuyTrinhSanXuatPhanLoaiIDToListAsync().subscribe(
      res => {
        this.ToChucQuyTrinhSanXuatChiTietService.list005 = (res as ToChucQuyTrinhSanXuatChiTiet[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucQuyTrinhSanXuatChiTietDataSource5 = new MatTableDataSource(this.ToChucQuyTrinhSanXuatChiTietService.list005);
        this.ToChucQuyTrinhSanXuatChiTietDataSource5.sort = this.ToChucQuyTrinhSanXuatChiTietSort5;
        this.ToChucQuyTrinhSanXuatChiTietDataSource5.paginator = this.ToChucQuyTrinhSanXuatChiTietPaginator5;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ToChucQuyTrinhSanXuatChiTietSearch4() {
    this.ToChucQuyTrinhSanXuatChiTietGetToList4();
  }

  ToChucQuyTrinhSanXuatChiTietGetToList4() {
    this.isShowLoading = true;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.ParentID = this.ToChucQuyTrinhSanXuatService.formData.ID;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.DanhMucQuyTrinhSanXuatPhanLoaiID = 4;
    this.ToChucQuyTrinhSanXuatChiTietService.GetByParentID_DanhMucQuyTrinhSanXuatPhanLoaiIDToListAsync().subscribe(
      res => {
        this.ToChucQuyTrinhSanXuatChiTietService.list004 = (res as ToChucQuyTrinhSanXuatChiTiet[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucQuyTrinhSanXuatChiTietDataSource4 = new MatTableDataSource(this.ToChucQuyTrinhSanXuatChiTietService.list004);
        this.ToChucQuyTrinhSanXuatChiTietDataSource4.sort = this.ToChucQuyTrinhSanXuatChiTietSort4;
        this.ToChucQuyTrinhSanXuatChiTietDataSource4.paginator = this.ToChucQuyTrinhSanXuatChiTietPaginator4;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ToChucQuyTrinhSanXuatChiTietSearch3() {
    this.ToChucQuyTrinhSanXuatChiTietGetToList3();
  }

  ToChucQuyTrinhSanXuatChiTietGetToList3() {
    this.isShowLoading = true;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.ParentID = this.ToChucQuyTrinhSanXuatService.formData.ID;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.DanhMucQuyTrinhSanXuatPhanLoaiID = 3;
    this.ToChucQuyTrinhSanXuatChiTietService.GetByParentID_DanhMucQuyTrinhSanXuatPhanLoaiIDToListAsync().subscribe(
      res => {
        this.ToChucQuyTrinhSanXuatChiTietService.list003 = (res as ToChucQuyTrinhSanXuatChiTiet[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucQuyTrinhSanXuatChiTietDataSource3 = new MatTableDataSource(this.ToChucQuyTrinhSanXuatChiTietService.list003);
        this.ToChucQuyTrinhSanXuatChiTietDataSource3.sort = this.ToChucQuyTrinhSanXuatChiTietSort3;
        this.ToChucQuyTrinhSanXuatChiTietDataSource3.paginator = this.ToChucQuyTrinhSanXuatChiTietPaginator3;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ToChucQuyTrinhSanXuatChiTietSearch2() {
    this.ToChucQuyTrinhSanXuatChiTietGetToList2();
  }

  ToChucQuyTrinhSanXuatChiTietGetToList2() {
    this.isShowLoading = true;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.ParentID = this.ToChucQuyTrinhSanXuatService.formData.ID;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.DanhMucQuyTrinhSanXuatPhanLoaiID = 2;
    this.ToChucQuyTrinhSanXuatChiTietService.GetByParentID_DanhMucQuyTrinhSanXuatPhanLoaiIDToListAsync().subscribe(
      res => {
        this.ToChucQuyTrinhSanXuatChiTietService.list002 = (res as ToChucQuyTrinhSanXuatChiTiet[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucQuyTrinhSanXuatChiTietDataSource2 = new MatTableDataSource(this.ToChucQuyTrinhSanXuatChiTietService.list002);
        this.ToChucQuyTrinhSanXuatChiTietDataSource2.sort = this.ToChucQuyTrinhSanXuatChiTietSort2;
        this.ToChucQuyTrinhSanXuatChiTietDataSource2.paginator = this.ToChucQuyTrinhSanXuatChiTietPaginator2;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ToChucQuyTrinhSanXuatChiTietSearch1() {
    this.ToChucQuyTrinhSanXuatChiTietGetToList1();
  }

  ToChucQuyTrinhSanXuatChiTietGetToList1() {
    this.isShowLoading = true;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.ParentID = this.ToChucQuyTrinhSanXuatService.formData.ID;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.DanhMucQuyTrinhSanXuatPhanLoaiID = 1;
    this.ToChucQuyTrinhSanXuatChiTietService.GetByParentID_DanhMucQuyTrinhSanXuatPhanLoaiIDToListAsync().subscribe(
      res => {
        this.ToChucQuyTrinhSanXuatChiTietService.list001 = (res as ToChucQuyTrinhSanXuatChiTiet[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucQuyTrinhSanXuatChiTietDataSource1 = new MatTableDataSource(this.ToChucQuyTrinhSanXuatChiTietService.list001);
        this.ToChucQuyTrinhSanXuatChiTietDataSource1.sort = this.ToChucQuyTrinhSanXuatChiTietSort1;
        this.ToChucQuyTrinhSanXuatChiTietDataSource1.paginator = this.ToChucQuyTrinhSanXuatChiTietPaginator1;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ToChucQuyTrinhSanXuatChiTietSearch() {
    this.ToChucQuyTrinhSanXuatChiTietGetToList();
  }

  ToChucQuyTrinhSanXuatChiTietGetToList() {
    this.isShowLoading = true;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.ParentID = this.ToChucQuyTrinhSanXuatService.formData.ID;
    this.ToChucQuyTrinhSanXuatChiTietService.GetByParentIDToListAsync().subscribe(
      res => {
        this.ToChucQuyTrinhSanXuatChiTietService.list = (res as ToChucQuyTrinhSanXuatChiTiet[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucQuyTrinhSanXuatChiTietDataSource = new MatTableDataSource(this.ToChucQuyTrinhSanXuatChiTietService.list);
        this.ToChucQuyTrinhSanXuatChiTietDataSource.sort = this.ToChucQuyTrinhSanXuatChiTietSort;
        this.ToChucQuyTrinhSanXuatChiTietDataSource.paginator = this.ToChucQuyTrinhSanXuatChiTietPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ToChucGetByID() {
    this.isShowLoading = true;
    this.ToChucService.baseParameter.ID = this.ToChucQuyTrinhSanXuatService.formData.ParentID;
    this.ToChucService.GetByIDAsync().subscribe(
      res => {
        this.ToChucService.formData = (res as ToChuc);
        if (this.ToChucService.formData) {
          this.ToChucQuyTrinhSanXuatService.formData.Name = this.ToChucService.formData.Name;
          this.ToChucQuyTrinhSanXuatService.formData.Code = this.ToChucService.formData.Code;
          this.ToChucQuyTrinhSanXuatService.formData.Display = this.ToChucService.formData.DienThoai;
        }
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ToChucQuyTrinhSanXuatSave() {
    this.NotificationService.warn(this.ToChucQuyTrinhSanXuatService.ComponentSaveForm());
  }

  DanhMucQuyTrinhSanXuatGetToList() {
    this.DanhMucQuyTrinhSanXuatService.ComponentGetAllToListAsync();
  }

  DanhMucPhanDoanSanXuatGetToList() {
    this.DanhMucPhanDoanSanXuatService.ComponentGetAllToListAsync();
  }

  Close() {
    this.dialogRef.close();
  }

}
