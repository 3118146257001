import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { UploadService } from 'src/app/shared/Upload.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
import { DanhMucThanhVien } from 'src/app/shared/DanhMucThanhVien.model';
import { DanhMucThanhVienService } from 'src/app/shared/DanhMucThanhVien.service';

import { ThoiTiet } from 'src/app/shared/ThoiTiet.model';
import { ThoiTietService } from 'src/app/shared/ThoiTiet.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {

  ToChucDataSource: MatTableDataSource<any>;
  @ViewChild('ToChucSort') ToChucSort: MatSort;
  @ViewChild('ToChucPaginator') ToChucPaginator: MatPaginator;



  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  domainName = environment.DomainDestination;
  DanhMucTinhThanhID: number = environment.DanhMucTinhThanhID;
  parentID: number = environment.ThanhVienCoQuanQuanLyID;
  NgayGhiNhan: Date = new Date();

  isToChuc: boolean = false;
  excelToChucURL: string = environment.APIUploadRootURL + environment.Download + "/ToChuc.xlsx";
  @ViewChild('uploadToChuc') uploadToChuc!: ElementRef;

  isDanhMucNguyenVatLieu: boolean = false;
  excelDanhMucNguyenVatLieuURL: string = environment.APIUploadRootURL + environment.Download + "/DanhMucNguyenVatLieu.xlsx";
  @ViewChild('uploadDanhMucNguyenVatLieu') uploadDanhMucNguyenVatLieu!: ElementRef;

  isToChucQuyTrinhSanXuat: boolean = false;
  excelToChucQuyTrinhSanXuatURL: string = environment.APIUploadRootURL + environment.Download + "/ToChucQuyTrinhSanXuat.xlsx";
  @ViewChild('uploadToChucQuyTrinhSanXuat') uploadToChucQuyTrinhSanXuat!: ElementRef;

  isToChucFix: boolean = false;
  excelToChucFixURL: string = environment.APIUploadRootURL + environment.Download + "/ToChuc.xlsx";
  @ViewChild('uploadToChucFix') uploadToChucFix!: ElementRef;

  isThanhVienCoQuanQuanLy: boolean = false;
  excelThanhVienCoQuanQuanLyURL: string = environment.APIUploadRootURL + environment.Download + "/ThanhVien_CoQuanQuanLy.xlsx";
  @ViewChild('uploadThanhVienCoQuanQuanLy') uploadThanhVienCoQuanQuanLy!: ElementRef;

  isDanhMucSanPhamGiong: boolean = false;
  excelDanhMucSanPhamGiongURL: string = environment.APIUploadRootURL + environment.Download + "/DanhMucSanPhamGiong.xlsx";
  @ViewChild('uploadDanhMucSanPhamGiong') uploadDanhMucSanPhamGiong!: ElementRef;

  isDanhMucSanPhamGiongBaoHo: boolean = false;
  excelDanhMucSanPhamGiongBaoHoURL: string = environment.APIUploadRootURL + environment.Download + "/DanhMucSanPhamGiongBaoHo.xlsx";
  @ViewChild('uploadDanhMucSanPhamGiongBaoHo') uploadDanhMucSanPhamGiongBaoHo!: ElementRef;

  @ViewChild('ThoiTietSort') ThoiTietSort: MatSort;
  @ViewChild('ThoiTietPaginator') ThoiTietPaginator: MatPaginator;

  constructor(
    public NotificationService: NotificationService,
    public UploadService: UploadService,
    public DownloadService: DownloadService,   

    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public DanhMucThanhVienService: DanhMucThanhVienService,
    public ThoiTietService: ThoiTietService,

  ) { }

  ngOnInit(): void {
    this.DanhMucThanhVienGetToList();
    this.DanhMucTinhThanhGetToList();
  } 

  changeToChucFix(files: FileList) {
    if (files) {
      this.isToChucFix = true;
    }
  }
  onSubmitToChucFix() {
    let fileToUpload: File;
    fileToUpload = this.uploadToChucFix.nativeElement.files[0];
    this.isShowLoading = true;    
    this.UploadService.PostToChucFixErrorByExcelFileAsync(fileToUpload).subscribe(
      res => {
        this.isShowLoading = false;        
        this.NotificationService.warn(environment.UploadSuccess);
      },
      err => {
        this.isShowLoading = false;
        this.NotificationService.warn(environment.UploadNotSuccess);
      }
    );
  }

  changeToChuc(files: FileList) {
    if (files) {
      this.isToChuc = true;
    }
  }
  onSubmitToChuc() {
    let fileToUpload: File;
    fileToUpload = this.uploadToChuc.nativeElement.files[0];
    this.isShowLoading = true;    
    this.UploadService.PostToChucByExcelFileAsync(fileToUpload).subscribe(
      res => {
        this.isShowLoading = false;        
        this.NotificationService.warn(environment.UploadSuccess);
      },
      err => {
        this.isShowLoading = false;
        this.NotificationService.warn(environment.UploadNotSuccess);
      }
    );
  }

  changeDanhMucNguyenVatLieu(files: FileList) {
    if (files) {
      this.isDanhMucNguyenVatLieu = true;
    }
  }
  onSubmitDanhMucNguyenVatLieu() {
    let fileToUpload: File;
    fileToUpload = this.uploadDanhMucNguyenVatLieu.nativeElement.files[0];
    this.isShowLoading = true;    
    this.UploadService.PostDanhMucNguyenVatLieuByExcelFileAsync(fileToUpload).subscribe(
      res => {
        this.isShowLoading = false;        
        this.NotificationService.warn(environment.UploadSuccess);
      },
      err => {
        this.isShowLoading = false;
        this.NotificationService.warn(environment.UploadNotSuccess);
      }
    );
  }

  changeToChucQuyTrinhSanXuat(files: FileList) {
    if (files) {
      this.isToChucQuyTrinhSanXuat = true;
    }
  }
  onSubmitToChucQuyTrinhSanXuat() {
    let fileToUpload: File;
    fileToUpload = this.uploadToChucQuyTrinhSanXuat.nativeElement.files[0];
    this.isShowLoading = true;    
    this.UploadService.PostToChucQuyTrinhSanXuatByExcelFileAsync(fileToUpload).subscribe(
      res => {
        this.isShowLoading = false;        
        this.NotificationService.warn(environment.UploadSuccess);
      },
      err => {
        this.isShowLoading = false;
        this.NotificationService.warn(environment.UploadNotSuccess);
      }
    );
  }

  changeThanhVienCoQuanQuanLy(files: FileList) {
    if (files) {
      this.isThanhVienCoQuanQuanLy = true;
    }
  }
  onSubmitThanhVienCoQuanQuanLy() {
    let fileToUpload: File;
    fileToUpload = this.uploadThanhVienCoQuanQuanLy.nativeElement.files[0];
    this.isShowLoading = true;    
    this.UploadService.PostThanhVienByExcelFileAsync(fileToUpload, this.parentID).subscribe(
      res => {
        this.isShowLoading = false;        
        this.NotificationService.warn(environment.UploadSuccess);
      },
      err => {
        this.isShowLoading = false;
        this.NotificationService.warn(environment.UploadNotSuccess);
      }
    );
  }

  changeDanhMucSanPhamGiong(files: FileList) {
    if (files) {
      this.isDanhMucSanPhamGiong = true;
    }
  }
  onSubmitDanhMucSanPhamGiong() {
    let fileToUpload: File;
    fileToUpload = this.uploadDanhMucSanPhamGiong.nativeElement.files[0];
    this.isShowLoading = true;    
    this.UploadService.PostDanhMucSanPhamGiongByExcelFileAsync(fileToUpload).subscribe(
      res => {
        this.isShowLoading = false;        
        this.NotificationService.warn(environment.UploadSuccess);
      },
      err => {
        this.isShowLoading = false;
        this.NotificationService.warn(environment.UploadNotSuccess);
      }
    );
  }

  changeDanhMucSanPhamGiongBaoHo(files: FileList) {
    if (files) {
      this.isDanhMucSanPhamGiongBaoHo = true;
    }
  }
  onSubmitDanhMucSanPhamGiongBaoHo() {
    let fileToUpload: File;
    fileToUpload = this.uploadDanhMucSanPhamGiongBaoHo.nativeElement.files[0];
    this.isShowLoading = true;    
    this.UploadService.PostDanhMucSanPhamGiongBaoHoByExcelFileAsync(fileToUpload).subscribe(
      res => {
        this.isShowLoading = false;        
        this.NotificationService.warn(environment.UploadSuccess);
      },
      err => {
        this.isShowLoading = false;
        this.NotificationService.warn(environment.UploadNotSuccess);
      }
    );
  }

  onChangeNgayGhiNhan(value) {
    this.NgayGhiNhan = new Date(value);
  }



  ThoiTietSearch() {
    this.isShowLoading = true;
    this.ThoiTietService.baseParameter.DanhMucTinhThanhID = this.DanhMucTinhThanhID;
    this.ThoiTietService.baseParameter.NgayGhiNhan = this.NgayGhiNhan;
    this.ThoiTietService.GetByDanhMucTinhThanhID_NgayGhiNhanToListAsync().subscribe(
      res => {
        this.ThoiTietService.list = (res as ThoiTiet[]).sort((a, b) => (a.ID > b.ID ? 1 : -1));
        this.ThoiTietService.list001 = this.ThoiTietService.list;
        this.ThoiTietService.DataSource = new MatTableDataSource(this.ThoiTietService.list);
        this.ThoiTietService.DataSource.sort = this.ThoiTietSort;
        this.ThoiTietService.DataSource.paginator = this.ThoiTietPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  } 

  DanhMucTinhThanhGetToList() {
    this.isShowLoading = true;
    this.DanhMucTinhThanhService.baseParameter.Active = true;
    this.DanhMucTinhThanhService.GetByActiveToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.list = (res as DanhMucTinhThanh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucTinhThanhService.list001 = this.DanhMucTinhThanhService.list;
        if (this.DanhMucTinhThanhService.list) {
          if (this.DanhMucTinhThanhService.list.length) {
            this.DanhMucTinhThanhID = this.DanhMucTinhThanhService.list[0].ID;
          }
        }
        this.ThoiTietSearch();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  DanhMucTinhThanhFilter(searchString: string) {
    this.DanhMucTinhThanhService.Filter(searchString);
  }

  DanhMucThanhVienGetToList() {
    this.DanhMucThanhVienService.ComponentGetAllToListAsync();
  }
}
