import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucXaPhuong } from 'src/app/shared/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';

import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';


import { Report } from 'src/app/shared/Report.model';
import { ReportService } from 'src/app/shared/Report.service';
import { ToChucDetailComponent } from '../to-chuc-detail/to-chuc-detail.component';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  ReportDataSource0002: MatTableDataSource<any>;
  @ViewChild('ReportSort0002') ReportSort0002: MatSort;
  @ViewChild('ReportPaginator0002') ReportPaginator0002: MatPaginator;

  ReportDataSource0003: MatTableDataSource<any>;
  @ViewChild('ReportSort0003') ReportSort0003: MatSort;
  @ViewChild('ReportPaginator0002') ReportPaginator0003: MatPaginator;

  ReportDataSource0004: MatTableDataSource<any>;
  @ViewChild('ReportSort0004') ReportSort0004: MatSort;
  @ViewChild('ReportPaginator0004') ReportPaginator0004: MatPaginator;

  ReportDataSource0005: MatTableDataSource<any>;
  @ViewChild('ReportSort0005') ReportSort0005: MatSort;
  @ViewChild('ReportPaginator0005') ReportPaginator0005: MatPaginator;

  ReportDataSource0006: MatTableDataSource<any>;
  @ViewChild('ReportSort0006') ReportSort0006: MatSort;
  @ViewChild('ReportPaginator0006') ReportPaginator0006: MatPaginator;

  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  DanhMucTinhThanhID: number = environment.InitializationNumber;
  DanhMucQuanHuyenID: number = environment.InitializationNumber;
  DanhMucXaPhuongID: number = environment.InitializationNumber;
  SortOrder: number = environment.InitializationNumber;
  year: number = new Date().getFullYear();

  constructor(
    private dialog: MatDialog,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,
    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,

    public ToChucService: ToChucService,


    public ReportService: ReportService,

  ) { }

  ngOnInit(): void {
    this.DanhMucTinhThanhGetToList();
  }

  Report0006Search() {
    if (this.ReportService.SearchString.length > 0) {
      this.ReportDataSource0006.filter = this.ReportService.SearchString.toLowerCase();
    }
    else {
      this.Report0006();
    }
  }
  Report0006() {
    this.isShowLoading = true;
    this.ReportService.baseParameter.DanhMucDuAnID = environment.DanhMucDuAnID;    
    this.ReportService.baseParameter.Year = this.year;   
    this.ReportService.baseParameter.DanhMucTinhThanhID = this.DanhMucTinhThanhID;
    this.ReportService.baseParameter.DanhMucQuanHuyenID = this.DanhMucQuanHuyenID;
    this.ReportService.baseParameter.DanhMucXaPhuongID = this.DanhMucXaPhuongID;
    this.ReportService.Report0006ToListAsync().subscribe(
      res => {
        this.ReportService.list0006 = (res as Report[]);       
        this.ReportDataSource0006 = new MatTableDataSource(this.ReportService.list0006);
        this.ReportDataSource0006.sort = this.ReportSort0006;
        this.ReportDataSource0006.paginator = this.ReportPaginator0006;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  Report0006ExportExcel() {
    this.isShowLoading = true;
    this.ReportService.baseParameter.DanhMucDuAnID = environment.DanhMucDuAnID;  
    this.ReportService.baseParameter.Year = this.year;     
    this.ReportService.baseParameter.DanhMucTinhThanhID = this.DanhMucTinhThanhID;
    this.ReportService.baseParameter.DanhMucQuanHuyenID = this.DanhMucQuanHuyenID;
    this.ReportService.baseParameter.DanhMucXaPhuongID = this.DanhMucXaPhuongID;
    this.ReportService.ExportReport0006ToExcelAsync().subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  Report0006ExportHTML() {
    this.isShowLoading = true;
    this.ReportService.baseParameter.DanhMucDuAnID = environment.DanhMucDuAnID;  
    this.ReportService.baseParameter.Year = this.year;     
    this.ReportService.baseParameter.DanhMucTinhThanhID = this.DanhMucTinhThanhID;
    this.ReportService.baseParameter.DanhMucQuanHuyenID = this.DanhMucQuanHuyenID;
    this.ReportService.baseParameter.DanhMucXaPhuongID = this.DanhMucXaPhuongID;
    this.ReportService.ExportReport0006ToHTMLAsync().subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  Report0005Search() {
    if (this.ReportService.SearchString.length > 0) {
      this.ReportDataSource0005.filter = this.ReportService.SearchString.toLowerCase();
    }
    else {
      this.Report0005();
    }
  }
  Report0005() {
    this.isShowLoading = true;
    this.ReportService.baseParameter.DanhMucDuAnID = environment.DanhMucDuAnID;    
    this.ReportService.baseParameter.Year = this.year;   
    this.ReportService.Report0005ToListAsync().subscribe(
      res => {
        this.ReportService.list0005 = (res as Report[]);       
        this.ReportDataSource0005 = new MatTableDataSource(this.ReportService.list0005);
        this.ReportDataSource0005.sort = this.ReportSort0005;
        this.ReportDataSource0005.paginator = this.ReportPaginator0005;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  Report0005ExportExcel() {
    this.isShowLoading = true;
    this.ReportService.baseParameter.DanhMucDuAnID = environment.DanhMucDuAnID;  
    this.ReportService.baseParameter.Year = this.year;     
    this.ReportService.ExportReport0005ToExcelAsync().subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  Report0005ExportHTML() {
    this.isShowLoading = true;
    this.ReportService.baseParameter.DanhMucDuAnID = environment.DanhMucDuAnID;  
    this.ReportService.baseParameter.Year = this.year;     
    this.ReportService.ExportReport0005ToHTMLAsync().subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  Report0004Search() {
    if (this.ReportService.SearchString.length > 0) {
      this.ReportDataSource0004.filter = this.ReportService.SearchString.toLowerCase();
    }
    else {
      this.Report0004();
    }
  }
  Report0004() {
    this.isShowLoading = true;
    this.ReportService.baseParameter.DanhMucDuAnID = environment.DanhMucDuAnID;    
    this.ReportService.Report0004ToListAsync().subscribe(
      res => {
        this.ReportService.list0004 = (res as Report[]);       
        this.ReportDataSource0004 = new MatTableDataSource(this.ReportService.list0004);
        this.ReportDataSource0004.sort = this.ReportSort0004;
        this.ReportDataSource0004.paginator = this.ReportPaginator0004;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  Report0004ExportExcel() {
    this.isShowLoading = true;
    this.ReportService.baseParameter.DanhMucDuAnID = environment.DanhMucDuAnID;    
    this.ReportService.ExportReport0004ToExcelAsync().subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  Report0004ExportHTML() {
    this.isShowLoading = true;
    this.ReportService.baseParameter.DanhMucDuAnID = environment.DanhMucDuAnID;    
    this.ReportService.ExportReport0004ToHTMLAsync().subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  Report0003Search() {
    if (this.ReportService.SearchString.length > 0) {
      this.ReportDataSource0003.filter = this.ReportService.SearchString.toLowerCase();
    }
    else {
      this.Report0003();
    }
  }
  Report0003() {
    this.isShowLoading = true;
    this.ReportService.baseParameter.DanhMucTinhThanhID = this.DanhMucTinhThanhID;
    this.ReportService.baseParameter.DanhMucQuanHuyenID = this.DanhMucQuanHuyenID;
    this.ReportService.baseParameter.DanhMucXaPhuongID = this.DanhMucXaPhuongID;
    this.ReportService.Report0003ToListAsync().subscribe(
      res => {
        this.ReportService.list0003 = (res as Report[]);
        if (this.SortOrder == 1) {
          this.ReportService.list0003 = this.ReportService.list0003.filter(item => item.SoLuongTongHop > 0 || item.SoLuongThamGia > 0);          
        }
        if (this.SortOrder == 2) {
          this.ReportService.list0003 = this.ReportService.list0003.filter(item => item.SoLuongTongHop == 0 && item.SoLuongThamGia == 0);          
        }
        this.ReportDataSource0003 = new MatTableDataSource(this.ReportService.list0003);
        this.ReportDataSource0003.sort = this.ReportSort0003;
        this.ReportDataSource0003.paginator = this.ReportPaginator0003;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  Report0003ExportExcel() {
    this.isShowLoading = true;
    this.ReportService.baseParameter.SortOrder = this.SortOrder;
    this.ReportService.baseParameter.DanhMucTinhThanhID = this.DanhMucTinhThanhID;
    this.ReportService.baseParameter.DanhMucQuanHuyenID = this.DanhMucQuanHuyenID;
    this.ReportService.baseParameter.DanhMucXaPhuongID = this.DanhMucXaPhuongID;
    this.ReportService.ExportReport0003ToExcelAsync().subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  Report0003ExportHTML() {
    this.isShowLoading = true;
    this.ReportService.baseParameter.SortOrder = this.SortOrder;
    this.ReportService.baseParameter.DanhMucTinhThanhID = this.DanhMucTinhThanhID;
    this.ReportService.baseParameter.DanhMucQuanHuyenID = this.DanhMucQuanHuyenID;
    this.ReportService.baseParameter.DanhMucXaPhuongID = this.DanhMucXaPhuongID;
    this.ReportService.ExportReport0003ToHTMLAsync().subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  Report0002Search() {
    if (this.ReportService.SearchString.length > 0) {
      this.ReportDataSource0002.filter = this.ReportService.SearchString.toLowerCase();
    }
    else {
      this.Report0002();
    }
  }
  Report0002() {
    this.isShowLoading = true;
    this.ReportService.baseParameter.DanhMucDuAnID = environment.DanhMucDuAnID;
    this.ReportService.baseParameter.DanhMucTinhThanhID = this.DanhMucTinhThanhID;
    this.ReportService.baseParameter.DanhMucQuanHuyenID = this.DanhMucQuanHuyenID;
    this.ReportService.baseParameter.DanhMucXaPhuongID = this.DanhMucXaPhuongID;
    this.ReportService.Report0002ToListAsync().subscribe(
      res => {
        this.ReportService.list0002 = (res as Report[]);
        this.ReportDataSource0002 = new MatTableDataSource(this.ReportService.list0002);
        this.ReportDataSource0002.sort = this.ReportSort0002;
        this.ReportDataSource0002.paginator = this.ReportPaginator0002;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  Report0002ExportExcel() {
    this.isShowLoading = true;
    this.ReportService.baseParameter.DanhMucDuAnID = environment.DanhMucDuAnID;
    this.ReportService.baseParameter.DanhMucTinhThanhID = this.DanhMucTinhThanhID;
    this.ReportService.baseParameter.DanhMucQuanHuyenID = this.DanhMucQuanHuyenID;
    this.ReportService.baseParameter.DanhMucXaPhuongID = this.DanhMucXaPhuongID;
    this.ReportService.ExportReport0002ToExcelAsync().subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  Report0002ExportHTML() {
    this.isShowLoading = true;
    this.ReportService.baseParameter.DanhMucDuAnID = environment.DanhMucDuAnID;
    this.ReportService.baseParameter.DanhMucTinhThanhID = this.DanhMucTinhThanhID;
    this.ReportService.baseParameter.DanhMucQuanHuyenID = this.DanhMucQuanHuyenID;
    this.ReportService.baseParameter.DanhMucXaPhuongID = this.DanhMucXaPhuongID;
    this.ReportService.ExportReport0002ToHTMLAsync().subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  DanhMucTinhThanhGetToList() {
    this.isShowLoading = true;
    this.DanhMucTinhThanhService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.list = (res as DanhMucTinhThanh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucTinhThanhService.list001 = this.DanhMucTinhThanhService.list;
        if (this.DanhMucTinhThanhService.list) {
          if (this.DanhMucTinhThanhService.list.length) {
            this.DanhMucTinhThanhID = this.DanhMucTinhThanhService.list[0].ID;
          }
        }
        this.Report0002Search();
        this.DanhMucQuanHuyenGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucQuanHuyenGetToList() {
    this.isShowLoading = true;
    this.DanhMucQuanHuyenService.baseParameter.ParentID = this.DanhMucTinhThanhID;
    this.DanhMucQuanHuyenService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucQuanHuyenService.list = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucQuanHuyenService.list001 = this.DanhMucQuanHuyenService.list;
        this.DanhMucXaPhuongGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucXaPhuongGetToList() {
    this.isShowLoading = true;
    this.DanhMucXaPhuongService.baseParameter.ParentID = this.DanhMucQuanHuyenID;
    this.DanhMucXaPhuongService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucXaPhuongService.list = (res as DanhMucXaPhuong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucXaPhuongService.list001 = this.DanhMucXaPhuongService.list;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  DanhMucTinhThanhFilter(searchString: string) {
    this.DanhMucTinhThanhService.Filter(searchString);
  }
  DanhMucQuanHuyenFilter(searchString: string) {
    this.DanhMucQuanHuyenService.Filter(searchString);
  }
  DanhMucXaPhuongFilter(searchString: string) {
    this.DanhMucXaPhuongService.Filter(searchString);
  }

  ToChucAdd(ID: any) {
    this.ToChucService.baseParameter.ID = ID;
    this.ToChucService.GetByIDAsync().subscribe(
      res => {
        this.ToChucService.formData = res as ToChuc;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ToChucDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
      },
      err => {
      }
    );
  }
}
