<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-gear fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">{{DanhMucTinhThanhToaDoService.baseParameter.Name}}
                [{{DanhMucTinhThanhToaDoService.baseParameter.ID}}]</h2>
            <h6 class="m-0 text-dark fw-light">
                Tọa dộ
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
            <a class="btn btn-danger" title="Đóng" (click)="Close()">
                <i class="bi bi-x-lg"></i>
            </a>
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-DanhMucTinhThanhToaDoDetail" data-bs-toggle="tab"
                                    href="#DanhMucTinhThanhToaDoDetail" role="tab"
                                    aria-controls="DanhMucTinhThanhToaDoDetail" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">01: Tọa độ</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-DanhMucTinhThanhToaDoMap" data-bs-toggle="tab"
                                    href="#DanhMucTinhThanhToaDoMap" role="tab" aria-controls="DanhMucTinhThanhToaDoMap"
                                    aria-selected="false">
                                    <span class="badge bg-danger" style="font-size: 16px;">02: Bản đồ</span>
                                </a>
                            </li>

                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="DanhMucTinhThanhToaDoDetail" role="tabpanel">
                                <div class="row gx-4">
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Tọa độ
                                            <span
                                                *ngIf="DanhMucTinhThanhToaDoService.list">({{DanhMucTinhThanhToaDoService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #DanhMucTinhThanhToaDoSort="matSort"
                                                    [dataSource]="DanhMucTinhThanhToaDoService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{DanhMucTinhThanhToaDoPaginator.pageSize *
                                                            DanhMucTinhThanhToaDoPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ID}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KinhDo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kinh độ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Kinh độ" name="KinhDo{{element.ID}}"
                                                                [(ngModel)]="element.KinhDo">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ViDo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Vĩ độ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text" placeholder="Vĩ độ"
                                                                name="ViDo{{element.ID}}" [(ngModel)]="element.ViDo">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SortOrder">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sắp xếp
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number" placeholder="0"
                                                                name="SortOrder{{element.ID}}"
                                                                [(ngModel)]="element.SortOrder"
                                                                style="text-align: right;">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kích hoạt
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="DanhMucTinhThanhToaDoSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="DanhMucTinhThanhToaDoDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="DanhMucTinhThanhToaDoService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: DanhMucTinhThanhToaDoService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #DanhMucTinhThanhToaDoPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="DanhMucTinhThanhToaDoMap" role="tabpanel">
                                <div class="map" #map></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>