<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-gear fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">{{ThanhVienService.formData.Name}}</h2>
            <h6 class="m-0 text-dark fw-light">
                Nông dân
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
            <a class="btn btn-danger" title="Đóng" (click)="Close()">
                <i class="bi bi-x-lg"></i>
            </a>
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ThanhVienDetail" data-bs-toggle="tab"
                                    href="#ThanhVienDetail" role="tab" aria-controls="ThanhVienDetail"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Bước 01: Thông tin</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ThanhVienTrangTraiDetail" data-bs-toggle="tab"
                                    href="#ThanhVienTrangTraiDetail" role="tab" aria-controls="ThanhVienTrangTraiDetail"
                                    aria-selected="false">
                                    <span class="badge bg-danger" style="font-size: 16px;">Bước 02: Vùng
                                        trồng</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucToaDoDetail" data-bs-toggle="tab"
                                    href="#ToChucToaDoDetail" role="tab" aria-controls="ToChucToaDoDetail"
                                    aria-selected="false">
                                    <span class="badge bg-success" style="font-size: 16px;">Bước 03: Tọa độ</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucDetail" data-bs-toggle="tab" href="#ToChucDetail"
                                    role="tab" aria-controls="ToChucDetail" aria-selected="false">
                                    <span class="badge bg-warning" style="font-size: 16px;">Bước 04: Hợp tác
                                        xã</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ThanhVienKhoDetail" data-bs-toggle="tab"
                                    href="#ThanhVienKhoDetail" role="tab" aria-controls="ThanhVienKhoDetail"
                                    aria-selected="false">
                                    <span class="badge bg-primary" style="font-size: 16px;">Bước 05: Kho hàng</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ThanhVienDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <a style="width: 100%;" title="Lưu thay đổi" (click)="ThanhVienSave()"
                                            class="btn btn-info"><i class="bi bi-sd-card"></i></a>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="Active"
                                                [(ngModel)]="ThanhVienService.formData.Active" />
                                            <label class="form-check-label" for="Active">Kích hoạt</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Họ tên</label>
                                            <input name="Name" [(ngModel)]="ThanhVienService.formData.Name"
                                                placeholder="Họ tên" type="text" class="form-control">
                                        </div>    
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tài khoản</label>
                                            <input name="TaiKhoan" [(ngModel)]="ThanhVienService.formData.TaiKhoan"
                                                placeholder="Tài khoản" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Điện thoại</label>
                                            <input name="DienThoai" [(ngModel)]="ThanhVienService.formData.DienThoai"
                                                placeholder="Điện thoại" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Email</label>
                                            <input name="Email" [(ngModel)]="ThanhVienService.formData.Email"
                                                placeholder="Email" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Mô tả</label>
                                            <input name="Description"
                                                [(ngModel)]="ThanhVienService.formData.Description" placeholder="Mô tả"
                                                type="text" class="form-control">
                                        </div>
                                        <!-- <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ghi chú</label>
                                            <input name="Note" [(ngModel)]="ThanhVienService.formData.Note"
                                                placeholder="Ghi chú" type="text" class="form-control">
                                        </div> -->
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <!-- <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Phân quyền</label>
                                            <mat-select class="form-control" name="ParentID"
                                                [(ngModel)]="ThanhVienService.formData.ParentID">
                                                <mat-option *ngFor="let item of DanhMucThanhVienService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div> -->
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tỉnh thành</label>
                                            <mat-select class="form-control" name="DanhMucTinhThanhID"
                                                (selectionChange)="DanhMucQuanHuyenGetToList()"
                                                [(ngModel)]="ThanhVienService.formData.DanhMucTinhThanhID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucTinhThanhFilter($event.target.value)">
                                                <mat-option *ngFor="let item of DanhMucTinhThanhService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Quận huyện</label>
                                            <mat-select class="form-control" name="DanhMucQuanHuyenID"
                                                (selectionChange)="DanhMucXaPhuongGetToList()"
                                                [(ngModel)]="ThanhVienService.formData.DanhMucQuanHuyenID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucQuanHuyenFilter($event.target.value)">
                                                <mat-option *ngFor="let item of DanhMucQuanHuyenService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Xã phường</label>
                                            <mat-select class="form-control" name="DanhMucXaPhuongID"
                                                [(ngModel)]="ThanhVienService.formData.DanhMucXaPhuongID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucXaPhuongFilter($event.target.value)">
                                                <mat-option *ngFor="let item of DanhMucXaPhuongService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Địa chỉ</label>
                                            <input name="DiaChi" [(ngModel)]="ThanhVienService.formData.DiaChi"
                                                placeholder="Địa chỉ" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ấp thôn</label>
                                            <input name="ApThon" [(ngModel)]="ThanhVienService.formData.ApThon"
                                                placeholder="Ấp thôn" type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ThanhVienTrangTraiDetail" role="tabpanel">
                                <div class="row gx-4">
                                </div>
                                <div class="card mb-4 card-danger">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Vùng trồng
                                            <span
                                                *ngIf="ThanhVienTrangTraiService.list">({{ThanhVienTrangTraiService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ThanhVienTrangTraiSort="matSort"
                                                    [dataSource]="ThanhVienTrangTraiService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a class="btn btn-info"
                                                                        (click)="ThanhVienTrangTraiSave(element)"><i
                                                                            class="bi bi-sd-card"></i>
                                                                    </a>&nbsp;&nbsp;&nbsp;
                                                                    <a class="btn btn-danger"
                                                                        (click)="ThanhVienTrangTraiDelete(element)"
                                                                        *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                                    </a>

                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">ID</label>
                                                                    {{element.ID}}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucTinhThanhID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tỉnh thành
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tỉnh thành</label>
                                                                    <select class="form-select"
                                                                        name="DanhMucTinhThanhID"
                                                                        [(ngModel)]="element.DanhMucTinhThanhID">
                                                                        <option
                                                                            *ngFor="let item of DanhMucTinhThanhService.list001;"
                                                                            [value]="item.ID">
                                                                            {{item.Name}}</option>
                                                                    </select>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Quận huyện</label>
                                                                    <select class="form-select"
                                                                        name="DanhMucTinhThanhID"
                                                                        [(ngModel)]="element.DanhMucQuanHuyenID">
                                                                        <option
                                                                            *ngFor="let item of DanhMucQuanHuyenService.list001;"
                                                                            [value]="item.ID">
                                                                            {{item.Name}}</option>
                                                                    </select>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Xã phường</label>
                                                                    <select class="form-select" name="DanhMucXaPhuongID"
                                                                        [(ngModel)]="element.DanhMucXaPhuongID">
                                                                        <option
                                                                            *ngFor="let item of DanhMucXaPhuongService.list001;"
                                                                            [value]="item.ID">
                                                                            {{item.Name}}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DiaChi">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Địa chỉ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Địa chỉ</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Địa chỉ"
                                                                        name="DiaChi{{element.ID}}"
                                                                        [(ngModel)]="element.DiaChi">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Ấp thôn</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Ấp thôn"
                                                                        name="ApThon{{element.ID}}"
                                                                        [(ngModel)]="element.ApThon">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsChuSoHuu{{element.ID}}"
                                                                            [(ngModel)]="element.IsChuSoHuu">
                                                                        <label class="form-check-label"
                                                                            for="IsChuSoHuu">Sở hữu
                                                                            / Thuê</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Trang trại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tiêu đề</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Tiêu đề" name="Name{{element.ID}}"
                                                                        [(ngModel)]="element.Name">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Diện tích</label>
                                                                    <input class="form-control" type="number"
                                                                        placeholder="0" name="DienTich{{element.ID}}"
                                                                        [(ngModel)]="element.DienTich"
                                                                        style="text-align: right;">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Sản phẩm</label>
                                                                    <select class="form-select" name="DanhMucSanPhamID"
                                                                        [(ngModel)]="element.DanhMucSanPhamID">
                                                                        <option
                                                                            *ngFor="let item of DanhMucSanPhamService.list001;"
                                                                            [value]="item.ID">
                                                                            {{item.Name}}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThanhVienTrangTraiService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThanhVienTrangTraiService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ThanhVienTrangTraiPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucToaDoDetail" role="tabpanel">
                                <div class="card mb-4 card-success">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Tọa độ
                                            <span *ngIf="ToChucToaDoService.list">({{ToChucToaDoService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucToaDoSort="matSort"
                                                    [dataSource]="ToChucToaDoService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucToaDoPaginator.pageSize *
                                                            ToChucToaDoPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KinhDo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kinh độ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Kinh độ" name="KinhDo{{element.ID}}"
                                                                [(ngModel)]="element.KinhDo">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ViDo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Vĩ độ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text" placeholder="Vĩ độ"
                                                                name="ViDo{{element.ID}}" [(ngModel)]="element.ViDo">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DiaChi">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Địa chỉ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Địa chỉ" name="DiaChi{{element.ID}}"
                                                                [(ngModel)]="element.DiaChi">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Trụ sở
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucToaDoSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucToaDoDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucToaDoService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucToaDoService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucToaDoPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucDetail" role="tabpanel">
                                <div class="card mb-4 card-warning">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Hợp tác xã
                                            <span
                                                *ngIf="ToChucThanhVienService.list">({{ToChucThanhVienService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucThanhVienSort="matSort"
                                                    [dataSource]="ToChucThanhVienService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucThanhVienPaginator.pageSize *
                                                            ToChucThanhVienPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ParentID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hợp tác xã
                                                        </th>
                                                        <td mat-cell *matCellDef="let element" style="width: 500px;">
                                                            <mat-select class="form-control" name="ParentID"
                                                                [(ngModel)]="element.ParentID">
                                                                <input class="form-control"
                                                                    (keyup)="ToChucFilter($event.target.value)">
                                                                <mat-option *ngFor="let item of ToChucService.list001"
                                                                    [value]="item.ID">
                                                                    {{item.Name}} - {{item.Code}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucChucDanhID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chức danh
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select" name="DanhMucChucDanhID"
                                                                [(ngModel)]="element.DanhMucChucDanhID">
                                                                <option
                                                                    *ngFor="let item of DanhMucChucDanhService.list001;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucChungChiID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chứng chỉ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select" name="DanhMucChungChiID"
                                                                [(ngModel)]="element.DanhMucChungChiID">
                                                                <option
                                                                    *ngFor="let item of DanhMucChungChiService.list001;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NamKinhNghiem">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kinh
                                                            nghiệm
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number" placeholder="0"
                                                                name="NamKinhNghiem{{element.ID}}"
                                                                [(ngModel)]="element.NamKinhNghiem"
                                                                style="text-align: right;">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucThanhVienSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucThanhVienDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucThanhVienService.displayColumns002">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucThanhVienService.displayColumns002;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucThanhVienPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ThanhVienKhoDetail" role="tabpanel">
                                <div class="card mb-4 card-primary">
                                    <div class="card-header">
                                        <h5 class="card-title text-white bg-primary">Kho hàng
                                            <span *ngIf="ThanhVienKhoService.list">({{ThanhVienKhoService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body bg-primary">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ThanhVienKhoSort="matSort"
                                                    [dataSource]="ThanhVienKhoService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ThanhVienKhoPaginator.pageSize *
                                                            ThanhVienKhoPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: center;">
                                                            {{element.ID}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucNguyenVatLieuID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hàng hóa
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div *ngFor="let item of DanhMucChucDanhService.list001;">
                                                                <div *ngIf="item.ID==element.DanhMucNguyenVatLieuID">
                                                                    {{item.Name}}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoLuongNhap">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nhập
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.SoLuongNhap |
                                                            number:
                                                            '1.2-2'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoLuongXuat">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Xuất
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.SoLuongXuat |
                                                            number:
                                                            '1.2-2'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoLuongTon">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tồn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.SoLuongTon |
                                                            number:
                                                            '1.2-2'}}
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThanhVienKhoService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThanhVienKhoService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ThanhVienKhoPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>