<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-shield-check fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">{{ThanhVienService.formData.Name}}</h2>
            <h6 class="m-0 text-dark fw-light">
                Thành viên
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
            <a class="btn btn-danger" title="Đóng" (click)="Close()">
                <i class="bi bi-x-lg"></i>
            </a>
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ThanhVienCoQuanQuanLyDetail" data-bs-toggle="tab" href="#ThanhVienCoQuanQuanLyDetail"
                                    role="tab" aria-controls="ThanhVienCoQuanQuanLyDetail" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Thông tin</span>
                                </a>
                            </li>                            
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ThanhVienDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <a style="width: 100%;" title="Lưu thay đổi" (click)="ThanhVienSave()"
                                            class="btn btn-info"><i class="bi bi-sd-card"></i></a>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">                                        
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="Active"
                                                [(ngModel)]="ThanhVienService.formData.Active" />
                                            <label class="form-check-label" for="Active">Kích hoạt</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Họ tên</label>
                                            <input name="Name" [(ngModel)]="ThanhVienService.formData.Name"
                                                placeholder="Họ tên" type="text" class="form-control">
                                        </div>    
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tài khoản</label>
                                            <input name="TaiKhoan" [(ngModel)]="ThanhVienService.formData.TaiKhoan"
                                                placeholder="Tài khoản" type="text" class="form-control">
                                        </div>                                  
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Điện thoại</label>
                                            <input name="DienThoai" [(ngModel)]="ThanhVienService.formData.DienThoai"
                                                placeholder="Điện thoại" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Email</label>
                                            <input name="Email" [(ngModel)]="ThanhVienService.formData.Email"
                                                placeholder="Email" type="text" class="form-control">
                                        </div>    
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Địa chỉ</label>
                                            <input name="DiaChi" [(ngModel)]="ThanhVienService.formData.DiaChi"
                                                placeholder="Địa chỉ" type="text" class="form-control">
                                        </div>
                                        <!-- <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ấp thôn</label>
                                            <input name="ApThon" [(ngModel)]="ThanhVienService.formData.ApThon"
                                                placeholder="Ấp thôn" type="text" class="form-control">
                                        </div>                            -->
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Đơn vị công tác</label>
                                            <input name="Description"
                                                [(ngModel)]="ThanhVienService.formData.Description" placeholder="Đơn vị công tác"
                                                type="text" class="form-control">
                                        </div>                                           
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Chức danh</label>
                                            <input name="Note"
                                                [(ngModel)]="ThanhVienService.formData.Note" placeholder="Chức danh"
                                                type="text" class="form-control">
                                        </div>                                      
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tỉnh thành</label>
                                            <mat-select class="form-control" name="DanhMucTinhThanhID"
                                                (selectionChange)="DanhMucQuanHuyenGetToList()"
                                                [(ngModel)]="ThanhVienService.formData.DanhMucTinhThanhID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucTinhThanhFilter($event.target.value)">
                                                <mat-option *ngFor="let item of DanhMucTinhThanhService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Quận huyện</label>
                                            <mat-select class="form-control" name="DanhMucQuanHuyenID"
                                                (selectionChange)="DanhMucXaPhuongGetToList()"
                                                [(ngModel)]="ThanhVienService.formData.DanhMucQuanHuyenID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucQuanHuyenFilter($event.target.value)">
                                                <mat-option *ngFor="let item of DanhMucQuanHuyenService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Xã phường</label>
                                            <mat-select class="form-control" name="DanhMucXaPhuongID"
                                                [(ngModel)]="ThanhVienService.formData.DanhMucXaPhuongID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucXaPhuongFilter($event.target.value)">
                                                <mat-option *ngFor="let item of DanhMucXaPhuongService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <!-- <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">URL</label>
                                            <input name="TypeName"
                                                [(ngModel)]="ThanhVienService.formData.TypeName" placeholder="URL"
                                                type="text" class="form-control">
                                        </div>                                         -->
                                    </div>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>