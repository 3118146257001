<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-hypnotize fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Tư vấn</h2>
            <h6 class="m-0 text-dark fw-light">
                Hợp tác xã
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">                                                       
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-DanhMucNguyenVatLieu" data-bs-toggle="tab"
                                    href="#DanhMucNguyenVatLieu" role="tab" aria-controls="DanhMucNguyenVatLieu"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Thuốc BVTV</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-DanhMucSanPhamGiong" data-bs-toggle="tab"
                                    href="#DanhMucSanPhamGiong" role="tab" aria-controls="DanhMucSanPhamGiong"
                                    aria-selected="false">
                                    <span class="badge bg-warning" style="font-size: 16px;">Giống</span>
                                </a>
                            </li>    
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucError" data-bs-toggle="tab" href="#ToChucError"
                                    role="tab" aria-controls="ToChucError" aria-selected="false">
                                    <span class="badge bg-success" style="font-size: 16px;">Lỗi địa chỉ</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">                                                      
                            <div class="tab-pane fade show active" id="DanhMucNguyenVatLieu" role="tabpanel">
                                <div class="row gx-4">
                                    <!-- <div class="col-lg-4 col-sm-12 col-12">
                                        <select class="form-select" name="DanhMucNguyenVatLieuPhanLoaiID"
                                            [(ngModel)]="DanhMucNguyenVatLieuPhanLoaiID">
                                            <option [value]="0">
                                                Tất cả (Cho phép + Cấm)</option>
                                            <option [value]="-1">
                                                Theo Cho phép + Cấm</option>
                                            <option *ngFor="let item of DanhMucNguyenVatLieuPhanLoaiService.list001;"
                                                [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="IsChoPhep"
                                                [(ngModel)]="IsChoPhep" (change)="onIsChoPhepChange()" />
                                            <label class="form-check-label" for="IsChoPhep">Cho phép</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="IsCam"
                                                [(ngModel)]="IsCam" (change)="onIsCamChange()" />
                                            <label class="form-check-label" for="IsCam">Cấm</label>
                                        </div>
                                    </div> -->
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="DanhMucNguyenVatLieuService.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="DanhMucNguyenVatLieuSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" (click)="DanhMucNguyenVatLieuSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        <!-- <a class="btn btn-success" title="Download excel"
                                            (click)="DanhMucNguyenVatLieuExportExcel()"><i
                                                class="bi bi-cloud-download"></i></a>&nbsp;&nbsp;&nbsp;
                                        <a class="btn btn-success" title="Báo cáo"
                                            (click)="DanhMucNguyenVatLieuExportHTML()"><i
                                                class="bi bi-printer"></i></a>&nbsp;&nbsp;&nbsp; -->
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Vật tư
                                            <span
                                                *ngIf="DanhMucNguyenVatLieuService.list">({{DanhMucNguyenVatLieuService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #DanhMucNguyenVatLieuSort="matSort"
                                                    [dataSource]="DanhMucNguyenVatLieuService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{DanhMucNguyenVatLieuPaginator.pageSize *
                                                            DanhMucNguyenVatLieuPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ParentID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phân loại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Loại hình</label>
                                                                    {{element.ParentName}}
                                                                    <!-- <select class="form-select" name="element.ParentID"
                                                                        [(ngModel)]="element.ParentID">
                                                                        <option
                                                                            *ngFor="let item of DanhMucNguyenVatLieuPhanLoaiService.list;"
                                                                            [value]="item.ID">
                                                                            {{item.Name}}</option>
                                                                    </select> -->
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Chức năng</label>
                                                                    {{element.FUNCTIONName}}
                                                                    <!-- <select class="form-select"
                                                                        name="element.FUNCTIONID"
                                                                        [(ngModel)]="element.FUNCTIONID">
                                                                        <option
                                                                            *ngFor="let item of DanhMucNguyenVatLieuPhanLoaiService.list;"
                                                                            [value]="item.ID">
                                                                            {{item.Name}}</option>
                                                                    </select> -->
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Thông số
                                                            kỹ thuật
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tên thương
                                                                        phẩm</label>
                                                                    {{element.Name}}
                                                                    <!-- <input class="form-control" type="text"
                                                                        placeholder="Tên thương phẩm"
                                                                        name="Name{{element.ID}}"
                                                                        [(ngModel)]="element.Name"> -->
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Hoạt chất</label>
                                                                    {{element.Code}}
                                                                    <!-- <input class="form-control" type="text"
                                                                        placeholder="Hoạt chất"
                                                                        name="Code{{element.ID}}"
                                                                        [(ngModel)]="element.Code"> -->
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Phòng trừ</label>
                                                                    {{element.Description}}
                                                                    <!-- <input class="form-control" type="text"
                                                                        placeholder="Phòng trừ"
                                                                        name="Description{{element.ID}}"
                                                                        [(ngModel)]="element.Description"> -->
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ToChucID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Pháp lý
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Đăng ký</label>
                                                                    {{element.ToChucName}}
                                                                    <!-- <select class="form-select" name="element.ToChucID"
                                                                        [(ngModel)]="element.ToChucID">
                                                                        <option *ngFor="let item of ToChucService.list;"
                                                                            [value]="item.ID">
                                                                            {{item.Name}}</option>
                                                                    </select> -->
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Pháp lý</label>
                                                                    {{element.Display}}
                                                                    <!-- <input class="form-control" type="text"
                                                                        placeholder="Pháp lý"
                                                                        name="Display{{element.ID}}"
                                                                        [(ngModel)]="element.Display"> -->
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline mb-6">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsChoPhep{{element.ID}}"
                                                                            [(ngModel)]="element.IsChoPhep"
                                                                            (change)="onDanhMucNguyenVatLieuIsChoPhepChange(element)">
                                                                        <label class="form-check-label"
                                                                            for="IsChoPhep">Cho phép</label>
                                                                    </div>
                                                                    <div class="form-check form-check-inline mb-6">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsCam{{element.ID}}"
                                                                            [(ngModel)]="element.IsCam"
                                                                            (change)="onDanhMucNguyenVatLieuIsCamChange(element)">
                                                                        <label class="form-check-label"
                                                                            for="IsCam">Cấm</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <!-- <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="DanhMucNguyenVatLieuSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="DanhMucNguyenVatLieuDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container> -->
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="DanhMucNguyenVatLieuService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: DanhMucNguyenVatLieuService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #DanhMucNguyenVatLieuPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="2"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                           
                            <div class="tab-pane fade" id="DanhMucSanPhamGiong" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="DanhMucSanPhamGiongService.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="DanhMucSanPhamGiongSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" (click)="DanhMucSanPhamGiongSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        <a class="btn btn-success" title="Thêm mới" (click)="DanhMucSanPhamGiongAdd(0)"><i
                                                class="bi bi-plus"></i></a>
                                    </div>
                                </div>
                                <div class="card mb-4 card-warning">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Giống
                                            <span
                                                *ngIf="DanhMucSanPhamGiongService.list">({{DanhMucSanPhamGiongService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #DanhMucSanPhamGiongSort="matSort"
                                                    [dataSource]="DanhMucSanPhamGiongService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{DanhMucSanPhamGiongPaginator.pageSize *
                                                            DanhMucSanPhamGiongPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ID}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã giống
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Code}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TypeName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Số bằng
                                                            bảo hộ</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TypeName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tên giống
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.Name}}"
                                                                class="link-primary"
                                                                (click)="DanhMucSanPhamGiongAdd(element.ID)"><b>{{element.Name}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Display">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Loài cây
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Display}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayHieuLuc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hiệu lực
                                                            (Tháng/Ngày/Năm)</th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.NgayHieuLuc | date:'MM/dd/yyyy'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tình trạng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Note}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsBaoHo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Bảo hộ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="IsBaoHo{{element.ID}}"
                                                                    [(ngModel)]="element.IsBaoHo">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <!-- <a class="btn btn-danger"
                                                                (click)="DanhMucSanPhamGiongDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a> -->
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="DanhMucSanPhamGiongService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: DanhMucSanPhamGiongService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #DanhMucSanPhamGiongPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucError" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucService.SearchString" placeholder="Tìm ..."
                                            class="form-control" (keyup.enter)="ToChucErrorSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" (click)="ToChucErrorSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="card mb-4 card-success">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Lỗi địa chỉ
                                            <span *ngIf="ToChucService.list0001">({{ToChucService.list0001.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucErrorSort="matSort"
                                                    [dataSource]="ToChucErrorDataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucErrorPaginator.pageSize *
                                                            ToChucErrorPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã số thuế
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Code}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.Name}}"
                                                                class="link-primary"
                                                                (click)="ToChucAdd(element.ID)"><b>{{element.Name}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DienThoai">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DienThoai}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DienTichSanXuatHa">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Diện tích
                                                            (ha)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.DienTichSanXuatHa |
                                                            number:
                                                            '1.2-2'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucTinhThanhName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tỉnh thành
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DanhMucTinhThanhName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucQuanHuyenName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Quận huyện
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DanhMucQuanHuyenName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucXaPhuongName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Xã phường
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DanhMucXaPhuongName}}
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucErrorPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>