import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToChucQuyTrinhSanXuatChiTiet } from 'src/app/shared/ToChucQuyTrinhSanXuatChiTiet.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ToChucQuyTrinhSanXuatChiTietService extends BaseService{
    list: ToChucQuyTrinhSanXuatChiTiet[] | undefined;
    list001: ToChucQuyTrinhSanXuatChiTiet[] | undefined;
    formData!: ToChucQuyTrinhSanXuatChiTiet;    


    list002: ToChucQuyTrinhSanXuatChiTiet[] | undefined;
    list003: ToChucQuyTrinhSanXuatChiTiet[] | undefined;
    list004: ToChucQuyTrinhSanXuatChiTiet[] | undefined;
    list005: ToChucQuyTrinhSanXuatChiTiet[] | undefined;
    list006: ToChucQuyTrinhSanXuatChiTiet[] | undefined;
    list007: ToChucQuyTrinhSanXuatChiTiet[] | undefined;
    displayColumns001: string[] = ['STT', 'DanhMucQuyTrinhSanXuatID', 'DanhMucPhanDoanSanXuatID', 'SoLuong', 'Note', 'Active', 'Save'];
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "ToChucQuyTrinhSanXuatChiTiet";
    }

    GetByToChucID_DanhMucSanPhamIDToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByToChucID_DanhMucSanPhamIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetByToChucID_DanhMucSanPhamID_DanhMucQuyTrinhSanXuatPhanLoaiIDToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByToChucID_DanhMucSanPhamID_DanhMucQuyTrinhSanXuatPhanLoaiIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetByParentID_DanhMucQuyTrinhSanXuatPhanLoaiIDToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByParentID_DanhMucQuyTrinhSanXuatPhanLoaiIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
}

