import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DanhMucVuMua } from 'src/app/shared/DanhMucVuMua.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class DanhMucVuMuaService extends BaseService{

    list: DanhMucVuMua[] | undefined;
    list001: DanhMucVuMua[] | undefined;
    formData!: DanhMucVuMua;     

    displayColumns001: string[] = ['STT', 'Code', 'Name', 'ThangBatDau', 'ThangKetThuc', 'SortOrder',  'Active', 'Save'];   

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "DanhMucVuMua";
    }
}

