import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ThoiTiet } from 'src/app/shared/ThoiTiet.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ThoiTietService extends BaseService{

    list: ThoiTiet[] | undefined;
    list001: ThoiTiet[] | undefined;
    formData!: ThoiTiet;    

    displayColumns001: string[] = ['STT', 'NgayGhiNhan', 'Name', 'TongQuan', 'NhietDo', 'DoAm', 'HuongGio'];   
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "ThoiTiet";
    }
    GetByDanhMucTinhThanhID_NgayGhiNhanToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByDanhMucTinhThanhID_NgayGhiNhanToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetByNowToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByNowToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
}

