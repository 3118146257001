<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-shield-check fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Phân quyền chức năng</h2>
            <h6 class="m-0 text-dark fw-light">
                Phân quyền
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">                      
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ThanhVienPhanQuyenChucNang" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <label class="form-label">Thành viên</label>
                                        <mat-select class="form-control" name="parentID" [(ngModel)]="parentID">
                                            <input placeholder="Tìm..." class="form-control"
                                                (keyup)="ThanhVienFilter($event.target.value)">
                                            <mat-option *ngFor="let item of ThanhVienService.list001"
                                                [value]="item.ID">
                                                {{item.ID}} - {{item.Name}} - {{item.TaiKhoan}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <div>
                                            <a class="btn btn-success" title="Tìm" (click)="ThanhVienPhanQuyenChucNangSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">           
                                        <br/>                        
                                        <div class="form-check form-check-inline">                                            
                                            <input type="checkbox" class="form-check-input" name="ActiveAll" (change)="ThanhVienPhanQuyenChucNangActiveAllChange()"
                                                [(ngModel)]="activeAll" />
                                            <label class="form-check-label" for="ActiveAll">Tất cả</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Phân quyền chức năng
                                            <span
                                                *ngIf="ThanhVienPhanQuyenChucNangService.list">({{ThanhVienPhanQuyenChucNangService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ThanhVienPhanQuyenChucNangSort="matSort"
                                                    [dataSource]="ThanhVienPhanQuyenChucNangService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ThanhVienPhanQuyenChucNangPaginator.pageSize *
                                                            ThanhVienPhanQuyenChucNangPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chức năng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Name}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kích hoạt
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox" (change)="ThanhVienPhanQuyenChucNangActiveChange(element)"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThanhVienPhanQuyenChucNangService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThanhVienPhanQuyenChucNangService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ThanhVienPhanQuyenChucNangPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>