import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucXaPhuong } from 'src/app/shared/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';
import { DanhMucDuAn } from 'src/app/shared/DanhMucDuAn.model';
import { DanhMucDuAnService } from 'src/app/shared/DanhMucDuAn.service';
import { DanhMucThanhVien } from 'src/app/shared/DanhMucThanhVien.model';
import { DanhMucThanhVienService } from 'src/app/shared/DanhMucThanhVien.service';

import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';
import { ToChucThanhVien } from 'src/app/shared/ToChucThanhVien.model';
import { ToChucThanhVienService } from 'src/app/shared/ToChucThanhVien.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { ThanhVienCoQuanQuanLyDetailComponent } from '../thanh-vien-co-quan-quan-ly-detail/thanh-vien-co-quan-quan-ly-detail.component';
import { ThanhVienNongDanDetailComponent } from '../thanh-vien-nong-dan-detail/thanh-vien-nong-dan-detail.component';
import { ThanhVienLichSuTruyCap } from 'src/app/shared/ThanhVienLichSuTruyCap.model';
import { ThanhVienLichSuTruyCapService } from 'src/app/shared/ThanhVienLichSuTruyCap.service';


import { ToChucDetailComponent } from '../to-chuc-detail/to-chuc-detail.component';

import { ThongBao } from 'src/app/shared/ThongBao.model';
import { ThongBaoService } from 'src/app/shared/ThongBao.service';
import { ThongBaoDetailComponent } from '../thong-bao-detail/thong-bao-detail.component';

import { TaiLieu } from 'src/app/shared/TaiLieu.model';
import { TaiLieuService } from 'src/app/shared/TaiLieu.service';

import { BaiViet } from 'src/app/shared/BaiViet.model';
import { BaiVietService } from 'src/app/shared/BaiViet.service';
import { BaiVietDetailComponent } from '../bai-viet-detail/bai-viet-detail.component';
import { ThanhVienDetailComponent } from '../thanh-vien-detail/thanh-vien-detail.component';

@Component({
  selector: 'app-thanh-vien',
  templateUrl: './thanh-vien.component.html',
  styleUrls: ['./thanh-vien.component.css']
})
export class ThanhVienComponent implements OnInit {


  @ViewChild('ThanhVienSort') ThanhVienSort: MatSort;
  @ViewChild('ThanhVienPaginator') ThanhVienPaginator: MatPaginator;

  ThanhVienQuanTriDataSource: MatTableDataSource<any>;
  @ViewChild('ThanhVienQuanTriSort') ThanhVienQuanTriSort: MatSort;
  @ViewChild('ThanhVienQuanTriPaginator') ThanhVienQuanTriPaginator: MatPaginator;

  ThanhVienCoQuanQuanLyDataSource: MatTableDataSource<any>;
  @ViewChild('ThanhVienCoQuanQuanLySort') ThanhVienCoQuanQuanLySort: MatSort;
  @ViewChild('ThanhVienCoQuanQuanLyPaginator') ThanhVienCoQuanQuanLyPaginator: MatPaginator;

  ThanhVienChuyenGiaDataSource: MatTableDataSource<any>;
  @ViewChild('ThanhVienChuyenGiaSort') ThanhVienChuyenGiaSort: MatSort;
  @ViewChild('ThanhVienChuyenGiaPaginator') ThanhVienChuyenGiaPaginator: MatPaginator;

  ThanhVienNongDanDataSource: MatTableDataSource<any>;
  @ViewChild('ThanhVienNongDanSort') ThanhVienNongDanSort: MatSort;
  @ViewChild('ThanhVienNongDanPaginator') ThanhVienNongDanPaginator: MatPaginator;

  @ViewChild('ThongBaoSort') ThongBaoSort: MatSort;
  @ViewChild('ThongBaoPaginator') ThongBaoPaginator: MatPaginator;

  @ViewChild('TaiLieuSort') TaiLieuSort: MatSort;
  @ViewChild('TaiLieuPaginator') TaiLieuPaginator: MatPaginator;

  @ViewChild('BaiVietSort') BaiVietSort: MatSort;
  @ViewChild('BaiVietPaginator') BaiVietPaginator: MatPaginator;

  @ViewChild('ThanhVienLichSuTruyCapSort') ThanhVienLichSuTruyCapSort: MatSort;
  @ViewChild('ThanhVienLichSuTruyCapPaginator') ThanhVienLichSuTruyCapPaginator: MatPaginator;

  @ViewChild('ThanhVienTrucTuyenSort') ThanhVienTrucTuyenSort: MatSort;
  @ViewChild('ThanhVienTrucTuyenPaginator') ThanhVienTrucTuyenPaginator: MatPaginator;

  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  DanhMucTinhThanhID: number = environment.DanhMucTinhThanhID;
  DanhMucQuanHuyenID: number = environment.InitializationNumber;
  DanhMucXaPhuongID: number = environment.InitializationNumber;
  parentID: number = environment.ThanhVienQuanTriID;

  ActiveAll: boolean = true;
  Active: boolean = true;

  constructor(
    private dialog: MatDialog,

    public NotificationService: NotificationService,

    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,
    public DanhMucDuAnService: DanhMucDuAnService,
    public DanhMucThanhVienService: DanhMucThanhVienService,

    public ToChucService: ToChucService,
    public ToChucThanhVienService: ToChucThanhVienService,

    public ThanhVienService: ThanhVienService,
    public ThanhVienLichSuTruyCapService: ThanhVienLichSuTruyCapService,

    public ThongBaoService: ThongBaoService,
    public TaiLieuService: TaiLieuService,
    public BaiVietService: BaiVietService,
  ) { }

  ngOnInit(): void {
    this.ThanhVienService.baseParameter.Active = true;
    this.DanhMucThanhVienGetToList();
    // this.DanhMucTinhThanhGetToList();
    // this.DanhMucDuAnGetToList();
    // this.ToChucGetToList();
    // this.ToChucThanhVienGetToList();    
    this.ThanhVienLichSuTruyCapService.baseParameter.BatDau = new Date();
    this.ThanhVienLichSuTruyCapService.baseParameter.KetThuc = new Date();
  }
  KhanCap() {
    this.isShowLoading = true;
    this.ThanhVienService.baseParameter.Active = this.ActiveAll;
    this.ThanhVienService.UpdateItemsByActiveAsync().subscribe(
      res => {
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  KhanCap001() {
    this.isShowLoading = true;
    this.ThanhVienService.baseParameter.ParentID = this.parentID;
    this.ThanhVienService.baseParameter.Active = this.Active;
    this.ThanhVienService.UpdateItemsByActive_ParentIDAsync().subscribe(
      res => {
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  onBatDau(value) {
    this.ThanhVienLichSuTruyCapService.baseParameter.BatDau = new Date(value);
  }
  onKetThuc(value) {
    this.ThanhVienLichSuTruyCapService.baseParameter.KetThuc = new Date(value);
  }
  ThanhVienLichSuTruyCapGetToListAsync() {
    this.isShowLoading = true;
    this.ThanhVienLichSuTruyCapService.GetBySearchString_BatDau_KetThucToListAsync().subscribe(
      res => {
        this.ThanhVienLichSuTruyCapService.list = res as ThanhVienLichSuTruyCap[];
        this.ThanhVienLichSuTruyCapService.DataSource = new MatTableDataSource(this.ThanhVienLichSuTruyCapService.list);
        this.ThanhVienLichSuTruyCapService.DataSource.sort = this.ThanhVienLichSuTruyCapSort;
        this.ThanhVienLichSuTruyCapService.DataSource.paginator = this.ThanhVienLichSuTruyCapPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ThanhVienLichSuTruyCapSearch() {
    // if (this.ThanhVienLichSuTruyCapService.baseParameter.SearchString.length > 0) {
    //   this.ThanhVienLichSuTruyCapService.DataSource.filter = this.searchString.toLowerCase();
    // }
    // else {
    //   this.ThanhVienLichSuTruyCapGetToListAsync();
    // }

    this.ThanhVienLichSuTruyCapGetToListAsync();
  }


  DanhMucDuAnGetToList() {
    this.DanhMucDuAnService.ComponentGetAllToListAsync();
  }

  ToChucGetToList() {
    this.ToChucService.baseParameter.ParentID = environment.HopTacXaID;
    this.ToChucService.ComponentGetByParentIDToListAsync();
  }
  ToChucThanhVienGetToList() {
    this.ToChucThanhVienService.ComponentGetAllToListAsync();
  }

  ToChucAdd(ID: any) {
    this.ToChucService.baseParameter.ID = ID;
    this.ToChucService.GetByIDAsync().subscribe(
      res => {
        this.ToChucService.formData = res as ToChuc;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ToChucDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
      },
      err => {
      }
    );
  }

  NongDanAdd(ID: any) {
    this.ThanhVienService.baseParameter.ID = ID;
    this.ThanhVienService.GetByIDAsync().subscribe(
      res => {
        this.ThanhVienService.formData = res as ThanhVien;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ThanhVienNongDanDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.NongDanSearch();
        });
      },
      err => {
      }
    );
  }

  CoQuanQuanLyAdd(ID: any) {
    this.ThanhVienService.baseParameter.ID = ID;
    this.ThanhVienService.GetByIDAsync().subscribe(
      res => {
        this.ThanhVienService.formData = res as ThanhVien;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ThanhVienDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.CoQuanQuanLySearch();
        });
      },
      err => {
      }
    );
  }

  ThanhVienAdd(ID: any) {
    this.ThanhVienService.baseParameter.ID = ID;
    this.ThanhVienService.GetByIDAsync().subscribe(
      res => {
        this.ThanhVienService.formData = res as ThanhVien;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ThanhVienDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.ThanhVienSearch();
        });
      },
      err => {
      }
    );
  }

  ChuyenGiaAdd(ID: any) {
    this.ThanhVienService.baseParameter.ID = ID;
    this.ThanhVienService.GetByIDAsync().subscribe(
      res => {
        this.ThanhVienService.formData = res as ThanhVien;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ThanhVienCoQuanQuanLyDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.ChuyenGiaSearch();
        });
      },
      err => {
      }
    );
  }

  NongDanSearch() {
    this.NongDanGetToList();
  }
  NongDanGetToList() {
    this.isShowLoading = true;
    this.ThanhVienService.baseParameter.ParentID = environment.ThanhVienNongDanID;
    this.ThanhVienService.baseParameter.SearchString = this.ThanhVienService.SearchString;
    this.ThanhVienService.baseParameter.DanhMucTinhThanhID = this.DanhMucTinhThanhID;
    this.ThanhVienService.baseParameter.DanhMucQuanHuyenID = this.DanhMucQuanHuyenID;
    this.ThanhVienService.baseParameter.DanhMucXaPhuongID = this.DanhMucXaPhuongID;
    this.ThanhVienService.GetByParentID_SearchString_DanhMucTinhThanhID_DanhMucQuanHuyenID_DanhMucXaPhuongIDToListAsync().subscribe(
      res => {
        this.ThanhVienService.list003 = (res as ThanhVien[]);
        for (let i = 0; i < this.ThanhVienService.list003.length; i++) {
          for (let j = 0; j < this.ToChucThanhVienService.list001.length; j++) {
            if (this.ThanhVienService.list003[i].ID == this.ToChucThanhVienService.list001[j].ThanhVienID) {
              for (let k = 0; k < this.ToChucService.list001.length; k++) {
                if (this.ToChucService.list001[k].ID == this.ToChucThanhVienService.list001[j].ParentID) {
                  this.ThanhVienService.list003[i].ParentID = this.ToChucService.list001[k].ID;
                  this.ThanhVienService.list003[i].TypeName = this.ToChucService.list001[k].Name;
                }
              }
            }
          }
        }
        this.ThanhVienNongDanDataSource = new MatTableDataSource(this.ThanhVienService.list003);
        this.ThanhVienNongDanDataSource.sort = this.ThanhVienNongDanSort;
        this.ThanhVienNongDanDataSource.paginator = this.ThanhVienNongDanPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  CoQuanQuanLySearch() {
    this.CoQuanQuanLyGetToList();
  }
  CoQuanQuanLyGetToList() {
    this.isShowLoading = true;
    this.ThanhVienService.baseParameter.ParentID = environment.ThanhVienCoQuanQuanLyID;
    this.ThanhVienService.baseParameter.SearchString = this.ThanhVienService.SearchString;
    this.ThanhVienService.baseParameter.DanhMucTinhThanhID = this.DanhMucTinhThanhID;
    this.ThanhVienService.baseParameter.DanhMucQuanHuyenID = environment.InitializationNumber;
    this.ThanhVienService.baseParameter.DanhMucXaPhuongID = environment.InitializationNumber;
    this.ThanhVienService.GetByParentID_SearchString_DanhMucTinhThanhID_DanhMucQuanHuyenID_DanhMucXaPhuongIDToListAsync().subscribe(
      res => {
        this.ThanhVienService.list002 = (res as ThanhVien[]);
        this.ThanhVienCoQuanQuanLyDataSource = new MatTableDataSource(this.ThanhVienService.list002);
        this.ThanhVienCoQuanQuanLyDataSource.sort = this.ThanhVienCoQuanQuanLySort;
        this.ThanhVienCoQuanQuanLyDataSource.paginator = this.ThanhVienCoQuanQuanLyPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ChuyenGiaSearch() {
    this.ChuyenGiaGetToList();
  }
  ChuyenGiaGetToList() {
    this.isShowLoading = true;
    this.ThanhVienService.baseParameter.ParentID = environment.ThanhVienChuyenGiaID;
    this.ThanhVienService.baseParameter.SearchString = this.ThanhVienService.SearchString;
    this.ThanhVienService.baseParameter.DanhMucTinhThanhID = this.DanhMucTinhThanhID;
    this.ThanhVienService.baseParameter.DanhMucQuanHuyenID = environment.InitializationNumber;
    this.ThanhVienService.baseParameter.DanhMucXaPhuongID = environment.InitializationNumber;
    this.ThanhVienService.GetByParentID_SearchString_DanhMucTinhThanhID_DanhMucQuanHuyenID_DanhMucXaPhuongIDToListAsync().subscribe(
      res => {
        this.ThanhVienService.listChuyenGia = (res as ThanhVien[]);
        this.ThanhVienChuyenGiaDataSource = new MatTableDataSource(this.ThanhVienService.listChuyenGia);
        this.ThanhVienChuyenGiaDataSource.sort = this.ThanhVienChuyenGiaSort;
        this.ThanhVienChuyenGiaDataSource.paginator = this.ThanhVienChuyenGiaPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  QuanTriSearch() {
    if (this.ThanhVienService.SearchString.length > 0) {
      this.ThanhVienService.DataSource.filter = this.ThanhVienService.SearchString.toLowerCase();
    }
    else {
      this.QuanTriGetToList();
    }
  }
  QuanTriGetToList() {
    this.isShowLoading = true;
    this.ThanhVienService.baseParameter.ParentID = environment.ThanhVienQuanTriID;
    this.ThanhVienService.GetByParentIDToListAsync().subscribe(
      res => {
        this.ThanhVienService.listQuanTri = (res as ThanhVien[]);
        this.ThanhVienQuanTriDataSource = new MatTableDataSource(this.ThanhVienService.listQuanTri);
        this.ThanhVienQuanTriDataSource.sort = this.ThanhVienQuanTriSort;
        this.ThanhVienQuanTriDataSource.paginator = this.ThanhVienQuanTriPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ThanhVienSearch() {
    this.ThanhVienGetToList();
  }
  ThanhVienGetToList() {
    this.isShowLoading = true;
    this.ThanhVienService.baseParameter.ParentID = this.parentID;
    this.ThanhVienService.baseParameter.SearchString = this.ThanhVienService.SearchString;
    this.ThanhVienService.GetByParentIDOrSearchStringToListAsync().subscribe(
      res => {
        this.ThanhVienService.list = (res as ThanhVien[]);
        this.ThanhVienService.DataSource = new MatTableDataSource(this.ThanhVienService.list);
        this.ThanhVienService.DataSource.sort = this.ThanhVienSort;
        this.ThanhVienService.DataSource.paginator = this.ThanhVienPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ThanhVienTrucTuyenSearch() {
    this.isShowLoading = true;
    this.ThanhVienService.GetByTrucTuyenAsync().subscribe(
      res => {
        this.ThanhVienService.listTrucTuyen = (res as ThanhVien[]);
        this.ThanhVienService.DataSourceTrucTuyen = new MatTableDataSource(this.ThanhVienService.listTrucTuyen);
        this.ThanhVienService.DataSourceTrucTuyen.sort = this.ThanhVienTrucTuyenSort;
        this.ThanhVienService.DataSourceTrucTuyen.paginator = this.ThanhVienTrucTuyenPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ThanhVienSave(element: ThanhVien) {
    element.ParentID = environment.ThanhVienQuanTriID;
    this.ThanhVienService.formData = element;
    this.ThanhVienService.baseParameter.ParentID = environment.ThanhVienQuanTriID;
    this.NotificationService.warn(this.ThanhVienService.ComponentSaveByParentID(this.ThanhVienSort, this.ThanhVienPaginator));
  }
  ThanhVienDelete(element: ThanhVien) {
    this.ThanhVienService.baseParameter.ID = element.ID;
    this.ThanhVienService.baseParameter.ParentID = environment.ThanhVienQuanTriID;
    this.NotificationService.warn(this.ThanhVienService.ComponentDeleteByParentID(this.ThanhVienSort, this.ThanhVienPaginator));
  }

  DanhMucTinhThanhGetToList() {
    this.isShowLoading = true;
    this.DanhMucTinhThanhService.baseParameter.Active = true;
    this.DanhMucTinhThanhService.GetSQLByThanhVienIDAndActiveToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.list = (res as DanhMucTinhThanh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucTinhThanhService.list001 = this.DanhMucTinhThanhService.list;
        if (this.DanhMucTinhThanhService.list) {
          if (this.DanhMucTinhThanhService.list.length) {
            this.DanhMucTinhThanhID = this.DanhMucTinhThanhService.list[0].ID;
          }
        }
        this.DanhMucQuanHuyenGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucQuanHuyenGetToList() {
    this.isShowLoading = true;
    this.DanhMucQuanHuyenService.baseParameter.ParentID = this.DanhMucTinhThanhID;
    this.DanhMucQuanHuyenService.GetSQLByThanhVienIDAndActiveAndParentIDToListAsync().subscribe(
      res => {
        this.DanhMucQuanHuyenService.list = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucQuanHuyenService.list001 = this.DanhMucQuanHuyenService.list;
        this.DanhMucXaPhuongGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucXaPhuongGetToList() {
    this.isShowLoading = true;
    this.DanhMucXaPhuongService.baseParameter.ParentID = this.DanhMucQuanHuyenID;
    this.DanhMucXaPhuongService.GetSQLByThanhVienIDAndActiveAndParentIDToListAsync().subscribe(
      res => {
        this.DanhMucXaPhuongService.list = (res as DanhMucXaPhuong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucXaPhuongService.list001 = this.DanhMucXaPhuongService.list;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  DanhMucTinhThanhFilter(searchString: string) {
    this.DanhMucTinhThanhService.Filter(searchString);
  }
  DanhMucQuanHuyenFilter(searchString: string) {
    this.DanhMucQuanHuyenService.Filter(searchString);
  }
  DanhMucXaPhuongFilter(searchString: string) {
    this.DanhMucXaPhuongService.Filter(searchString);
  }

  ThongBaoSearch() {
    this.ThongBaoService.SearchAllNotEmpty(this.ThongBaoSort, this.ThongBaoPaginator);
  }
  ThongBaoDelete(element: ThongBao) {
    this.ThongBaoService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ThongBaoService.ComponentDeleteAll(this.ThongBaoSort, this.ThongBaoPaginator));
  }
  ThongBaoAdd(ID: any) {
    this.ThongBaoService.baseParameter.ID = ID;
    this.ThongBaoService.GetByIDAsync().subscribe(
      res => {
        this.ThongBaoService.formData = res as ThongBao;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ThongBaoDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.ThongBaoSearch();
        });
      },
      err => {
      }
    );
  }

  BaiVietSearch() {
    this.BaiVietService.SearchAllNotEmpty(this.BaiVietSort, this.BaiVietPaginator);
  }
  BaiVietDelete(element: BaiViet) {
    this.ThongBaoService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.BaiVietService.ComponentDeleteAll(this.BaiVietSort, this.BaiVietPaginator));
  }
  BaiVietAdd(ID: any) {
    this.BaiVietService.baseParameter.ID = ID;
    this.BaiVietService.GetByIDAsync().subscribe(
      res => {
        this.BaiVietService.formData = res as BaiViet;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(BaiVietDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.BaiVietSearch();
        });
      },
      err => {
      }
    );
  }

  fileToUpload: any;
  fileToUpload0: File = null;
  changeFileName(element: TaiLieu, files: FileList) {
    if (files) {
      this.fileToUpload = files;
      this.fileToUpload0 = files.item(0);
      var reader = new FileReader();
      reader.onload = (event: any) => {
        element.FileName = event.target.result;
      };
      reader.readAsDataURL(this.fileToUpload0);
    }
  }
  TaiLieuSearch() {
    this.TaiLieuService.SearchAll(this.TaiLieuSort, this.TaiLieuPaginator);
  }
  TaiLieuDelete(element: TaiLieu) {
    this.TaiLieuService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.TaiLieuService.ComponentDeleteAll(this.TaiLieuSort, this.TaiLieuPaginator));
  }
  TaiLieuSave(element: TaiLieu) {
    this.isShowLoading = true;
    this.TaiLieuService.formData = element;
    this.TaiLieuService.fileToUpload = this.fileToUpload;
    this.TaiLieuService.SaveAndUploadFilesAsync().subscribe(
      res => {
        this.fileToUpload = null;
        this.fileToUpload0 = null;
        this.TaiLieuSearch();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucThanhVienGetToList() {
    this.isShowLoading = true;
    this.DanhMucThanhVienService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucThanhVienService.list = (res as DanhMucXaPhuong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.parentID = environment.ThanhVienQuanTriID;
        this.ThanhVienSearch();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
}
