<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-gear fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">{{BaiVietService.formData.Name}} [{{BaiVietService.formData.ID}}]</h2>
            <h6 class="m-0 text-dark fw-light">
                Bài viết
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
            <a class="btn btn-danger" title="Đóng" (click)="Close()">
                <i class="bi bi-x-lg"></i>
            </a>
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-BaiVietDetail" data-bs-toggle="tab"
                                    href="#BaiVietDetail" role="tab" aria-controls="BaiVietDetail" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">01: Bài viết</span>
                                </a>
                            </li>
                            <!-- <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-BaiVietThanhVien" data-bs-toggle="tab"
                                    href="#BaiVietThanhVien" role="tab" aria-controls="BaiVietThanhVien"
                                    aria-selected="false">
                                    <span class="badge bg-danger" style="font-size: 16px;">02: Phân phối</span>
                                </a>
                            </li> -->
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="BaiVietDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a style="width: 100%;" title="Lưu thay đổi" (click)="BaiVietSave()"
                                            class="btn btn-info"><i class="bi bi-sd-card"></i> Lưu thay đổi</a>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a style="width: 100%;" title="Thêm mới" (click)="BaiVietNew()"
                                            class="btn btn-success"><i class="bi bi-plus"></i> Thêm mới</a>
                                    </div>
                                    <div class="col-lg-8 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="Active"
                                                [(ngModel)]="BaiVietService.formData.Active" />
                                            <label class="form-check-label" for="Active">Kích hoạt</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tiêu đề</label>
                                            <input name="Name" [(ngModel)]="BaiVietService.formData.Name"
                                                placeholder="Tiêu đề" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Mô tả</label>
                                            <input name="Description" [(ngModel)]="BaiVietService.formData.Description"
                                                placeholder="Mô tả" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ghi chú</label>
                                            <input name="Note" [(ngModel)]="BaiVietService.formData.Note"
                                                placeholder="Ghi chú" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ngày đăng (Tháng/Ngày/Năm)</label>
                                            <input name="NgayGhiNhan" class="form-control" style="text-align: right;"
                                                [ngModel]="BaiVietService.formData.NgayGhiNhan | date:'yyyy-MM-dd'"
                                                (ngModelChange)="NgayGhiNhanChange($event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date">
                                        </div>
                                    </div>
                                    <div class="col-lg-8 col-sm-12 col-12">
                                        <ck-editor [config]="{'height': 400}" name="HTMLContent"
                                            [(ngModel)]="BaiVietService.formData.HTMLContent" skin="moono-lisa"
                                            language="en" [fullPage]="true"></ck-editor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>