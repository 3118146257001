import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';

import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucXaPhuong } from 'src/app/shared/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';

import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';
import { ToChucToaDo } from 'src/app/shared/ToChucToaDo.model';
import { ToChucToaDoService } from 'src/app/shared/ToChucToaDo.service';

import * as maplibregl from 'maplibre-gl';

@Component({
  selector: 'app-map-to-chuc-toa-do',
  templateUrl: './map-to-chuc-toa-do.component.html',
  styleUrls: ['./map-to-chuc-toa-do.component.css']
})
export class MapToChucToaDoComponent implements OnInit {

  isShowLoading: boolean = false;
  domainName = environment.DomainDestination;
  domainURL = environment.DomainURL;
  danhMucTinhThanhID: number = environment.InitializationNumber;
  danhMucQuanHuyenID: number = environment.InitializationNumber;
  danhMucXaPhuongID: number = environment.InitializationNumber;

  map: maplibregl.Map | undefined;

  @ViewChild('map')
  private mapContainer!: ElementRef<HTMLElement>;

  constructor(
    public NotificationService: NotificationService,

    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,

    public ToChucService: ToChucService,
    public ToChucToaDoService: ToChucToaDoService,
  ) { }

  ngOnInit(): void {    
  }

  ngAfterViewInit() {
    this.DanhMucTinhThanhGetToList();
    this.MapLoad();
  }

  ngOnDestroy() {
    this.map?.remove();
  }

  ToChucGetAllToList() {
    this.isShowLoading = true;
    this.ToChucService.baseParameter.ParentID = environment.HopTacXaID;
    this.ToChucService.baseParameter.DanhMucTinhThanhID = this.danhMucTinhThanhID;
    this.ToChucService.baseParameter.DanhMucQuanHuyenID = this.danhMucQuanHuyenID;
    this.ToChucService.baseParameter.DanhMucXaPhuongID = this.danhMucXaPhuongID;
    this.ToChucService.baseParameter.SearchString = this.ToChucService.SearchString;
    this.ToChucService.list = [];
    this.ToChucService.list001 = [];
    this.ToChucService.GetByParentID_SearchString_DanhMucTinhThanhID_DanhMucQuanHuyenID_DanhMucXaPhuongIDToListAsync().subscribe(
      res => {
        this.ToChucService.list = (res as ToChuc[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        this.ToChucService.list001 = this.ToChucService.list;
        if (this.ToChucService.list) {
          if (this.ToChucService.list.length > 0) {

          }
        }
        console.log();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucFilter(searchString: string) {
    searchString = searchString.trim();
    this.ToChucService.Filter(searchString);
  }

  onSave() {
    if (this.ToChucToaDoService.formData.ParentID > 0) {
    }
    else {
      this.NotificationService.warn("Vui lòng chọn Hợp tác xã");
    }
  }
  onDelete() {
    alert("Đã xóa bản đồ. Vui lòng chọn lại");
  }

 


  DanhMucTinhThanhGetToList() {
    this.isShowLoading = true;
    this.DanhMucTinhThanhService.baseParameter.Active = true;
    this.DanhMucTinhThanhService.GetSQLByThanhVienIDAndActiveToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.list = (res as DanhMucTinhThanh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucTinhThanhService.list001 = this.DanhMucTinhThanhService.list;
        if (this.DanhMucTinhThanhService.list) {
          if (this.DanhMucTinhThanhService.list.length) {
            this.danhMucTinhThanhID = this.DanhMucTinhThanhService.list[0].ID;
          }
        }
        this.DanhMucQuanHuyenGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucQuanHuyenGetToList() {
    this.isShowLoading = true;
    this.DanhMucQuanHuyenService.baseParameter.ParentID = this.danhMucTinhThanhID;
    this.DanhMucQuanHuyenService.GetSQLByThanhVienIDAndActiveAndParentIDToListAsync().subscribe(
      res => {
        this.DanhMucQuanHuyenService.list = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucQuanHuyenService.list001 = this.DanhMucQuanHuyenService.list;
        if (this.DanhMucQuanHuyenService.list) {
          if (this.DanhMucQuanHuyenService.list.length) {
            this.danhMucQuanHuyenID = this.DanhMucQuanHuyenService.list[0].ID;
          }
        }
        this.DanhMucXaPhuongGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucXaPhuongGetToList() {
    this.isShowLoading = true;
    this.DanhMucXaPhuongService.baseParameter.ParentID = this.danhMucQuanHuyenID;
    this.DanhMucXaPhuongService.GetSQLByThanhVienIDAndActiveAndParentIDToListAsync().subscribe(
      res => {
        this.DanhMucXaPhuongService.list = (res as DanhMucXaPhuong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucXaPhuongService.list001 = this.DanhMucXaPhuongService.list;
        if (this.DanhMucXaPhuongService.list) {
          if (this.DanhMucXaPhuongService.list.length) {
            this.danhMucXaPhuongID = this.DanhMucXaPhuongService.list[0].ID;
          }
        }
        this.ToChucGetAllToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  DanhMucTinhThanhFilter(searchString: string) {
    this.DanhMucTinhThanhService.Filter(searchString);
  }
  DanhMucQuanHuyenFilter(searchString: string) {
    this.DanhMucQuanHuyenService.Filter(searchString);
  }
  DanhMucXaPhuongFilter(searchString: string) {
    this.DanhMucXaPhuongService.Filter(searchString);
  }

  MapInitialization(longitude, latitude) {

    let zoom = environment.MapZoom;
    if (this.ToChucService.baseParameter.DanhMucTinhThanhID > 0) {
      zoom = 9;
    }
    if (this.ToChucService.baseParameter.SearchString.length > 0) {
      zoom = 11;
    }
    if ((latitude > 90) || (latitude == 0)) {
      latitude = environment.Latitude;
      longitude = environment.Longitude;
    }
    this.map = new maplibregl.Map({
      container: this.mapContainer.nativeElement,
      style: 'https://api.maptiler.com/maps/hybrid/style.json?key=' + environment.MaptilerAPIKey,
      center: [longitude, latitude],
      zoom: zoom,      
    });   
    
    this.map.addControl(
      new maplibregl.NavigationControl({
        visualizePitch: true,
        showZoom: true,
        showCompass: true
      })
    );

    this.map.on('load', () => {

      this.map.addSource("HoangSa", {
        "type": "image",
        "url": environment.DomainURL + "assets/image/HoangSa01.png",
        "coordinates": [
          [111.09665858054495, 17.432475898867523],
          [113.11720985517763, 17.38420482529338],
          [112.79285037220984, 15.643938718432054],
          [110.88537855035554, 15.672592116966598],
        ]
      });
      this.map.addLayer({
        "id": "HoangSa",
        "source": "HoangSa",
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

      this.map.addSource("TruongSa", {
        "type": "image",
        "url": environment.DomainURL + "assets/image/TruongSa01.png",
        "coordinates": [
          [112.32373278444106, 12.236103169381323],
          [117.4620551483049, 11.606334626304161],
          [115.59654957671216, 7.357025445897818],
          [110.62186805246108, 7.811210355974268],


        ]
      });
      this.map.addLayer({
        "id": "TruongSa",
        "source": "TruongSa",
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

    });
  }

  MapLoad() {
    this.MapInitialization(0, 0);    
  }
}
