import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { DanhMucDuAn } from 'src/app/shared/DanhMucDuAn.model';
import { DanhMucDuAnService } from 'src/app/shared/DanhMucDuAn.service';

import { TaiLieu } from 'src/app/shared/TaiLieu.model';
import { TaiLieuService } from 'src/app/shared/TaiLieu.service';
@Component({
  selector: 'app-tai-lieu',
  templateUrl: './tai-lieu.component.html',
  styleUrls: ['./tai-lieu.component.css']
})
export class TaiLieuComponent implements OnInit {

  @ViewChild('TaiLieuSort') TaiLieuSort: MatSort;
  @ViewChild('TaiLieuPaginator') TaiLieuPaginator: MatPaginator;

  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;

  constructor(
    private dialog: MatDialog,

    public NotificationService: NotificationService,

    public DanhMucDuAnService: DanhMucDuAnService,
    public TaiLieuService: TaiLieuService,
  ) { }

  ngOnInit(): void {
  }
  
  DanhMucDuAnGetToList() {
    this.DanhMucDuAnService.ComponentGetAllToListAsync();
  }


  fileToUpload: any;
  fileToUpload0: File = null;
  changeFileName(element: TaiLieu, files: FileList) {
    if (files) {
      this.fileToUpload = files;
      this.fileToUpload0 = files.item(0);
      var reader = new FileReader();
      reader.onload = (event: any) => {
        element.FileName = event.target.result;
      };
      reader.readAsDataURL(this.fileToUpload0);
    }
  }
  TaiLieuSearch() {
    this.TaiLieuService.SearchAll(this.TaiLieuSort, this.TaiLieuPaginator);
  }
  TaiLieuDelete(element: TaiLieu) {
    this.TaiLieuService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.TaiLieuService.ComponentDeleteAll(this.TaiLieuSort, this.TaiLieuPaginator));
  }
  TaiLieuSave(element: TaiLieu) {
    this.isShowLoading = true;
    this.TaiLieuService.formData = element;
    this.TaiLieuService.fileToUpload = this.fileToUpload;
    this.TaiLieuService.SaveAndUploadFilesAsync().subscribe(
      res => {
        this.fileToUpload = null;
        this.fileToUpload0 = null;
        this.TaiLieuSearch();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

}
