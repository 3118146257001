import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { DanhMucNguyenVatLieuPhanLoai } from 'src/app/shared/DanhMucNguyenVatLieuPhanLoai.model';
import { DanhMucNguyenVatLieuPhanLoaiService } from 'src/app/shared/DanhMucNguyenVatLieuPhanLoai.service';
import { DanhMucSanPham } from 'src/app/shared/DanhMucSanPham.model';
import { DanhMucSanPhamService } from 'src/app/shared/DanhMucSanPham.service';
import { DanhMucSanPhamGiong } from 'src/app/shared/DanhMucSanPhamGiong.model';
import { DanhMucSanPhamGiongService } from 'src/app/shared/DanhMucSanPhamGiong.service';
import { DanhMucNguyenVatLieu } from 'src/app/shared/DanhMucNguyenVatLieu.model';
import { DanhMucNguyenVatLieuService } from 'src/app/shared/DanhMucNguyenVatLieu.service';
import { DanhMucDuAn } from 'src/app/shared/DanhMucDuAn.model';
import { DanhMucDuAnService } from 'src/app/shared/DanhMucDuAn.service';
import { DanhMucQuyTrinhSanXuat } from 'src/app/shared/DanhMucQuyTrinhSanXuat.model';
import { DanhMucQuyTrinhSanXuatService } from 'src/app/shared/DanhMucQuyTrinhSanXuat.service';
import { DanhMucPhanDoanSanXuat } from 'src/app/shared/DanhMucPhanDoanSanXuat.model';
import { DanhMucPhanDoanSanXuatService } from 'src/app/shared/DanhMucPhanDoanSanXuat.service';
import { DanhMucQuyTrinhSanXuatPhanLoai } from 'src/app/shared/DanhMucQuyTrinhSanXuatPhanLoai.model';
import { DanhMucQuyTrinhSanXuatPhanLoaiService } from 'src/app/shared/DanhMucQuyTrinhSanXuatPhanLoai.service';
import { DanhMucVuMua } from 'src/app/shared/DanhMucVuMua.model';
import { DanhMucVuMuaService } from 'src/app/shared/DanhMucVuMua.service';


import { DanhMucHaTangKyThuat } from 'src/app/shared/DanhMucHaTangKyThuat.model';
import { DanhMucHaTangKyThuatService } from 'src/app/shared/DanhMucHaTangKyThuat.service';


import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';
import { DanhMucSanPhamGiongDetailComponent } from '../danh-muc-san-pham-giong-detail/danh-muc-san-pham-giong-detail.component';

@Component({
  selector: 'app-danh-muc-nguyen-vat-lieu',
  templateUrl: './danh-muc-nguyen-vat-lieu.component.html',
  styleUrls: ['./danh-muc-nguyen-vat-lieu.component.css']
})
export class DanhMucNguyenVatLieuComponent implements OnInit {


  @ViewChild('DanhMucNguyenVatLieuPhanLoaiSort') DanhMucNguyenVatLieuPhanLoaiSort: MatSort;
  @ViewChild('DanhMucNguyenVatLieuPhanLoaiPaginator') DanhMucNguyenVatLieuPhanLoaiPaginator: MatPaginator;

  @ViewChild('DanhMucSanPhamSort') DanhMucSanPhamSort: MatSort;
  @ViewChild('DanhMucSanPhamPaginator') DanhMucSanPhamPaginator: MatPaginator;

  @ViewChild('DanhMucSanPhamGiongSort') DanhMucSanPhamGiongSort: MatSort;
  @ViewChild('DanhMucSanPhamGiongPaginator') DanhMucSanPhamGiongPaginator: MatPaginator;

  @ViewChild('DanhMucNguyenVatLieuSort') DanhMucNguyenVatLieuSort: MatSort;
  @ViewChild('DanhMucNguyenVatLieuPaginator') DanhMucNguyenVatLieuPaginator: MatPaginator;

  @ViewChild('DanhMucDuAnSort') DanhMucDuAnSort: MatSort;
  @ViewChild('DanhMucDuAnPaginator') DanhMucDuAnPaginator: MatPaginator;

  @ViewChild('DanhMucQuyTrinhSanXuatSort') DanhMucQuyTrinhSanXuatSort: MatSort;
  @ViewChild('DanhMucQuyTrinhSanXuatPaginator') DanhMucQuyTrinhSanXuatPaginator: MatPaginator;


  @ViewChild('DanhMucPhanDoanSanXuatSort') DanhMucPhanDoanSanXuatSort: MatSort;
  @ViewChild('DanhMucPhanDoanSanXuatPaginator') DanhMucPhanDoanSanXuatPaginator: MatPaginator;

  @ViewChild('DanhMucQuyTrinhSanXuatPhanLoaiSort') DanhMucQuyTrinhSanXuatPhanLoaiSort: MatSort;
  @ViewChild('DanhMucQuyTrinhSanXuatPhanLoaiPaginator') DanhMucQuyTrinhSanXuatPhanLoaiPaginator: MatPaginator;

  @ViewChild('DanhMucVuMuaSort') DanhMucVuMuaSort: MatSort;
  @ViewChild('DanhMucVuMuaPaginator') DanhMucVuMuaPaginator: MatPaginator;

  @ViewChild('DanhMucHaTangKyThuatSort') DanhMucHaTangKyThuatSort: MatSort;
  @ViewChild('DanhMucHaTangKyThuatPaginator') DanhMucHaTangKyThuatPaginator: MatPaginator;

  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  DanhMucNguyenVatLieuPhanLoaiID: number = environment.InitializationNumber;
  IsChoPhep: boolean = true;
  IsCam: boolean = false;
  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DanhMucNguyenVatLieuPhanLoaiService: DanhMucNguyenVatLieuPhanLoaiService,
    public DanhMucSanPhamService: DanhMucSanPhamService,
    public DanhMucSanPhamGiongService: DanhMucSanPhamGiongService,
    public DanhMucNguyenVatLieuService: DanhMucNguyenVatLieuService,
    public DanhMucDuAnService: DanhMucDuAnService,
    public DanhMucQuyTrinhSanXuatService: DanhMucQuyTrinhSanXuatService,
    public DanhMucPhanDoanSanXuatService: DanhMucPhanDoanSanXuatService,
    public DanhMucQuyTrinhSanXuatPhanLoaiService: DanhMucQuyTrinhSanXuatPhanLoaiService,
    public DanhMucVuMuaService: DanhMucVuMuaService,
    public DanhMucHaTangKyThuatService: DanhMucHaTangKyThuatService,

    public ToChucService: ToChucService,

  ) { }

  ngOnInit(): void {
    this.ToChucGetToList();
    this.DanhMucHaTangKyThuatSearch();
  }

  DanhMucHaTangKyThuatSearch() {
    this.DanhMucHaTangKyThuatService.SearchAll(this.DanhMucHaTangKyThuatSort, this.DanhMucHaTangKyThuatPaginator);
  }
  DanhMucHaTangKyThuatSave(element: DanhMucHaTangKyThuat) {
    this.DanhMucHaTangKyThuatService.formData = element;
    this.NotificationService.warn(this.DanhMucHaTangKyThuatService.ComponentSaveAll(this.DanhMucHaTangKyThuatSort, this.DanhMucHaTangKyThuatPaginator));
  }
  DanhMucHaTangKyThuatDelete(element: DanhMucHaTangKyThuat) {
    this.DanhMucHaTangKyThuatService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucHaTangKyThuatService.ComponentDeleteAll(this.DanhMucHaTangKyThuatSort, this.DanhMucHaTangKyThuatPaginator));
  }

  DanhMucVuMuaSearch() {
    this.DanhMucVuMuaService.SearchAll(this.DanhMucVuMuaSort, this.DanhMucVuMuaPaginator);
  }
  DanhMucVuMuaSave(element: DanhMucVuMua) {
    this.DanhMucVuMuaService.formData = element;
    this.NotificationService.warn(this.DanhMucVuMuaService.ComponentSaveAll(this.DanhMucVuMuaSort, this.DanhMucVuMuaPaginator));
  }
  DanhMucVuMuaDelete(element: DanhMucVuMua) {
    this.DanhMucVuMuaService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucVuMuaService.ComponentDeleteAll(this.DanhMucVuMuaSort, this.DanhMucVuMuaPaginator));
  }

  DanhMucQuyTrinhSanXuatPhanLoaiSearch() {
    this.DanhMucQuyTrinhSanXuatPhanLoaiService.SearchAll(this.DanhMucQuyTrinhSanXuatPhanLoaiSort, this.DanhMucQuyTrinhSanXuatPhanLoaiPaginator);
  }
  DanhMucQuyTrinhSanXuatPhanLoaiSave(element: DanhMucQuyTrinhSanXuatPhanLoai) {
    this.DanhMucQuyTrinhSanXuatPhanLoaiService.formData = element;
    this.NotificationService.warn(this.DanhMucQuyTrinhSanXuatPhanLoaiService.ComponentSaveAll(this.DanhMucQuyTrinhSanXuatPhanLoaiSort, this.DanhMucQuyTrinhSanXuatPhanLoaiPaginator));
  }
  DanhMucQuyTrinhSanXuatPhanLoaiDelete(element: DanhMucQuyTrinhSanXuatPhanLoai) {
    this.DanhMucQuyTrinhSanXuatPhanLoaiService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucQuyTrinhSanXuatPhanLoaiService.ComponentDeleteAll(this.DanhMucQuyTrinhSanXuatPhanLoaiSort, this.DanhMucQuyTrinhSanXuatPhanLoaiPaginator));
  }

  DanhMucQuyTrinhSanXuatSearch() {
    this.DanhMucQuyTrinhSanXuatService.SearchAll(this.DanhMucQuyTrinhSanXuatSort, this.DanhMucQuyTrinhSanXuatPaginator);
  }
  DanhMucQuyTrinhSanXuatSave(element: DanhMucQuyTrinhSanXuat) {
    this.DanhMucQuyTrinhSanXuatService.formData = element;
    this.NotificationService.warn(this.DanhMucQuyTrinhSanXuatService.ComponentSaveAll(this.DanhMucQuyTrinhSanXuatSort, this.DanhMucQuyTrinhSanXuatPaginator));
  }
  DanhMucQuyTrinhSanXuatDelete(element: DanhMucQuyTrinhSanXuat) {
    this.DanhMucQuyTrinhSanXuatService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucQuyTrinhSanXuatService.ComponentDeleteAll(this.DanhMucQuyTrinhSanXuatSort, this.DanhMucQuyTrinhSanXuatPaginator));
  }

  DanhMucPhanDoanSanXuatSearch() {
    this.DanhMucPhanDoanSanXuatService.SearchAll(this.DanhMucPhanDoanSanXuatSort, this.DanhMucPhanDoanSanXuatPaginator);
  }
  DanhMucPhanDoanSanXuatSave(element: DanhMucPhanDoanSanXuat) {
    this.DanhMucPhanDoanSanXuatService.formData = element;
    this.NotificationService.warn(this.DanhMucPhanDoanSanXuatService.ComponentSaveAll(this.DanhMucPhanDoanSanXuatSort, this.DanhMucPhanDoanSanXuatPaginator));
  }
  DanhMucPhanDoanSanXuatDelete(element: DanhMucPhanDoanSanXuat) {
    this.DanhMucPhanDoanSanXuatService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucPhanDoanSanXuatService.ComponentDeleteAll(this.DanhMucPhanDoanSanXuatSort, this.DanhMucPhanDoanSanXuatPaginator));
  }

  DanhMucDuAnSearch() {
    this.DanhMucDuAnService.SearchAll(this.DanhMucDuAnSort, this.DanhMucDuAnPaginator);
  }
  DanhMucDuAnSave(element: DanhMucDuAn) {
    this.DanhMucDuAnService.formData = element;
    this.NotificationService.warn(this.DanhMucDuAnService.ComponentSaveAll(this.DanhMucDuAnSort, this.DanhMucDuAnPaginator));
  }
  DanhMucDuAnDelete(element: DanhMucDuAn) {
    this.DanhMucDuAnService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucDuAnService.ComponentDeleteAll(this.DanhMucDuAnSort, this.DanhMucDuAnPaginator));
  }


  DanhMucNguyenVatLieuGetToList() {
    this.isShowLoading = true;
    this.DanhMucNguyenVatLieuService.baseParameter.ParentID = this.DanhMucNguyenVatLieuPhanLoaiID;
    if (this.IsChoPhep == true) {
      this.DanhMucNguyenVatLieuService.baseParameter.Active = true;
    }
    if (this.IsCam == true) {
      this.DanhMucNguyenVatLieuService.baseParameter.Active = false;
    }
    this.DanhMucNguyenVatLieuService.baseParameter.SearchString = this.DanhMucNguyenVatLieuService.SearchString;    
    this.DanhMucNguyenVatLieuService.GetBySearchStringToListAsync().subscribe(
      res => {
        this.DanhMucNguyenVatLieuService.list = (res as DanhMucNguyenVatLieu[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));        
        for (let i = 0; i < this.DanhMucNguyenVatLieuService.list.length; i++) {
          if (this.DanhMucNguyenVatLieuService.list[i].Active == true) {
            this.DanhMucNguyenVatLieuService.list[i].IsChoPhep = true;
          }
          if (this.DanhMucNguyenVatLieuService.list[i].Active == false) {
            this.DanhMucNguyenVatLieuService.list[i].IsCam = true;
          }
        }        
        this.DanhMucNguyenVatLieuService.DataSource = new MatTableDataSource(this.DanhMucNguyenVatLieuService.list);
        this.DanhMucNguyenVatLieuService.DataSource.sort = this.DanhMucNguyenVatLieuSort;
        this.DanhMucNguyenVatLieuService.DataSource.paginator = this.DanhMucNguyenVatLieuPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucNguyenVatLieuSearch() {
    this.DanhMucNguyenVatLieuGetToList();
  }
  DanhMucNguyenVatLieuSave(element: DanhMucNguyenVatLieu) {
    if (element.IsChoPhep == true) {
      element.Active = true;
    }
    if (element.IsCam == true) {
      element.Active = false;
    }
    this.DanhMucNguyenVatLieuService.formData = element;
    this.DanhMucNguyenVatLieuService.SaveAsync().subscribe(
      res => {
        this.DanhMucNguyenVatLieuSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  DanhMucNguyenVatLieuDelete(element: DanhMucNguyenVatLieu) {
    if (confirm(environment.DeleteConfirm)) {
      this.DanhMucNguyenVatLieuService.baseParameter.ID = element.ID;
      this.DanhMucNguyenVatLieuService.RemoveAsync().subscribe(
        res => {
          this.DanhMucNguyenVatLieuSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
  onDanhMucNguyenVatLieuIsChoPhepChange(element: DanhMucNguyenVatLieu) {
    element.IsCam = !element.IsChoPhep;
    if (element.IsChoPhep == true) {
      element.Active = true;
    }
  }
  onDanhMucNguyenVatLieuIsCamChange(element: DanhMucNguyenVatLieu) {
    element.IsChoPhep = !element.IsCam;
    if (element.IsCam == true) {
      element.Active = false;
    }
  }
  DanhMucNguyenVatLieuExportExcel() {
    this.isShowLoading = true;
    this.DownloadService.baseParameter.ParentID = this.DanhMucNguyenVatLieuPhanLoaiID;
    if (this.IsChoPhep == true) {
      this.DownloadService.baseParameter.Active = true;
    }
    if (this.IsCam == true) {
      this.DownloadService.baseParameter.Active = false;
    }
    this.DownloadService.ExportDanhMucNguyenVatLieuToExcelAsync().subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucNguyenVatLieuExportHTML() {
    this.isShowLoading = true;
    this.DownloadService.baseParameter.ParentID = this.DanhMucNguyenVatLieuPhanLoaiID;
    if (this.IsChoPhep == true) {
      this.DownloadService.baseParameter.Active = true;
    }
    if (this.IsCam == true) {
      this.DownloadService.baseParameter.Active = false;
    }
    this.DownloadService.ExportDanhMucNguyenVatLieuToHTMLAsync().subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  onIsChoPhepChange() {
    this.IsCam = !this.IsChoPhep;
  }
  onIsCamChange() {
    this.IsChoPhep = !this.IsCam;
  }

  DanhMucSanPhamSearch() {
    this.DanhMucSanPhamService.SearchAll(this.DanhMucSanPhamSort, this.DanhMucSanPhamPaginator);
  }
  DanhMucSanPhamSave(element: DanhMucSanPham) {
    this.DanhMucSanPhamService.formData = element;
    this.NotificationService.warn(this.DanhMucSanPhamService.ComponentSaveAll(this.DanhMucSanPhamSort, this.DanhMucSanPhamPaginator));
  }
  DanhMucSanPhamDelete(element: DanhMucSanPham) {
    this.DanhMucSanPhamService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucSanPhamService.ComponentDeleteAll(this.DanhMucSanPhamSort, this.DanhMucSanPhamPaginator));
  }

  DanhMucSanPhamGiongSearch() {
    this.DanhMucSanPhamGiongService.SearchAllNotEmpty(this.DanhMucSanPhamGiongSort, this.DanhMucSanPhamGiongPaginator);
  } 
  DanhMucSanPhamGiongDelete(element: DanhMucSanPhamGiong) {
    this.DanhMucSanPhamGiongService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucSanPhamGiongService.ComponentDeleteAll(this.DanhMucSanPhamGiongSort, this.DanhMucSanPhamGiongPaginator));
  }
  DanhMucSanPhamGiongAdd(ID: any) {
    this.DanhMucSanPhamGiongService.baseParameter.ID = ID;
    this.DanhMucSanPhamGiongService.GetByIDAsync().subscribe(
      res => {
        this.DanhMucSanPhamGiongService.formData = res as DanhMucSanPhamGiong;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(DanhMucSanPhamGiongDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.DanhMucSanPhamGiongSearch();
        });
      },
      err => {
      }
    );
  }

  DanhMucNguyenVatLieuPhanLoaiSearch() {
    this.DanhMucNguyenVatLieuPhanLoaiService.SearchAll(this.DanhMucNguyenVatLieuPhanLoaiSort, this.DanhMucNguyenVatLieuPhanLoaiPaginator);
    this.isShowLoading = true;
    this.DanhMucNguyenVatLieuPhanLoaiService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucNguyenVatLieuService.list001 = (res as DanhMucNguyenVatLieu[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        if (this.DanhMucNguyenVatLieuService.list001) {
          if (this.DanhMucNguyenVatLieuService.list001.length > 0) {
            this.DanhMucNguyenVatLieuPhanLoaiID = this.DanhMucNguyenVatLieuService.list001[0].ID;
          }
        }
        this.DanhMucNguyenVatLieuSearch();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucNguyenVatLieuPhanLoaiSave(element: DanhMucNguyenVatLieuPhanLoai) {
    this.DanhMucNguyenVatLieuPhanLoaiService.formData = element;
    this.NotificationService.warn(this.DanhMucNguyenVatLieuPhanLoaiService.ComponentSaveAll(this.DanhMucNguyenVatLieuPhanLoaiSort, this.DanhMucNguyenVatLieuPhanLoaiPaginator));
  }
  DanhMucNguyenVatLieuPhanLoaiDelete(element: DanhMucNguyenVatLieuPhanLoai) {
    this.DanhMucNguyenVatLieuPhanLoaiService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucNguyenVatLieuPhanLoaiService.ComponentDeleteAll(this.DanhMucNguyenVatLieuPhanLoaiSort, this.DanhMucNguyenVatLieuPhanLoaiPaginator));
  }

  ToChucGetToList() {
    this.ToChucService.baseParameter.ParentID = 2;
    this.ToChucService.ComponentGetByParentIDToListAsync();
  }
}
