<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-hypnotize fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Upload excel</h2>
            <h6 class="m-0 text-dark fw-light">
                Hợp tác xã
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">          
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ToChuc" data-bs-toggle="tab" href="#ToChuc"
                                    role="tab" aria-controls="ToChuc" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Hợp tác xã</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-DanhMucNguyenVatLieu" data-bs-toggle="tab"
                                    href="#DanhMucNguyenVatLieu" role="tab" aria-controls="DanhMucNguyenVatLieu"
                                    aria-selected="false">
                                    <span class="badge bg-danger" style="font-size: 16px;">Vật tư</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucQuyTrinhSanXuat" data-bs-toggle="tab"
                                    href="#ToChucQuyTrinhSanXuat" role="tab" aria-controls="ToChucQuyTrinhSanXuat"
                                    aria-selected="false">
                                    <span class="badge bg-success" style="font-size: 16px;">Sản xuất</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucFix" data-bs-toggle="tab" href="#ToChucFix" role="tab"
                                    aria-controls="ToChucFix" aria-selected="false">
                                    <span class="badge bg-warning" style="font-size: 16px;">Tổ chức</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ThanhVienCoQuanQuanLy" data-bs-toggle="tab"
                                    href="#ThanhVienCoQuanQuanLy" role="tab" aria-controls="ThanhVienCoQuanQuanLy"
                                    aria-selected="false">
                                    <span class="badge bg-primary" style="font-size: 16px;">Cán bộ</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ThoiTiet" data-bs-toggle="tab" href="#ThoiTiet" role="tab"
                                    aria-controls="ThoiTiet" aria-selected="false">
                                    <span class="badge bg-dark" style="font-size: 16px;">Thời tiết</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-DanhMucSanPhamGiong" data-bs-toggle="tab" href="#DanhMucSanPhamGiong"
                                    role="tab" aria-controls="DanhMucSanPhamGiong" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Giống</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ToChuc" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h1>Hợp tác xã</h1>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 01:</label> <a class="link-primary"
                                                target="_blank" href="{{excelToChucURL}}" title="Tải về">Tải về</a> file
                                            excel mẫu
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 02:</label> Chọn file
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <input class="form-control" type="file" #uploadToChuc
                                                (change)="changeToChuc($event.target.files)">
                                        </div>
                                        <br />
                                        <button [disabled]="!isToChuc" class="btn btn-info" (click)="onSubmitToChuc()"
                                            style="width: 100%;">Upload</button>
                                    </div>
                                    <div class="col-lg-8 col-sm-12 col-12">

                                    </div>
                                </div>

                            </div>
                            <div class="tab-pane fade" id="DanhMucNguyenVatLieu" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h1>Vật tư</h1>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 01:</label> <a class="link-primary"
                                                target="_blank" href="{{excelDanhMucNguyenVatLieuURL}}"
                                                title="Tải về">Tải về</a> file
                                            excel mẫu
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 02:</label> Chọn file
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <input class="form-control" type="file" #uploadDanhMucNguyenVatLieu
                                                (change)="changeDanhMucNguyenVatLieu($event.target.files)">
                                        </div>
                                        <br />
                                        <button [disabled]="!isDanhMucNguyenVatLieu" class="btn btn-info"
                                            (click)="onSubmitDanhMucNguyenVatLieu()"
                                            style="width: 100%;">Upload</button>
                                    </div>
                                    <div class="col-lg-8 col-sm-12 col-12">

                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucQuyTrinhSanXuat" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h1>Sản xuất</h1>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 01:</label> <a class="link-primary"
                                                target="_blank" href="{{excelToChucQuyTrinhSanXuatURL}}"
                                                title="Tải về">Tải về</a> file
                                            excel mẫu
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 02:</label> Chọn file
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <input class="form-control" type="file" #uploadToChucQuyTrinhSanXuat
                                                (change)="changeToChucQuyTrinhSanXuat($event.target.files)">
                                        </div>
                                        <br />
                                        <button [disabled]="!isToChucQuyTrinhSanXuat" class="btn btn-info"
                                            (click)="onSubmitToChucQuyTrinhSanXuat()"
                                            style="width: 100%;">Upload</button>
                                    </div>
                                    <div class="col-lg-8 col-sm-12 col-12">

                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucFix" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h1>Tổ chức</h1>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 01:</label> <a class="link-primary"
                                                target="_blank" href="{{excelToChucFixURL}}" title="Tải về">Tải về</a>
                                            file
                                            excel mẫu
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 02:</label> Chọn file
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <input class="form-control" type="file" #uploadToChucFix
                                                (change)="changeToChucFix($event.target.files)">
                                        </div>
                                        <br />
                                        <button [disabled]="!isToChucFix" class="btn btn-info"
                                            (click)="onSubmitToChucFix()" style="width: 100%;">Upload</button>
                                    </div>
                                    <div class="col-lg-8 col-sm-12 col-12">

                                    </div>
                                </div>

                            </div>
                            <div class="tab-pane fade" id="ThanhVienCoQuanQuanLy" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h1>Cán bộ</h1>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 01:</label> <a class="link-primary"
                                                target="_blank" href="{{excelThanhVienCoQuanQuanLyURL}}"
                                                title="Tải về">Tải về</a> file
                                            excel mẫu
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 02:</label> Chọn file
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <input class="form-control" type="file" #uploadThanhVienCoQuanQuanLy
                                                (change)="changeThanhVienCoQuanQuanLy($event.target.files)">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 03:</label> Chọn danh mục
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <select class="form-select" name="parentID"
                                                [(ngModel)]="parentID">
                                                <option *ngFor="let item of DanhMucThanhVienService.list;" [value]="item.ID">
                                                    {{item.Name}}</option>
                                            </select>
                                        </div>
                                        <br />
                                        <button [disabled]="!isThanhVienCoQuanQuanLy" class="btn btn-info"
                                            (click)="onSubmitThanhVienCoQuanQuanLy()"
                                            style="width: 100%;">Upload</button>
                                    </div>
                                    <div class="col-lg-8 col-sm-12 col-12">

                                    </div>
                                </div>

                            </div>
                            <div class="tab-pane fade" id="ThoiTiet" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label class="form-label">Tỉnh thành</label>
                                        <mat-select class="form-control" name="DanhMucTinhThanhID"
                                            [(ngModel)]="DanhMucTinhThanhID">
                                            <input placeholder="Tìm..." class="form-control"
                                                (keyup)="DanhMucTinhThanhFilter($event.target.value)">
                                            <mat-option *ngFor="let item of DanhMucTinhThanhService.list001"
                                                [value]="item.ID">
                                                {{item.Name}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label class="form-label">Tháng/Ngày/Năm</label>
                                        <div class="col s12 m12 l2">
                                            <input [ngModel]="NgayGhiNhan | date:'yyyy-MM-dd'"
                                                (ngModelChange)="onChangeNgayGhiNhan($event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                                autocomplete="off">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <div>
                                            <a class="btn btn-success" title="Tìm" (click)="ThoiTietSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;

                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-dark">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Thời tiết
                                            <span *ngIf="ThoiTietService.list000">({{ThoiTietService.list000.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ThoiTietSort="matSort"
                                                    [dataSource]="ThoiTietService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ThoiTietPaginator.pageSize *
                                                            ThoiTietPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayGhiNhan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ngày ghi
                                                            nhận (Tháng/Ngày/Năm)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.NgayGhiNhan | date:'MM/dd/yyyy'}} |
                                                            {{element.GioGhiNhan}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <b>{{element.Name}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TongQuan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tổng quan
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TongQuan}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NhietDo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nhiệt độ
                                                            môi trường
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.NhietDo}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoAm">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Độ ẩm môi
                                                            trường
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.DoAm}} %
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="HuongGio">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hướng gió
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.HuongGio}}
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThoiTietService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThoiTietService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ThoiTietPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="12"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="DanhMucSanPhamGiong" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h1>Giống lưu hành</h1>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 01:</label> <a class="link-primary"
                                                target="_blank" href="{{excelDanhMucSanPhamGiongURL}}" title="Tải về">Tải về</a> file
                                            excel mẫu
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 02:</label> Chọn file
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <input class="form-control" type="file" #uploadDanhMucSanPhamGiong
                                                (change)="changeDanhMucSanPhamGiong($event.target.files)">
                                        </div>
                                        <br />
                                        <button [disabled]="!isDanhMucSanPhamGiong" class="btn btn-info" (click)="onSubmitDanhMucSanPhamGiong()"
                                            style="width: 100%;">Upload</button>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h1>Giống bảo hộ</h1>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 01:</label> <a class="link-primary"
                                                target="_blank" href="{{excelDanhMucSanPhamGiongBaoHoURL}}" title="Tải về">Tải về</a> file
                                            excel mẫu
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 02:</label> Chọn file
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <input class="form-control" type="file" #uploadDanhMucSanPhamGiongBaoHo
                                                (change)="changeDanhMucSanPhamGiongBaoHo($event.target.files)">
                                        </div>
                                        <br />
                                        <button [disabled]="!isDanhMucSanPhamGiongBaoHo" class="btn btn-info" (click)="onSubmitDanhMucSanPhamGiongBaoHo()"
                                            style="width: 100%;">Upload</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>