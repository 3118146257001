import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DownloadService } from 'src/app/shared/Download.service';


import * as maplibregl from 'maplibre-gl';

import { Report } from 'src/app/shared/Report.model';
import { ReportService } from 'src/app/shared/Report.service';
import { DanhMucTinhThanhToaDo } from 'src/app/shared/DanhMucTinhThanhToaDo.model';
import { DanhMucTinhThanhToaDoService } from 'src/app/shared/DanhMucTinhThanhToaDo.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {

  isShowLoading: boolean = false;
  isShowSearch: boolean = false;
  domainName = environment.DomainDestination;
  domainURL = environment.DomainURL;

  map: maplibregl.Map | undefined;

  @ViewChild('map')
  private mapContainer!: ElementRef<HTMLElement>;


  constructor(
    public DownloadService: DownloadService,

    public ReportService: ReportService,
    public DanhMucTinhThanhToaDoService: DanhMucTinhThanhToaDoService,
  ) { }

  ngOnInit(): void {

  }

  rotateCamera(timestamp) {
    if (this.map) {
      console.log(1);
      this.map.rotateTo((timestamp / 100) % 360, { duration: 0 });
      requestAnimationFrame(this.rotateCamera);
    }
  }

  ngAfterViewInit() {
    this.MapLoad();
  }

  ngOnDestroy() {
    this.map?.remove();
  }

  MapInitialization() {
    this.map = new maplibregl.Map({
      container: this.mapContainer.nativeElement,
      style: 'https://api.maptiler.com/maps/hybrid/style.json?key=' + environment.MaptilerAPIKey,
      center: [environment.Longitude, environment.Latitude],
      zoom: environment.MapZoom,
      pitch: 45,
    });
    
    this.map.addControl(
      new maplibregl.NavigationControl({
        visualizePitch: true,
        showZoom: true,
        showCompass: true
      })
    );

    this.map.on('load', () => {

      this.map.addSource("HoangSa", {
        "type": "image",
        "url": environment.DomainURL + "assets/image/HoangSa01.png",
        "coordinates": [
          [111.09665858054495, 17.432475898867523],
          [113.11720985517763, 17.38420482529338],
          [112.79285037220984, 15.643938718432054],
          [110.88537855035554, 15.672592116966598],
        ]
      });
      this.map.addLayer({
        "id": "HoangSa",
        "source": "HoangSa",
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

      this.map.addSource("TruongSa", {
        "type": "image",
        "url": environment.DomainURL + "assets/image/TruongSa01.png",
        "coordinates": [
          [112.32373278444106, 12.236103169381323],
          [117.4620551483049, 11.606334626304161],
          [115.59654957671216, 7.357025445897818],
          [110.62186805246108, 7.811210355974268],


        ]
      });
      this.map.addLayer({
        "id": "TruongSa",
        "source": "TruongSa",
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

    });
  }

  MapLoad() {
    this.MapInitialization();
    this.isShowLoading = true;
    this.ReportService.Report2001ToListAsync().subscribe(
      res => {
        this.ReportService.list2001 = (res as Report[]);
        this.isShowLoading = false;

        this.isShowLoading = true;
        this.DanhMucTinhThanhToaDoService.baseParameter.Active = true;
        this.DanhMucTinhThanhToaDoService.GetSQLByDanhMucTinhThanhActiveToListAsync().subscribe(
          res => {
            this.DanhMucTinhThanhToaDoService.list = (res as DanhMucTinhThanhToaDo[]);
            if (this.DanhMucTinhThanhToaDoService.list) {
              if (this.DanhMucTinhThanhToaDoService.list.length > 0) {
                let code = 0;
                for (let i = 0; i < this.DanhMucTinhThanhToaDoService.list.length; i++) {
                  let listToaDoPolygon = [];
                  let listPolygon = [];

                  if (code != this.DanhMucTinhThanhToaDoService.list[i].DanhMucTinhThanhID) {
                    code = this.DanhMucTinhThanhToaDoService.list[i].DanhMucTinhThanhID;
                    let listToaDo = this.DanhMucTinhThanhToaDoService.list.filter((item) => item.DanhMucTinhThanhID == code);


                    for (let j = 0; j < listToaDo.length; j++) {
                      let latitudeSub = Number(listToaDo[j].ViDo);
                      let longitudeSub = Number(listToaDo[j].KinhDo);
                      listToaDoPolygon.push([longitudeSub, latitudeSub]);
                    }

                    listPolygon.push(listToaDoPolygon);

                    for (let j = 0; j < this.ReportService.list2001.length; j++) {
                      if (this.ReportService.list2001[j].ID == this.DanhMucTinhThanhToaDoService.list[i].DanhMucTinhThanhID) {
                        let report = this.ReportService.list2001[j];
                        let html = "<div style='background-image: url(" + environment.DomainURL + "assets/image/ping150.png); background-repeat: no-repeat; width: 150px; height: 128px; text-align: center; padding: 10px; '>";
                        html = html + "<b>" + report.DanhMucTinhThanhName + "</b>";
                        html = html + "<br/>";
                        html = html + "Hợp tác xã: <b>" + report.SoLuongThamGia.toLocaleString("vi-VN") + "</b>";
                        html = html + "<br/>";
                        html = html + "Diện tích (ha): <b>" + report.DienTichThamGia.toLocaleString("vi-VN") + "</b>";
                        html = html + "<br/>";
                        html = html + "Sản lượng (tấn): <b>" + report.SanLuongThamGia.toLocaleString("vi-VN") + "</b>";
                        html = html + "</div>";
                        var el = document.createElement('div');
                        el.innerHTML = html;

                        let latitude = Number(report.ViDo);
                        let longitude = Number(report.KinhDo);                      
                        let marker = new maplibregl.Marker({ element: el })
                          .setLngLat([longitude, latitude])
                          .addTo(this.map);
                      }
                    }

                    this.map.on('load', () => {

                      let layerID = 'Layer_' + Math.random();
                      let sourceID = 'Source_' + Math.random();

                      this.map.addSource(sourceID, {
                        'type': 'geojson',
                        'data': {
                          'type': 'Feature',
                          'properties': {
                            "name": this.DanhMucTinhThanhToaDoService.list[i].Name,
                          },
                          'geometry': {
                            'type': 'Polygon',
                            'coordinates': listPolygon,
                          }
                        }
                      });
                      let color = this.DownloadService.GetRandomColor(listToaDoPolygon.length);

                      this.map.addLayer({
                        'id': layerID,
                        'type': 'fill',
                        'source': sourceID,
                        'paint': {
                          'fill-color': color,
                          'fill-opacity': 0.5,
                          'fill-outline-color': color,
                        }
                      });

                    });
                  }
                }
              }
            }

            this.isShowLoading = false;
          },
          err => {
            this.isShowLoading = false;
          }
        );
      },
      err => {
        this.isShowLoading = false;
      }
    );

  }
}
