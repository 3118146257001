import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HaTangKyThuat } from 'src/app/shared/HaTangKyThuat.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class HaTangKyThuatService extends BaseService{

    list: HaTangKyThuat[] | undefined;
    list001: HaTangKyThuat[] | undefined;
    formData!: HaTangKyThuat;

    displayColumns001: string[] = ['STT', 'DanhMucTinhThanhName', 'DanhMucQuanHuyenName', 'DanhMucXaPhuongName', 'Code', 'Name', 'DienThoai', 'Active', 'Save'];    

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "HaTangKyThuat";
    }
}

