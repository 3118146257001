import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ThongBao } from 'src/app/shared/ThongBao.model';
import { ThongBaoService } from 'src/app/shared/ThongBao.service';
import { ThongBaoDetailComponent } from '../thong-bao-detail/thong-bao-detail.component';

@Component({
  selector: 'app-thong-bao',
  templateUrl: './thong-bao.component.html',
  styleUrls: ['./thong-bao.component.css']
})
export class ThongBaoComponent implements OnInit {

  @ViewChild('ThongBaoSort') ThongBaoSort: MatSort;
  @ViewChild('ThongBaoPaginator') ThongBaoPaginator: MatPaginator;

  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public ThongBaoService: ThongBaoService,
  ) { }

  ngOnInit(): void {
    this.ThongBaoSearch();
  }

  ThongBaoSearch() {
    this.ThongBaoService.SearchAllNotEmpty(this.ThongBaoSort, this.ThongBaoPaginator);
  }
  ThongBaoDelete(element: ThongBao) {
    this.ThongBaoService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ThongBaoService.ComponentDeleteAll(this.ThongBaoSort, this.ThongBaoPaginator));
  }
  ThongBaoAdd(ID: any) {
    this.ThongBaoService.baseParameter.ID = ID;
    this.ThongBaoService.GetByIDAsync().subscribe(
      res => {
        this.ThongBaoService.formData = res as ThongBao;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ThongBaoDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.ThongBaoSearch();
        });
      },
      err => {
      }
    );
  }
}
