import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';

import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucXaPhuong } from 'src/app/shared/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';

import { HaTangKyThuat } from 'src/app/shared/HaTangKyThuat.model';
import { HaTangKyThuatService } from 'src/app/shared/HaTangKyThuat.service';

import { DanhMucHaTangKyThuat } from 'src/app/shared/DanhMucHaTangKyThuat.model';
import { DanhMucHaTangKyThuatService } from 'src/app/shared/DanhMucHaTangKyThuat.service';

@Component({
  selector: 'app-ha-tang-ky-thuat-detail',
  templateUrl: './ha-tang-ky-thuat-detail.component.html',
  styleUrls: ['./ha-tang-ky-thuat-detail.component.css']
})
export class HaTangKyThuatDetailComponent implements OnInit {

  isShowLoading: boolean = false;
  queryString: string = environment.InitializationString;
  searchString: string = environment.InitializationString;

  constructor(
    public dialogRef: MatDialogRef<HaTangKyThuatDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,

    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,

    public HaTangKyThuatService: HaTangKyThuatService,

    public DanhMucHaTangKyThuatService: DanhMucHaTangKyThuatService,

  ) { }

  ngOnInit(): void {
    this.DanhMucHaTangKyThuatSearch();
    this.DanhMucTinhThanhGetToList();
  }

  DanhMucHaTangKyThuatSearch() {
    this.DanhMucHaTangKyThuatService.ComponentGetAllToListAsync();
  }

  HaTangKyThuatSave() {
    this.isShowLoading = true;
    this.HaTangKyThuatService.SaveAsync().subscribe(
      res => {
        this.HaTangKyThuatService.formData = res as HaTangKyThuat;
        this.NotificationService.warn(environment.SaveSuccess);
        this.MapLoad();
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  HaTangKyThuatNew() {
    this.isShowLoading = true;
    this.HaTangKyThuatService.formData.ID = 0;
    this.HaTangKyThuatService.SaveAsync().subscribe(
      res => {
        this.HaTangKyThuatService.formData = res as HaTangKyThuat;
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }

  Close() {
    this.dialogRef.close();
  }

  DanhMucTinhThanhGetToList() {
    this.isShowLoading = true;
    this.DanhMucTinhThanhService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.list = (res as DanhMucTinhThanh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucTinhThanhService.list001 = this.DanhMucTinhThanhService.list;
        this.DanhMucQuanHuyenGetToList();
        this.MapLoad();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucQuanHuyenGetToList() {
    this.isShowLoading = true;
    this.DanhMucQuanHuyenService.baseParameter.ParentID = this.HaTangKyThuatService.formData.DanhMucTinhThanhID;
    this.DanhMucQuanHuyenService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucQuanHuyenService.list = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucQuanHuyenService.list001 = this.DanhMucQuanHuyenService.list;
        this.DanhMucXaPhuongGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucXaPhuongGetToList() {
    this.isShowLoading = true;
    this.DanhMucXaPhuongService.baseParameter.ParentID = this.HaTangKyThuatService.formData.DanhMucQuanHuyenID;
    this.DanhMucXaPhuongService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucXaPhuongService.list = (res as DanhMucXaPhuong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucXaPhuongService.list001 = this.DanhMucXaPhuongService.list;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucTinhThanhFilter(searchString: string) {
    this.DanhMucTinhThanhService.Filter(searchString);
  }
  DanhMucQuanHuyenFilter(searchString: string) {
    this.DanhMucQuanHuyenService.Filter(searchString);
  }
  DanhMucXaPhuongFilter(searchString: string) {
    this.DanhMucXaPhuongService.Filter(searchString);
  }

  icon: string = environment.MapIcon;
  latitude: number = 9.967757;
  longitude: number = 105.595843;
  display: any;
  center: google.maps.LatLngLiteral = { lat: this.latitude, lng: this.longitude };
  zoom = 16;
  map: any;
  markerOptions: any = {
    draggable: false,
    icon: this.icon,
  };
  markers: any = [];
  infoWindows: any = [];
  polygon;

  MapLoad() {
    if (this.HaTangKyThuatService.formData) {
      if (this.HaTangKyThuatService.formData.ID > 0) {
        if (this.HaTangKyThuatService.formData.ViDo) {
          if (this.HaTangKyThuatService.formData.KinhDo) {
            this.latitude = Number(this.HaTangKyThuatService.formData.ViDo);
            this.longitude = Number(this.HaTangKyThuatService.formData.KinhDo);
          }
        }
      }
    }
    this.map = new google.maps.Map(document.getElementById("HaTangKyThuatMap"), {
      center: { lat: this.latitude, lng: this.longitude },
      zoom: this.zoom,
      mapTypeId: 'hybrid',
    });

    this.polygon = new google.maps.Circle({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      center: { lat: Number(this.latitude), lng: Number(this.longitude) },
      radius: 50,
    });
    this.polygon.setMap(this.map);

    let marker = new google.maps.Marker({
      title: "Vị trí bạn chọn",
      position: { lat: Number(this.latitude), lng: Number(this.longitude) },
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        fillColor: "white",
        strokeColor: "white",
        scale: 3
      }
    });
    marker.setMap(this.map);
    this.markers.push(marker);

    this.map.addListener("click", (mapsMouseEvent) => {
      if (this.markers) {
        for (let i = 1; i < this.markers.length; i++) {
          this.markers[i].setMap(null);
        }
      }
      if (this.polygon) {
        this.polygon.setMap(null);
      }
      this.latitude = Number(mapsMouseEvent.latLng.toJSON().lat);
      this.longitude = Number(mapsMouseEvent.latLng.toJSON().lng);

      this.HaTangKyThuatService.formData.ViDo = mapsMouseEvent.latLng.toJSON().lat;
      this.HaTangKyThuatService.formData.KinhDo = mapsMouseEvent.latLng.toJSON().lng;

      this.polygon = new google.maps.Circle({
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        center: { lat: Number(this.latitude), lng: Number(this.longitude) },
        radius: 50,
      });
      this.polygon.setMap(this.map);

      let marker = new google.maps.Marker({
        title: "Vị trí bạn chọn",
        position: { lat: Number(this.latitude), lng: Number(this.longitude) },
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          fillColor: "white",
          strokeColor: "white",
          scale: 3
        }
      });
      marker.setMap(this.map);
      this.markers.push(marker);
    });
  }
}
