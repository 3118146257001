import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DanhMucNguyenVatLieu } from 'src/app/shared/DanhMucNguyenVatLieu.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class DanhMucNguyenVatLieuService extends BaseService{

    list: DanhMucNguyenVatLieu[] | undefined;
    list001: DanhMucNguyenVatLieu[] | undefined;
    formData!: DanhMucNguyenVatLieu;    

    //displayColumns001: string[] = ['STT', 'ParentID', 'FUNCTIONID', 'ToChucID', 'Code', 'Name', 'Description', 'Display', 'Active', 'Save'];
    displayColumns001: string[] = ['STT', 'ParentID', 'Name', 'ToChucID'];
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "DanhMucNguyenVatLieu";
    }    
    GetSQLByParentIDAndActiveToListAsync() {
        let url = this.aPIURL + this.controller + '/GetSQLByParentIDAndActiveToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }   
    GetByParentID_SearchStringToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByParentID_SearchStringToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }   
}

