import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaiViet } from 'src/app/shared/BaiViet.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class BaiVietService extends BaseService{

    
    list: BaiViet[] | undefined;
    list001: BaiViet[] | undefined;
    formData!: BaiViet;    

    displayColumns001: string[] = ['STT', 'ID', 'NgayGhiNhan', 'Name', 'SortOrder', 'Active', 'Save'];   

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "BaiViet";
    }
}

