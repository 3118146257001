import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';

import { ThoiTiet } from 'src/app/shared/ThoiTiet.model';
import { ThoiTietService } from 'src/app/shared/ThoiTiet.service';

@Component({
  selector: 'app-thoi-tiet',
  templateUrl: './thoi-tiet.component.html',
  styleUrls: ['./thoi-tiet.component.css']
})
export class ThoiTietComponent implements OnInit {

  @ViewChild('ThoiTietSort') ThoiTietSort: MatSort;
  @ViewChild('ThoiTietPaginator') ThoiTietPaginator: MatPaginator;

  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  DanhMucTinhThanhID: number = environment.DanhMucTinhThanhID;
  NgayGhiNhan: Date = new Date();

  constructor(
    private dialog: MatDialog,

    public NotificationService: NotificationService,

    public DanhMucTinhThanhService: DanhMucTinhThanhService,

    public ThoiTietService: ThoiTietService,

  ) { }

  ngOnInit(): void {
    this.DanhMucTinhThanhGetToList();
  }
  onChangeNgayGhiNhan(value) {
    this.NgayGhiNhan = new Date(value);
  }

  ThoiTietSearch() {
    this.isShowLoading = true;
    this.ThoiTietService.baseParameter.DanhMucTinhThanhID = this.DanhMucTinhThanhID;
    this.ThoiTietService.baseParameter.NgayGhiNhan = this.NgayGhiNhan;
    this.ThoiTietService.GetByDanhMucTinhThanhID_NgayGhiNhanToListAsync().subscribe(
      res => {
        this.ThoiTietService.list = (res as ThoiTiet[]).sort((a, b) => (a.ID > b.ID ? 1 : -1));
        this.ThoiTietService.list001 = this.ThoiTietService.list;
        this.ThoiTietService.DataSource = new MatTableDataSource(this.ThoiTietService.list);
        this.ThoiTietService.DataSource.sort = this.ThoiTietSort;
        this.ThoiTietService.DataSource.paginator = this.ThoiTietPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  } 

  DanhMucTinhThanhGetToList() {
    this.isShowLoading = true;
    this.DanhMucTinhThanhService.baseParameter.Active = true;
    this.DanhMucTinhThanhService.GetByActiveToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.list = (res as DanhMucTinhThanh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucTinhThanhService.list001 = this.DanhMucTinhThanhService.list;
        if (this.DanhMucTinhThanhService.list) {
          if (this.DanhMucTinhThanhService.list.length) {
            this.DanhMucTinhThanhID = this.DanhMucTinhThanhService.list[0].ID;
          }
        }
        this.ThoiTietSearch();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  DanhMucTinhThanhFilter(searchString: string) {
    this.DanhMucTinhThanhService.Filter(searchString);
  }
}
