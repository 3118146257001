import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToChucQuyTrinhSanXuat } from 'src/app/shared/ToChucQuyTrinhSanXuat.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ToChucQuyTrinhSanXuatService extends BaseService{
    list: ToChucQuyTrinhSanXuat[] | undefined;
    list001: ToChucQuyTrinhSanXuat[] | undefined;
    formData!: ToChucQuyTrinhSanXuat;    

    displayColumns001: string[] = ['STT', 'LastUpdatedDate','LastUpdatedMembershipID', 'Name', 'Display', 'DienTich', 'SoVu', 'Save'];
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "ToChucQuyTrinhSanXuat";
    }
    GetByDanhMucSanPhamID_DanhMucTinhThanhID_SearchStringAsync() {
        let url = this.aPIURL + this.controller + '/GetByDanhMucSanPhamID_DanhMucTinhThanhID_SearchStringAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetByDanhMucSanPhamID_DanhMucTinhThanhID_DanhMucQuanHuyenID_DanhMucXaPhuongID_SearchStringAsync() {
        let url = this.aPIURL + this.controller + '/GetByDanhMucSanPhamID_DanhMucTinhThanhID_DanhMucQuanHuyenID_DanhMucXaPhuongID_SearchStringAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetByParentIDAsync() {
        let url = this.aPIURL + this.controller + '/GetByParentIDAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetByParentID_DanhMucDuAnIDAsync() {
        let url = this.aPIURL + this.controller + '/GetByParentID_DanhMucDuAnIDAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetByParentID_DanhMucSanPhamIDAsync() {
        let url = this.aPIURL + this.controller + '/GetByParentID_DanhMucSanPhamIDAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetByParentID_DanhMucDuAnID_DanhMucSanPhamIDAsync() {
        let url = this.aPIURL + this.controller + '/GetByParentID_DanhMucDuAnID_DanhMucSanPhamIDAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
}

