import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';

import * as maplibregl from 'maplibre-gl';

@Component({
  selector: 'app-map-vung-trong-info',
  templateUrl: './map-vung-trong-info.component.html',
  styleUrls: ['./map-vung-trong-info.component.css']
})
export class MapVungTrongInfoComponent implements OnInit {

  isShowLoading: boolean = false;
  domainName = environment.DomainDestination;
  domainURL = environment.DomainURL;
  queryString: string = environment.InitializationString;


  constructor(
    public router: Router,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public ToChucService: ToChucService,
  ) {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.queryString = event.url;
        this.ToChucService.formData.ID = parseInt(this.queryString.split("/")[2]);
        if (this.queryString) {
          if (this.queryString.length > 0) {
            this.MapLoad();
          }
        }
      }
    });
  }

  ngOnInit(): void {
  }

  map: maplibregl.Map | undefined;

  @ViewChild('map')
  private mapContainer!: ElementRef<HTMLElement>;

  MapInitialization(longitude, latitude) {

    let zoom = 14;

    if ((latitude > 90) || (latitude == 0)) {
      latitude = environment.Latitude;
      longitude = environment.Longitude;
    }
    this.map = new maplibregl.Map({
      container: this.mapContainer.nativeElement,
      style: 'https://api.maptiler.com/maps/hybrid/style.json?key=' + environment.MaptilerAPIKey,
      center: [longitude, latitude],
      zoom: zoom,
      pitch: 45,
    });

    this.map.addControl(
      new maplibregl.NavigationControl({
        visualizePitch: true,
        showZoom: true,
        showCompass: true
      })
    );

    this.map.on('load', () => {

      this.map.addSource("HoangSa", {
        "type": "image",
        "url": environment.DomainURL + "assets/image/HoangSa01.png",
        "coordinates": [
          [111.09665858054495, 17.432475898867523],
          [113.11720985517763, 17.38420482529338],
          [112.79285037220984, 15.643938718432054],
          [110.88537855035554, 15.672592116966598],
        ]
      });
      this.map.addLayer({
        "id": "HoangSa",
        "source": "HoangSa",
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

      this.map.addSource("TruongSa", {
        "type": "image",
        "url": environment.DomainURL + "assets/image/TruongSa01.png",
        "coordinates": [
          [112.32373278444106, 12.236103169381323],
          [117.4620551483049, 11.606334626304161],
          [115.59654957671216, 7.357025445897818],
          [110.62186805246108, 7.811210355974268],


        ]
      });
      this.map.addLayer({
        "id": "TruongSa",
        "source": "TruongSa",
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

    });
  }

  MapLoad() {
    this.ToChucService.baseParameter.IDString = this.queryString
    this.ToChucService.GetByIDStringAsync().subscribe(res => {
      this.ToChucService.formData = res as ToChuc;
      if (this.ToChucService.formData) {
        this.ToChucService.baseParameter.ID = this.ToChucService.formData.ID;
        this.ToChucService.GetSQLByToChucToaDoByIDToListAsync().subscribe(res => {
          this.ToChucService.list1003 = res as ToChuc[];
          if (this.ToChucService.list1003) {
            if (this.ToChucService.list1003.length > 0) {
              let listToChucToaDoActive = [];
              let listToChucToaDoActiveNot = [];

              for (let i = 0; i < this.ToChucService.list1003.length; i++) {
                if (this.ToChucService.list1003[i].Active == true) {
                  listToChucToaDoActive.push(this.ToChucService.list1003[i]);
                }
              }
              if (listToChucToaDoActive) {
                if (listToChucToaDoActive.length > 0) {
                  let toChuc = listToChucToaDoActive[0];
                  let latitude = Number(toChuc.ViDo);
                  let longitude = Number(toChuc.KinhDo);

                  this.MapInitialization(longitude, latitude);

                  if (latitude <= 90) {

                    let truSo = "Chưa có trụ sở";
                    if (toChuc.IsTruSo) {
                      truSo = "Có trụ sở";
                    }
                    let dapUngDuAn = "";
                    if (toChuc.IsDapUngDuAn) {
                      dapUngDuAn = "Đáp ứng tiêu chí";
                    }

                    let popupContent = "<div style='opacity: 0.8; background-color: transparent;'>";
                    popupContent = popupContent + "<a class='link-primary form-label' href='#' style='text-align: center;'><h1>" + toChuc.Name + "</h1></a>";
                    popupContent = popupContent + '<table>';
                    popupContent = popupContent + '<tr>';
                    popupContent = popupContent + '<td style="vertical-align: top; padding: 5px;">';
                    popupContent = popupContent + '<div>Trụ sở: <b>' + truSo + '</b></div>';
                    popupContent = popupContent + '<div>Địa chỉ: <b>' + toChuc.DanhMucXaPhuongName + ', ' + toChuc.DanhMucQuanHuyenName + ', ' + toChuc.DanhMucTinhThanhName + '</b></div>';
                    popupContent = popupContent + '<div>Giám đốc: <b>' + toChuc.Display + '</b></div>';
                    popupContent = popupContent + '<div>Điện thoại: <b>' + toChuc.DienThoai + '</b></div>';
                    popupContent = popupContent + '<div>Ngành nghề: <b>' + toChuc.Description + '</b></div>';
                    popupContent = popupContent + '<div>Thành viên: <b>' + toChuc.SoLuongThanhVien + '</b></div>';
                    popupContent = popupContent + '<div>Diện tích: <b>' + toChuc.DienTichSanXuatHa + ' ha</b></div>';                    
                    popupContent = popupContent + '<div>Đáp ứng tiêu chí: <b>' + dapUngDuAn + '</b></div>';
                    popupContent = popupContent + '</td>';
                    popupContent = popupContent + '<td style="vertical-align: top; padding: 5px;">';
                    popupContent = popupContent + '<div>Thời tiết nông vụ: <b>' + toChuc.TongQuan + '</b></div>';
                    popupContent = popupContent + '<div>Nhiệt độ: <b>' + toChuc.NhietDo + '</b></div>';
                    popupContent = popupContent + '<div>Độ ẩm: <b>' + toChuc.DoAm + ' %</b></div>';
                    popupContent = popupContent + '<div>Hướng gió: <b>' + toChuc.HuongGio + '</b></div>';
                    popupContent = popupContent + "</div>";
                    popupContent = popupContent + '</td>';
                    popupContent = popupContent + '</tr>';
                    popupContent = popupContent + '</table>';



                    let popup = new maplibregl.Popup({ offset: 25 }).setHTML(popupContent)
                      .setMaxWidth("600px");

                    var el = document.createElement('div');
                    el.style.backgroundImage = "url(" + environment.DomainURL + "assets/image/HTX4_30.png)";
                    el.style.width = '30px';
                    el.style.height = '30px';

                    let marker = new maplibregl.Marker({ element: el })
                      .setLngLat([longitude, latitude])
                      .setPopup(popup)
                      .addTo(this.map);
                  }
                }
              }

              let listToChucToaDoPolygon = [];
              let listPolygon = [];

              for (let i = 0; i < this.ToChucService.list1003.length; i++) {
                if (this.ToChucService.list1003[i].Active == false) {
                  let latitudeSub1 = Number(this.ToChucService.list1003[i].ViDo);
                  let longitudeSub1 = Number(this.ToChucService.list1003[i].KinhDo);
                  listToChucToaDoPolygon.push([longitudeSub1, latitudeSub1]);
                }
              }

              if (listToChucToaDoPolygon.length > 0) {

                listPolygon.push(listToChucToaDoPolygon);

                let layerID = 'Layer_';
                let sourceID = 'Source_';

                this.map.on('load', () => {

                  this.map.addSource(sourceID, {
                    'type': 'geojson',
                    'data': {
                      'type': 'Feature',
                      'properties': {
                        "name": "",
                        "address": "",
                      },
                      'geometry': {
                        'type': 'Polygon',
                        'coordinates': listPolygon,
                      }
                    }
                  });

                  let color = this.DownloadService.GetRandomColor(listToChucToaDoPolygon.length);

                  this.map.addLayer({
                    'id': layerID,
                    'type': 'fill',
                    'source': sourceID,
                    'paint': {
                      'fill-color': color,
                      'fill-opacity': 0.5,
                      'fill-outline-color': color,
                    }
                  });

                });
              }
            }
          }
        });
      }
    });

  }
}