import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DanhMucDuAn } from 'src/app/shared/DanhMucDuAn.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class DanhMucDuAnService extends BaseService{
    list: DanhMucDuAn[] | undefined;
    list001: DanhMucDuAn[] | undefined;
    formData!: DanhMucDuAn;    

    displayColumns001: string[] = ['STT', 'Code', 'Name', 'BatDau', 'KetThuc', 'Active', 'Save']; 
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "DanhMucDuAn";
    }
}

