<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-gear fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">{{ThongBaoService.formData.Name}}</h2>
            <h6 class="m-0 text-dark fw-light">
                Thông báo
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
            <a class="btn btn-danger" title="Đóng" (click)="Close()">
                <i class="bi bi-x-lg"></i>
            </a>
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ThongBaoDetail" data-bs-toggle="tab"
                                    href="#ThongBaoDetail" role="tab" aria-controls="ThongBaoDetail"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">01: Thông báo</span>
                                </a>
                            </li>
                            <!-- <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ThongBaoThanhVien" data-bs-toggle="tab"
                                    href="#ThongBaoThanhVien" role="tab" aria-controls="ThongBaoThanhVien"
                                    aria-selected="false">
                                    <span class="badge bg-danger" style="font-size: 16px;">02: Phân phối</span>
                                </a>
                            </li> -->
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ThongBaoDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <a style="width: 100%;" title="Lưu thay đổi" (click)="ThongBaoSave()"
                                            class="btn btn-info"><i class="bi bi-sd-card"></i> Lưu thay đổi</a>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <a style="width: 100%;" title="Thêm mới" (click)="ThongBaoNew()"
                                            class="btn btn-success"><i class="bi bi-plus"></i> Thêm mới</a>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="Active"
                                                [(ngModel)]="ThongBaoService.formData.Active" />
                                            <label class="form-check-label" for="Active">Kích hoạt</label>
                                        </div>
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="Active"
                                                [(ngModel)]="ThongBaoService.formData.IsSendAll" />
                                            <label class="form-check-label" for="IsSendAll">Gửi tất cả</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tiêu đề</label>
                                            <input name="Name" [(ngModel)]="ThongBaoService.formData.Name"
                                                placeholder="Tiêu đề" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Nội dung</label>
                                            <input name="Description" [(ngModel)]="ThongBaoService.formData.Description"
                                                placeholder="Nội dung" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bắt đầu (Tháng/Ngày/Năm)</label>
                                            <input name="BatDau" class="form-control" style="text-align: right;"
                                                [ngModel]="ThongBaoService.formData.BatDau | date:'yyyy-MM-dd'"
                                                (ngModelChange)="BatDauChange($event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Kết thúc (Tháng/Ngày/Năm)</label>
                                            <input name="KetThuc" class="form-control" style="text-align: right;"
                                                [ngModel]="ThongBaoService.formData.KetThuc | date:'yyyy-MM-dd'"
                                                (ngModelChange)="KetThucChange($event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="row gx-4">
                                            <div class="col-lg-8 col-sm-12 col-12">
                                                <label class="form-label">Thành viên</label>
                                                <mat-select class="form-control" name="ThanhVienID"
                                                    [(ngModel)]="ThongBaoThanhVienService.formData.ThanhVienID">
                                                    <input placeholder="Tìm..." class="form-control"
                                                        (keyup)="ThanhVienFilter($event.target.value)">
                                                    <mat-option *ngFor="let item of ThanhVienService.list001"
                                                        [value]="item.ID">
                                                        {{item.Name}} - {{item.TaiKhoan}}
                                                    </mat-option>
                                                </mat-select>
                                            </div>
                                            <div class="col-lg-2 col-sm-12 col-12">
                                                <label class="form-label">Kích hoạt</label>
                                                <div class="form-check form-check-inline mb-6">
                                                    <input type="checkbox" class="form-check-input" name="Active"
                                                        [(ngModel)]="ThongBaoThanhVienService.formData.Active" />
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-sm-12 col-12">
                                                <label class="form-label">.</label>
                                                <div>
                                                    <a title="Lưu thay đổi" (click)="ThongBaoThanhVienSave()"
                                                        class="btn btn-info"><i class="bi bi-sd-card"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row gx-4">
                                            <div class="card mb-4 card-warning">
                                                <div class="card-header">
                                                    <h5 class="card-title text-white">Thành viên
                                                        <span
                                                            *ngIf="ThongBaoThanhVienService.list">({{ThongBaoThanhVienService.list.length}}
                                                            items)</span>
                                                    </h5>
                                                </div>
                                                <div class="card-body">
                                                    <div class="table-outer">
                                                        <div class="table-responsive">
                                                            <table mat-table matSort #ThongBaoThanhVienSort="matSort"
                                                                [dataSource]="ThongBaoThanhVienService.DataSource"
                                                                class="table table-striped m-0">
                                                                <ng-container matColumnDef="STT">
                                                                    <th mat-header-cell *matHeaderCellDef
                                                                        mat-sort-header>
                                                                        STT
                                                                    </th>
                                                                    <td mat-cell *matCellDef="let element; index as i;"
                                                                        style="text-align: center;">
                                                                        {{ThongBaoThanhVienPaginator.pageSize *
                                                                        ThongBaoThanhVienPaginator.pageIndex + i + 1}}
                                                                    </td>
                                                                </ng-container>                                                               
                                                                <ng-container matColumnDef="Name">
                                                                    <th mat-header-cell *matHeaderCellDef
                                                                        mat-sort-header>Thành viên
                                                                    </th>
                                                                    <td mat-cell *matCellDef="let element">
                                                                        {{element.Name}}
                                                                    </td>
                                                                </ng-container>
                                                                <ng-container matColumnDef="Active">
                                                                    <th mat-header-cell *matHeaderCellDef
                                                                        mat-sort-header>Kích hoạt
                                                                    </th>
                                                                    <td mat-cell *matCellDef="let element">
                                                                        <div class="form-check form-check-inline">
                                                                            <input class="form-check-input"
                                                                                type="checkbox"
                                                                                name="Active{{element.ID}}"
                                                                                [(ngModel)]="element.Active">
                                                                        </div>
                                                                    </td>
                                                                </ng-container>
                                                                <ng-container matColumnDef="Save">
                                                                    <th mat-header-cell *matHeaderCellDef
                                                                        mat-sort-header>
                                                                    </th>
                                                                    <td mat-cell *matCellDef="let element">
                                                                        <a class="btn btn-danger"
                                                                            (click)="ThongBaoThanhVienDelete(element)"
                                                                            *ngIf="element.ID>0"><i
                                                                                class="bi bi-trash"></i>
                                                                        </a>
                                                                    </td>
                                                                </ng-container>
                                                                <tr mat-header-row
                                                                    *matHeaderRowDef="ThongBaoThanhVienService.displayColumns001">
                                                                </tr>
                                                                <tr mat-row
                                                                    *matRowDef="let row; columns: ThongBaoThanhVienService.displayColumns001;">
                                                                </tr>
                                                            </table>
                                                            <mat-paginator #ThongBaoThanhVienPaginator="matPaginator"
                                                                [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                                [showFirstLastButtons]></mat-paginator>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>