import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TaiLieu } from 'src/app/shared/TaiLieu.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class TaiLieuService extends BaseService{

    displayColumns001: string[] = ['STT', 'ParentID', 'Code', 'Display', 'Name', 'SortOrder','Active', 'FileName', 'Save'];    

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "TaiLieu";
    }
}

