<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-hypnotize fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">{{ToChucQuyTrinhSanXuatService.formData.Name}} [{{ToChucQuyTrinhSanXuatService.formData.ParentID}}]</h2>
            <h6 class="m-0 text-dark fw-light">
                Hợp tác xã
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
            <a class="btn btn-danger" title="Đóng" (click)="Close()">
                <i class="bi bi-x-lg"></i>
            </a>
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ToChucQuyTrinhSanXuatDetail" data-bs-toggle="tab"
                                    href="#ToChucQuyTrinhSanXuatDetail" role="tab"
                                    aria-controls="ToChucQuyTrinhSanXuatDetail" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Hợp tác xã</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucQuyTrinhSanXuatChiTietDetail" data-bs-toggle="tab"
                                    href="#ToChucQuyTrinhSanXuatChiTietDetail" role="tab"
                                    aria-controls="ToChucQuyTrinhSanXuatChiTietDetail" aria-selected="false">
                                    <span class="badge bg-danger" style="font-size: 16px;">Tổng hợp</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucQuyTrinhSanXuatChiTiet1Detail" data-bs-toggle="tab"
                                    href="#ToChucQuyTrinhSanXuatChiTiet1Detail" role="tab"
                                    aria-controls="ToChucQuyTrinhSanXuatChiTiet1Detail" aria-selected="false">
                                    <span class="badge bg-success" style="font-size: 16px;">Vụ mùa</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucQuyTrinhSanXuatChiTiet2Detail" data-bs-toggle="tab"
                                    href="#ToChucQuyTrinhSanXuatChiTiet2Detail" role="tab"
                                    aria-controls="ToChucQuyTrinhSanXuatChiTiet2Detail" aria-selected="false">
                                    <span class="badge bg-warning" style="font-size: 16px;">Làm đất</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucQuyTrinhSanXuatChiTiet3Detail" data-bs-toggle="tab"
                                    href="#ToChucQuyTrinhSanXuatChiTiet3Detail" role="tab"
                                    aria-controls="ToChucQuyTrinhSanXuatChiTiet3Detail" aria-selected="false">
                                    <span class="badge bg-primary" style="font-size: 16px;">Giống</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucQuyTrinhSanXuatChiTiet4Detail" data-bs-toggle="tab"
                                    href="#ToChucQuyTrinhSanXuatChiTiet4Detail" role="tab"
                                    aria-controls="ToChucQuyTrinhSanXuatChiTiet4Detail" aria-selected="false">
                                    <span class="badge bg-dark" style="font-size: 16px;">Phân bón</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucQuyTrinhSanXuatChiTiet5Detail" data-bs-toggle="tab"
                                    href="#ToChucQuyTrinhSanXuatChiTiet5Detail" role="tab"
                                    aria-controls="ToChucQuyTrinhSanXuatChiTiet5Detail" aria-selected="false">
                                    <span class="badge bg-info" style="font-size: 16px;">BVTV</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucQuyTrinhSanXuatChiTiet6Detail" data-bs-toggle="tab"
                                    href="#ToChucQuyTrinhSanXuatChiTiet6Detail" role="tab"
                                    aria-controls="ToChucQuyTrinhSanXuatChiTiet6Detail" aria-selected="false">
                                    <span class="badge bg-danger" style="font-size: 16px;">Thu hoạch</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucQuyTrinhSanXuatChiTiet7Detail" data-bs-toggle="tab"
                                    href="#ToChucQuyTrinhSanXuatChiTiet7Detail" role="tab"
                                    aria-controls="ToChucQuyTrinhSanXuatChiTiet7Detail" aria-selected="false">
                                    <span class="badge bg-success" style="font-size: 16px;">Sau thu hoạch</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ToChucQuyTrinhSanXuatDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <a style="width: 100%;" title="Lưu thay đổi"
                                            (click)="ToChucQuyTrinhSanXuatSave()" class="btn btn-info"><i
                                                class="bi bi-sd-card"></i></a>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <a style="width: 100%;" title="Năng lực sản xuất" (click)="ToChucAdd()"
                                            class="btn btn-success"><i class="bi bi-arrows-fullscreen"></i> Hợp tác xã</a>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <!-- <a style="width: 100%;" title="Hồ sơ (In và xuất PDF)" (click)="ToChucExportHopTacXaByIDToHTMLAsync()"
                                            class="btn btn-success"><i class="bi bi-printer"></i> Hồ sơ (In và xuất PDF)</a> -->
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="IsDapUngDuAn"
                                                [(ngModel)]="ToChucQuyTrinhSanXuatService.formData.IsDapUngDuAn" />
                                            <label class="form-check-label" for="IsDapUngDuAn">Đáp ứng dự án</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tiêu đề</label>
                                            <input name="Name" [(ngModel)]="ToChucQuyTrinhSanXuatService.formData.Name"
                                                placeholder="Tiêu đề" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Mã số thuế</label>
                                            <input name="MaSoThue"
                                                [(ngModel)]="ToChucQuyTrinhSanXuatService.formData.Code"
                                                placeholder="Mã số thuế" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Điện thoại</label>
                                            <input name="DienThoai"
                                                [(ngModel)]="ToChucQuyTrinhSanXuatService.formData.Display"
                                                placeholder="Điện thoại" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ghi chú</label>
                                            <input name="Note" [(ngModel)]="ToChucQuyTrinhSanXuatService.formData.Note"
                                                placeholder="Ghi chú" type="text" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Diện tích (ha)</label>
                                            <input name="DienTich"
                                                [(ngModel)]="ToChucQuyTrinhSanXuatService.formData.DienTich"
                                                placeholder="0" type="number" class="form-control"
                                                style="text-align: right;">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Số vụ</label>
                                            <input name="SoVu" [(ngModel)]="ToChucQuyTrinhSanXuatService.formData.SoVu"
                                                placeholder="SoVu" type="number" class="form-control"
                                                style="text-align: right;">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ngày cập nhật cuối</label>
                                            <input name="LastUpdatedDate"
                                                [ngModel]="ToChucQuyTrinhSanXuatService.formData.LastUpdatedDate"
                                                disabled (ngModelChange)="LastUpdatedDateChange($event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" placeholder="Ngày cập nhật cuối"
                                                type="datetime" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Người cập nhật cuối</label>
                                            <input name="LastUpdatedMembershipID"
                                                [(ngModel)]="ToChucQuyTrinhSanXuatService.formData.LastUpdatedMembershipID"
                                                disabled placeholder="Người cập nhật cuối" type="text"
                                                class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucQuyTrinhSanXuatChiTietDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucQuyTrinhSanXuatChiTietService.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="ToChucQuyTrinhSanXuatChiTietSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm"
                                            (click)="ToChucQuyTrinhSanXuatChiTietSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="card mb-4 card-danger">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Tổng hợp
                                            <span
                                                *ngIf="ToChucQuyTrinhSanXuatChiTietService.list">({{ToChucQuyTrinhSanXuatChiTietService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucQuyTrinhSanXuatChiTietSort="matSort"
                                                    [dataSource]="ToChucQuyTrinhSanXuatChiTietDataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucQuyTrinhSanXuatChiTietPaginator.pageSize *
                                                            ToChucQuyTrinhSanXuatChiTietPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucQuyTrinhSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Quy trình
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucQuyTrinhSanXuatID"
                                                                [(ngModel)]="element.DanhMucQuyTrinhSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucQuyTrinhSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucPhanDoanSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phân đoạn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucPhanDoanSanXuatID"
                                                                [(ngModel)]="element.DanhMucPhanDoanSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucPhanDoanSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoLuong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chỉ số kế
                                                            hoạch (Tháng | %)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder=""
                                                                name="SoLuong{{element.ID}}"
                                                                [(ngModel)]="element.SoLuong">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chọn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucQuyTrinhSanXuatChiTietService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucQuyTrinhSanXuatChiTietService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucQuyTrinhSanXuatChiTietPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucQuyTrinhSanXuatChiTiet1Detail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucQuyTrinhSanXuatChiTietService.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="ToChucQuyTrinhSanXuatChiTietSearch1()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm"
                                            (click)="ToChucQuyTrinhSanXuatChiTietSearch1()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="card mb-4 card-success">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Vụ mùa
                                            <span
                                                *ngIf="ToChucQuyTrinhSanXuatChiTietService.list001">({{ToChucQuyTrinhSanXuatChiTietService.list001.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucQuyTrinhSanXuatChiTietSort1="matSort"
                                                    [dataSource]="ToChucQuyTrinhSanXuatChiTietDataSource1"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucQuyTrinhSanXuatChiTietPaginator1.pageSize *
                                                            ToChucQuyTrinhSanXuatChiTietPaginator1.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucQuyTrinhSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Quy trình
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucQuyTrinhSanXuatID"
                                                                [(ngModel)]="element.DanhMucQuyTrinhSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucQuyTrinhSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucPhanDoanSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phân đoạn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucPhanDoanSanXuatID"
                                                                [(ngModel)]="element.DanhMucPhanDoanSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucPhanDoanSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoLuong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chỉ số kế
                                                            hoạch (Tháng | %)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder=""
                                                                name="SoLuong{{element.ID}}"
                                                                [(ngModel)]="element.SoLuong">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chọn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucQuyTrinhSanXuatChiTietService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucQuyTrinhSanXuatChiTietService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucQuyTrinhSanXuatChiTietPaginator1="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucQuyTrinhSanXuatChiTiet2Detail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucQuyTrinhSanXuatChiTietService.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="ToChucQuyTrinhSanXuatChiTietSearch2()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm"
                                            (click)="ToChucQuyTrinhSanXuatChiTietSearch2()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="card mb-4 card-warning">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Làm đất
                                            <span
                                                *ngIf="ToChucQuyTrinhSanXuatChiTietService.list002">({{ToChucQuyTrinhSanXuatChiTietService.list002.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucQuyTrinhSanXuatChiTietSort2="matSort"
                                                    [dataSource]="ToChucQuyTrinhSanXuatChiTietDataSource2"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucQuyTrinhSanXuatChiTietPaginator2.pageSize *
                                                            ToChucQuyTrinhSanXuatChiTietPaginator2.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucQuyTrinhSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Quy trình
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucQuyTrinhSanXuatID"
                                                                [(ngModel)]="element.DanhMucQuyTrinhSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucQuyTrinhSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucPhanDoanSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phân đoạn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucPhanDoanSanXuatID"
                                                                [(ngModel)]="element.DanhMucPhanDoanSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucPhanDoanSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoLuong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chỉ số kế
                                                            hoạch (Tháng | %)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder=""
                                                                name="SoLuong{{element.ID}}"
                                                                [(ngModel)]="element.SoLuong">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chọn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucQuyTrinhSanXuatChiTietService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucQuyTrinhSanXuatChiTietService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucQuyTrinhSanXuatChiTietPaginator2="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucQuyTrinhSanXuatChiTiet3Detail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucQuyTrinhSanXuatChiTietService.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="ToChucQuyTrinhSanXuatChiTietSearch3()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm"
                                            (click)="ToChucQuyTrinhSanXuatChiTietSearch3()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="card mb-4 card-primary">
                                    <div class="card-header">
                                        <h5 class="card-title text-white bg-primary">Giống
                                            <span
                                                *ngIf="ToChucQuyTrinhSanXuatChiTietService.list003">({{ToChucQuyTrinhSanXuatChiTietService.list003.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body bg-primary">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucQuyTrinhSanXuatChiTietSort3="matSort"
                                                    [dataSource]="ToChucQuyTrinhSanXuatChiTietDataSource3"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucQuyTrinhSanXuatChiTietPaginator3.pageSize *
                                                            ToChucQuyTrinhSanXuatChiTietPaginator3.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucQuyTrinhSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Quy trình
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucQuyTrinhSanXuatID"
                                                                [(ngModel)]="element.DanhMucQuyTrinhSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucQuyTrinhSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucPhanDoanSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phân đoạn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucPhanDoanSanXuatID"
                                                                [(ngModel)]="element.DanhMucPhanDoanSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucPhanDoanSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoLuong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chỉ số kế
                                                            hoạch (Tháng | %)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder=""
                                                                name="SoLuong{{element.ID}}"
                                                                [(ngModel)]="element.SoLuong">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chọn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucQuyTrinhSanXuatChiTietService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucQuyTrinhSanXuatChiTietService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucQuyTrinhSanXuatChiTietPaginator3="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucQuyTrinhSanXuatChiTiet4Detail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucQuyTrinhSanXuatChiTietService.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="ToChucQuyTrinhSanXuatChiTietSearch4()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm"
                                            (click)="ToChucQuyTrinhSanXuatChiTietSearch4()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="card mb-4 card-dark">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Phân bón
                                            <span
                                                *ngIf="ToChucQuyTrinhSanXuatChiTietService.list004">({{ToChucQuyTrinhSanXuatChiTietService.list004.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucQuyTrinhSanXuatChiTietSort4="matSort"
                                                    [dataSource]="ToChucQuyTrinhSanXuatChiTietDataSource4"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucQuyTrinhSanXuatChiTietPaginator4.pageSize *
                                                            ToChucQuyTrinhSanXuatChiTietPaginator4.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucQuyTrinhSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Quy trình
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucQuyTrinhSanXuatID"
                                                                [(ngModel)]="element.DanhMucQuyTrinhSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucQuyTrinhSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucPhanDoanSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phân đoạn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucPhanDoanSanXuatID"
                                                                [(ngModel)]="element.DanhMucPhanDoanSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucPhanDoanSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoLuong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chỉ số kế
                                                            hoạch (Tháng | %)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder=""
                                                                name="SoLuong{{element.ID}}"
                                                                [(ngModel)]="element.SoLuong">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chọn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucQuyTrinhSanXuatChiTietService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucQuyTrinhSanXuatChiTietService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucQuyTrinhSanXuatChiTietPaginator4="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucQuyTrinhSanXuatChiTiet5Detail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucQuyTrinhSanXuatChiTietService.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="ToChucQuyTrinhSanXuatChiTietSearch5()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm"
                                            (click)="ToChucQuyTrinhSanXuatChiTietSearch5()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Bảo vệ thực vật
                                            <span
                                                *ngIf="ToChucQuyTrinhSanXuatChiTietService.list005">({{ToChucQuyTrinhSanXuatChiTietService.list005.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucQuyTrinhSanXuatChiTietSort5="matSort"
                                                    [dataSource]="ToChucQuyTrinhSanXuatChiTietDataSource5"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucQuyTrinhSanXuatChiTietPaginator5.pageSize *
                                                            ToChucQuyTrinhSanXuatChiTietPaginator5.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucQuyTrinhSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Quy trình
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucQuyTrinhSanXuatID"
                                                                [(ngModel)]="element.DanhMucQuyTrinhSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucQuyTrinhSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucPhanDoanSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phân đoạn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucPhanDoanSanXuatID"
                                                                [(ngModel)]="element.DanhMucPhanDoanSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucPhanDoanSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoLuong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chỉ số kế
                                                            hoạch (Tháng | %)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder=""
                                                                name="SoLuong{{element.ID}}"
                                                                [(ngModel)]="element.SoLuong">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chọn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucQuyTrinhSanXuatChiTietService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucQuyTrinhSanXuatChiTietService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucQuyTrinhSanXuatChiTietPaginator5="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucQuyTrinhSanXuatChiTiet6Detail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucQuyTrinhSanXuatChiTietService.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="ToChucQuyTrinhSanXuatChiTietSearch6()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm"
                                            (click)="ToChucQuyTrinhSanXuatChiTietSearch6()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="card mb-4 card-danger">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Thu hoạch
                                            <span
                                                *ngIf="ToChucQuyTrinhSanXuatChiTietService.list006">({{ToChucQuyTrinhSanXuatChiTietService.list006.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucQuyTrinhSanXuatChiTietSort6="matSort"
                                                    [dataSource]="ToChucQuyTrinhSanXuatChiTietDataSource6"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucQuyTrinhSanXuatChiTietPaginator6.pageSize *
                                                            ToChucQuyTrinhSanXuatChiTietPaginator6.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucQuyTrinhSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Quy trình
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucQuyTrinhSanXuatID"
                                                                [(ngModel)]="element.DanhMucQuyTrinhSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucQuyTrinhSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucPhanDoanSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phân đoạn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucPhanDoanSanXuatID"
                                                                [(ngModel)]="element.DanhMucPhanDoanSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucPhanDoanSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoLuong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chỉ số kế
                                                            hoạch (Tháng | %)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder=""
                                                                name="SoLuong{{element.ID}}"
                                                                [(ngModel)]="element.SoLuong">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chọn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucQuyTrinhSanXuatChiTietService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucQuyTrinhSanXuatChiTietService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucQuyTrinhSanXuatChiTietPaginator6="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucQuyTrinhSanXuatChiTiet7Detail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucQuyTrinhSanXuatChiTietService.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="ToChucQuyTrinhSanXuatChiTietSearch7()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm"
                                            (click)="ToChucQuyTrinhSanXuatChiTietSearch7()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="card mb-4 card-success">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Sau Thu hoạch
                                            <span
                                                *ngIf="ToChucQuyTrinhSanXuatChiTietService.list007">({{ToChucQuyTrinhSanXuatChiTietService.list007.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucQuyTrinhSanXuatChiTietSort7="matSort"
                                                    [dataSource]="ToChucQuyTrinhSanXuatChiTietDataSource7"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucQuyTrinhSanXuatChiTietPaginator7.pageSize *
                                                            ToChucQuyTrinhSanXuatChiTietPaginator7.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucQuyTrinhSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Quy trình
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucQuyTrinhSanXuatID"
                                                                [(ngModel)]="element.DanhMucQuyTrinhSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucQuyTrinhSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucPhanDoanSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phân đoạn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucPhanDoanSanXuatID"
                                                                [(ngModel)]="element.DanhMucPhanDoanSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucPhanDoanSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoLuong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chỉ số kế
                                                            hoạch (Tháng | %)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder=""
                                                                name="SoLuong{{element.ID}}"
                                                                [(ngModel)]="element.SoLuong">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chọn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucQuyTrinhSanXuatChiTietService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucQuyTrinhSanXuatChiTietService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucQuyTrinhSanXuatChiTietPaginator7="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>