import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets, Chart, ChartConfiguration, ChartData } from 'chart.js';
import { Color, Label, SingleDataSet, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { Report } from 'src/app/shared/Report.model';
import { ReportService } from 'src/app/shared/Report.service';

import { ThoiTiet } from 'src/app/shared/ThoiTiet.model';
import { ThoiTietService } from 'src/app/shared/ThoiTiet.service';
import { TaiLieu } from 'src/app/shared/TaiLieu.model';
import { TaiLieuService } from 'src/app/shared/TaiLieu.service';

@Component({
  selector: 'app-thong-ke001',
  templateUrl: './thong-ke001.component.html',
  styleUrls: ['./thong-ke001.component.css']
})
export class ThongKe001Component implements OnInit {

  isShowLoading: boolean = false;
  domainName = environment.DomainDestination;
  searchString: string = environment.InitializationString;
  DanhMucTinhThanhID: number = environment.InitializationNumber;
  DanhMucQuanHuyenID: number = environment.InitializationNumber;
  DanhMucXaPhuongID: number = environment.InitializationNumber;

  constructor(
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,


    public ReportService: ReportService,
    public ThoiTietService: ThoiTietService,
    public TaiLieuService: TaiLieuService,
  ) { }

  ngOnInit(): void {
    //this.ThongKe0001Async();
    this.ThongKe0002ToListAsync();
    // this.ThongKe0003ToListAsync();
    // this.ThongKe0004ToListAsync();
    // this.ThongKe0005ToListAsync();
    // this.ThongKe0006ToListAsync();
    // this.ThongKe0007ToListAsync();
    // this.ThongKe0008ToListAsync();
    // this.ThongKe0009ToListAsync();
    // this.ThongKe0010ToListAsync();
    // this.ThongKe0011ToListAsync();
    // this.ThongKe0012ToListAsync();
    // this.ThongKe0013ToListAsync();
    // this.ThongKe0014ToListAsync();
    // this.ThongKe0015ToListAsync();
    // this.ThongKe0016ToListAsync();
    // this.ThongKe0017ToListAsync();
    // this.ThongKe0018ToListAsync();
    // this.ThongKe0019ToListAsync();
    // this.ThongKe0020ToListAsync();
    // this.ThoiTietGetByNowToListAsync();
    // this.ReportThoiTiet0001();
    // this.TaiLieuGetToListAsync();
  }

  TaiLieuGetToListAsync() {
    this.isShowLoading = true;
    this.TaiLieuService.baseParameter.ParentID = 1;
    this.TaiLieuService.baseParameter.Active = true;
    this.TaiLieuService.GetByParentIDAndActiveToListAsync().subscribe(
      res => {
        this.TaiLieuService.list = (res as TaiLieu[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ReportThoiTiet0001() {
    this.isShowLoading = true;
    this.ReportService.ReportThoiTiet0001ToListAsync().subscribe(
      res => {
        this.ReportService.listThoiTiet0001 = (res as Report[]);
        let codeArray = [];
        let nameArray = [];
        for (let i = 0; i < this.ReportService.listThoiTiet0001.length; i++) {
          let code = codeArray.filter(item => item == this.ReportService.listThoiTiet0001[i].Code);
          if (code) {
            if (code.length == 0) {
              codeArray.push(this.ReportService.listThoiTiet0001[i].Code);
            }
          }
          let nameArraySub = nameArray.filter(item => item == this.ReportService.listThoiTiet0001[i].Name);
          if (nameArraySub) {
            if (nameArraySub.length == 0) {
              let name = this.ReportService.listThoiTiet0001[i].Name;

              nameArray.push(name);
              let dataArray = this.ReportService.listThoiTiet0001.filter(item => item.Name == name);

              let NhietDo = [];
              let DoAm = [];
              for (let j = 0; j < dataArray.length; j++) {
                NhietDo.push(dataArray[j].NhietDoCaoNhat);
                DoAm.push(dataArray[j].DoAmCaoNhat);
              }
              let dataNhietDo: any = {
                type: "line",
                fill: false,
                data: NhietDo,
                label: name,
                borderColor: this.DownloadService.GetRandomColor(NhietDo.length)
              }
              let dataDoAm: any = {
                type: "line",
                fill: false,
                data: DoAm,
                label: name,
                borderColor: this.DownloadService.GetRandomColor(DoAm.length)
              }
              this.ChartDataReportThoiTiet0001NhietDo.push(dataNhietDo);
              this.ChartDataReportThoiTiet0001DoAm.push(dataDoAm);
            }
          }
        }
        this.ChartLabelsReportThoiTiet0001NhietDo = codeArray;
        this.ChartLabelsReportThoiTiet0001DoAm = codeArray;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ThoiTietGetByNowToListAsync() {
    this.isShowLoading = true;
    this.ThoiTietService.GetByNowToListAsync().subscribe(
      res => {
        this.ThoiTietService.list = (res as ThoiTiet[]);

        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ThongKe0001Async() {
    this.isShowLoading = true;
    this.ReportService.ThongKe0001Async().subscribe(
      res => {
        this.ReportService.formData = (res as Report);
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ThongKe0002ToListAsync() {
    this.isShowLoading = true;
    this.ReportService.ThongKe0002ToListAsync().subscribe(
      res => {
        this.ReportService.listThongKe0002 = (res as Report[]);

        this.ThongKe0003ToListAsync();
    

        let labelArray = [];
        let dataArray001 = [];
        let dataArray002 = [];
        let dataArray003 = [];

        let dataArray011 = [];
        let dataArray012 = [];
        let dataArray013 = [];

        for (let i = 0; i < this.ReportService.listThongKe0002.length-1; i++) {
          let report = this.ReportService.listThongKe0002[i];
          if (report.Name != environment.ZTong) {
            labelArray.push(report.Name);
            dataArray001.push(report.SoLuongTongHop);
            dataArray002.push(report.SoLuongKhaoSat);
            dataArray003.push(report.TyLeHoanThanhSoLuong);

            dataArray011.push(report.DienTichTongHop);
            dataArray012.push(report.DienTichKhaoSat);
            dataArray013.push(report.TyLeHoanThanhDienTich);
          }
        }
        let label001: string = 'Tổng số lượng';
        let label002: string = 'Số lượng tham gia';
        let label003: string = 'Tỷ lệ (%)';

        let label011: string = 'Tổng diện tích';
        let label012: string = 'Diện tích tham gia';
        let label013: string = 'Diện tích trung bình';

        this.ChartLabelsReportThongKe0002 = labelArray;
        this.ChartDataReportThongKe0002 = [
          { data: dataArray001, label: label001, stack: 'b', yAxisID: 'B', },
          { data: dataArray002, label: label002, stack: 'b', yAxisID: 'B', },
          { data: dataArray003, label: label003, stack: 'a', type: 'line', fill: false, yAxisID: 'A', },
        ];

        this.ChartLabelsReportThongKe0002DienTich = labelArray;
        this.ChartDataReportThongKe0002DienTich = [
          { data: dataArray011, label: label011, stack: 'b', yAxisID: 'B', },
          { data: dataArray012, label: label012, stack: 'b', yAxisID: 'B', },
          { data: dataArray013, label: label013, stack: 'a', type: 'line', fill: false, yAxisID: 'A', },
        ];

        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ThongKe0003ToListAsync() {
    this.isShowLoading = true;
    this.ReportService.ThongKe0003ToListAsync().subscribe(
      res => {
        this.ReportService.listThongKe0003 = (res as Report[]);

        this.ThongKe0004ToListAsync();
   

        let labelArray = [];
        let dataArray001 = [];
        let dataArray002 = [];
        let dataArray003 = [];


        for (let i = 0; i < this.ReportService.listThongKe0003.length; i++) {
          let report = this.ReportService.listThongKe0003[i];
          if (report.Name != environment.ZTong) {
            labelArray.push(report.Name);
            dataArray001.push(report.SoLuongKhaoSat);
            dataArray002.push(report.ThanhVienKhaoSat);
            dataArray003.push(report.TyLeHoanThanhSoLuong);
          }
        }
        let label001: string = 'Tổng số HTX THAM GIA ĐỀ ÁN giai đoạn 2023-2025';
        let label002: string = 'Tổng số thành viên';
        let label003: string = 'Thành viên trung bình';



        this.ChartLabelsReportThongKe0003 = labelArray;
        this.ChartDataReportThongKe0003 = [
          { data: dataArray001, label: label001, stack: 'b', yAxisID: 'A', },
          { data: dataArray002, label: label002, stack: 'b', yAxisID: 'B', },
          { data: dataArray003, label: label003, stack: 'a', type: 'line', fill: false, yAxisID: 'A', },
        ];

        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ThongKe0004ToListAsync() {
    this.isShowLoading = true;
    this.ReportService.ThongKe0004ToListAsync().subscribe(
      res => {
        this.ReportService.listThongKe0004 = (res as Report[]);

        this.ThongKe0005ToListAsync();
        

        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ThongKe0005ToListAsync() {
    this.isShowLoading = true;
    this.ReportService.ThongKe0005ToListAsync().subscribe(
      res => {
        this.ReportService.listThongKe0005 = (res as Report[]);

        this.ThongKe0006ToListAsync();
        

        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ThongKe0006ToListAsync() {
    this.isShowLoading = true;
    this.ReportService.ThongKe0006ToListAsync().subscribe(
      res => {
        this.ReportService.listThongKe0006 = (res as Report[]);

        this.ThongKe0007ToListAsync();
        

        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ThongKe0007ToListAsync() {
    this.isShowLoading = true;
    this.ReportService.ThongKe0007ToListAsync().subscribe(
      res => {
        this.ReportService.listThongKe0007 = (res as Report[]);

        this.ThongKe0008ToListAsync();
        

        let labelArray = [];
        let dataArray001 = [];
        let dataArray002 = [];
        let dataArray003 = [];


        for (let i = 0; i < this.ReportService.listThongKe0007.length; i++) {
          let report = this.ReportService.listThongKe0007[i];
          if (report.Name != environment.ZTong) {
            labelArray.push(report.Name);
            dataArray001.push(report.SoLuongTongHop);
            dataArray002.push(report.SoLuongKhaoSat);
            dataArray003.push(report.TyLeHoanThanhSoLuong);
          }
        }
        let label001: string = 'Tổng số HTX THAM GIA ĐỀ ÁN giai đoạn 2023-2025';
        let label002: string = 'HTX có trụ sở';
        let label003: string = 'Tỷ lệ (%)';



        this.ChartLabelsReportThongKe0007 = labelArray;
        this.ChartDataReportThongKe0007 = [
          { data: dataArray001, label: label001, stack: 'b', yAxisID: 'B', },
          { data: dataArray002, label: label002, stack: 'b', yAxisID: 'B', },
          { data: dataArray003, label: label003, stack: 'a', type: 'line', fill: false, yAxisID: 'A', },
        ];

        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ThongKe0008ToListAsync() {
    this.isShowLoading = true;
    this.ReportService.ThongKe0008ToListAsync().subscribe(
      res => {
        this.ReportService.listThongKe0008 = (res as Report[]);

        this.ThongKe0009ToListAsync();
        

        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ThongKe0009ToListAsync() {
    this.isShowLoading = true;
    this.ReportService.ThongKe0009ToListAsync().subscribe(
      res => {
        this.ReportService.listThongKe0009 = (res as Report[]);

        this.ThongKe0010ToListAsync();
        

        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ThongKe0010ToListAsync() {
    this.isShowLoading = true;
    this.ReportService.ThongKe0010ToListAsync().subscribe(
      res => {
        this.ReportService.listThongKe0010 = (res as Report[]);

        this.ThongKe0011ToListAsync();
        

        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ThongKe0011ToListAsync() {
    this.isShowLoading = true;
    this.ReportService.ThongKe0011ToListAsync().subscribe(
      res => {
        this.ReportService.listThongKe0011 = (res as Report[]);

        this.ThongKe0012ToListAsync();
       

        let labelArray = [];
        let dataArray001 = [];
        let dataArray002 = [];



        for (let i = 0; i < this.ReportService.listThongKe0011.length; i++) {
          let report = this.ReportService.listThongKe0011[i];
          if (report.Name != environment.ZTong) {
            labelArray.push(report.Name);
            dataArray001.push(report.DienTichKhaoSat);
            dataArray002.push(report.SanLuongKhaoSat);
          }
        }
        let label001: string = 'Diện tích';
        let label002: string = 'Sản lượng';



        this.ChartLabelsReportThongKe0011 = labelArray;
        this.ChartDataReportThongKe0011 = [
          { data: dataArray001, label: label001, stack: 'b', yAxisID: 'B', },
          { data: dataArray002, label: label002, stack: 'a', type: 'line', fill: false, yAxisID: 'A', },
        ];

        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ThongKe0012ToListAsync() {
    this.isShowLoading = true;
    this.ReportService.ThongKe0012ToListAsync().subscribe(
      res => {
        this.ReportService.listThongKe0012 = (res as Report[]);

        this.ThongKe0013ToListAsync();
        

        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ThongKe0013ToListAsync() {
    this.isShowLoading = true;
    this.ReportService.ThongKe0013ToListAsync().subscribe(
      res => {
        this.ReportService.listThongKe0013 = (res as Report[]);

        this.ThongKe0014ToListAsync();
        

        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ThongKe0014ToListAsync() {
    this.isShowLoading = true;
    this.ReportService.ThongKe0014ToListAsync().subscribe(
      res => {
        this.ReportService.listThongKe0014 = (res as Report[]);

        this.ThongKe0015ToListAsync();
        

        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ThongKe0015ToListAsync() {
    this.isShowLoading = true;
    this.ReportService.ThongKe0015ToListAsync().subscribe(
      res => {
        this.ReportService.listThongKe0015 = (res as Report[]);

        this.ThongKe0016ToListAsync();
        

        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ThongKe0016ToListAsync() {
    this.isShowLoading = true;
    this.ReportService.ThongKe0016ToListAsync().subscribe(
      res => {
        this.ReportService.listThongKe0016 = (res as Report[]);

        this.ThongKe0017ToListAsync();
       

        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ThongKe0017ToListAsync() {
    this.isShowLoading = true;
    this.ReportService.ThongKe0017ToListAsync().subscribe(
      res => {
        this.ReportService.listThongKe0017 = (res as Report[]);

        this.ThongKe0018ToListAsync();
        

        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ThongKe0018ToListAsync() {
    this.isShowLoading = true;
    this.ReportService.ThongKe0018ToListAsync().subscribe(
      res => {
        this.ReportService.listThongKe0018 = (res as Report[]);

        this.ThongKe0019ToListAsync();
        

        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ThongKe0019ToListAsync() {
    this.isShowLoading = true;
    this.ReportService.ThongKe0019ToListAsync().subscribe(
      res => {
        this.ReportService.listThongKe0019 = (res as Report[]);

        this.ThongKe0020ToListAsync();
        

        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ThongKe0020ToListAsync() {
    this.isShowLoading = true;
    this.ReportService.ThongKe0020ToListAsync().subscribe(
      res => {
        this.ReportService.listThongKe0020 = (res as Report[]);

        this.ThoiTietGetByNowToListAsync();
        this.ReportThoiTiet0001();
        this.TaiLieuGetToListAsync();

        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }




  public ChartOptionsReportThongKe0002: ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      position: 'bottom'
    },
    animation: {
      duration: 1,
      onComplete: function () {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        ctx.textAlign = 'center';
        ctx.fillStyle = "rgba(0, 0, 0, 1)";
        ctx.textBaseline = 'bottom';
        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            var data = dataset.data[index];
            ctx.fillText(data, bar._model.x, bar._model.y - 5);

          });
        });
      }
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          return Number(tooltipItem.yLabel).toFixed(0).replace(/./g, function (c, i, a) {
            return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "." + c : c;
          });
        }
      }
    },
    scales: {
      yAxes: [
        {
          id: 'A',
          position: 'left',
        }, {
          id: 'B',
          position: 'right',
        }
      ]
    },
  };
  public ChartColorsReportThongKe0002: Color[] = [
  ]
  public ChartLabelsReportThongKe0002: Label[] = [];
  public ChartTypeReportThongKe0002: ChartType = 'bar';
  public ChartLegendReportThongKe0002 = true;
  public ChartPluginsReportThongKe0002 = [];

  public ChartDataReportThongKe0002: ChartDataSets[] = [
  ];

  public ChartOptionsReportThongKe0002DienTich: ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      position: 'bottom'
    },
    animation: {
      duration: 1,
      onComplete: function () {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        ctx.textAlign = 'center';
        ctx.fillStyle = "rgba(0, 0, 0, 1)";
        ctx.textBaseline = 'bottom';
        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            var data = dataset.data[index];
            ctx.fillText(data, bar._model.x, bar._model.y - 5);

          });
        });
      }
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          return Number(tooltipItem.yLabel).toFixed(0).replace(/./g, function (c, i, a) {
            return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "." + c : c;
          });
        }
      }
    },
    scales: {
      yAxes: [
        {
          id: 'A',
          position: 'left',
        }, {
          id: 'B',
          position: 'right',
        }
      ]
    },
  };
  public ChartColorsReportThongKe0002DienTich: Color[] = [
  ]
  public ChartLabelsReportThongKe0002DienTich: Label[] = [];
  public ChartTypeReportThongKe0002DienTich: ChartType = 'bar';
  public ChartLegendReportThongKe0002DienTich = true;
  public ChartPluginsReportThongKe0002DienTich = [];

  public ChartDataReportThongKe0002DienTich: ChartDataSets[] = [
  ];

  public ChartOptionsReportThongKe0003: ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      position: 'bottom'
    },
    animation: {
      duration: 1,
      onComplete: function () {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        ctx.textAlign = 'center';
        ctx.fillStyle = "rgba(0, 0, 0, 1)";
        ctx.textBaseline = 'bottom';
        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            var data = dataset.data[index];
            ctx.fillText(data, bar._model.x, bar._model.y - 5);

          });
        });
      }
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          return Number(tooltipItem.yLabel).toFixed(0).replace(/./g, function (c, i, a) {
            return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "." + c : c;
          });
        }
      }
    },
    scales: {
      yAxes: [
        {
          id: 'A',
          position: 'left',
        }, {
          id: 'B',
          position: 'right',
        }
      ]
    },
  };
  public ChartColorsReportThongKe0003: Color[] = [
  ]
  public ChartLabelsReportThongKe0003: Label[] = [];
  public ChartTypeReportThongKe0003: ChartType = 'bar';
  public ChartLegendReportThongKe0003 = true;
  public ChartPluginsReportThongKe0003 = [];

  public ChartDataReportThongKe0003: ChartDataSets[] = [
  ];

  public ChartOptionsReportThongKe0007: ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      position: 'bottom'
    },
    animation: {
      duration: 1,
      onComplete: function () {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        ctx.textAlign = 'center';
        ctx.fillStyle = "rgba(0, 0, 0, 1)";
        ctx.textBaseline = 'bottom';
        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            var data = dataset.data[index];
            ctx.fillText(data, bar._model.x, bar._model.y - 5);

          });
        });
      }
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          return Number(tooltipItem.yLabel).toFixed(0).replace(/./g, function (c, i, a) {
            return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "." + c : c;
          });
        }
      }
    },
    scales: {
      yAxes: [
        {
          id: 'A',
          position: 'left',
        }, {
          id: 'B',
          position: 'right',
        }
      ]
    },
  };
  public ChartColorsReportThongKe0007: Color[] = [
  ]
  public ChartLabelsReportThongKe0007: Label[] = [];
  public ChartTypeReportThongKe0007: ChartType = 'bar';
  public ChartLegendReportThongKe0007 = true;
  public ChartPluginsReportThongKe0007 = [];

  public ChartDataReportThongKe0007: ChartDataSets[] = [
  ];

  public ChartOptionsReportThongKe0011: ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      position: 'bottom'
    },
    animation: {
      duration: 1,
      onComplete: function () {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        ctx.textAlign = 'center';
        ctx.fillStyle = "rgba(0, 0, 0, 1)";
        ctx.textBaseline = 'bottom';
        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            var data = dataset.data[index];
            ctx.fillText(data, bar._model.x, bar._model.y - 5);

          });
        });
      }
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          return Number(tooltipItem.yLabel).toFixed(0).replace(/./g, function (c, i, a) {
            return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "." + c : c;
          });
        }
      }
    },
    scales: {
      yAxes: [
        {
          id: 'A',
          position: 'left',
        }, {
          id: 'B',
          position: 'right',
        }
      ]
    },
  };
  public ChartColorsReportThongKe0011: Color[] = [
  ]
  public ChartLabelsReportThongKe0011: Label[] = [];
  public ChartTypeReportThongKe0011: ChartType = 'bar';
  public ChartLegendReportThongKe0011 = true;
  public ChartPluginsReportThongKe0011 = [];

  public ChartDataReportThongKe0011: ChartDataSets[] = [
  ];

  public ChartOptionsReportThoiTiet0001NhietDo: ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      position: 'right'
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.labels[tooltipItem.index];
          var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return label + '';
        }
      }
    }
  };
  public ChartColorsReportThoiTiet0001NhietDo: Color[] = [
  ]
  public ChartLabelsReportThoiTiet0001NhietDo: Label[] = [];
  public ChartTypeReportThoiTiet0001NhietDo: ChartType = 'line';
  public ChartLegendReportThoiTiet0001NhietDo = true;
  public ChartPluginsReportThoiTiet0001NhietDo = [];

  public ChartDataReportThoiTiet0001NhietDo: ChartDataSets[] = [
  ];

  public ChartOptionsReportThoiTiet0001DoAm: ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      position: 'right'
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.labels[tooltipItem.index];
          var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return label + '';
        }
      }
    }
  };
  public ChartColorsReportThoiTiet0001DoAm: Color[] = [
  ]
  public ChartLabelsReportThoiTiet0001DoAm: Label[] = [];
  public ChartTypeReportThoiTiet0001DoAm: ChartType = 'line';
  public ChartLegendReportThoiTiet0001DoAm = true;
  public ChartPluginsReportThoiTiet0001DoAm = [];

  public ChartDataReportThoiTiet0001DoAm: ChartDataSets[] = [
  ];
}