<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-bar-chart fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Thống kê</h2>
            <h6 class="m-0 text-dark fw-light">
                Báo cáo thống kê
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
    </div>
</div>
<div class="app-body">
    <!-- <div class="row gx-4">
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-2 card-info">
                <div class="card-header">
                    <h5 class="card-title text-white">Địa điểm điều tra
                    </h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class=" text-white">
                            <h2 class="display-6 mb-2 fw-semibold">{{ReportService.formData.ID |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75" style="font-size: 16px;">Địa điểm điều tra thực hiện
                                đạt kế hoạch (Đơn vị tính: Tỉnh thành)
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-2 card-danger">
                <div class="card-header">
                    <h5 class="card-title text-white">HTX đăng ký
                    </h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class=" text-white">
                            <h2 class="display-6 mb-2 fw-semibold">{{ReportService.formData.SoLuongKhaoSat |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75" style="font-size: 16px;">Số lượng HTX đăng ký tham gia
                                đề án
                                (Đơn vị tính: Hợp tác xã)</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-2 card-success">
                <div class="card-header">
                    <h5 class="card-title text-white">HTX điều tra
                    </h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class=" text-white">
                            <h2 class="display-6 mb-2 fw-semibold">{{ReportService.formData.SoLuongTongHop |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75" style="font-size: 16px;">Số lượng Hợp tác xã tham gia
                                điều tra (Đơn vị tính: Hợp tác xã)</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-2 card-warning">
                <div class="card-header">
                    <h5 class="card-title text-white">Tổng diện tích
                    </h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class="text-white">
                            <h2 class="display-6 mb-2 fw-semibold">{{ReportService.formData.DienTichTongHop |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75" style="font-size: 16px;">Tổng diện tích khảo sát ghi
                                nhận tại HTX (Đơn vị tính: ha)</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-2 card-primary">
                <div class="card-header">
                    <h5 class="card-title text-white bg-primary">Tổng diện tích đăng ký
                    </h5>
                </div>
                <div class="card-body bg-primary">
                    <div class="d-flex justify-content-between">
                        <div class="text-white">
                            <h2 class="display-6 mb-2 fw-semibold">{{ReportService.formData.DienTichKhaoSat |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75" style="font-size: 16px;">Tổng diện tích đăng ký tham
                                gia đề án đến năm 2025 (Đơn vị tính: ha)</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-2 card-dark">
                <div class="card-header">
                    <h5 class="card-title text-white">Tỷ lệ diện tích
                    </h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class="text-white">
                            <h2 class="display-6 mb-2 fw-semibold">{{ReportService.formData.TyLeHoanThanhDienTich |
                                number:
                                '1.0-0'}} %</h2>
                            <h5 class="mb-3 fw-light opacity-75" style="font-size: 16px;">Diện tích tham gia đến năm
                                2025 so với diện tích đăng ký (Đơn vị tính: %)</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br /> -->
    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase;">Hợp tác xã ĐĂNG KÝ THAM GIA ĐỀ ÁN giai đoạn
                    2023-2025</h4>
                <canvas style="font-size: 30px;" baseChart [datasets]="ChartDataReportThongKe0002"
                    [labels]="ChartLabelsReportThongKe0002" [chartType]="ChartTypeReportThongKe0002"
                    [colors]="ChartColorsReportThongKe0002" [options]="ChartOptionsReportThongKe0002">
                </canvas>
            </div>
        </div>
        <div class="col-lg-12 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase;">Hợp tác xã ĐĂNG KÝ THAM GIA ĐỀ ÁN giai đoạn
                    2023-2025</h4>
                <div class="table-responsive">
                    <table class="table m-0 align-middle">
                        <thead>
                            <tr>
                                <th style="text-align: center;">STT</th>
                                <th style="text-align: center;">Tỉnh thành</th>
                                <th style="text-align: center;">Tổng số HTX THAM GIA ĐỀ ÁN giai đoạn 2023-2025</th>
                                <th style="text-align: center;">Tổng số HTX THAM GIA ĐỀ ÁN giai đoạn 2023-2025</th>
                                <th style="text-align: center;">Tỷ lệ (%) HTX ĐĂNG KÝ THAM GIA ĐỀ ÁN giai đoạn 2023-2025
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of ReportService.listThongKe0002; index as i;">
                                <td style="text-align: center;">
                                    {{i + 1}}
                                </td>
                                <td>
                                    <b>{{item.Name}}</b>
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongTongHop |
                                    number:
                                    '1.0-0'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat |
                                    number:
                                    '1.0-2'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.TyLeHoanThanhSoLuong |
                                    number:
                                    '1.0-2'}} %
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase;">Diện tích sản xuất</h4>
                <canvas style="font-size: 30px;" baseChart [datasets]="ChartDataReportThongKe0002DienTich"
                    [labels]="ChartLabelsReportThongKe0002DienTich" [chartType]="ChartTypeReportThongKe0002DienTich"
                    [colors]="ChartColorsReportThongKe0002DienTich" [options]="ChartOptionsReportThongKe0002DienTich">
                </canvas>
            </div>
        </div>
        <div class="col-lg-12 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase;">Diện tích sản xuất</h4>
                <div class="table-responsive">
                    <table class="table m-0 align-middle">
                        <thead>
                            <tr>
                                <th style="text-align: center;">STT</th>
                                <th style="text-align: center;">Tỉnh thành</th>
                                <th style="text-align: center;">Tổng diện tích đất sản xuất (ha)
                                </th>
                                <th style="text-align: center;">Tổng diện tích đất sản xuất đăng ký (ha)
                                </th>
                                <th style="text-align: center;">Diện tích đất sản xuất trung bình của 01 HTX (ha)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of ReportService.listThongKe0002; index as i;">
                                <td style="text-align: center;">
                                    {{i + 1}}
                                </td>
                                <td>
                                    <b>{{item.Name}}</b>
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichTongHop |
                                    number:
                                    '1.0-0'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichKhaoSat |
                                    number:
                                    '1.0-2'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.TyLeHoanThanhDienTich |
                                    number:
                                    '1.0-2'}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase;">Thành viên</h4>
                <canvas style="font-size: 30px;" baseChart [datasets]="ChartDataReportThongKe0003"
                    [labels]="ChartLabelsReportThongKe0003" [chartType]="ChartTypeReportThongKe0003"
                    [colors]="ChartColorsReportThongKe0003" [options]="ChartOptionsReportThongKe0003">
                </canvas>
            </div>
        </div>
        <div class="col-lg-12 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase;">Thành viên</h4>
                <div class="table-responsive">
                    <table class="table m-0 align-middle">
                        <thead>
                            <tr>
                                <th style="text-align: center;">STT</th>
                                <th style="text-align: center;">Tỉnh thành</th>
                                <th style="text-align: center;">Tổng số HTX THAM GIA ĐỀ ÁN giai đoạn 2023-2025
                                </th>
                                <th style="text-align: center;">Tổng số thành viên HTX
                                </th>
                                <th style="text-align: center;">Thành viên trung bình của 01 HTX
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of ReportService.listThongKe0003; index as i;">
                                <td style="text-align: center;">
                                    {{i + 1}}
                                </td>
                                <td>
                                    <b>{{item.Name}}</b>
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat |
                                    number:
                                    '1.0-0'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.ThanhVienKhaoSat |
                                    number:
                                    '1.0-2'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.TyLeHoanThanhSoLuong |
                                    number:
                                    '1.0-2'}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase;">5.1. TRÌNH ĐỘ ĐÀO TẠO CỦA HĐQT HTX THAM GIA
                    ĐỀ ÁN
                    giai đoạn 2023-2025</h4>
                <div class="table-responsive">
                    <table class="table m-0 align-middle">
                        <thead>
                            <tr>
                                <th style="text-align: center;">STT</th>
                                <th style="text-align: center;">Tỉnh thành</th>
                                <th style="text-align: center;">Tổng số Thành viên HĐQT
                                </th>
                                <th style="text-align: center;">Số lượng HĐQT có trình độ Đại Học, sau Đại học
                                </th>
                                <th style="text-align: center;">Tỷ lệ HĐQT có trình độ Đại Học, sau Đại học (%)
                                </th>
                                <th style="text-align: center;">Số lượng HĐQT có trình độ Trung cấp và tương đương
                                </th>
                                <th style="text-align: center;">Tỷ lệ HĐQT có trình độ Trung cấp và tương đương (%)
                                </th>
                                <th style="text-align: center;">Số lượng HĐQT chưa qua đào tạo
                                </th>
                                <th style="text-align: center;">Tỷ lệ HĐQT chưa qua đào tạo (%)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of ReportService.listThongKe0004; index as i;">
                                <td style="text-align: center;">
                                    {{i + 1}}
                                </td>
                                <td>
                                    <b>{{item.Name}}</b>
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat |
                                    number:
                                    '1.0-0'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichKhaoSat001 |
                                    number:
                                    '1.0-2'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichThamGia001 |
                                    number:
                                    '1.0-2'}} %
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichKhaoSat002 |
                                    number:
                                    '1.0-2'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichThamGia002 |
                                    number:
                                    '1.0-2'}} %
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichKhaoSat003 |
                                    number:
                                    '1.0-2'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichThamGia003 |
                                    number:
                                    '1.0-2'}} %
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase;">5.2. TRÌNH ĐỘ ĐÀO TẠO CỦA GIÁM ĐỐC HTX THAM
                    GIA
                    ĐỀ ÁN giai đoạn 2023-2025
                </h4>
                <div class="table-responsive">
                    <table class="table m-0 align-middle">
                        <thead>
                            <tr>
                                <th style="text-align: center;">STT</th>
                                <th style="text-align: center;">Tỉnh thành</th>
                                <th style="text-align: center;">Tổng số Thành viên GIÁM ĐỐC
                                </th>
                                <th style="text-align: center;">Số lượng GIÁM ĐỐC có trình độ Đại Học, sau Đại học
                                </th>
                                <th style="text-align: center;">Tỷ lệ GIÁM ĐỐC có trình độ Đại Học, sau Đại học (%)
                                </th>
                                <th style="text-align: center;">Số lượng GIÁM ĐỐC có trình độ Trung cấp và tương đương
                                </th>
                                <th style="text-align: center;">Tỷ lệ GIÁM ĐỐC có trình độ Trung cấp và tương đương (%)
                                </th>
                                <th style="text-align: center;">Số lượng GIÁM ĐỐC chưa qua đào tạo
                                </th>
                                <th style="text-align: center;">Tỷ lệ GIÁM ĐỐC chưa qua đào tạo (%)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of ReportService.listThongKe0005; index as i;">
                                <td style="text-align: center;">
                                    {{i + 1}}
                                </td>
                                <td>
                                    <b>{{item.Name}}</b>
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat |
                                    number:
                                    '1.0-0'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichKhaoSat001 |
                                    number:
                                    '1.0-2'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichThamGia001 |
                                    number:
                                    '1.0-2'}} %
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichKhaoSat002 |
                                    number:
                                    '1.0-2'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichThamGia002 |
                                    number:
                                    '1.0-2'}} %
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichKhaoSat003 |
                                    number:
                                    '1.0-2'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichThamGia003 |
                                    number:
                                    '1.0-2'}} %
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase;">5.3. BỘ PHẬN HẬU CẦN CỦA HTX THAM GIA ĐỀ ÁN
                    giai
                    đoạn 2023-2025
                </h4>
                <div class="table-responsive">
                    <table class="table m-0 align-middle">
                        <thead>
                            <tr>
                                <th style="text-align: center;">STT</th>
                                <th style="text-align: center;">Tỉnh thành</th>
                                <!-- <th style="text-align: center;">Tổng số
                            </th> -->
                                <th style="text-align: center;">Kế toán HTX
                                </th>
                                <th style="text-align: center;">Tỷ lệ (%)
                                </th>
                                <th style="text-align: center;">Thủ quỷ HTX
                                </th>
                                <th style="text-align: center;">Tỷ lệ (%)
                                </th>
                                <th style="text-align: center;">Thủ kho HTX
                                </th>
                                <th style="text-align: center;">Tỷ lệ (%)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of ReportService.listThongKe0005; index as i;">
                                <td style="text-align: center;">
                                    {{i + 1}}
                                </td>
                                <td>
                                    <b>{{item.Name}}</b>
                                </td>
                                <!-- <td style="text-align: center;">
                                {{item.SoLuongKhaoSat |
                                number:
                                '1.0-0'}}
                            </td> -->
                                <td style="text-align: center;">
                                    {{item.DienTichKhaoSat001 |
                                    number:
                                    '1.0-2'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichThamGia001 |
                                    number:
                                    '1.0-2'}} %
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichKhaoSat002 |
                                    number:
                                    '1.0-2'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichThamGia002 |
                                    number:
                                    '1.0-2'}} %
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichKhaoSat003 |
                                    number:
                                    '1.0-2'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichThamGia003 |
                                    number:
                                    '1.0-2'}} %
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase;">6.1. Trụ sở làm việc chính của HTX THAM GIA
                    ĐỀ ÁN
                    giai đoạn 2023-2025
                </h4>
                <canvas style="font-size: 30px;" baseChart [datasets]="ChartDataReportThongKe0007"
                    [labels]="ChartLabelsReportThongKe0007" [chartType]="ChartTypeReportThongKe0007"
                    [colors]="ChartColorsReportThongKe0007" [options]="ChartOptionsReportThongKe0007">
                </canvas>
            </div>
        </div>
        <div class="col-lg-612 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase;">6.1. Trụ sở làm việc chính của HTX THAM GIA
                    ĐỀ ÁN
                    giai đoạn 2023-2025
                </h4>
                <div class="table-responsive">
                    <table class="table m-0 align-middle">
                        <thead>
                            <tr>
                                <th style="text-align: center;">STT</th>
                                <th style="text-align: center;">Tỉnh thành</th>
                                <th style="text-align: center;">Tổng số HTX THAM GIA ĐỀ ÁN giai đoạn 2023-2025
                                </th>
                                <th style="text-align: center;">HTX có trụ sở
                                </th>
                                <th style="text-align: center;">Tỷ lệ (%)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of ReportService.listThongKe0007; index as i;">
                                <td style="text-align: center;">
                                    {{i + 1}}
                                </td>
                                <td>
                                    <b>{{item.Name}}</b>
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongTongHop |
                                    number:
                                    '1.0-0'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat |
                                    number:
                                    '1.0-2'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.TyLeHoanThanhSoLuong |
                                    number:
                                    '1.0-2'}} %
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase;">6.2. Tỷ lệ trang bị máy móc thiết bị của HTX
                    THAM
                    GIA ĐỀ ÁN giai đoạn 2023-2025
                </h4>
                <div class="table-responsive">
                    <table class="table m-0 align-middle">
                        <thead>
                            <tr>
                                <th style="text-align: center;">STT</th>
                                <th style="text-align: center;">Tỉnh thành</th>
                                <!-- <th style="text-align: center;">Tổng số HTX THAM GIA ĐỀ ÁN giai đoạn 2023-2025</th> -->
                                <th style="text-align: center;">Máy vi tính có Internet (%)
                                </th>
                                <th style="text-align: center;">Phương tiện vận chuyển (%)
                                </th>
                                <th style="text-align: center;">Máy bơm (%)
                                </th>
                                <th style="text-align: center;">Máy làm đất (%)
                                </th>
                                <th style="text-align: center;">Máy sấy (%)
                                </th>
                                <th style="text-align: center;">Máy gặt đập (%)
                                </th>
                                <th style="text-align: center;">Hệ thống tưới tự động (%)
                                </th>
                                <th style="text-align: center;">Drone (%)
                                </th>
                                <th style="text-align: center;">Nhà kho (%)
                                </th>
                                <th style="text-align: center;">Nhà sơ chế (%) 
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of ReportService.listThongKe0008; index as i;">
                                <td style="text-align: center;">
                                    {{i + 1}}
                                </td>
                                <td>
                                    <b>{{item.Name}}</b>
                                </td>
                                <!-- <td style="text-align: center;">
                                {{item.SoLuongKhaoSat |
                                number:
                                '1.0-0'}}
                            </td> -->
                                <td style="text-align: center;">
                                    {{item.DienTichKhaoSat001 |
                                    number:
                                    '1.0-2'}} ({{item.DienTichThamGia001 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichKhaoSat002 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia002 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichKhaoSat003 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia003 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichKhaoSat004 |
                                    number:
                                    '1.0-2'}} ({{item.DienTichThamGia004 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichKhaoSat005 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia005 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichKhaoSat006 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia006 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichKhaoSat007 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia007 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichKhaoSat008 |
                                    number:
                                    '1.0-2'}} ({{item.DienTichThamGia008 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichKhaoSat009 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia009 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichKhaoSat010 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia010 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase;">7. HOẠT ĐỘNG LIÊN KẾT / CHUỔI GIÁ TRỊ NGHÀNH
                    LÚA
                    GẠO CỦA HTX THAM GIA ĐỀ ÁN giai đoạn 2023-2025
                </h4>
                <div class="table-responsive">
                    <table class="table m-0 align-middle">
                        <thead>
                            <tr>
                                <th style="text-align: center;">STT</th>
                                <th style="text-align: center;">Tỉnh thành</th>
                                <!-- <th style="text-align: center;">Tổng số HTX THAM GIA ĐỀ ÁN giai đoạn 2023-2025</th> -->
                                <th style="text-align: center;">HTX có liên kết đầu vào
                                </th>
                                <th style="text-align: center;">Tỷ lệ HTX (%)
                                </th>
                                <th style="text-align: center;">Diện tích canh tác (Ha)
                                </th>
                                <th style="text-align: center;">Sản lượng liên kết (Tấn)
                                </th>
                                <th style="text-align: center;">HTX có liên kết đầu ra
                                </th>
                                <th style="text-align: center;">Tỷ lệ HTX (%)
                                </th>
                                <th style="text-align: center;">Diện tích canh tác (Ha)
                                </th>
                                <th style="text-align: center;">Sản lượng liên kết (Tấn)
                                </th>
                                <th style="text-align: center;">HTX có liên kết theo chuỗi
                                </th>
                                <th style="text-align: center;">Tỷ lệ HTX (%)
                                </th>
                                <th style="text-align: center;">Diện tích canh tác (Ha)
                                </th>
                                <th style="text-align: center;">Sản lượng liên kết (Tấn)
                                </th>
                                <th style="text-align: center;">HTX có liên kết tiêu thụ sản phẩm cho thành viên
                                </th>
                                <th style="text-align: center;">Tỷ lệ HTX (%)
                                </th>
                                <th style="text-align: center;">Diện tích canh tác (Ha)
                                </th>
                                <th style="text-align: center;">Sản lượng liên kết (Tấn)
                                </th>
                                <th style="text-align: center;">HTX có liên kết với HTX khác
                                </th>
                                <th style="text-align: center;">Tỷ lệ HTX (%)
                                </th>
                                <th style="text-align: center;">Diện tích canh tác (Ha)
                                </th>
                                <th style="text-align: center;">Sản lượng liên kết (Tấn)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of ReportService.listThongKe0009; index as i;">
                                <td style="text-align: center;">
                                    {{i + 1}}
                                </td>
                                <td>
                                    <b>{{item.Name}}</b>
                                </td>
                                <!-- <td style="text-align: center;">
                                {{item.SoLuongKhaoSat |
                                number:
                                '1.0-0'}}
                            </td> -->
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat001 |
                                    number:
                                    '1.0-0'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichThamGia001 |
                                    number:
                                    '1.0-0'}} %
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichKhaoSat001 |
                                    number:
                                    '1.0-0'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.SanLuongKhaoSat001 |
                                    number:
                                    '1.0-0'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat002 |
                                    number:
                                    '1.0-0'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichThamGia002 |
                                    number:
                                    '1.0-0'}} %
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichKhaoSat002 |
                                    number:
                                    '1.0-0'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.SanLuongKhaoSat002 |
                                    number:
                                    '1.0-0'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat003 |
                                    number:
                                    '1.0-0'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichThamGia003 |
                                    number:
                                    '1.0-0'}} %
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichKhaoSat003 |
                                    number:
                                    '1.0-0'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.SanLuongKhaoSat003 |
                                    number:
                                    '1.0-0'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat004 |
                                    number:
                                    '1.0-0'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichThamGia004 |
                                    number:
                                    '1.0-0'}} %
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichKhaoSat004 |
                                    number:
                                    '1.0-0'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.SanLuongKhaoSat004 |
                                    number:
                                    '1.0-0'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat005 |
                                    number:
                                    '1.0-0'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichThamGia005 |
                                    number:
                                    '1.0-0'}} %
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichKhaoSat005 |
                                    number:
                                    '1.0-0'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.SanLuongKhaoSat005 |
                                    number:
                                    '1.0-0'}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase;">8. TIÊU CHUẨN SẢN XUẤT CỦA HTX THAM GIA ĐỀ ÁN
                    giai đoạn 2023-2025
                </h4>
                <div class="table-responsive">
                    <table class="table m-0 align-middle">
                        <thead>
                            <tr>
                                <th style="text-align: center;">STT</th>
                                <th style="text-align: center;">Tỉnh thành</th>
                                <!-- <th style="text-align: center;">Tổng số HTX THAM GIA ĐỀ ÁN giai đoạn 2023-2025</th> -->
                                <th style="text-align: center;">SX theo hướng an toàn (%)
                                </th>
                                <th style="text-align: center;">SX theo tiêu chuẩn VietGAP (%)
                                </th>
                                <th style="text-align: center;">SX theo tiêu chuẩn GlobalGAP (%)
                                </th>
                                <th style="text-align: center;">SX theo tiêu chuẩn SRP (%)
                                </th>
                                <th style="text-align: center;">SX theo tiêu chuẩn khác (%)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of ReportService.listThongKe0010; index as i;">
                                <td style="text-align: center;">
                                    {{i + 1}}
                                </td>
                                <td>
                                    <b>{{item.Name}}</b>
                                </td>
                                <!-- <td style="text-align: center;">
                                {{item.SoLuongKhaoSat |
                                number:
                                '1.0-0'}}
                            </td> -->
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat001 |
                                    number:
                                    '1.0-2'}} ({{item.DienTichThamGia001 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat002 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia002 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat003 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia003 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat004 |
                                    number:
                                    '1.0-2'}} ({{item.DienTichThamGia004 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat005 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia005 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase;">9. VIETGAP / GLOBALGAP CỦA HTX THAM GIA ĐỀ ÁN
                    giai đoạn 2023-2025
                </h4>
                <canvas style="font-size: 30px;" baseChart [datasets]="ChartDataReportThongKe0011"
                    [labels]="ChartLabelsReportThongKe0011" [chartType]="ChartTypeReportThongKe0011"
                    [colors]="ChartColorsReportThongKe0011" [options]="ChartOptionsReportThongKe0011">
                </canvas>
            </div>
        </div>
        <div class="col-lg-12 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase;">9. VIETGAP / GLOBALGAP CỦA HTX THAM GIA ĐỀ ÁN
                    giai đoạn 2023-2025
                </h4>
                <div class="table-responsive">
                    <table class="table m-0 align-middle">
                        <thead>
                            <tr>
                                <th style="text-align: center;">STT</th>
                                <th style="text-align: center;">Tỉnh thành</th>
                                <th style="text-align: center;">Diện tích chứng nhận (ha)
                                </th>
                                <th style="text-align: center;">Sản lượng (tấn/năm)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of ReportService.listThongKe0011; index as i;">
                                <td style="text-align: center;">
                                    {{i + 1}}
                                </td>
                                <td>
                                    <b>{{item.Name}}</b>
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichKhaoSat |
                                    number:
                                    '1.0-0'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.SanLuongKhaoSat |
                                    number:
                                    '1.0-2'}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase;">10. HOẠT ĐỘNG LÀM ĐẤT CỦA HTX THAM GIA ĐỀ ÁN
                    giai
                    đoạn 2023-2025
                </h4>
                <div class="table-responsive">
                    <table class="table m-0 align-middle">
                        <thead>
                            <tr>
                                <th style="text-align: center;">STT</th>
                                <th style="text-align: center;">Tỉnh thành</th>
                                <!-- <th style="text-align: center;">Tổng số HTX THAM GIA ĐỀ ÁN giai đoạn 2023-2025</th> -->
                                <th style="text-align: center;">Cày xới (%)
                                </th>
                                <th style="text-align: center;">Trục trạc (%)
                                </th>
                                <th style="text-align: center;">San phẳng (%)
                                </th>
                                <th style="text-align: center;">Đánh rãnh (%)
                                </th>
                                <th style="text-align: center;">Khác (%)
                                </th>
                                <th style="text-align: center;">Máy của HTX (%)
                                </th>
                                <th style="text-align: center;">Máy thuê (%)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of ReportService.listThongKe0012; index as i;">
                                <td style="text-align: center;">
                                    {{i + 1}}
                                </td>
                                <td>
                                    <b>{{item.Name}}</b>
                                </td>
                                <!-- <td style="text-align: center;">
                                {{item.SoLuongKhaoSat |
                                number:
                                '1.0-0'}}
                            </td> -->
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat001 |
                                    number:
                                    '1.0-2'}} ({{item.DienTichThamGia001 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat002 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia002 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat003 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia003 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat004 |
                                    number:
                                    '1.0-2'}} ({{item.DienTichThamGia004 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat005 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia005 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat006 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia006 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat007 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia007 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase;">11.1. TỶ LỆ (%) CHUẨN LOẠI GIỐNG ĐƯỢC HTX
                    THAM
                    GIA ĐỀ ÁN giai đoạn 2023-2025 SỬ DỤNG
                </h4>
                <div class="table-responsive">
                    <table class="table m-0 align-middle">
                        <thead>
                            <tr>
                                <th style="text-align: center;">STT</th>
                                <th style="text-align: center;">Tỉnh thành</th>
                                <!-- <th style="text-align: center;">Tổng số HTX THAM GIA ĐỀ ÁN giai đoạn 2023-2025</th> -->
                                <th style="text-align: center;">Từ nông dân (%)
                                </th>
                                <th style="text-align: center;">Xác nhận (%)
                                </th>
                                <th style="text-align: center;">Nguyên chủng (%)
                                </th>
                                <th style="text-align: center;">Siêu nguyên chủng (%)
                                </th>
                                <th style="text-align: center;">Khác (%)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of ReportService.listThongKe0013; index as i;">
                                <td style="text-align: center;">
                                    {{i + 1}}
                                </td>
                                <td>
                                    <b>{{item.Name}}</b>
                                </td>
                                <!-- <td style="text-align: center;">
                                {{item.SoLuongKhaoSat |
                                number:
                                '1.0-0'}}
                            </td> -->
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat001 |
                                    number:
                                    '1.0-2'}} ({{item.DienTichThamGia001 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat002 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia002 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat003 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia003 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat004 |
                                    number:
                                    '1.0-2'}} ({{item.DienTichThamGia004 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat005 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia005 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase;">11.2. TỶ LỆ (%) NGUỒN GIỐNG ĐƯỢC HTX THAM GIA
                    ĐỀ
                    ÁN giai đoạn 2023-2025 SỬ DỤNG
                </h4>
                <div class="table-responsive">
                    <table class="table m-0 align-middle">
                        <thead>
                            <tr>
                                <th style="text-align: center;">STT</th>
                                <th style="text-align: center;">Tỉnh thành</th>
                                <!-- <th style="text-align: center;">Tổng số HTX THAM GIA ĐỀ ÁN giai đoạn 2023-2025</th> -->
                                <th style="text-align: center;">Tự giữ lại (%)
                                </th>
                                <th style="text-align: center;">Từ nông dân khác (%)
                                </th>
                                <th style="text-align: center;">Công ty sản xuất giống (%)
                                </th>
                                <th style="text-align: center;">Cơ quan nhà nước (%)
                                </th>
                                <th style="text-align: center;">Cửa hàng (%)
                                </th>
                                <th style="text-align: center;">Thương lái (%)
                                </th>
                                <th style="text-align: center;">Khác (%)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of ReportService.listThongKe0014; index as i;">
                                <td style="text-align: center;">
                                    {{i + 1}}
                                </td>
                                <td>
                                    <b>{{item.Name}}</b>
                                </td>
                                <!-- <td style="text-align: center;">
                                {{item.SoLuongKhaoSat |
                                number:
                                '1.0-0'}}
                            </td> -->
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat001 |
                                    number:
                                    '1.0-2'}} ({{item.DienTichThamGia001 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat002 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia002 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat003 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia003 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat004 |
                                    number:
                                    '1.0-2'}} ({{item.DienTichThamGia004 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat005 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia005 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat006 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia006 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat007 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia007 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase;">11.3. HOẠT ĐỘNG GIEO SẠ GIỐNG CỦA HTX THAM
                    GIA ĐỀ
                    ÁN giai đoạn 2023-2025
                </h4>
                <div class="table-responsive">
                    <table class="table m-0 align-middle">
                        <thead>
                            <tr>
                                <th style="text-align: center;">STT</th>
                                <th style="text-align: center;">Tỉnh thành</th>
                                <th style="text-align: center;">Dưới 80kg (%)</th>
                                <th style="text-align: center;">80-100 kg (%)
                                </th>
                                <th style="text-align: center;">100-120kg (%)
                                </th>
                                <th style="text-align: center;">Trên 120kg (%)
                                </th>
                                <th style="text-align: center;">Đồng Loạt (%)
                                </th>
                                <th style="text-align: center;">Theo đợt (%)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of ReportService.listThongKe0015; index as i;">
                                <td style="text-align: center;">
                                    {{i + 1}}
                                </td>
                                <td>
                                    <b>{{item.Name}}</b>
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat001 |
                                    number:
                                    '1.0-2'}} ({{item.DienTichThamGia001 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat002 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia002 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat003 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia003 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat004 |
                                    number:
                                    '1.0-2'}} ({{item.DienTichThamGia004 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat005 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia005 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat006 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia006 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase;">11.4. PHƯƠNG PHÁP GIEO SẠ GIỐNG CỦA HTX THAM
                    GIA
                    ĐỀ ÁN giai đoạn 2023-2025
                </h4>
                <div class="table-responsive">
                    <table class="table m-0 align-middle">
                        <thead>
                            <tr>
                                <th style="text-align: center;">STT</th>
                                <th style="text-align: center;">Tỉnh thành</th>
                                <th style="text-align: center;">Bằng tay (%)</th>
                                <th style="text-align: center;">Bằng máy (%)
                                </th>
                                <th style="text-align: center;">Máy phun hạt (%)
                                </th>
                                <th style="text-align: center;">Máy sạ hàng (%)
                                </th>
                                <th style="text-align: center;">Máy sạ cụm (%)
                                </th>
                                <th style="text-align: center;">Drone (%)
                                </th>
                                <th style="text-align: center;">Khác (%)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of ReportService.listThongKe0016; index as i;">
                                <td style="text-align: center;">
                                    {{i + 1}}
                                </td>
                                <td>
                                    <b>{{item.Name}}</b>
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat001 |
                                    number:
                                    '1.0-2'}} ({{item.DienTichThamGia001 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat002 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia002 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat003 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia003 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat004 |
                                    number:
                                    '1.0-2'}} ({{item.DienTichThamGia004 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat005 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia005 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat006 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia006 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat007 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia007 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase;">12.1. PHÂN BÓN ĐƯỢC SỬ DỤNG TẠI HTX THAM GIA
                    ĐỀ
                    ÁN giai đoạn 2023-2025
                </h4>
                <div class="table-responsive">
                    <table class="table m-0 align-middle">
                        <thead>
                            <tr>
                                <th style="text-align: center;">STT</th>
                                <th style="text-align: center;">Tỉnh thành</th>
                                <th style="text-align: center;">1-3 lần (%)</th>
                                <th style="text-align: center;">4-5 lần (%)
                                </th>
                                <th style="text-align: center;">Trên 5 lần (%)
                                </th>
                                <th style="text-align: center;">Bằng tay (%)
                                </th>
                                <th style="text-align: center;">Bằng máy phun (%)
                                </th>
                                <th style="text-align: center;">Bằng Drone (%)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of ReportService.listThongKe0017; index as i;">
                                <td style="text-align: center;">
                                    {{i + 1}}
                                </td>
                                <td>
                                    <b>{{item.Name}}</b>
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat001 |
                                    number:
                                    '1.0-2'}} ({{item.DienTichThamGia001 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat002 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia002 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat003 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia003 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat004 |
                                    number:
                                    '1.0-2'}} ({{item.DienTichThamGia004 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat005 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia005 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat006 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia006 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase;">12.2. THUỐC BVTV ĐƯỢC SỬ DỤNG TẠI HTX THAM
                    GIA ĐỀ
                    ÁN giai đoạn 2023-2025
                </h4>
                <div class="table-responsive">
                    <table class="table m-0 align-middle">
                        <thead>
                            <tr>
                                <th style="text-align: center;">STT</th>
                                <th style="text-align: center;">Tỉnh thành</th>
                                <th style="text-align: center;">Thuốc trừ sâu: 1-3 lần (%)</th>
                                <th style="text-align: center;">Thuốc trừ sâu: 4-5 lần (%)
                                </th>
                                <th style="text-align: center;">Thuốc trừ sâu: Trên 5 lần (%)
                                </th>
                                <th style="text-align: center;">Thuốc trừ bệnh: 1-3 lần (%)</th>
                                <th style="text-align: center;">Thuốc trừ bệnh: 4-5 lần (%)
                                </th>
                                <th style="text-align: center;">Thuốc trừ bệnh: Trên 5 lần (%)
                                </th>
                                <th style="text-align: center;">Bằng bình phun máy (%)
                                </th>
                                <th style="text-align: center;">Bằng Done (%)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of ReportService.listThongKe0018; index as i;">
                                <td style="text-align: center;">
                                    {{i + 1}}
                                </td>
                                <td>
                                    <b>{{item.Name}}</b>
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat001 |
                                    number:
                                    '1.0-2'}} ({{item.DienTichThamGia001 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat002 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia002 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat003 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia003 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat004 |
                                    number:
                                    '1.0-2'}} ({{item.DienTichThamGia004 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat005 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia005 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat006 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia006 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat007 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia007 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat008 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia008 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase;">12.3. TỔNG HỢP TỶ LỆ (%) SỐ LẦN PHUN THUỐC
                    TRỪ
                    SÂU, BỆNH / VỤ
                </h4>
                <div class="table-responsive">
                    <table class="table m-0 align-middle">
                        <thead>
                            <tr>
                                <th style="text-align: center;">STT</th>
                                <th style="text-align: center;">Tiêu đề</th>
                                <th style="text-align: center;">Số lượng</th>
                                <th style="text-align: center;">Tỷ lệ (%)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of ReportService.listThongKe0020; index as i;">
                                <td style="text-align: center;">
                                    {{i + 1}}
                                </td>
                                <td>
                                    <b>{{item.Name}}</b>
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat |
                                    number:
                                    '1.0-0'}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.DienTichThamGia |
                                    number:
                                    '1.0-2'}} %
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase;">13. HOẠT ĐỘNG THU GOM RƠM (%)
                </h4>
                <div class="table-responsive">
                    <table class="table m-0 align-middle">
                        <thead>
                            <tr>
                                <th style="text-align: center;">STT</th>
                                <th style="text-align: center;">Tỉnh thành</th>
                                <!-- <th style="text-align: center;">Tổng số HTX THAM GIA ĐỀ ÁN giai đoạn 2023-2025</th> -->
                                <th style="text-align: center;">Đốt (%)</th>
                                <th style="text-align: center;">Vùi (%)
                                </th>
                                <th style="text-align: center;">Thu gom (%)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of ReportService.listThongKe0019; index as i;">
                                <td style="text-align: center;">
                                    {{i + 1}}
                                </td>
                                <td>
                                    <b>{{item.Name}}</b>
                                </td>
                                <!-- <td style="text-align: center;">
                                {{item.SoLuongKhaoSat |
                                number:
                                '1.0-0'}}
                            </td> -->
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat001 |
                                    number:
                                    '1.0-2'}} ({{item.DienTichThamGia001 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat002 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia002 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                                <td style="text-align: center;">
                                    {{item.SoLuongKhaoSat003 |
                                    number:
                                    '1.0-2'}} ( {{item.DienTichThamGia003 |
                                    number:
                                    '1.0-2'}} %)
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase;">14.1. HOẠT ĐỘNG THỜI TIẾT TRONG NGÀY
                </h4>
                <div class="table-responsive">
                    <table class="table m-0 align-middle">
                        <thead>
                            <tr>
                                <th style="text-align: center;">STT</th>
                                <th style="text-align: center;">Tỉnh thành</th>
                                <th style="text-align: center;">Tổng quan
                                </th>
                                <th style="text-align: center;">Nhiệt độ Trung bình
                                </th>
                                <th style="text-align: center;">Độ ẩm trung bình
                                </th>
                                <th style="text-align: center;">Sức gió trung bình
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of ThoiTietService.list; index as i;">
                                <td style="text-align: center;">
                                    {{i + 1}}
                                </td>
                                <td>
                                    <b>{{item.DanhMucTinhThanhName}}</b>
                                </td>
                                <td style="text-align: center;">
                                    {{item.TongQuan}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.NhietDoCaoNhat}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.DoAmCaoNhat}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.HuongGio}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row gx-4">
        <div class="col-lg-6 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase;">DỰ BÁO NHIỆT ĐỘ</h4>
                <canvas baseChart [datasets]="ChartDataReportThoiTiet0001NhietDo"
                    [labels]="ChartLabelsReportThoiTiet0001NhietDo" [chartType]="ChartTypeReportThoiTiet0001NhietDo"
                    [colors]="ChartColorsReportThoiTiet0001NhietDo" [options]="ChartOptionsReportThoiTiet0001NhietDo">
                </canvas>
            </div>
        </div>
        <div class="col-lg-6 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase;">DỰ BÁO ĐỘ ẨM</h4>
                <canvas baseChart [datasets]="ChartDataReportThoiTiet0001DoAm"
                    [labels]="ChartLabelsReportThoiTiet0001DoAm" [chartType]="ChartTypeReportThoiTiet0001DoAm"
                    [colors]="ChartColorsReportThoiTiet0001DoAm" [options]="ChartOptionsReportThoiTiet0001DoAm">
                </canvas>
            </div>
        </div>
    </div>
    <!-- <br />
    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12">
            <h4 class="text-center" style="text-transform: uppercase;">BẢNG TÓM LƯỢC ĐÁNH GIÁ TỔNG QUAN TÍNH SẲN SÀNG
                CỦA CÁC HỢP TÁC XÃ TẠI 08 TỈNH ĐỒNG BẰNG SÔNG CỬU LONG ĐĂNG KÝ THAM GIA ĐỀ ÁN GIAI ĐOẠN ĐẾN 2025
            </h4>
            <div class="table-responsive">
                <table class="table m-0 align-middle">
                    <thead>
                        <tr>
                            <th style="text-align: center;" rowspan="2">STT</th>
                            <th style="text-align: center;" rowspan="2">Tiêu chí đề án</th>
                            <th style="text-align: center;" rowspan="2">Đến năm 2025</th>
                            <th style="text-align: center;" rowspan="2">Đến năm 2030</th>
                            <th style="text-align: center;" rowspan="2">Đánh giá tính sẵn sàng của vùng nguyên liệu đăng
                                ký tham gia đề án</th>
                            <th style="text-align: center;" colspan="3">Đánh giá chung so với các tiêu chí</th>
                        </tr>
                        <tr>                            
                            <th style="text-align: center;">Hiện trạng</th>
                            <th style="text-align: center;">Mức độ đạt theo tiêu chí</th>
                            <th style="text-align: center;">Giải pháp khắc phục</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Lúa giống gieo sạ</td>
                            <td>20%
                            </td>
                            <td>30%
                            </td>
                            <td>Giảm từ 20-30% lượng phân bón
                            </td>
                            <td>Có 67,93% HTX chỉ bón phân 1-3 lần cho một vụ sản xuất và 30,71% HTX bón phân 4-5 lần
                                cho vụ sản xuất. Như vậy, hiện tại các HTX đã chủ động giảm từ 1 đến 2 lần bón phân cho
                                1 vụ sản xuất. Từ đó, lượng phân bón đã được giảm đi từ 20 đến 30% cho một vụ sản xuất.
                                Bên cạnh đó, sử dụng thiết bị cơ giới bón phân thay thế bón bằng tay như: bón vùi phân
                                bằng máy bắn phân/Drone…
                            </td>
                            <td>Đạt</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Giảm lượng nước tưới</td>
                            <td>20%
                            </td>
                            <td>20%
                            </td>
                            <td>Giảm 20% lượng nước tưới
                            </td>
                            <td>Có 100% HTX thực hiện giảm nước tưới (Áp dụng tưới ngập khô xen kẽ)</td>
                            <td>Đạt</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>100% diện tích áp dụng quy trình canh tác bền vững
                            </td>
                            <td></td>
                            <td></td>
                            <td>9,62% số HTX chưa áp dụng quy trình canh tác bền vững
                            </td>
                            <td>Có 90,38% HTX áp dụng quy trình canh tác bền vững</td>
                            <td>Chưa đạt
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>Diện tích sản xuất vùng chuyên canh có liên kết giữa DN với tổ hợp tác, HTX
                            </td>
                            <td>100%
                            </td>
                            <td></td>
                            <td>31,31% số HTX khảo sát đang có liên kết theo chuỗi
                            </td>
                            <td>Hiện nay có 46,05% HTX liên kết với nhà cung cấp vật tư đầu vào; có 48,31% HTX liên kết
                                tiêu thụ sản phẩm đầu ra; có 28,81% HTX liên kết sản xuất theo chuỗi và có 9,04% HTX
                                liên kết với các HTX nông nghiệp khác trong và ngoài tỉnh.
                            </td>
                            <td>Chưa đạt
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>Tỷ lệ cơ giới hóa đồng bộ
                            </td>
                            <td>50%
                            </td>
                            <td>70%
                            </td>
                            <td>> 90%
                            </td>
                            <td>100% cơ giới hóa khâu làm đất, thu hoạch; có trên 90% cơ giới hóa khâu gieo sạ, bón
                                phân, phun thuốc.
                            </td>
                            <td>Đạt
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td>Số hộ áp dụng quy trình canh tác bền vững
                            </td>
                            <td>200,000
                            </td>
                            <td>1,000,000
                            </td>
                            <td></td>
                            <td>
                            </td>
                            <td>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>8</td>
                            <td>Tỷ lệ thất thoát sau thu hoạch</td>
                            <td>Dưới 10%</td>
                            <td>Dưới 8%
                            </td>
                            <td>Dưới 10%
                            </td>
                            <td>Trên 90% HTX thu hoạch bằng máy gặt đập liên hợp (giảm thất thoát)
                            </td>
                            <td>Đạt
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>9</td>
                            <td>Rơm tại vùng chuyên canh được thu gom và chế biến tái sử dụng
                            </td>
                            <td>70%
                            </td>
                            <td>100%
                            </td>
                            <td>79.35%
                            </td>
                            <td>79,35% HTX áp dụng biện pháp thu gom rơm ra khỏi đồng ruộng
                            </td>
                            <td>Đạt
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>10</td>
                            <td>Giảm phát thải khí nhà kính so với canh tác truyền thống
                            </td>
                            <td>10%
                            </td>
                            <td>10%
                            </td>
                            <td></td>
                            <td>
                            </td>
                            <td>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>11</td>
                            <td>Giá trị gia tăng trong chuỗi
                            </td>
                            <td>30%
                            </td>
                            <td>40%
                            </td>
                            <td></td>
                            <td>
                            </td>
                            <td>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>12</td>
                            <td>Tỷ suất lợi nhuận người trồng lúa
                            </td>
                            <td>40%
                            </td>
                            <td>50%
                            </td>
                            <td>Lợi nhuận ròng trung bình 42,7% (Năm 2023)
                            </td>
                            <td>Năng suất tăng (trung bình 6,3 tấn/ha/năm); Giá thị trường tăng
                            </td>
                            <td>Đạt
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>13</td>
                            <td>Lượng gạo CLC và phát thải thấp/ tổng lượng gạo xuất khẩu vùng chuyên canh
                            </td>
                            <td>20%
                            </td>
                            <td>20%
                            </td>
                            <td>20%
                            </td>
                            <td>
                            </td>
                            <td>Đạt
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div> -->
    <br />
    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase; color: red;">Đánh giá tổng quát tính sẳn sàng
                    của
                    các HTX ĐĂNG KÝ THAM GIA ĐỀ ÁN giai đoạn 2023-2025
                </h4>
                <div class="table-responsive">
                    <table class="table m-0 align-middle">
                        <thead>
                            <tr>
                                <th style="text-align: center; background-color: green; color: #ffffff;" rowspan="2">STT
                                </th>
                                <th style="text-align: center; background-color: green; color: #ffffff;" rowspan="2">
                                    Tiêu
                                    chí đề án</th>
                                <th style="text-align: center; background-color: green; color: #ffffff;" rowspan="2">Đến
                                    năm
                                    2025</th>
                                <th style="text-align: center; background-color: green; color: #ffffff;" rowspan="2">Đến
                                    năm
                                    2030</th>
                                <th style="text-align: center; background-color: green; color: #ffffff;" rowspan="2">
                                    Đánh
                                    giá tính sẵn sàng của vùng nguyên liệu đăng ký tham gia đề án</th>
                                <th style="text-align: center; background-color: green; color: #ffffff;" colspan="3">
                                    Đánh
                                    giá chung so với các tiêu chí</th>
                            </tr>
                            <tr>
                                <th style="text-align: center; background-color: green; color: #ffffff;">Hiện trạng</th>
                                <th style="text-align: center; background-color: green; color: #ffffff;">Mức độ đạt theo
                                    tiêu chí</th>
                                <th style="text-align: center; background-color: green; color: #ffffff;">Giải pháp khắc
                                    phục
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Lúa giống gieo sạ</td>
                                <td>80-100kg/ha
                                </td>
                                <td>Dưới 70kg/ha
                                </td>
                                <td style="font-weight: bold;">43,96% đạt dưới 100kg/ha, 9,62% đạt dưới 80kg
                                </td>
                                <td>Có 46,2 % HTX chưa tuân thủ giảm lượng gieo sạ ổn định trên các vụ lúa/năm; có vụ
                                    gieo
                                    sạ giống trên 100 ký/ ha
                                </td>
                                <td style="color: red; background-color: yellow;">Chưa đạt</td>
                                <td>Rà soát quy trình đề xuất giải pháp quản lý rũi ro thiếu giống cấy giậm do tác động
                                    bất
                                    lợi của biến đổi khí hậu (ngập lũ cục bộ, sinh vật gây hại…). Hỗ trợ hoàn thiện cơ
                                    sở hạ
                                    tầng, thuỷ lợi nội đồng cho các vùng VnSAT mở rộng để hạn chế rũi ro trong sản xuất
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Giảm lượng phân bón hóa học</td>
                                <td>20%
                                </td>
                                <td>20%
                                </td>
                                <td style="font-weight: bold;">Giảm từ 20-30% lượng phân bón
                                </td>
                                <td>Có 67,93% HTX chỉ bón phân 1-3 lần cho một vụ sản xuất và 30,71% HTX bón phân 4-5
                                    lần
                                    cho vụ sản xuất. Như vậy, hiện tại các HTX đã chủ động giảm từ 1 đến 2 lần bón phân
                                    cho
                                    1 vụ sản xuất. Từ đó, lượng phân bón đã được giảm đi từ 20 đến 30% cho một vụ sản
                                    xuất.
                                    Bên cạnh đó, sử dụng thiết bị cơ giới bón phân thay thế bón bằng tay như: bón vùi
                                    phân
                                    bằng máy bắn phân/Drone…</td>
                                <td>Đạt</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Giảm lượng nước tưới</td>
                                <td>20%
                                </td>
                                <td>20%
                                </td>
                                <td style="font-weight: bold;">Giảm 20% lượng nước tưới
                                </td>
                                <td>Có 100% HTX thực hiện giảm nước tưới (Áp dụng tưới ngập khô xen kẽ)</td>
                                <td>Đạt</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>100% diện tích áp dụng quy trình canh tác bền vững
                                </td>
                                <td></td>
                                <td></td>
                                <td style="font-weight: bold;">Có 90,38% HTX điều tra có áp dụng quy trình canh tác bền
                                    vững
                                </td>
                                <td>9,62% số HTX chưa tuân thủ hoàn toàn quy trình canh tác bền vững</td>
                                <td style="color: red; background-color: yellow;">Chưa đạt
                                </td>
                                <td>Tiếp tục đào tạo, bồi dưỡng quy trình canh tác; hướng dẫn hỗ trợ HTX tổ chức sản
                                    xuất
                                    theo quy trình canh tác bền vững</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>Diện tích sản xuất vùng chuyên canh có liên kết giữa DN với tổ hợp tác, HTX
                                </td>
                                <td>100%
                                </td>
                                <td></td>
                                <td style="font-weight: bold;">50,09% tỷ lệ diện tích canh tác có thực hiện tham gia vào
                                    các
                                    hình thức liên kết
                                </td>
                                <td>49,91% chưa tham gia vào các hình thức liên kết
                                </td>
                                <td style="color: red; background-color: yellow;">Chưa đạt
                                </td>
                                <td>Đào tạo, bồi dưỡng kiến thức về xây dựng chuỗi giá trị lúa gạo, tư vấn hướng dẫn xây
                                    dựng các dự án/kế hoạch liên kết; tuyên truyền, tham quan học tập mô hình</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Tỷ lệ cơ giới hóa đồng bộ
                                </td>
                                <td>50%
                                </td>
                                <td>70%
                                </td>
                                <td style="font-weight: bold;">Trên 90%
                                </td>
                                <td>100% cơ giới hóa khâu làm đất, thu hoạch; có trên 90% cơ giới hóa khâu gieo sạ, bón
                                    phân, phun thuốc.
                                </td>
                                <td>Đạt
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>Số hộ áp dụng quy trình canh tác bền vững
                                </td>
                                <td>200,000
                                </td>
                                <td>1,000,000
                                </td>
                                <td style="font-weight: bold;"></td>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>Tỷ lệ thất thoát sau thu hoạch</td>
                                <td>Dưới 10%</td>
                                <td>Dưới 8%
                                </td>
                                <td style="font-weight: bold;">Dưới 10%
                                </td>
                                <td>Trên 90% HTX thu hoạch bằng máy gặt đập liên hợp (giảm thất thoát)
                                </td>
                                <td>Đạt
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>Rơm tại vùng chuyên canh được thu gom và chế biến tái sử dụng
                                </td>
                                <td>70%
                                </td>
                                <td>100%
                                </td>
                                <td style="font-weight: bold;">79,35% có thu gom rơm ra khỏi đồng ruộng, tuy nhiên còn
                                    hạn
                                    chế trong mùa mưa
                                </td>
                                <td>79,35% HTX áp dụng biện pháp thu gom rơm ra khỏi đồng ruộng. Tuy nhiên, việc thu gom
                                    rơm
                                    ra khỏi đồng ruộng không ổn định, đặc biệt là trong mùa mưa (Vụ Hè Thu)
                                </td>
                                <td style="color: red; background-color: yellow;">Chưa đạt
                                </td>
                                <td>Hỗ trợ, tư vấn giúp người dân tiếp cận quy trình, thiết bị công nghệ để sử dụng phụ
                                    phẩm
                                    trong sản xuất lúa (rơm, rạ, trấu) thành nguyên liệu đầu vào của quy trình sản xuất
                                    khác; Phát triển đa dạng sản phẩm từ phụ phẩm như trồng nấm, làm thức ăn cho gia
                                    súc,
                                    khuôn ương cây giống, khuôn đựng trứng, thùng chứa nông sản, tấm carton, phân hữu
                                    cơ…,
                                    và marketing tốt cho các sản phẩm bảo vệ môi trường.</td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>Giảm phát thải khí nhà kính so với canh tác truyền thống
                                </td>
                                <td>10%
                                </td>
                                <td>10%
                                </td>
                                <td style="font-weight: bold;"></td>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>11</td>
                                <td>Giá trị gia tăng trong chuỗi
                                </td>
                                <td>30%
                                </td>
                                <td>40%
                                </td>
                                <td style="font-weight: bold;"></td>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>12</td>
                                <td>Tỷ suất lợi nhuận người trồng lúa
                                </td>
                                <td>40%
                                </td>
                                <td>50%
                                </td>
                                <td style="font-weight: bold;">Lợi nhuận ròng trung bình 42,7% (Năm 2023)
                                </td>
                                <td>Năng suất tăng (trung bình 6,3 tấn/ha/năm); Giá thị trường tăng
                                </td>
                                <td>Đạt
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>13</td>
                                <td>Lượng gạo CLC và phát thải thấp/ tổng lượng gạo xuất khẩu vùng chuyên canh
                                </td>
                                <td>20%
                                </td>
                                <td>20%
                                </td>
                                <td style="font-weight: bold;">20%
                                </td>
                                <td>
                                </td>
                                <td>Đạt
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase;">TÀI LIỆU BÁO CÁO
                </h4>
                <div class="table-responsive">
                    <table class="table m-0 align-middle">
                        <thead>
                            <tr>
                                <th style="text-align: center;">STT</th>
                                <th style="text-align: center;">Tiêu đề</th>
                                <th style="text-align: center;">Ngày ghi nhận (Tháng/Ngày/Năm)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of TaiLieuService.list; index as i;">
                                <td style="text-align: center;">
                                    {{i + 1}}
                                </td>
                                <td>
                                    <a class="link-primary" href="{{item.FileName}}"
                                        target="_blank"><b>{{item.Name}}</b>
                                    </a>
                                </td>
                                <td style="text-align: center;">
                                    {{item.LastUpdatedDate | date:'MM/dd/yyyy'}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<br />
<br />
<br />
<app-loading *ngIf="isShowLoading"></app-loading>