import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gioi-thieu',
  templateUrl: './gioi-thieu.component.html',
  styleUrls: ['./gioi-thieu.component.css']
})
export class GioiThieuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
