import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DanhMucTinhThanhToaDo } from 'src/app/shared/DanhMucTinhThanhToaDo.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class DanhMucTinhThanhToaDoService extends BaseService{

    list: DanhMucTinhThanhToaDo[] | undefined;
    list001: DanhMucTinhThanhToaDo[] | undefined;
    formData!: DanhMucTinhThanhToaDo;

    displayColumns001: string[] = ['STT', 'ID', 'KinhDo', 'ViDo', 'SortOrder', 'Active', 'Save'];

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "DanhMucTinhThanhToaDo";
    }

    GetSQLByDanhMucTinhThanhActiveToListAsync() {
        let url = this.aPIURL + this.controller + '/GetSQLByDanhMucTinhThanhActiveToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }   
    GetByDanhMucTinhThanhIDToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByDanhMucTinhThanhIDToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }   
    GetByDanhMucQuanHuyenIDToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByDanhMucQuanHuyenIDToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }   
    GetByDanhMucXaPhuongIDToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByDanhMucXaPhuongIDToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }   
}

