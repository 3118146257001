import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DownloadService } from 'src/app/shared/Download.service';


import * as maplibregl from 'maplibre-gl';

import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';

import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';

@Component({
  selector: 'app-map-hop-tac-xa',
  templateUrl: './map-hop-tac-xa.component.html',
  styleUrls: ['./map-hop-tac-xa.component.css']
})
export class MapHopTacXaComponent implements OnInit, AfterViewInit, OnDestroy {

  isShowLoading: boolean = false;
  isShowSearch: boolean = false;
  domainName = environment.DomainDestination;
  domainURL = environment.DomainURL;

  map: maplibregl.Map | undefined;

  @ViewChild('map')
  private mapContainer!: ElementRef<HTMLElement>;

  constructor(
    public DownloadService: DownloadService,

    public DanhMucTinhThanhService: DanhMucTinhThanhService,

    public ToChucService: ToChucService,
  ) { }

  ngOnInit(): void {

  }

  SetIsShowSearch() {
    this.isShowSearch = !this.isShowSearch;
  }

  rotateCamera(timestamp) {
    if (this.map) {
      this.map.rotateTo((timestamp / 100) % 360, { duration: 0 });
      requestAnimationFrame(this.rotateCamera);
    }
  }

  ngAfterViewInit() {
    this.DanhMucTinhThanhGetToList();
    this.MapLoad();
  }

  ngOnDestroy() {
    this.map?.remove();
  }

  MapInitialization(longitude, latitude) {

    let zoom = environment.MapZoom;
    if (this.ToChucService.baseParameter.DanhMucTinhThanhID > 0) {
      zoom = 9;
    }
    if (this.ToChucService.baseParameter.SearchString.length > 0) {
      zoom = 11;
    }
    if ((latitude > 90) || (latitude == 0)) {
      latitude = environment.Latitude;
      longitude = environment.Longitude;
    }
    this.map = new maplibregl.Map({
      container: this.mapContainer.nativeElement,
      style: 'https://api.maptiler.com/maps/hybrid/style.json?key=' + environment.MaptilerAPIKey,
      center: [longitude, latitude],
      zoom: zoom,
      pitch: 45,
    });
    
    this.map.addControl(
      new maplibregl.NavigationControl({
        visualizePitch: true,
        showZoom: true,
        showCompass: true
      })
    );

    this.map.on('load', () => {

      this.map.addSource("HoangSa", {
        "type": "image",
        "url": environment.DomainURL + "assets/image/HoangSa01.png",
        "coordinates": [
          [111.09665858054495, 17.432475898867523],
          [113.11720985517763, 17.38420482529338],
          [112.79285037220984, 15.643938718432054],
          [110.88537855035554, 15.672592116966598],
        ]
      });
      this.map.addLayer({
        "id": "HoangSa",
        "source": "HoangSa",
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

      this.map.addSource("TruongSa", {
        "type": "image",
        "url": environment.DomainURL + "assets/image/TruongSa01.png",
        "coordinates": [
          [112.32373278444106, 12.236103169381323],
          [117.4620551483049, 11.606334626304161],
          [115.59654957671216, 7.357025445897818],
          [110.62186805246108, 7.811210355974268],


        ]
      });
      this.map.addLayer({
        "id": "TruongSa",
        "source": "TruongSa",
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

    });
  }

  MapLoad() {
    this.isShowLoading = true;
    this.ToChucService.baseParameter.Active = true;
    if (this.ToChucService.baseParameter.DanhMucTinhThanhID == null) {
      this.ToChucService.baseParameter.DanhMucTinhThanhID = environment.DanhMucTinhThanhID;
    }
    if (this.ToChucService.baseParameter.SearchString == null) {
      this.ToChucService.baseParameter.SearchString = environment.InitializationString;
    }
    this.ToChucService.GetSQLByToChucToaDoByActive_DanhMucTinhThanhID_SearchStringToListAsync().subscribe(
      res => {
        this.ToChucService.list1002 = (res as ToChuc[]);
        if (this.ToChucService.list1002) {
          if (this.ToChucService.list1002.length > 0) {

            let latitude = environment.Latitude;
            let longitude = environment.Longitude;
            if(this.ToChucService.baseParameter.DanhMucTinhThanhID>0){
              latitude = Number(this.ToChucService.list1002[0].ViDo);
              longitude = Number(this.ToChucService.list1002[0].KinhDo);
            }           
            this.MapInitialization(longitude, latitude);

            for (let i = 0; i < this.ToChucService.list1002.length; i++) {
              let toChuc = this.ToChucService.list1002[i];             
              if (toChuc.KinhDo && toChuc.KinhDo.length > 0) {
                if (toChuc.ViDo && toChuc.ViDo.length > 0) {

                  let latitudeSub = Number(toChuc.ViDo);
                  let longitudeSub = Number(toChuc.KinhDo);

                  if (latitudeSub <= 90) {

                    let truSo = "Chưa có trụ sở";
                    if (toChuc.IsTruSo) {
                      truSo = "Có trụ sở";
                    }
                    let dapUngDuAn = "";
                    if (toChuc.IsDapUngDuAn) {
                      dapUngDuAn = "Đáp ứng tiêu chí";
                    }

                    let popupContent = "<div style='opacity: 0.8; background-color: transparent;'>";
                    popupContent = popupContent + "<a class='link-primary form-label' style='cursor: pointer; text-align: center;' onclick='OpenWindowByToChuc(" + toChuc.ID + ")'><h3>" + toChuc.Name + "</h3></a>";
                    popupContent = popupContent + "<div style='text-align: center;'>";
                    popupContent = popupContent + "<a class='link-primary form-label' style='cursor: pointer; text-align: center;' onclick='OpenWindowByVungTrong(" + toChuc.ID + ")'>Xem vùng trồng</a>";
                    popupContent = popupContent + " | <a class='link-primary form-label' style='cursor: pointer; text-align: center;' onclick='OpenWindowByToChuc(" + toChuc.ID + ")'>Xem hồ sơ</a>";
                    popupContent = popupContent + '</div>';
                    popupContent = popupContent + '<div>Trụ sở: <b>' + truSo + '</b></div>';
                    popupContent = popupContent + '<div>Địa chỉ: <b>' + toChuc.DanhMucXaPhuongName + ', ' + toChuc.DanhMucQuanHuyenName + ', ' + toChuc.DanhMucTinhThanhName + '</b></div>';
                    popupContent = popupContent + '<div>Giám đốc: <b>' + toChuc.Display + '</b></div>';
                    popupContent = popupContent + '<div>Điện thoại: <b>' + toChuc.DienThoai + '</b></div>';
                    popupContent = popupContent + '<div>Ngành nghề: <b>' + toChuc.Description + '</b></div>';
                    popupContent = popupContent + '<div>Thành viên: <b>' + toChuc.SoLuongThanhVien + '</b></div>';
                    popupContent = popupContent + '<div>Diện tích: <b>' + toChuc.DienTichSanXuatHa + ' ha</b></div>';
                    // popupContent = popupContent + '<div>Vùng canh tác: <b>' + toChuc.HTMLContent + '</b></div>';
                    popupContent = popupContent + '<div>Đáp ứng tiêu chí: <b>' + dapUngDuAn + '</b></div>';
                    popupContent = popupContent + "</div>";

                    let popup = new maplibregl.Popup({ offset: 25 }).setHTML(popupContent)
                      .setMaxWidth("600px");

                    var el = document.createElement('div');
                    el.style.backgroundImage = "url(" + environment.DomainURL + "assets/image/HTX4_30.png)";
                    el.style.width = '30px';
                    el.style.height = '30px';

                    let marker = new maplibregl.Marker({ element: el })
                      .setLngLat([longitudeSub, latitudeSub])
                      .setPopup(popup)
                      .addTo(this.map);

                  }
                  else {
                    console.log(this.ToChucService.list1002[i]);
                  }
                }
              }
            }

          }
        }

        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  DanhMucTinhThanhGetToList() {
    this.isShowLoading = true;
    this.DanhMucTinhThanhService.baseParameter.Active = true;
    this.DanhMucTinhThanhService.GetSQLByThanhVienIDAndActiveToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.list = (res as DanhMucTinhThanh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucTinhThanhService.list001 = this.DanhMucTinhThanhService.list;
        if (this.DanhMucTinhThanhService.list) {
          if (this.DanhMucTinhThanhService.list.length) {            
          }
        }        
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucTinhThanhFilter(searchString: string) {
    this.DanhMucTinhThanhService.Filter(searchString);
  }
}