import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseService } from './Base.service';
import { Report } from './Report.model';

@Injectable({
    providedIn: 'root'
})
export class ReportService extends BaseService{

    aPIURL: string = environment.APIReportURL;
    
  
    list000: Report[] | undefined;
    list: Report[] | undefined;
    list001: Report[] | undefined;
    formData!: Report;

    list0001: Report[] | undefined;
    list0002: Report[] | undefined;
    list0003: Report[] | undefined;
    list0004: Report[] | undefined;
    list0005: Report[] | undefined;
    list0006: Report[] | undefined;

    list1002: Report[] | undefined;
    listThoiTiet0001: Report[] | undefined;
    listThoiTiet0002: Report[] | undefined;

    listThongKe0002: Report[] | undefined;
    listThongKe0003: Report[] | undefined;
    listThongKe0004: Report[] | undefined;
    listThongKe0005: Report[] | undefined;
    listThongKe0006: Report[] | undefined;
    listThongKe0007: Report[] | undefined;
    listThongKe0008: Report[] | undefined;
    listThongKe0009: Report[] | undefined;
    listThongKe0010: Report[] | undefined;
    listThongKe0011: Report[] | undefined;
    listThongKe0012: Report[] | undefined;
    listThongKe0013: Report[] | undefined;
    listThongKe0014: Report[] | undefined;
    listThongKe0015: Report[] | undefined;
    listThongKe0016: Report[] | undefined;
    listThongKe0017: Report[] | undefined;
    listThongKe0018: Report[] | undefined;
    listThongKe0019: Report[] | undefined;
    listThongKe0020: Report[] | undefined;

    list2001: Report[] | undefined;

    displayColumns0001: string[] = ['STT', 'DanhMucTinhThanhName', 'DanhMucQuanHuyenName', 'DanhMucXaPhuongName', 'SoLuongThamGia', 'SoLuongKhaoSat', 'TyLeHoanThanh']; 
    displayColumns0003: string[] = ['STT', 'DanhMucTinhThanhName', 'DanhMucQuanHuyenName', 'DanhMucXaPhuongName', 'Name', 'SoLuongTongHop', 'SoLuongThamGia']; 
    displayColumns0004: string[] = ['STT', 'SoLuongThamGia', 'DienTichThamGia001', 'DienTichThamGia002', 'DienTichThamGia003', 'DienTichThamGia004', 'DienTichThamGia005', 'DienTichThamGia006', 'DienTichThamGia007', 'SoLuongKhaoSat', 'DienTichKhaoSat001', 'DienTichKhaoSat002', 'DienTichKhaoSat003', 'DienTichKhaoSat004', 'DienTichKhaoSat005', 'DienTichKhaoSat006', 'DienTichKhaoSat007', 'TyLeHoanThanhSoLuong', 'TyLeHoanThanhDienTich']; 
    displayColumns0005: string[] = ['STT', 'SoLuongThamGia', 'DienTichThamGia', 'SoLuongKhaoSat', 'DienTichKhaoSat001', 'DienTichKhaoSat002', 'DienTichKhaoSat003', 'DienTichKhaoSat013', 'DienTichKhaoSat004', 'DienTichKhaoSat005', 'DienTichKhaoSat006', 'DienTichKhaoSat014', 'DienTichKhaoSat007', 'DienTichKhaoSat008', 'DienTichKhaoSat009', 'DienTichKhaoSat015', 'DienTichKhaoSat010', 'DienTichKhaoSat011','DienTichKhaoSat012', 'DienTichKhaoSat016', 'DienTichKhaoSat', 'TyLeHoanThanhSoLuong', 'TyLeHoanThanhDienTich']; 
    displayColumns0006: string[] = ['STT', 'SoLuongThamGia', 'DienTichThamGia', 'SoLuongKhaoSat', 'DienTichKhaoSat001', 'DienTichKhaoSat002', 'DienTichKhaoSat003', 'DienTichKhaoSat013', 'DienTichKhaoSat004', 'DienTichKhaoSat005', 'DienTichKhaoSat006', 'DienTichKhaoSat014', 'DienTichKhaoSat007', 'DienTichKhaoSat008', 'DienTichKhaoSat009', 'DienTichKhaoSat015', 'DienTichKhaoSat010', 'DienTichKhaoSat011','DienTichKhaoSat012', 'DienTichKhaoSat016', 'DienTichKhaoSat', 'TyLeHoanThanhSoLuong', 'TyLeHoanThanhDienTich']; 
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "Report";
    }    

    Report2001ToListAsync() {
        let url = this.aPIURL + this.controller + '/Report2001ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }   

    Report1002ToListAsync() {
        let url = this.aPIURL + this.controller + '/Report1002ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }   
    Report1001Async() {
        let url = this.aPIURL + this.controller + '/Report1001Async';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }   
    ReportThoiTiet0001ToListAsync() {
        let url = this.aPIURL + this.controller + '/ReportThoiTiet0001ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }   
    ReportThoiTiet0002ToListAsync() {
        let url = this.aPIURL + this.controller + '/ReportThoiTiet0002ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }   

    ThongKe0001Async() {
        let url = this.aPIURL + this.controller + '/ThongKe0001Async';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }   
    ThongKe0002ToListAsync() {
        let url = this.aPIURL + this.controller + '/ThongKe0002ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }   
    ThongKe0003ToListAsync() {
        let url = this.aPIURL + this.controller + '/ThongKe0003ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }   
    ThongKe0004ToListAsync() {
        let url = this.aPIURL + this.controller + '/ThongKe0004ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }   
    ThongKe0005ToListAsync() {
        let url = this.aPIURL + this.controller + '/ThongKe0005ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }   
    ThongKe0006ToListAsync() {
        let url = this.aPIURL + this.controller + '/ThongKe0006ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }   
    ThongKe0007ToListAsync() {
        let url = this.aPIURL + this.controller + '/ThongKe0007ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }   
    ThongKe0008ToListAsync() {
        let url = this.aPIURL + this.controller + '/ThongKe0008ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }   
    ThongKe0009ToListAsync() {
        let url = this.aPIURL + this.controller + '/ThongKe0009ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }   
    ThongKe0010ToListAsync() {
        let url = this.aPIURL + this.controller + '/ThongKe0010ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }   
    ThongKe0011ToListAsync() {
        let url = this.aPIURL + this.controller + '/ThongKe0011ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }   
    ThongKe0012ToListAsync() {
        let url = this.aPIURL + this.controller + '/ThongKe0012ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }  
    ThongKe0013ToListAsync() {
        let url = this.aPIURL + this.controller + '/ThongKe0013ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }  
    ThongKe0014ToListAsync() {
        let url = this.aPIURL + this.controller + '/ThongKe0014ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }  
    ThongKe0015ToListAsync() {
        let url = this.aPIURL + this.controller + '/ThongKe0015ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }  
    ThongKe0016ToListAsync() {
        let url = this.aPIURL + this.controller + '/ThongKe0016ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }  
    ThongKe0017ToListAsync() {
        let url = this.aPIURL + this.controller + '/ThongKe0017ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }  
    ThongKe0018ToListAsync() {
        let url = this.aPIURL + this.controller + '/ThongKe0018ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }  
    ThongKe0019ToListAsync() {
        let url = this.aPIURL + this.controller + '/ThongKe0019ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }  
    ThongKe0020ToListAsync() {
        let url = this.aPIURL + this.controller + '/ThongKe0020ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }  



    Report0001ToListAsync() {
        let url = this.aPIURL + this.controller + '/Report0001ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }   
    ExportReport0001ToExcelAsync() {
        let url = this.aPIURL + this.controller + '/ExportReport0001ToExcelAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    ExportReport0001ToHTMLAsync() {
        let url = this.aPIURL + this.controller + '/ExportReport0001ToHTMLAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }

    Report0002ToListAsync() {
        let url = this.aPIURL + this.controller + '/Report0002ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }   
    ExportReport0002ToExcelAsync() {
        let url = this.aPIURL + this.controller + '/ExportReport0002ToExcelAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    ExportReport0002ToHTMLAsync() {
        let url = this.aPIURL + this.controller + '/ExportReport0002ToHTMLAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }

    Report0003ToListAsync() {
        let url = this.aPIURL + this.controller + '/Report0003ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }   
    ExportReport0003ToExcelAsync() {
        let url = this.aPIURL + this.controller + '/ExportReport0003ToExcelAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    ExportReport0003ToHTMLAsync() {
        let url = this.aPIURL + this.controller + '/ExportReport0003ToHTMLAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }

    Report0004ToListAsync() {
        let url = this.aPIURL + this.controller + '/Report0004ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }   
    ExportReport0004ToExcelAsync() {
        let url = this.aPIURL + this.controller + '/ExportReport0004ToExcelAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    ExportReport0004ToHTMLAsync() {
        let url = this.aPIURL + this.controller + '/ExportReport0004ToHTMLAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }

    Report0005ToListAsync() {
        let url = this.aPIURL + this.controller + '/Report0005ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }   
    ExportReport0005ToExcelAsync() {
        let url = this.aPIURL + this.controller + '/ExportReport0005ToExcelAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    ExportReport0005ToHTMLAsync() {
        let url = this.aPIURL + this.controller + '/ExportReport0005ToHTMLAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }

    Report0006ToListAsync() {
        let url = this.aPIURL + this.controller + '/Report0006ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.baseParameter));          
        return this.httpClient.post(url, formUpload);
    }  
    ExportReport0006ToExcelAsync() {
        let url = this.aPIURL + this.controller + '/ExportReport0006ToExcelAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    ExportReport0006ToHTMLAsync() {
        let url = this.aPIURL + this.controller + '/ExportReport0006ToHTMLAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    } 
}




