import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { DanhMucTinhThanhToaDo } from 'src/app/shared/DanhMucTinhThanhToaDo.model';
import { DanhMucTinhThanhToaDoService } from 'src/app/shared/DanhMucTinhThanhToaDo.service';


@Component({
  selector: 'app-danh-muc-tinh-thanh-toa-do-detail',
  templateUrl: './danh-muc-tinh-thanh-toa-do-detail.component.html',
  styleUrls: ['./danh-muc-tinh-thanh-toa-do-detail.component.css']
})
export class DanhMucTinhThanhToaDoDetailComponent implements OnInit {

  @ViewChild('DanhMucTinhThanhToaDoSort') DanhMucTinhThanhToaDoSort: MatSort;
  @ViewChild('DanhMucTinhThanhToaDoPaginator') DanhMucTinhThanhToaDoPaginator: MatPaginator;

  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;

  
  constructor(
    public dialogRef: MatDialogRef<DanhMucTinhThanhToaDoDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,


    public DanhMucTinhThanhToaDoService: DanhMucTinhThanhToaDoService,
  ) { }

  ngOnInit(): void {
    this.DanhMucTinhThanhToaDoGetToList();
   

  }

  Close() {
    this.dialogRef.close();
  }

  DanhMucTinhThanhToaDoGetToList() {
    if (this.DanhMucTinhThanhToaDoService.baseParameter.DanhMucTinhThanhID > 0) {
      this.isShowLoading = true;
      this.DanhMucTinhThanhToaDoService.GetByDanhMucTinhThanhIDToListAsync().subscribe(
        res => {
          this.DanhMucTinhThanhToaDoService.list = (res as DanhMucTinhThanhToaDo[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.DanhMucTinhThanhToaDoService.DataSource = new MatTableDataSource(this.DanhMucTinhThanhToaDoService.list);
          this.DanhMucTinhThanhToaDoService.DataSource.sort = this.DanhMucTinhThanhToaDoSort;
          this.DanhMucTinhThanhToaDoService.DataSource.paginator = this.DanhMucTinhThanhToaDoPaginator;
          this.isShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
          this.isShowLoading = false;
        }
      );
    }

    if (this.DanhMucTinhThanhToaDoService.baseParameter.DanhMucQuanHuyenID > 0) {
      this.isShowLoading = true;
      this.DanhMucTinhThanhToaDoService.GetByDanhMucQuanHuyenIDToListAsync().subscribe(
        res => {
          this.DanhMucTinhThanhToaDoService.list = (res as DanhMucTinhThanhToaDo[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.DanhMucTinhThanhToaDoService.DataSource = new MatTableDataSource(this.DanhMucTinhThanhToaDoService.list);
          this.DanhMucTinhThanhToaDoService.DataSource.sort = this.DanhMucTinhThanhToaDoSort;
          this.DanhMucTinhThanhToaDoService.DataSource.paginator = this.DanhMucTinhThanhToaDoPaginator;
          this.isShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
          this.isShowLoading = false;
        }
      );
    }

    if (this.DanhMucTinhThanhToaDoService.baseParameter.DanhMucXaPhuongID > 0) {
      this.isShowLoading = true;
      this.DanhMucTinhThanhToaDoService.GetByDanhMucXaPhuongIDToListAsync().subscribe(
        res => {
          this.DanhMucTinhThanhToaDoService.list = (res as DanhMucTinhThanhToaDo[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.DanhMucTinhThanhToaDoService.DataSource = new MatTableDataSource(this.DanhMucTinhThanhToaDoService.list);
          this.DanhMucTinhThanhToaDoService.DataSource.sort = this.DanhMucTinhThanhToaDoSort;
          this.DanhMucTinhThanhToaDoService.DataSource.paginator = this.DanhMucTinhThanhToaDoPaginator;
          this.isShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
          this.isShowLoading = false;
        }
      );
    }

  }

  DanhMucTinhThanhToaDoSave(element: DanhMucTinhThanhToaDo) {
    this.DanhMucTinhThanhToaDoService.formData = element;
    this.isShowLoading = true;
    this.DanhMucTinhThanhToaDoService.SaveAsync().subscribe(
      res => {
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  DanhMucTinhThanhToaDoDelete(element: DanhMucTinhThanhToaDo) {
    this.DanhMucTinhThanhToaDoService.baseParameter.ID = element.ID;
    this.isShowLoading = true;
    this.DanhMucTinhThanhToaDoService.RemoveAsync().subscribe(
      res => {
        this.NotificationService.warn(environment.DeleteSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.DeleteNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
}
