import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToChucQuyTrinhSanXuatLichSu } from 'src/app/shared/ToChucQuyTrinhSanXuatLichSu.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ToChucQuyTrinhSanXuatLichSuService extends BaseService{

    displayColumns001: string[] = ['STT', 'LastUpdatedDate','LastUpdatedMembershipID','FileName'];
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "ToChucQuyTrinhSanXuatLichSu";
    }
}

