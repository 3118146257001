import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucXaPhuong } from 'src/app/shared/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';
import { DanhMucThanhVien } from 'src/app/shared/DanhMucThanhVien.model';
import { DanhMucThanhVienService } from 'src/app/shared/DanhMucThanhVien.service';
import { DanhMucSanPham } from 'src/app/shared/DanhMucSanPham.model';
import { DanhMucSanPhamService } from 'src/app/shared/DanhMucSanPham.service';
import { DanhMucChucDanh } from 'src/app/shared/DanhMucChucDanh.model';
import { DanhMucChucDanhService } from 'src/app/shared/DanhMucChucDanh.service';
import { DanhMucChungChi } from 'src/app/shared/DanhMucChungChi.model';
import { DanhMucChungChiService } from 'src/app/shared/DanhMucChungChi.service';
import { DanhMucNguyenVatLieu } from 'src/app/shared/DanhMucNguyenVatLieu.model';
import { DanhMucNguyenVatLieuService } from 'src/app/shared/DanhMucNguyenVatLieu.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { ThanhVienTrangTrai } from 'src/app/shared/ThanhVienTrangTrai.model';
import { ThanhVienTrangTraiService } from 'src/app/shared/ThanhVienTrangTrai.service';
import { ThanhVienKho } from 'src/app/shared/ThanhVienKho.model';
import { ThanhVienKhoService } from 'src/app/shared/ThanhVienKho.service';


import { ToChucToaDo } from 'src/app/shared/ToChucToaDo.model';
import { ToChucToaDoService } from 'src/app/shared/ToChucToaDo.service';
import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';
import { ToChucThanhVien } from 'src/app/shared/ToChucThanhVien.model';
import { ToChucThanhVienService } from 'src/app/shared/ToChucThanhVien.service';

@Component({
  selector: 'app-thanh-vien-nong-dan-detail',
  templateUrl: './thanh-vien-nong-dan-detail.component.html',
  styleUrls: ['./thanh-vien-nong-dan-detail.component.css']
})
export class ThanhVienNongDanDetailComponent implements OnInit {


  @ViewChild('ThanhVienTrangTraiSort') ThanhVienTrangTraiSort: MatSort;
  @ViewChild('ThanhVienTrangTraiPaginator') ThanhVienTrangTraiPaginator: MatPaginator;

  ToChucToaDoDataSource: MatTableDataSource<any>;
  @ViewChild('ToChucToaDoSort') ToChucToaDoSort: MatSort;
  @ViewChild('ToChucToaDoPaginator') ToChucToaDoPaginator: MatPaginator;

  @ViewChild('ToChucThanhVienSort') ToChucThanhVienSort: MatSort;
  @ViewChild('ToChucThanhVienPaginator') ToChucThanhVienPaginator: MatPaginator;

  @ViewChild('ThanhVienKhoSort') ThanhVienKhoSort: MatSort;
  @ViewChild('ThanhVienKhoPaginator') ThanhVienKhoPaginator: MatPaginator;

  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;


  constructor(
    public dialogRef: MatDialogRef<ThanhVienNongDanDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,
    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,
    public DanhMucThanhVienService: DanhMucThanhVienService,
    public DanhMucSanPhamService: DanhMucSanPhamService,
    public DanhMucChucDanhService: DanhMucChucDanhService,
    public DanhMucChungChiService: DanhMucChungChiService,
    public DanhMucNguyenVatLieuService: DanhMucNguyenVatLieuService,


    public ThanhVienService: ThanhVienService,
    public ThanhVienTrangTraiService: ThanhVienTrangTraiService,
    public ThanhVienKhoService: ThanhVienKhoService,

    public ToChucToaDoService: ToChucToaDoService,
    public ToChucService: ToChucService,
    public ToChucThanhVienService: ToChucThanhVienService,

  ) { }

  ngOnInit(): void {

    this.DanhMucNguyenVatLieuGetToList();    
    this.DanhMucChungChiGetToList();
    this.DanhMucChucDanhGetToList();
    this.DanhMucSanPhamGetToList();
    this.DanhMucThanhVienGetToList();
    this.DanhMucTinhThanhGetToList();

    this.ToChucGetToList();

    this.GetAll();
  }

  GetAll() {
    this.ThanhVienTrangTraiSearch();
    this.ThanhVienKhoSearch();

    this.ToChucToaDoSearch();
    this.ToChucThanhVienSearch();
  }

  ThanhVienKhoSearch() {   
    this.isShowLoading = true;
    this.ThanhVienKhoService.baseParameter.ParentID = this.ThanhVienService.formData.ID;
      this.ThanhVienKhoService.GetByParentIDToListAsync().subscribe(
        res => {
          this.ThanhVienKhoService.list = (res as ThanhVienKho[]).sort((a, b) => (a.SoLuongTon < b.SoLuongTon ? 1 : -1));
          this.ThanhVienKhoService.list001 = this.ThanhVienKhoService.list;
          this.ThanhVienKhoService.DataSource = new MatTableDataSource(this.ThanhVienKhoService.list);
          this.ThanhVienKhoService.DataSource.sort = this.ThanhVienKhoSort;
          this.ThanhVienKhoService.DataSource.paginator = this.ThanhVienKhoPaginator;
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
  }

  ToChucThanhVienSearch() {
    if (this.ToChucThanhVienService.SearchString.length > 0) {
      this.ToChucThanhVienService.DataSource.filter = this.ToChucThanhVienService.SearchString.toLowerCase();
    }
    else {
      this.isShowLoading = true;
      this.ToChucThanhVienService.baseParameter.ThanhVienID = this.ThanhVienService.formData.ID;
      this.ToChucThanhVienService.GetByThanhVienIDAndEmptyToListAsync().subscribe(
        res => {
          this.ToChucThanhVienService.list = (res as ToChucThanhVien[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.ToChucThanhVienService.list001 = this.ToChucThanhVienService.list;
          this.ToChucThanhVienService.DataSource = new MatTableDataSource(this.ToChucThanhVienService.list);
          this.ToChucThanhVienService.DataSource.sort = this.ToChucThanhVienSort;
          this.ToChucThanhVienService.DataSource.paginator = this.ToChucThanhVienPaginator;
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
  }
  ToChucThanhVienSave(element: ToChucThanhVien) {
    this.isShowLoading = true;
    element.ThanhVienID = this.ThanhVienService.formData.ID;
    this.ToChucThanhVienService.formData = element;
    this.ToChucThanhVienService.SaveAsync().subscribe(
      res => {
        this.ToChucThanhVienSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  ToChucThanhVienDelete(element: ToChucThanhVien) {
    if (confirm(environment.DeleteConfirm)) {
      this.isShowLoading = true;
      this.ToChucThanhVienService.baseParameter.ID = element.ID;
      this.ToChucThanhVienService.RemoveAsync().subscribe(
        res => {
          this.ToChucThanhVienSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
          this.isShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
          this.isShowLoading = false;
        }
      );
    }
  }

  ToChucToaDoSearch() {
    this.ToChucToaDoService.baseParameter.SearchString = this.ThanhVienService.formData.DienThoai;
    this.ToChucToaDoService.SearchBySearchString(this.ToChucToaDoSort, this.ToChucToaDoPaginator);
  }
  ToChucToaDoSave(element: ToChucToaDo) {
    element.ParentID = this.ThanhVienService.formData.ID;
    this.ToChucToaDoService.formData = element;
    this.NotificationService.warn(this.ToChucToaDoService.ComponentSaveBySearchString(this.ToChucToaDoSort, this.ToChucToaDoPaginator));
  }
  ToChucToaDoDelete(element: ToChucToaDo) {
    this.ToChucToaDoService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ToChucToaDoService.ComponentDeleteBySearchString(this.ToChucToaDoSort, this.ToChucToaDoPaginator));
  }

  ThanhVienTrangTraiSearch() {
    this.ThanhVienTrangTraiService.baseParameter.ParentID = this.ThanhVienService.formData.ID;
    this.ThanhVienTrangTraiService.SearchByParentID(this.ThanhVienTrangTraiSort, this.ThanhVienTrangTraiPaginator);
  }
  ThanhVienTrangTraiSave(element: ThanhVienTrangTrai) {
    element.ParentID = this.ThanhVienService.formData.ID;
    this.ThanhVienTrangTraiService.formData = element;
    this.NotificationService.warn(this.ThanhVienTrangTraiService.ComponentSaveByParentID(this.ThanhVienTrangTraiSort, this.ThanhVienTrangTraiPaginator));
  }
  ThanhVienTrangTraiDelete(element: ThanhVienTrangTrai) {
    this.ThanhVienTrangTraiService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ThanhVienTrangTraiService.ComponentDeleteByParentID(this.ThanhVienTrangTraiSort, this.ThanhVienTrangTraiPaginator));
  }

  ThanhVienSave() {
    this.ThanhVienService.formData.ParentID=environment.ThanhVienNongDanID;
    this.NotificationService.warn(this.ThanhVienService.ComponentSaveForm());
  }

  Close() {
    this.dialogRef.close();
  }

  ToChucGetToList() {
    this.ToChucService.baseParameter.ParentID = environment.HopTacXaID;
    this.ToChucService.ComponentGetByParentIDToListAsync();
  }

  DanhMucNguyenVatLieuGetToList() {
    this.DanhMucNguyenVatLieuService.baseParameter.Active = true;
    this.DanhMucNguyenVatLieuService.ComponentGetByActiveToListAsync();
  }

  DanhMucChucDanhGetToList() {
    this.DanhMucChucDanhService.ComponentGetAllToListAsync();
  }

  DanhMucChungChiGetToList() {
    this.DanhMucChungChiService.ComponentGetAllToListAsync();
  }

  DanhMucSanPhamGetToList() {
    this.DanhMucSanPhamService.baseParameter.Active = true;
    this.DanhMucSanPhamService.ComponentGetByActiveToListAsync();
  }

  DanhMucThanhVienGetToList() {
    this.DanhMucThanhVienService.ComponentGetAllToListAsync();
  }

  DanhMucTinhThanhGetToList() {
    this.isShowLoading = true;
    this.DanhMucTinhThanhService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.list = (res as DanhMucTinhThanh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucTinhThanhService.list001 = this.DanhMucTinhThanhService.list;
        this.DanhMucQuanHuyenGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucQuanHuyenGetToList() {
    this.isShowLoading = true;
    this.DanhMucQuanHuyenService.baseParameter.ParentID = this.ThanhVienService.formData.DanhMucTinhThanhID;
    this.DanhMucQuanHuyenService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucQuanHuyenService.list = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucQuanHuyenService.list001 = this.DanhMucQuanHuyenService.list;
        this.DanhMucXaPhuongGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucXaPhuongGetToList() {
    this.isShowLoading = true;
    this.DanhMucXaPhuongService.baseParameter.ParentID = this.ThanhVienService.formData.DanhMucQuanHuyenID;
    this.DanhMucXaPhuongService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucXaPhuongService.list = (res as DanhMucXaPhuong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucXaPhuongService.list001 = this.DanhMucXaPhuongService.list;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucTinhThanhFilter(searchString: string) {
    this.DanhMucTinhThanhService.Filter(searchString);
  }
  DanhMucQuanHuyenFilter(searchString: string) {
    this.DanhMucQuanHuyenService.Filter(searchString);
  }
  DanhMucXaPhuongFilter(searchString: string) {
    this.DanhMucXaPhuongService.Filter(searchString);
  }
  ToChucFilter(searchString: string) {
    this.ToChucService.Filter(searchString);
  }
}