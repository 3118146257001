import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DanhMucChucNang } from 'src/app/shared/DanhMucChucNang.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class DanhMucChucNangService extends BaseService {

    ListChild: DanhMucChucNang[] | undefined;
    ListParent: DanhMucChucNang[] | undefined;

    displayColumns001: string[] = ['STT', 'ParentID', 'Name', 'Code', 'Display', 'SortOrder', 'Active', 'Save'];

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "DanhMucChucNang";
    }

    GetSQLByThanhVienIDToListAsync() {
        var lastUpdatedMembershipID = localStorage.getItem(environment.ThanhVienID);
        if (lastUpdatedMembershipID) {
            this.baseParameter.ThanhVienID = Number(lastUpdatedMembershipID);
        }
        let url = this.aPIURL + this.controller + '/GetSQLByThanhVienIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetSQLByThanhVienIDAndActiveToListAsync() {
        var lastUpdatedMembershipID = localStorage.getItem(environment.ThanhVienID);
        if (lastUpdatedMembershipID) {
            this.baseParameter.ThanhVienID = Number(lastUpdatedMembershipID);
        }
        this.baseParameter.Active = true;
        let url = this.aPIURL + this.controller + '/GetSQLByThanhVienIDAndActiveToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
}

