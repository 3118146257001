import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class DanhMucTinhThanhService extends BaseService{

    list: DanhMucTinhThanh[] | undefined;
    list001: DanhMucTinhThanh[] | undefined;
    formData!: DanhMucTinhThanh;

    displayColumns001: string[] = ['STT', 'ID', 'Code', 'Name', 'Note', 'KinhDo', 'ViDo', 'SortOrder', 'Active', 'Save'];
    displayColumns002: string[] = ['STT'];
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "DanhMucTinhThanh";
    }

    GetSQLByThanhVienIDAndActiveToListAsync() {
        var lastUpdatedMembershipID = localStorage.getItem(environment.ThanhVienID);
        if (lastUpdatedMembershipID) {
            this.baseParameter.ThanhVienID = Number(lastUpdatedMembershipID);
        }
        this.baseParameter.Active = true;
        let url = this.aPIURL + this.controller + '/GetSQLByThanhVienIDAndActiveToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
}

