import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class DanhMucQuanHuyenService extends BaseService{

    list: DanhMucQuanHuyen[] | undefined;
    list001: DanhMucQuanHuyen[] | undefined;
    formData!: DanhMucQuanHuyen;

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "DanhMucQuanHuyen";
    }

    GetSQLByThanhVienIDAndActiveAndParentIDToListAsync() {
        var lastUpdatedMembershipID = localStorage.getItem(environment.ThanhVienID);
        if (lastUpdatedMembershipID) {
            this.baseParameter.ThanhVienID = Number(lastUpdatedMembershipID);
        }
        this.baseParameter.Active = true;
        let url = this.aPIURL + this.controller + '/GetSQLByThanhVienIDAndActiveAndParentIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
}

