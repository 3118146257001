import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';


import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucXaPhuong } from 'src/app/shared/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';


import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';
import { ToChucThanhVien } from 'src/app/shared/ToChucThanhVien.model';
import { ToChucThanhVienService } from 'src/app/shared/ToChucThanhVien.service';


import { HaTangKyThuat } from 'src/app/shared/HaTangKyThuat.model';
import { HaTangKyThuatService } from 'src/app/shared/HaTangKyThuat.service';
import { HaTangKyThuatDetailComponent } from '../ha-tang-ky-thuat-detail/ha-tang-ky-thuat-detail.component';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { ThanhVienNongDanDetailComponent } from '../thanh-vien-nong-dan-detail/thanh-vien-nong-dan-detail.component';

@Component({
  selector: 'app-doanh-nghiep',
  templateUrl: './doanh-nghiep.component.html',
  styleUrls: ['./doanh-nghiep.component.css']
})
export class DoanhNghiepComponent implements OnInit {


  @ViewChild('ToChucSort') ToChucSort: MatSort;
  @ViewChild('ToChucPaginator') ToChucPaginator: MatPaginator;

  CoQuanQuanLyDataSource: MatTableDataSource<any>;
  @ViewChild('CoQuanQuanLySort') CoQuanQuanLySort: MatSort;
  @ViewChild('CoQuanQuanLyPaginator') CoQuanQuanLyPaginator: MatPaginator;

  @ViewChild('HaTangKyThuatSort') HaTangKyThuatSort: MatSort;
  @ViewChild('HaTangKyThuatPaginator') HaTangKyThuatPaginator: MatPaginator;

  ThanhVienNongDanDataSource: MatTableDataSource<any>;
  @ViewChild('ThanhVienNongDanSort') ThanhVienNongDanSort: MatSort;
  @ViewChild('ThanhVienNongDanPaginator') ThanhVienNongDanPaginator: MatPaginator;

  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  DanhMucTinhThanhID: number = environment.DanhMucTinhThanhID;
  DanhMucQuanHuyenID: number = environment.InitializationNumber;
  DanhMucXaPhuongID: number = environment.InitializationNumber;


  constructor(
    private dialog: MatDialog,

    public NotificationService: NotificationService,

    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,

    public ToChucService: ToChucService,
    public ToChucThanhVienService: ToChucThanhVienService,

    public HaTangKyThuatService: HaTangKyThuatService,

    public ThanhVienService: ThanhVienService,
  ) { }

  ngOnInit(): void {
    this.DanhMucTinhThanhGetToList();
    this.HaTangKyThuatSearch();
  }

  
  NongDanAdd(ID: any) {
    this.ThanhVienService.baseParameter.ID = ID;
    this.ThanhVienService.GetByIDAsync().subscribe(
      res => {
        this.ThanhVienService.formData = res as ThanhVien;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ThanhVienNongDanDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.CoQuanQuanLySearch();
        });
      },
      err => {
      }
    );
  }
  NongDanSearch() {
    this.NongDanGetToList();
  }
  NongDanGetToList() {
    this.isShowLoading = true;
    this.ThanhVienService.baseParameter.ParentID = environment.ThanhVienNongDanID;
    this.ThanhVienService.baseParameter.SearchString = this.ThanhVienService.SearchString;
    this.ThanhVienService.baseParameter.DanhMucTinhThanhID = this.DanhMucTinhThanhID;
    this.ThanhVienService.baseParameter.DanhMucQuanHuyenID = this.DanhMucQuanHuyenID;
    this.ThanhVienService.baseParameter.DanhMucXaPhuongID = this.DanhMucXaPhuongID;
    this.ThanhVienService.GetByParentID_SearchString_DanhMucTinhThanhID_DanhMucQuanHuyenID_DanhMucXaPhuongIDToListAsync().subscribe(
      res => {
        this.ThanhVienService.list003 = (res as ThanhVien[]);
        for (let i = 0; i < this.ThanhVienService.list003.length; i++) {
          for (let j = 0; j < this.ToChucThanhVienService.list001.length; j++) {
            if (this.ThanhVienService.list003[i].ID == this.ToChucThanhVienService.list001[j].ThanhVienID) {
              for (let k = 0; k < this.ToChucService.list001.length; k++) {
                if (this.ToChucService.list001[k].ID == this.ToChucThanhVienService.list001[j].ParentID) {
                  this.ThanhVienService.list003[i].ParentID = this.ToChucService.list001[k].ID;
                  this.ThanhVienService.list003[i].TypeName = this.ToChucService.list001[k].Name;
                }
              }
            }
          }
        }
        this.ThanhVienNongDanDataSource = new MatTableDataSource(this.ThanhVienService.list003);
        this.ThanhVienNongDanDataSource.sort = this.ThanhVienNongDanSort;
        this.ThanhVienNongDanDataSource.paginator = this.ThanhVienNongDanPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }


  CoQuanQuanLySearch() {
    if (this.ToChucService.SearchString.length > 0) {
      this.CoQuanQuanLyDataSource.filter = this.ToChucService.SearchString.toLowerCase();
    }
    else {
      this.CoQuanQuanLyGetToList();
    }
  }
  CoQuanQuanLyGetToList() {
    this.isShowLoading = true;
    this.ToChucService.baseParameter.ParentID = 3;
    this.ToChucService.GetByParentIDAndEmptyToListAsync().subscribe(
      res => {
        this.ToChucService.list0002 = (res as ToChuc[]);
        this.CoQuanQuanLyDataSource = new MatTableDataSource(this.ToChucService.list0002);
        this.CoQuanQuanLyDataSource.sort = this.CoQuanQuanLySort;
        this.CoQuanQuanLyDataSource.paginator = this.CoQuanQuanLyPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  CoQuanQuanLySave(element: ToChuc) {
    element.ParentID = 3;
    this.ToChucService.formData = element;    
    this.ToChucService.SaveAsync().subscribe(
      res => {
        console.log(res);
        this.CoQuanQuanLySearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );    
  }

  ToChucSearch() {
    this.ToChucService.baseParameter.ParentID = 2;
    this.ToChucService.SearchByParentID(this.ToChucSort, this.ToChucPaginator);
  }
  ToChucSave(element: ToChuc) {
    element.ParentID = 2;
    this.ToChucService.formData = element;
    this.NotificationService.warn(this.ToChucService.ComponentSaveByParentID(this.ToChucSort, this.ToChucPaginator));
  }
  ToChucDelete(element: ToChuc) {
    this.ToChucService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ToChucService.ComponentDeleteByParentID(this.ToChucSort, this.ToChucPaginator));
  }

  HaTangKyThuatSearch() {    
    this.HaTangKyThuatService.SearchAllNotEmpty(this.HaTangKyThuatSort, this.HaTangKyThuatPaginator);
  }  
  HaTangKyThuatDelete(element: HaTangKyThuat) {
    this.HaTangKyThuatService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.HaTangKyThuatService.ComponentDeleteAll(this.HaTangKyThuatSort, this.HaTangKyThuatPaginator));
  }
  HaTangKyThuatAdd(ID: any) {
    this.HaTangKyThuatService.baseParameter.ID = ID;
    this.HaTangKyThuatService.GetByIDAsync().subscribe(
      res => {
        this.HaTangKyThuatService.formData = res as HaTangKyThuat;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(HaTangKyThuatDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.HaTangKyThuatSearch();
        });
      },
      err => {
      }
    );
  }

  ToChucGetToList() {    
    this.isShowLoading = true;    
    this.ToChucService.baseParameter.ParentID = environment.HopTacXaID;
    this.ToChucService.GetByParentIDToListAsync().subscribe(
      res => {
        this.ToChucService.list = (res as ToChuc[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucService.list001 = this.ToChucService.list;
        this.NongDanSearch();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );   
  }
  
  ToChucThanhVienGetToList() {
    this.isShowLoading = true;    
    this.ToChucThanhVienService.GetAllToListAsync().subscribe(
      res => {
        this.ToChucThanhVienService.list = (res as ToChucThanhVien[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucThanhVienService.list001 = this.ToChucThanhVienService.list;
        this.ToChucGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );   
  }

  DanhMucTinhThanhGetToList() {
    this.isShowLoading = true;
    this.DanhMucTinhThanhService.baseParameter.Active = true;
    this.DanhMucTinhThanhService.GetSQLByThanhVienIDAndActiveToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.list = (res as DanhMucTinhThanh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucTinhThanhService.list001 = this.DanhMucTinhThanhService.list;
        if (this.DanhMucTinhThanhService.list) {
          if (this.DanhMucTinhThanhService.list.length) {
            this.DanhMucTinhThanhID = this.DanhMucTinhThanhService.list[0].ID;
          }
        }
        this.DanhMucQuanHuyenGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucQuanHuyenGetToList() {
    this.isShowLoading = true;
    this.DanhMucQuanHuyenService.baseParameter.ParentID = this.DanhMucTinhThanhID;
    this.DanhMucQuanHuyenService.GetSQLByThanhVienIDAndActiveAndParentIDToListAsync().subscribe(
      res => {
        this.DanhMucQuanHuyenService.list = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucQuanHuyenService.list001 = this.DanhMucQuanHuyenService.list;
        this.DanhMucXaPhuongGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucXaPhuongGetToList() {
    this.isShowLoading = true;
    this.DanhMucXaPhuongService.baseParameter.ParentID = this.DanhMucQuanHuyenID;
    this.DanhMucXaPhuongService.GetSQLByThanhVienIDAndActiveAndParentIDToListAsync().subscribe(
      res => {
        this.DanhMucXaPhuongService.list = (res as DanhMucXaPhuong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucXaPhuongService.list001 = this.DanhMucXaPhuongService.list;
        this.ToChucThanhVienGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );    
  }

  DanhMucTinhThanhFilter(searchString: string) {
    this.DanhMucTinhThanhService.Filter(searchString);
  }
  DanhMucQuanHuyenFilter(searchString: string) {
    this.DanhMucQuanHuyenService.Filter(searchString);
  }
  DanhMucXaPhuongFilter(searchString: string) {
    this.DanhMucXaPhuongService.Filter(searchString);
  }
}
