<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-globe fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Vẽ vùng trồng</h2>
            <h6 class="m-0 text-dark fw-light">
                Bản đồ số
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row">
        <div class="col-lg-3 col-sm-12 col-12">
            <div class="row">
                <div class="col-lg-6 col-sm-12 col-12">
                    <a style="width: 100%;" id="Save" (click)="onSave()" class="btn btn-info"><i
                            class="bi bi-sd-card"></i>
                    </a>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <a style="width: 100%;" (click)="onDelete()" class="btn btn-danger"><i class="bi bi-trash"></i> Xóa
                        và chọn lại
                    </a>
                </div>
            </div>
            <div class="col-lg-12 col-sm-12 col-12">
                <label class="form-label">Tỉnh thành</label>
                <mat-select class="form-control" name="DanhMucTinhThanhID"
                    (selectionChange)="DanhMucQuanHuyenGetToList()" [(ngModel)]="danhMucTinhThanhID">
                    <input class="form-control" (keyup)="DanhMucTinhThanhFilter($event.target.value)">
                    <mat-option *ngFor="let item of DanhMucTinhThanhService.list001" [value]="item.ID">
                        {{item.Name}}
                    </mat-option>
                </mat-select>
            </div>
            <div class="col-lg-12 col-sm-12 col-12">
                <label class="form-label">Quận huyện</label>
                <mat-select class="form-control" name="DanhMucQuanHuyenID"
                    (selectionChange)="DanhMucXaPhuongGetToList()" [(ngModel)]="danhMucQuanHuyenID">
                    <input class="form-control" (keyup)="DanhMucQuanHuyenFilter($event.target.value)">
                    <mat-option *ngFor="let item of DanhMucQuanHuyenService.list001" [value]="item.ID">
                        {{item.Name}}
                    </mat-option>
                </mat-select>
            </div>
            <div class="col-lg-12 col-sm-12 col-12">
                <label class="form-label">Xã phường</label>
                <mat-select class="form-control" name="DanhMucXaPhuongID" (selectionChange)="ToChucGetAllToList()"
                    [(ngModel)]="danhMucXaPhuongID">
                    <input class="form-control" (keyup)="DanhMucXaPhuongFilter($event.target.value)">
                    <mat-option *ngFor="let item of DanhMucXaPhuongService.list001" [value]="item.ID">
                        {{item.Name}}
                    </mat-option>
                </mat-select>
            </div>
            <div class="col-lg-12 col-sm-12 col-12">
                <label class="form-label">Hợp tác xã</label>
                <mat-select class="form-control" name="ParentID" [(ngModel)]="ToChucToaDoService.formData.ParentID">
                    <input placeholder="Tìm..." class="form-control" (keyup)="ToChucFilter($event.target.value)">
                    <mat-option *ngFor="let item of ToChucService.list001" [value]="item.ID">
                        {{item.Name}} - {{item.DienThoai}}
                    </mat-option>
                </mat-select>
            </div>
            <div class="col-lg-12 col-sm-12 col-12">
                <label class="form-label">Điện thoại (*)</label>
                <input [(ngModel)]="ToChucToaDoService.formData.Code" placeholder="Điện thoại" class="form-control">
            </div>
            <div class="col-lg-12 col-sm-12 col-12">
                <label class="form-label">Nông hộ (*)</label>
                <input [(ngModel)]="ToChucToaDoService.formData.Name" placeholder="Nông hộ" class="form-control">
            </div>
            <div class="col-lg-12 col-sm-12 col-12">
                <label class="form-label">Sản phẩm (*)</label>
                <input [(ngModel)]="ToChucToaDoService.formData.Note" placeholder="Sản phẩm" class="form-control">
            </div>
            <div class="col-lg-12 col-sm-12 col-12">
                <div class="form-check form-check-inline mb-6">
                    <input type="checkbox" class="form-check-input" name="Active"
                        [(ngModel)]="ToChucToaDoService.formData.Active" />
                    <label class="form-check-label" for="Active">Trụ sở (Chỉ chọn một điểm)</label>
                </div>
            </div>
        </div>
        <div class="col-lg-9 col-sm-12 col-12">
            <div class="map" #map style="height: 750px; width: 100%; position: relative;">
                <div style="z-index: 1000; position: absolute; top: 0;">
                    <img src="{{domainURL}}assets/image/vungtrong.png" />
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>