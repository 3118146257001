<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-globe fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Bản đồ vùng trồng của {{ToChucService.formData.Name}}, {{ToChucService.formData.DienThoai}}
            </h2>
            <h6 class="m-0 text-dark fw-light">
                Bản đồ số
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row">
        <div class="col-lg-12 col-sm-12 col-12">
            <div class="map" #map style="height: 700px; width: 100%; position: relative;">
                <div style="z-index: 1000; position: absolute; top: 0;">
                    <img src="{{domainURL}}assets/image/vungtrong.png" />
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>