import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToChucDuAn } from 'src/app/shared/ToChucDuAn.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ToChucDuAnService extends BaseService{
    list: ToChucDuAn[] | undefined;
    list001: ToChucDuAn[] | undefined;
    formData!: ToChucDuAn;    

    displayColumns001: string[] = ['STT', 'DanhMucDuAnID', 'DienTich','SanLuong', 'Save'];
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "ToChucDuAn";
    }
}

