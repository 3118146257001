import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';

import { ThongBao } from 'src/app/shared/ThongBao.model';
import { ThongBaoService } from 'src/app/shared/ThongBao.service';

import { ThongBaoThanhVien } from 'src/app/shared/ThongBaoThanhVien.model';
import { ThongBaoThanhVienService } from 'src/app/shared/ThongBaoThanhVien.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';

@Component({
  selector: 'app-thong-bao-detail',
  templateUrl: './thong-bao-detail.component.html',
  styleUrls: ['./thong-bao-detail.component.css']
})
export class ThongBaoDetailComponent implements OnInit {

  isShowLoading: boolean = false;
  queryString: string = environment.InitializationString;
  searchString: string = environment.InitializationString;


  @ViewChild('ThongBaoThanhVienSort') ThongBaoThanhVienSort: MatSort;
  @ViewChild('ThongBaoThanhVienPaginator') ThongBaoThanhVienPaginator: MatPaginator;

  constructor(
    public dialogRef: MatDialogRef<ThongBaoDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,

    public ThongBaoService: ThongBaoService,
    public ThongBaoThanhVienService: ThongBaoThanhVienService,

    public ThanhVienService: ThanhVienService,
  ) { }

  ngOnInit(): void {
    this.ThongBaoThanhVienGetToList();
    this.ThanhVienGetAllToList();
  }

  ThongBaoThanhVienDelete(element: ThongBaoThanhVien) {
    if (confirm(environment.DeleteConfirm)) {
      this.isShowLoading = true;
      this.ThongBaoThanhVienService.baseParameter.ID = element.ID;
      this.ThongBaoThanhVienService.RemoveAsync().subscribe(
        res => {
          this.NotificationService.warn(environment.DeleteSuccess);
          this.ThongBaoThanhVienGetToList();
          this.isShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
          this.isShowLoading = false;
        }
      );
    }
  }

  ThongBaoThanhVienSave() {
    this.isShowLoading = true;
    this.ThongBaoThanhVienService.formData.ID = environment.InitializationNumber;
    this.ThongBaoThanhVienService.formData.ParentID = this.ThongBaoService.formData.ID;
    for (let i = 0; i < this.ThanhVienService.list001.length; i++) {
      if (this.ThongBaoThanhVienService.formData.ThanhVienID == this.ThanhVienService.list001[i].ID) {
        this.ThongBaoThanhVienService.formData.Name = this.ThanhVienService.list001[i].Name + " - " + this.ThanhVienService.list001[i].TaiKhoan;
        i = this.ThanhVienService.list001.length;
      }
    }
    this.ThongBaoThanhVienService.SaveAsync().subscribe(
      res => {
        this.ThongBaoThanhVienService.formData = res as ThongBaoThanhVien;
        this.NotificationService.warn(environment.SaveSuccess);
        this.ThongBaoThanhVienGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }

  ThongBaoThanhVienGetToList() {
    this.isShowLoading = true;

    this.ThongBaoThanhVienService.baseParameter.ParentID = this.ThongBaoService.formData.ID;
    this.ThongBaoThanhVienService.GetByParentIDToListAsync().subscribe(
      res => {
        this.ThongBaoThanhVienService.list = res as ThongBaoThanhVien[];
        this.ThongBaoThanhVienService.DataSource = new MatTableDataSource(this.ThongBaoThanhVienService.list);
        this.ThongBaoThanhVienService.DataSource.sort = this.ThongBaoThanhVienSort;
        this.ThongBaoThanhVienService.DataSource.paginator = this.ThongBaoThanhVienPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }


  ThanhVienGetAllToList() {
    this.ThanhVienService.baseParameter.ParentID = environment.ThanhVienNongDanID;
    this.ThanhVienService.ComponentGetByParentIDToListAsync();
  }

  ThanhVienFilter(searchString: string) {
    searchString = searchString.trim();
    this.ThanhVienService.Filter(searchString);
  }

  ThongBaoSave() {
    this.isShowLoading = true;
    this.ThongBaoService.SaveAsync().subscribe(
      res => {
        this.ThongBaoService.formData = res as ThongBao;
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  ThongBaoNew() {
    this.isShowLoading = true;
    this.ThongBaoService.formData.ID = 0;
    this.ThongBaoService.SaveAsync().subscribe(
      res => {
        this.ThongBaoService.formData = res as ThongBao;
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }

  Close() {
    this.dialogRef.close();
  }
  BatDauChange(value) {
    this.ThongBaoService.formData.BatDau = new Date(value);
  }
  KetThucChange(value) {
    this.ThongBaoService.formData.KetThuc = new Date(value);
  }

}
