import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ThanhVienLichSuTruyCap } from 'src/app/shared/ThanhVienLichSuTruyCap.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ThanhVienLichSuTruyCapService extends BaseService{

    list: ThanhVienLichSuTruyCap[] | undefined;
    list001: ThanhVienLichSuTruyCap[] | undefined;
    formData!: ThanhVienLichSuTruyCap;    

    displayColumns001: string[] = ['STT','Name','URL','NgayTruyCap','Description'];    

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "ThanhVienLichSuTruyCap";
    }

    GetByBatDau_KetThucToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByBatDau_KetThucToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));       
        return this.httpClient.post(url, formUpload);
    }
    GetBySearchString_BatDau_KetThucToListAsync() {
        let url = this.aPIURL + this.controller + '/GetBySearchString_BatDau_KetThucToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));       
        return this.httpClient.post(url, formUpload);
    }
}

