import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToChucToaDo } from 'src/app/shared/ToChucToaDo.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ToChucToaDoService extends BaseService{
    list: ToChucToaDo[] | undefined;
    list001: ToChucToaDo[] | undefined;
    listMap: ToChucToaDo[] | undefined;
    formData!: ToChucToaDo;    

    displayColumns001: string[] = ['STT', 'KinhDo', 'ViDo', 'DiaChi', 'Active', 'Save'];   
    displayColumns002: string[] = ['STT', 'KinhDo', 'ViDo', 'Name', 'Code', 'Note', 'SortOrder', 'Active', 'Save'];   
    displayColumns003: string[] = ['STT', 'KinhDo', 'ViDo', 'Name', 'Code', 'Note', 'Active', 'Save'];   
    displayColumns004: string[] = ['STT', 'LastUpdatedDate', 'KinhDo', 'ViDo', 'Name', 'Code', 'Note', 'SortOrder', 'Active', 'Save']; 
    
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "ToChucToaDo";
    }
    GetSQLByParentIDToListAsync() {
        let url = this.aPIURL + this.controller + '/GetSQLByParentIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetByToChucVungTrongIDToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByToChucVungTrongIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetByToChucVungTrongIDAndEmptyToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByToChucVungTrongIDAndEmptyToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }

    DeleteItemsByListIDAsync() {
        let url = this.aPIURL + this.controller + '/DeleteItemsByListIDAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    DeleteItemsByParentID_ListIDAsync() {
        let url = this.aPIURL + this.controller + '/DeleteItemsByParentID_ListIDAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
}


