import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';

import { BaiViet } from 'src/app/shared/BaiViet.model';
import { BaiVietService } from 'src/app/shared/BaiViet.service';

@Component({
  selector: 'app-bai-viet-detail',
  templateUrl: './bai-viet-detail.component.html',
  styleUrls: ['./bai-viet-detail.component.css']
})
export class BaiVietDetailComponent implements OnInit {

  isShowLoading: boolean = false;
  queryString: string = environment.InitializationString;
  searchString: string = environment.InitializationString;

  constructor(
    public dialogRef: MatDialogRef<BaiVietDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,

    public BaiVietService: BaiVietService,    
  ) { }

  ngOnInit(): void {
  }

  BaiVietSave() {
    this.isShowLoading = true;
    this.BaiVietService.SaveAsync().subscribe(
      res => {
        this.BaiVietService.formData = res as BaiViet;
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  BaiVietNew() {
    this.isShowLoading = true;
    this.BaiVietService.formData.ID = 0;
    this.BaiVietService.SaveAsync().subscribe(
      res => {
        this.BaiVietService.formData = res as BaiViet;
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }

  Close() {
    this.dialogRef.close();
  }
  NgayGhiNhanChange(value) {
    this.BaiVietService.formData.NgayGhiNhan = new Date(value);
  }  
}