<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-hypnotize fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Năng lực sản xuất</h2>
            <h6 class="m-0 text-dark fw-light">
                bi bi-hypnotize
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ToChucQuyTrinhSanXuat" data-bs-toggle="tab"
                                    href="#ToChucQuyTrinhSanXuat" role="tab" aria-controls="ToChucQuyTrinhSanXuat"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Hợp tác xã</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucQuyTrinhSanXuatChiTiet" data-bs-toggle="tab"
                                    href="#ToChucQuyTrinhSanXuatChiTiet" role="tab"
                                    aria-controls="ToChucQuyTrinhSanXuatChiTiet" aria-selected="false">
                                    <span class="badge bg-danger" style="font-size: 16px;">Tổng hợp</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucQuyTrinhSanXuatChiTiet1" data-bs-toggle="tab"
                                    href="#ToChucQuyTrinhSanXuatChiTiet1" role="tab"
                                    aria-controls="ToChucQuyTrinhSanXuatChiTiet1" aria-selected="false">
                                    <span class="badge bg-success" style="font-size: 16px;">Vụ mùa</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucQuyTrinhSanXuatChiTiet2" data-bs-toggle="tab"
                                    href="#ToChucQuyTrinhSanXuatChiTiet2" role="tab"
                                    aria-controls="ToChucQuyTrinhSanXuatChiTiet2" aria-selected="false">
                                    <span class="badge bg-warning" style="font-size: 16px;">Làm đất</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucQuyTrinhSanXuatChiTiet3" data-bs-toggle="tab"
                                    href="#ToChucQuyTrinhSanXuatChiTiet3" role="tab"
                                    aria-controls="ToChucQuyTrinhSanXuatChiTiet3" aria-selected="false">
                                    <span class="badge bg-primary" style="font-size: 16px;">Giống</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucQuyTrinhSanXuatChiTiet4" data-bs-toggle="tab"
                                    href="#ToChucQuyTrinhSanXuatChiTiet4" role="tab"
                                    aria-controls="ToChucQuyTrinhSanXuatChiTiet4" aria-selected="false">
                                    <span class="badge bg-dark" style="font-size: 16px;">Phân bón</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucQuyTrinhSanXuatChiTiet5" data-bs-toggle="tab"
                                    href="#ToChucQuyTrinhSanXuatChiTiet5" role="tab"
                                    aria-controls="ToChucQuyTrinhSanXuatChiTiet5" aria-selected="false">
                                    <span class="badge bg-info" style="font-size: 16px;">BVTV</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucQuyTrinhSanXuatChiTiet6" data-bs-toggle="tab"
                                    href="#ToChucQuyTrinhSanXuatChiTiet6" role="tab"
                                    aria-controls="ToChucQuyTrinhSanXuatChiTiet6" aria-selected="false">
                                    <span class="badge bg-danger" style="font-size: 16px;">Thu hoạch</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucQuyTrinhSanXuatChiTiet7" data-bs-toggle="tab"
                                    href="#ToChucQuyTrinhSanXuatChiTiet7" role="tab"
                                    aria-controls="ToChucQuyTrinhSanXuatChiTiet7" aria-selected="false">
                                    <span class="badge bg-success" style="font-size: 16px;">Sau thu hoạch</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucQuyTrinhSanXuatLichSu" data-bs-toggle="tab"
                                    href="#ToChucQuyTrinhSanXuatLichSu" role="tab"
                                    aria-controls="ToChucQuyTrinhSanXuatLichSu" aria-selected="false">
                                    <span class="badge bg-warning" style="font-size: 16px;">Lịch sử</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ToChucQuyTrinhSanXuat" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label class="form-label">Tỉnh thành</label>
                                        <mat-select class="form-control" name="DanhMucTinhThanhID"
                                            (selectionChange)="DanhMucQuanHuyenGetToList()"
                                            [(ngModel)]="DanhMucTinhThanhID">
                                            <input class="form-control"
                                                (keyup)="DanhMucTinhThanhFilter($event.target.value)">
                                            <mat-option *ngFor="let item of DanhMucTinhThanhService.list001"
                                                [value]="item.ID">
                                                {{item.Name}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">Quận huyện</label>
                                        <mat-select class="form-control" name="DanhMucQuanHuyenID"
                                            (selectionChange)="DanhMucXaPhuongGetToList()"
                                            [(ngModel)]="DanhMucQuanHuyenID">
                                            <input class="form-control"
                                                (keyup)="DanhMucQuanHuyenFilter($event.target.value)">
                                            <mat-option [value]="0">
                                                Tất cả
                                            </mat-option>
                                            <mat-option *ngFor="let item of DanhMucQuanHuyenService.list001"
                                                [value]="item.ID">
                                                {{item.Name}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">Xã phường</label>
                                        <mat-select class="form-control" name="DanhMucXaPhuongID"
                                            [(ngModel)]="DanhMucXaPhuongID"
                                            (selectionChange)="ToChucQuyTrinhSanXuatSearch()">
                                            <input class="form-control"
                                                (keyup)="DanhMucXaPhuongFilter($event.target.value)">
                                            <mat-option [value]="0">
                                                Tất cả
                                            </mat-option>
                                            <mat-option *ngFor="let item of DanhMucXaPhuongService.list001"
                                                [value]="item.ID">
                                                {{item.Name}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <input [(ngModel)]="ToChucQuyTrinhSanXuatService.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="ToChucQuyTrinhSanXuatSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <div>
                                            <a class="btn btn-success" title="Tìm"
                                                (click)="ToChucQuyTrinhSanXuatSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                            <!-- <a class="btn btn-success" title="Download excel"
                                                (click)="ToChucQuyTrinhSanXuatExportExcel()"><i
                                                    class="bi bi-cloud-download"></i></a>&nbsp;&nbsp;&nbsp; -->
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Hợp tác xã
                                            <span
                                                *ngIf="ToChucQuyTrinhSanXuatService.list">({{ToChucQuyTrinhSanXuatService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucQuyTrinhSanXuatSort="matSort"
                                                    [dataSource]="ToChucQuyTrinhSanXuatDataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucQuyTrinhSanXuatPaginator.pageSize *
                                                            ToChucQuyTrinhSanXuatPaginator.pageIndex + i + 1}}
                                                            [{{element.ParentID}}]
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="LastUpdatedDate">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ngày cập
                                                            nhật cuối
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.LastUpdatedDate | date:'dd/MM/yyyy h:mm:ss a'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="LastUpdatedMembershipID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Người cập
                                                            nhật cuối
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            Quản trị DGK
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.Name}}"
                                                                class="link-primary"
                                                                (click)="ToChucQuyTrinhSanXuatAdd(element.ID)"><b>{{element.Name}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Display">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Display}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DienTich">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Diện tích
                                                            (ha)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder="0"
                                                                name="DienTich{{element.ID}}"
                                                                [(ngModel)]="element.DienTich">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoVu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Số vụ</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder="0"
                                                                name="SoVu{{element.ID}}" [(ngModel)]="element.SoVu">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucQuyTrinhSanXuatSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <!-- <a class="btn btn-danger"
                                                                (click)="ToChucQuyTrinhSanXuatDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a> -->
                                                            <a class="btn btn-success"
                                                                (click)="ToChucExportHopTacXaByIDToHTMLAsync(element)"
                                                                *ngIf="(ThanhVienService.formDataLogin.ParentID==5 || ThanhVienService.formDataLogin.ParentID==3) && element.ID>0"><i
                                                                    class="bi bi-printer"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucQuyTrinhSanXuatService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucQuyTrinhSanXuatService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucQuyTrinhSanXuatPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucQuyTrinhSanXuatChiTiet" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <label class="form-label">Hợp tác xã</label>
                                        <mat-select class="form-control" name="ToChucID" [(ngModel)]="ToChucID"
                                            (selectionChange)="ToChucQuyTrinhSanXuatChiTietSearch()">
                                            <input class="form-control" (keyup)="ToChucFilter($event.target.value)">
                                            <mat-option *ngFor="let item of ToChucService.list001" [value]="item.ID">
                                                {{item.Code}} - {{item.Name}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <input [(ngModel)]="ToChucQuyTrinhSanXuatChiTietService.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="ToChucQuyTrinhSanXuatChiTietSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <div>
                                            <a class="btn btn-success" title="Tìm"
                                                (click)="ToChucQuyTrinhSanXuatChiTietSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-danger">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Tổng hợp
                                            <span
                                                *ngIf="ToChucQuyTrinhSanXuatChiTietService.list">({{ToChucQuyTrinhSanXuatChiTietService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucQuyTrinhSanXuatChiTietSort="matSort"
                                                    [dataSource]="ToChucQuyTrinhSanXuatChiTietDataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucQuyTrinhSanXuatChiTietPaginator.pageSize *
                                                            ToChucQuyTrinhSanXuatChiTietPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucQuyTrinhSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Quy trình
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucQuyTrinhSanXuatID"
                                                                [(ngModel)]="element.DanhMucQuyTrinhSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucQuyTrinhSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucPhanDoanSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phân đoạn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucPhanDoanSanXuatID"
                                                                [(ngModel)]="element.DanhMucPhanDoanSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucPhanDoanSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoLuong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chỉ số kế
                                                            hoạch (Tháng | %)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder=""
                                                                name="SoLuong{{element.ID}}"
                                                                [(ngModel)]="element.SoLuong">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chọn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucQuyTrinhSanXuatChiTietService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucQuyTrinhSanXuatChiTietService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucQuyTrinhSanXuatChiTietPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucQuyTrinhSanXuatChiTiet1" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <label class="form-label">Hợp tác xã</label>
                                        <mat-select class="form-control" name="ToChucID" [(ngModel)]="ToChucID"
                                            (selectionChange)="ToChucQuyTrinhSanXuatChiTietSearch1()">
                                            <input class="form-control" (keyup)="ToChucFilter($event.target.value)">
                                            <mat-option *ngFor="let item of ToChucService.list001" [value]="item.ID">
                                                {{item.Code}} - {{item.Name}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <input [(ngModel)]="ToChucQuyTrinhSanXuatChiTietService.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="ToChucQuyTrinhSanXuatChiTietSearch1()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <div>
                                            <a class="btn btn-success" title="Tìm"
                                                (click)="ToChucQuyTrinhSanXuatChiTietSearch1()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-success">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Vụ mùa
                                            <span
                                                *ngIf="ToChucQuyTrinhSanXuatChiTietService.list001">({{ToChucQuyTrinhSanXuatChiTietService.list001.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucQuyTrinhSanXuatChiTietSort1="matSort"
                                                    [dataSource]="ToChucQuyTrinhSanXuatChiTietDataSource1"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucQuyTrinhSanXuatChiTietPaginator1.pageSize *
                                                            ToChucQuyTrinhSanXuatChiTietPaginator1.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucQuyTrinhSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Quy trình
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucQuyTrinhSanXuatID"
                                                                [(ngModel)]="element.DanhMucQuyTrinhSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucQuyTrinhSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucPhanDoanSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phân đoạn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucPhanDoanSanXuatID"
                                                                [(ngModel)]="element.DanhMucPhanDoanSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucPhanDoanSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoLuong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chỉ số kế
                                                            hoạch (Tháng | %)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder=""
                                                                name="SoLuong{{element.ID}}"
                                                                [(ngModel)]="element.SoLuong">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chọn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucQuyTrinhSanXuatChiTietService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucQuyTrinhSanXuatChiTietService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucQuyTrinhSanXuatChiTietPaginator1="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucQuyTrinhSanXuatChiTiet2" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <label class="form-label">Hợp tác xã</label>
                                        <mat-select class="form-control" name="ToChucID" [(ngModel)]="ToChucID"
                                            (selectionChange)="ToChucQuyTrinhSanXuatChiTietSearch2()">
                                            <input class="form-control" (keyup)="ToChucFilter($event.target.value)">
                                            <mat-option *ngFor="let item of ToChucService.list001" [value]="item.ID">
                                                {{item.Code}} - {{item.Name}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <input [(ngModel)]="ToChucQuyTrinhSanXuatChiTietService.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="ToChucQuyTrinhSanXuatChiTietSearch2()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <div>
                                            <a class="btn btn-success" title="Tìm"
                                                (click)="ToChucQuyTrinhSanXuatChiTietSearch2()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-warning">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Làm đất
                                            <span
                                                *ngIf="ToChucQuyTrinhSanXuatChiTietService.list002">({{ToChucQuyTrinhSanXuatChiTietService.list002.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucQuyTrinhSanXuatChiTietSort2="matSort"
                                                    [dataSource]="ToChucQuyTrinhSanXuatChiTietDataSource2"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucQuyTrinhSanXuatChiTietPaginator2.pageSize *
                                                            ToChucQuyTrinhSanXuatChiTietPaginator2.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucQuyTrinhSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Quy trình
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucQuyTrinhSanXuatID"
                                                                [(ngModel)]="element.DanhMucQuyTrinhSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucQuyTrinhSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucPhanDoanSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phân đoạn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucPhanDoanSanXuatID"
                                                                [(ngModel)]="element.DanhMucPhanDoanSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucPhanDoanSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoLuong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chỉ số kế
                                                            hoạch (Tháng | %)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder=""
                                                                name="SoLuong{{element.ID}}"
                                                                [(ngModel)]="element.SoLuong">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chọn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucQuyTrinhSanXuatChiTietService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucQuyTrinhSanXuatChiTietService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucQuyTrinhSanXuatChiTietPaginator2="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucQuyTrinhSanXuatChiTiet3" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <label class="form-label">Hợp tác xã</label>
                                        <mat-select class="form-control" name="ToChucID" [(ngModel)]="ToChucID"
                                            (selectionChange)="ToChucQuyTrinhSanXuatChiTietSearch3()">
                                            <input class="form-control" (keyup)="ToChucFilter($event.target.value)">
                                            <mat-option *ngFor="let item of ToChucService.list001" [value]="item.ID">
                                                {{item.Code}} - {{item.Name}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <input [(ngModel)]="ToChucQuyTrinhSanXuatChiTietService.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="ToChucQuyTrinhSanXuatChiTietSearch3()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <div>
                                            <a class="btn btn-success" title="Tìm"
                                                (click)="ToChucQuyTrinhSanXuatChiTietSearch3()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-primary">
                                    <div class="card-header">
                                        <h5 class="card-title text-white bg-primary">Giống
                                            <span
                                                *ngIf="ToChucQuyTrinhSanXuatChiTietService.list003">({{ToChucQuyTrinhSanXuatChiTietService.list003.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body bg-primary">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucQuyTrinhSanXuatChiTietSort3="matSort"
                                                    [dataSource]="ToChucQuyTrinhSanXuatChiTietDataSource3"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucQuyTrinhSanXuatChiTietPaginator3.pageSize *
                                                            ToChucQuyTrinhSanXuatChiTietPaginator3.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucQuyTrinhSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Quy trình
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucQuyTrinhSanXuatID"
                                                                [(ngModel)]="element.DanhMucQuyTrinhSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucQuyTrinhSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucPhanDoanSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phân đoạn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucPhanDoanSanXuatID"
                                                                [(ngModel)]="element.DanhMucPhanDoanSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucPhanDoanSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoLuong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chỉ số kế
                                                            hoạch (Tháng | %)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder=""
                                                                name="SoLuong{{element.ID}}"
                                                                [(ngModel)]="element.SoLuong">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chọn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucQuyTrinhSanXuatChiTietService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucQuyTrinhSanXuatChiTietService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucQuyTrinhSanXuatChiTietPaginator3="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucQuyTrinhSanXuatChiTiet4" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <label class="form-label">Hợp tác xã</label>
                                        <mat-select class="form-control" name="ToChucID" [(ngModel)]="ToChucID"
                                            (selectionChange)="ToChucQuyTrinhSanXuatChiTietSearch4()">
                                            <input class="form-control" (keyup)="ToChucFilter($event.target.value)">
                                            <mat-option *ngFor="let item of ToChucService.list001" [value]="item.ID">
                                                {{item.Code}} - {{item.Name}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <input [(ngModel)]="ToChucQuyTrinhSanXuatChiTietService.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="ToChucQuyTrinhSanXuatChiTietSearch4()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <div>
                                            <a class="btn btn-success" title="Tìm"
                                                (click)="ToChucQuyTrinhSanXuatChiTietSearch4()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-dark">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Phân bón
                                            <span
                                                *ngIf="ToChucQuyTrinhSanXuatChiTietService.list004">({{ToChucQuyTrinhSanXuatChiTietService.list004.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucQuyTrinhSanXuatChiTietSort4="matSort"
                                                    [dataSource]="ToChucQuyTrinhSanXuatChiTietDataSource4"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucQuyTrinhSanXuatChiTietPaginator4.pageSize *
                                                            ToChucQuyTrinhSanXuatChiTietPaginator4.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucQuyTrinhSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Quy trình
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucQuyTrinhSanXuatID"
                                                                [(ngModel)]="element.DanhMucQuyTrinhSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucQuyTrinhSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucPhanDoanSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phân đoạn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucPhanDoanSanXuatID"
                                                                [(ngModel)]="element.DanhMucPhanDoanSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucPhanDoanSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoLuong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chỉ số kế
                                                            hoạch (Tháng | %)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder=""
                                                                name="SoLuong{{element.ID}}"
                                                                [(ngModel)]="element.SoLuong">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chọn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucQuyTrinhSanXuatChiTietService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucQuyTrinhSanXuatChiTietService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucQuyTrinhSanXuatChiTietPaginator4="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucQuyTrinhSanXuatChiTiet5" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <label class="form-label">Hợp tác xã</label>
                                        <mat-select class="form-control" name="ToChucID" [(ngModel)]="ToChucID"
                                            (selectionChange)="ToChucQuyTrinhSanXuatChiTietSearch5()">
                                            <input class="form-control" (keyup)="ToChucFilter($event.target.value)">
                                            <mat-option *ngFor="let item of ToChucService.list001" [value]="item.ID">
                                                {{item.Code}} - {{item.Name}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <input [(ngModel)]="ToChucQuyTrinhSanXuatChiTietService.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="ToChucQuyTrinhSanXuatChiTietSearch5()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <div>
                                            <a class="btn btn-success" title="Tìm"
                                                (click)="ToChucQuyTrinhSanXuatChiTietSearch5()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Bảo vệ thực vật
                                            <span
                                                *ngIf="ToChucQuyTrinhSanXuatChiTietService.list005">({{ToChucQuyTrinhSanXuatChiTietService.list005.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucQuyTrinhSanXuatChiTietSort5="matSort"
                                                    [dataSource]="ToChucQuyTrinhSanXuatChiTietDataSource5"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucQuyTrinhSanXuatChiTietPaginator5.pageSize *
                                                            ToChucQuyTrinhSanXuatChiTietPaginator5.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucQuyTrinhSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Quy trình
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucQuyTrinhSanXuatID"
                                                                [(ngModel)]="element.DanhMucQuyTrinhSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucQuyTrinhSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucPhanDoanSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phân đoạn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucPhanDoanSanXuatID"
                                                                [(ngModel)]="element.DanhMucPhanDoanSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucPhanDoanSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoLuong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chỉ số kế
                                                            hoạch (Tháng | %)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder=""
                                                                name="SoLuong{{element.ID}}"
                                                                [(ngModel)]="element.SoLuong">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chọn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucQuyTrinhSanXuatChiTietService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucQuyTrinhSanXuatChiTietService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucQuyTrinhSanXuatChiTietPaginator5="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucQuyTrinhSanXuatChiTiet6" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <label class="form-label">Hợp tác xã</label>
                                        <mat-select class="form-control" name="ToChucID" [(ngModel)]="ToChucID"
                                            (selectionChange)="ToChucQuyTrinhSanXuatChiTietSearch6()">
                                            <input class="form-control" (keyup)="ToChucFilter($event.target.value)">
                                            <mat-option *ngFor="let item of ToChucService.list001" [value]="item.ID">
                                                {{item.Code}} - {{item.Name}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <input [(ngModel)]="ToChucQuyTrinhSanXuatChiTietService.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="ToChucQuyTrinhSanXuatChiTietSearch6()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <div>
                                            <a class="btn btn-success" title="Tìm"
                                                (click)="ToChucQuyTrinhSanXuatChiTietSearch6()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-danger">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Thu hoạch
                                            <span
                                                *ngIf="ToChucQuyTrinhSanXuatChiTietService.list006">({{ToChucQuyTrinhSanXuatChiTietService.list006.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucQuyTrinhSanXuatChiTietSort6="matSort"
                                                    [dataSource]="ToChucQuyTrinhSanXuatChiTietDataSource6"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucQuyTrinhSanXuatChiTietPaginator6.pageSize *
                                                            ToChucQuyTrinhSanXuatChiTietPaginator6.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucQuyTrinhSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Quy trình
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucQuyTrinhSanXuatID"
                                                                [(ngModel)]="element.DanhMucQuyTrinhSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucQuyTrinhSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucPhanDoanSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phân đoạn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucPhanDoanSanXuatID"
                                                                [(ngModel)]="element.DanhMucPhanDoanSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucPhanDoanSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoLuong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chỉ số kế
                                                            hoạch (Tháng | %)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder=""
                                                                name="SoLuong{{element.ID}}"
                                                                [(ngModel)]="element.SoLuong">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chọn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucQuyTrinhSanXuatChiTietService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucQuyTrinhSanXuatChiTietService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucQuyTrinhSanXuatChiTietPaginator6="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucQuyTrinhSanXuatChiTiet7" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <label class="form-label">Hợp tác xã</label>
                                        <mat-select class="form-control" name="ToChucID" [(ngModel)]="ToChucID"
                                            (selectionChange)="ToChucQuyTrinhSanXuatChiTietSearch7()">
                                            <input class="form-control" (keyup)="ToChucFilter($event.target.value)">
                                            <mat-option *ngFor="let item of ToChucService.list001" [value]="item.ID">
                                                {{item.Code}} - {{item.Name}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <input [(ngModel)]="ToChucQuyTrinhSanXuatChiTietService.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="ToChucQuyTrinhSanXuatChiTietSearch7()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <div>
                                            <a class="btn btn-success" title="Tìm"
                                                (click)="ToChucQuyTrinhSanXuatChiTietSearch7()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-success">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Sau Thu hoạch
                                            <span
                                                *ngIf="ToChucQuyTrinhSanXuatChiTietService.list007">({{ToChucQuyTrinhSanXuatChiTietService.list007.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucQuyTrinhSanXuatChiTietSort7="matSort"
                                                    [dataSource]="ToChucQuyTrinhSanXuatChiTietDataSource7"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucQuyTrinhSanXuatChiTietPaginator7.pageSize *
                                                            ToChucQuyTrinhSanXuatChiTietPaginator7.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucQuyTrinhSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Quy trình
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucQuyTrinhSanXuatID"
                                                                [(ngModel)]="element.DanhMucQuyTrinhSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucQuyTrinhSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucPhanDoanSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phân đoạn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucPhanDoanSanXuatID"
                                                                [(ngModel)]="element.DanhMucPhanDoanSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucPhanDoanSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoLuong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chỉ số kế
                                                            hoạch (Tháng | %)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder=""
                                                                name="SoLuong{{element.ID}}"
                                                                [(ngModel)]="element.SoLuong">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chọn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucQuyTrinhSanXuatChiTietDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucQuyTrinhSanXuatChiTietService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucQuyTrinhSanXuatChiTietService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucQuyTrinhSanXuatChiTietPaginator7="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucQuyTrinhSanXuatLichSu" role="tabpanel">
                                <div class="card mb-4 card-success">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Lịch sử cập nhật
                                            <span
                                                *ngIf="ToChucQuyTrinhSanXuatLichSuService.list">({{ToChucQuyTrinhSanXuatLichSuService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucQuyTrinhSanXuatLichSuSort="matSort"
                                                    [dataSource]="ToChucQuyTrinhSanXuatLichSuService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucQuyTrinhSanXuatLichSuPaginator.pageSize *
                                                            ToChucQuyTrinhSanXuatLichSuPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="LastUpdatedDate">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ngày cập
                                                            nhật cuối
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.LastUpdatedDate | date:'dd/MM/yyyy h:mm:ss a'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="LastUpdatedMembershipID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Người cập
                                                            nhật cuối
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.LastUpdatedMembershipID}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="FileName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>File
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.Code}}"
                                                                class="link-primary" href="{{element.Code}}"><b>Tải
                                                                    về</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucQuyTrinhSanXuatLichSuService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucQuyTrinhSanXuatLichSuService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucQuyTrinhSanXuatLichSuPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>