import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';

import { DanhMucSanPhamGiong } from 'src/app/shared/DanhMucSanPhamGiong.model';
import { DanhMucSanPhamGiongService } from 'src/app/shared/DanhMucSanPhamGiong.service';

@Component({
  selector: 'app-danh-muc-san-pham-giong-detail',
  templateUrl: './danh-muc-san-pham-giong-detail.component.html',
  styleUrls: ['./danh-muc-san-pham-giong-detail.component.css']
})
export class DanhMucSanPhamGiongDetailComponent implements OnInit {

  isShowLoading: boolean = false;
  queryString: string = environment.InitializationString;
  searchString: string = environment.InitializationString;

  constructor(
    public dialogRef: MatDialogRef<DanhMucSanPhamGiongDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,

    public DanhMucSanPhamGiongService: DanhMucSanPhamGiongService,    
  ) { }

  ngOnInit(): void {
  }

  DanhMucSanPhamGiongSave() {
    this.isShowLoading = true;
    this.DanhMucSanPhamGiongService.SaveAsync().subscribe(
      res => {
        this.DanhMucSanPhamGiongService.formData = res as DanhMucSanPhamGiong;
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  DanhMucSanPhamGiongNew() {
    this.isShowLoading = true;
    this.DanhMucSanPhamGiongService.formData.ID = 0;
    this.DanhMucSanPhamGiongService.SaveAsync().subscribe(
      res => {
        this.DanhMucSanPhamGiongService.formData = res as DanhMucSanPhamGiong;
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }

  Close() {
    this.dialogRef.close();
  }
  NgayQuyetDinhChange(value) {
    this.DanhMucSanPhamGiongService.formData.NgayQuyetDinh = new Date(value);
  }
  NgayHieuLucChange(value) {
    this.DanhMucSanPhamGiongService.formData.NgayHieuLuc = new Date(value);
  }
}
