import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucXaPhuong } from 'src/app/shared/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';
import { DanhMucNgonNgu } from 'src/app/shared/DanhMucNgonNgu.model';
import { DanhMucNgonNguService } from 'src/app/shared/DanhMucNgonNgu.service';
import { DanhMucThanhVien } from 'src/app/shared/DanhMucThanhVien.model';
import { DanhMucThanhVienService } from 'src/app/shared/DanhMucThanhVien.service';
import { DanhMucToChuc } from 'src/app/shared/DanhMucToChuc.model';
import { DanhMucToChucService } from 'src/app/shared/DanhMucToChuc.service';
import { DanhMucChucDanh } from 'src/app/shared/DanhMucChucDanh.model';
import { DanhMucChucDanhService } from 'src/app/shared/DanhMucChucDanh.service';
import { DanhMucHoaDon } from 'src/app/shared/DanhMucHoaDon.model';
import { DanhMucHoaDonService } from 'src/app/shared/DanhMucHoaDon.service';
import { DanhMucDonViTinh } from 'src/app/shared/DanhMucDonViTinh.model';
import { DanhMucDonViTinhService } from 'src/app/shared/DanhMucDonViTinh.service';
import { DanhMucChungChi } from 'src/app/shared/DanhMucChungChi.model';
import { DanhMucChungChiService } from 'src/app/shared/DanhMucChungChi.service';
import { DanhMucThietBi } from 'src/app/shared/DanhMucThietBi.model';
import { DanhMucThietBiService } from 'src/app/shared/DanhMucThietBi.service';
import { DanhMucTieuChuanSanXuat } from 'src/app/shared/DanhMucTieuChuanSanXuat.model';
import { DanhMucTieuChuanSanXuatService } from 'src/app/shared/DanhMucTieuChuanSanXuat.service';
import { DanhMucLienKet } from 'src/app/shared/DanhMucLienKet.model';
import { DanhMucLienKetService } from 'src/app/shared/DanhMucLienKet.service';
import { DanhMucTinhThanhToaDoDetailComponent } from '../danh-muc-tinh-thanh-toa-do-detail/danh-muc-tinh-thanh-toa-do-detail.component';
import { DanhMucTinhThanhToaDo } from 'src/app/shared/DanhMucTinhThanhToaDo.model';
import { DanhMucTinhThanhToaDoService } from 'src/app/shared/DanhMucTinhThanhToaDo.service';

@Component({
  selector: 'app-danh-muc',
  templateUrl: './danh-muc.component.html',
  styleUrls: ['./danh-muc.component.css']
})
export class DanhMucComponent implements OnInit {


  @ViewChild('DanhMucTinhThanhSort') DanhMucTinhThanhSort: MatSort;
  @ViewChild('DanhMucTinhThanhPaginator') DanhMucTinhThanhPaginator: MatPaginator;


  @ViewChild('DanhMucQuanHuyenSort') DanhMucQuanHuyenSort: MatSort;
  @ViewChild('DanhMucQuanHuyenPaginator') DanhMucQuanHuyenPaginator: MatPaginator;


  @ViewChild('DanhMucXaPhuongSort') DanhMucXaPhuongSort: MatSort;
  @ViewChild('DanhMucXaPhuongPaginator') DanhMucXaPhuongPaginator: MatPaginator;


  @ViewChild('DanhMucNgonNguSort') DanhMucNgonNguSort: MatSort;
  @ViewChild('DanhMucNgonNguPaginator') DanhMucNgonNguPaginator: MatPaginator;


  @ViewChild('DanhMucThanhVienSort') DanhMucThanhVienSort: MatSort;
  @ViewChild('DanhMucThanhVienPaginator') DanhMucThanhVienPaginator: MatPaginator;


  @ViewChild('DanhMucToChucSort') DanhMucToChucSort: MatSort;
  @ViewChild('DanhMucToChucPaginator') DanhMucToChucPaginator: MatPaginator;


  @ViewChild('DanhMucChucDanhSort') DanhMucChucDanhSort: MatSort;
  @ViewChild('DanhMucChucDanhPaginator') DanhMucChucDanhPaginator: MatPaginator;


  @ViewChild('DanhMucHoaDonSort') DanhMucHoaDonSort: MatSort;
  @ViewChild('DanhMucHoaDonPaginator') DanhMucHoaDonPaginator: MatPaginator;


  @ViewChild('DanhMucDonViTinhSort') DanhMucDonViTinhSort: MatSort;
  @ViewChild('DanhMucDonViTinhPaginator') DanhMucDonViTinhPaginator: MatPaginator;


  @ViewChild('DanhMucChungChiSort') DanhMucChungChiSort: MatSort;
  @ViewChild('DanhMucChungChiPaginator') DanhMucChungChiPaginator: MatPaginator;


  @ViewChild('DanhMucThietBiSort') DanhMucThietBiSort: MatSort;
  @ViewChild('DanhMucThietBiPaginator') DanhMucThietBiPaginator: MatPaginator;


  @ViewChild('DanhMucTieuChuanSanXuatSort') DanhMucTieuChuanSanXuatSort: MatSort;
  @ViewChild('DanhMucTieuChuanSanXuatPaginator') DanhMucTieuChuanSanXuatPaginator: MatPaginator;


  @ViewChild('DanhMucLienKetSort') DanhMucLienKetSort: MatSort;
  @ViewChild('DanhMucLienKetPaginator') DanhMucLienKetPaginator: MatPaginator;

  isShowLoading: boolean = false;
  isMobile: boolean = false;
  searchString: string = environment.InitializationString;
  DanhMucTinhThanhID: number = environment.InitializationNumber;
  DanhMucQuanHuyenID: number = environment.InitializationNumber;

  constructor(
    private dialog: MatDialog,

    public NotificationService: NotificationService,
    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,
    public DanhMucNgonNguService: DanhMucNgonNguService,
    public DanhMucThanhVienService: DanhMucThanhVienService,
    public DanhMucToChucService: DanhMucToChucService,
    public DanhMucChucDanhService: DanhMucChucDanhService,
    public DanhMucHoaDonService: DanhMucHoaDonService,
    public DanhMucDonViTinhService: DanhMucDonViTinhService,
    public DanhMucChungChiService: DanhMucChungChiService,
    public DanhMucThietBiService: DanhMucThietBiService,
    public DanhMucTieuChuanSanXuatService: DanhMucTieuChuanSanXuatService,
    public DanhMucLienKetService: DanhMucLienKetService,
    public DanhMucTinhThanhToaDoService: DanhMucTinhThanhToaDoService,

  ) {

  }

  ngOnInit(): void {    
  }


  DanhMucTinhThanhSearch() {
    this.DanhMucTinhThanhService.SearchAll(this.DanhMucTinhThanhSort, this.DanhMucTinhThanhPaginator);
  }
  DanhMucTinhThanhSave(element: DanhMucTinhThanh) {
    this.DanhMucTinhThanhService.formData = element;
    this.NotificationService.warn(this.DanhMucTinhThanhService.ComponentSaveAll(this.DanhMucTinhThanhSort, this.DanhMucTinhThanhPaginator));
  }
  DanhMucTinhThanhDelete(element: DanhMucTinhThanh) {
    this.DanhMucTinhThanhService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucTinhThanhService.ComponentDeleteAll(this.DanhMucTinhThanhSort, this.DanhMucTinhThanhPaginator));
  }

  DanhMucQuanHuyenSearch() {
    this.DanhMucQuanHuyenService.baseParameter.ParentID = this.DanhMucTinhThanhID;
    this.DanhMucQuanHuyenService.SearchByParentID(this.DanhMucQuanHuyenSort, this.DanhMucQuanHuyenPaginator);
  }
  DanhMucQuanHuyenSave(element: DanhMucQuanHuyen) {
    this.DanhMucQuanHuyenService.formData = element;
    this.NotificationService.warn(this.DanhMucQuanHuyenService.ComponentSaveAll(this.DanhMucQuanHuyenSort, this.DanhMucQuanHuyenPaginator));
  }
  DanhMucQuanHuyenDelete(element: DanhMucQuanHuyen) {
    this.DanhMucQuanHuyenService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucQuanHuyenService.ComponentDeleteAll(this.DanhMucQuanHuyenSort, this.DanhMucQuanHuyenPaginator));
  }

  DanhMucXaPhuongSearch() {
    this.DanhMucXaPhuongService.baseParameter.ParentID = this.DanhMucQuanHuyenID;
    this.DanhMucXaPhuongService.SearchByParentID(this.DanhMucXaPhuongSort, this.DanhMucXaPhuongPaginator);
  }
  DanhMucXaPhuongSave(element: DanhMucXaPhuong) {
    this.DanhMucXaPhuongService.formData = element;
    this.NotificationService.warn(this.DanhMucXaPhuongService.ComponentSaveAll(this.DanhMucXaPhuongSort, this.DanhMucXaPhuongPaginator));
  }
  DanhMucXaPhuongDelete(element: DanhMucXaPhuong) {
    this.DanhMucXaPhuongService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucXaPhuongService.ComponentDeleteAll(this.DanhMucXaPhuongSort, this.DanhMucXaPhuongPaginator));
  }

  DanhMucNgonNguSearch() {
    this.DanhMucNgonNguService.SearchAll(this.DanhMucNgonNguSort, this.DanhMucNgonNguPaginator);
  }
  DanhMucNgonNguSave(element: DanhMucNgonNgu) {
    this.DanhMucNgonNguService.formData = element;
    this.NotificationService.warn(this.DanhMucNgonNguService.ComponentSaveAll(this.DanhMucNgonNguSort, this.DanhMucNgonNguPaginator));
  }
  DanhMucNgonNguDelete(element: DanhMucNgonNgu) {
    this.DanhMucNgonNguService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucNgonNguService.ComponentDeleteAll(this.DanhMucNgonNguSort, this.DanhMucNgonNguPaginator));
  }

  DanhMucThanhVienSearch() {
    this.DanhMucThanhVienService.SearchAll(this.DanhMucThanhVienSort, this.DanhMucThanhVienPaginator);
  }
  DanhMucThanhVienSave(element: DanhMucThanhVien) {
    this.DanhMucThanhVienService.formData = element;
    this.NotificationService.warn(this.DanhMucThanhVienService.ComponentSaveAll(this.DanhMucThanhVienSort, this.DanhMucThanhVienPaginator));
  }
  DanhMucThanhVienDelete(element: DanhMucThanhVien) {
    this.DanhMucThanhVienService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucThanhVienService.ComponentDeleteAll(this.DanhMucThanhVienSort, this.DanhMucThanhVienPaginator));
  }

  DanhMucToChucSearch() {
    this.DanhMucToChucService.SearchAll(this.DanhMucToChucSort, this.DanhMucToChucPaginator);
  }
  DanhMucToChucSave(element: DanhMucToChuc) {
    this.DanhMucToChucService.formData = element;
    this.NotificationService.warn(this.DanhMucToChucService.ComponentSaveAll(this.DanhMucToChucSort, this.DanhMucToChucPaginator));
  }
  DanhMucToChucDelete(element: DanhMucToChuc) {
    this.DanhMucToChucService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucToChucService.ComponentDeleteAll(this.DanhMucToChucSort, this.DanhMucToChucPaginator));
  }

  DanhMucChucDanhSearch() {
    this.DanhMucChucDanhService.SearchAll(this.DanhMucChucDanhSort, this.DanhMucChucDanhPaginator);
  }
  DanhMucChucDanhSave(element: DanhMucChucDanh) {
    this.DanhMucChucDanhService.formData = element;
    this.NotificationService.warn(this.DanhMucChucDanhService.ComponentSaveAll(this.DanhMucChucDanhSort, this.DanhMucChucDanhPaginator));
  }
  DanhMucChucDanhDelete(element: DanhMucChucDanh) {
    this.DanhMucChucDanhService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucChucDanhService.ComponentDeleteAll(this.DanhMucChucDanhSort, this.DanhMucChucDanhPaginator));
  }

  DanhMucHoaDonSearch() {
    this.DanhMucHoaDonService.SearchAll(this.DanhMucHoaDonSort, this.DanhMucHoaDonPaginator);
  }
  DanhMucHoaDonSave(element: DanhMucHoaDon) {
    this.DanhMucHoaDonService.formData = element;
    this.NotificationService.warn(this.DanhMucHoaDonService.ComponentSaveAll(this.DanhMucHoaDonSort, this.DanhMucHoaDonPaginator));
  }
  DanhMucHoaDonDelete(element: DanhMucHoaDon) {
    this.DanhMucHoaDonService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucHoaDonService.ComponentDeleteAll(this.DanhMucHoaDonSort, this.DanhMucHoaDonPaginator));
  }

  DanhMucDonViTinhSearch() {
    this.DanhMucDonViTinhService.SearchAll(this.DanhMucDonViTinhSort, this.DanhMucDonViTinhPaginator);
  }
  DanhMucDonViTinhSave(element: DanhMucDonViTinh) {
    this.DanhMucDonViTinhService.formData = element;
    this.NotificationService.warn(this.DanhMucDonViTinhService.ComponentSaveAll(this.DanhMucDonViTinhSort, this.DanhMucDonViTinhPaginator));
  }
  DanhMucDonViTinhDelete(element: DanhMucDonViTinh) {
    this.DanhMucDonViTinhService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucDonViTinhService.ComponentDeleteAll(this.DanhMucDonViTinhSort, this.DanhMucDonViTinhPaginator));
  }

  DanhMucChungChiSearch() {
    this.DanhMucChungChiService.SearchAll(this.DanhMucChungChiSort, this.DanhMucChungChiPaginator);
  }
  DanhMucChungChiSave(element: DanhMucChungChi) {
    this.DanhMucChungChiService.formData = element;
    this.NotificationService.warn(this.DanhMucChungChiService.ComponentSaveAll(this.DanhMucChungChiSort, this.DanhMucChungChiPaginator));
  }
  DanhMucChungChiDelete(element: DanhMucChungChi) {
    this.DanhMucChungChiService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucChungChiService.ComponentDeleteAll(this.DanhMucChungChiSort, this.DanhMucChungChiPaginator));
  }

  DanhMucThietBiSearch() {
    this.DanhMucThietBiService.SearchAll(this.DanhMucThietBiSort, this.DanhMucThietBiPaginator);
  }
  DanhMucThietBiSave(element: DanhMucThietBi) {
    this.DanhMucThietBiService.formData = element;
    this.NotificationService.warn(this.DanhMucThietBiService.ComponentSaveAll(this.DanhMucThietBiSort, this.DanhMucThietBiPaginator));
  }
  DanhMucThietBiDelete(element: DanhMucThietBi) {
    this.DanhMucThietBiService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucThietBiService.ComponentDeleteAll(this.DanhMucThietBiSort, this.DanhMucThietBiPaginator));
  }

  DanhMucTieuChuanSanXuatSearch() {
    this.DanhMucTieuChuanSanXuatService.SearchAll(this.DanhMucTieuChuanSanXuatSort, this.DanhMucTieuChuanSanXuatPaginator);
  }
  DanhMucTieuChuanSanXuatSave(element: DanhMucTieuChuanSanXuat) {
    this.DanhMucTieuChuanSanXuatService.formData = element;
    this.NotificationService.warn(this.DanhMucTieuChuanSanXuatService.ComponentSaveAll(this.DanhMucTieuChuanSanXuatSort, this.DanhMucTieuChuanSanXuatPaginator));
  }
  DanhMucTieuChuanSanXuatDelete(element: DanhMucTieuChuanSanXuat) {
    this.DanhMucTieuChuanSanXuatService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucTieuChuanSanXuatService.ComponentDeleteAll(this.DanhMucTieuChuanSanXuatSort, this.DanhMucTieuChuanSanXuatPaginator));
  }

  DanhMucLienKetSearch() {
    this.DanhMucLienKetService.SearchAll(this.DanhMucLienKetSort, this.DanhMucLienKetPaginator);
  }
  DanhMucLienKetSave(element: DanhMucLienKet) {
    this.DanhMucLienKetService.formData = element;
    this.NotificationService.warn(this.DanhMucLienKetService.ComponentSaveAll(this.DanhMucLienKetSort, this.DanhMucLienKetPaginator));
  }
  DanhMucLienKetDelete(element: DanhMucLienKet) {
    this.DanhMucLienKetService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucLienKetService.ComponentDeleteAll(this.DanhMucLienKetSort, this.DanhMucLienKetPaginator));
  }

  DanhMucTinhThanhToaDoOpen(element: DanhMucTinhThanh) {
    this.DanhMucTinhThanhToaDoService.baseParameter.DanhMucTinhThanhID = element.ID;
    this.DanhMucTinhThanhToaDoService.baseParameter.ID = element.ID;
    this.DanhMucTinhThanhToaDoService.baseParameter.Name = element.Name;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = environment.DialogConfigWidth;
    dialogConfig.data = { ID: element.ID };
    const dialog = this.dialog.open(DanhMucTinhThanhToaDoDetailComponent, dialogConfig);
    dialog.afterClosed().subscribe(() => {
    });
  }
}
