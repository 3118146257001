import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucXaPhuong } from 'src/app/shared/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';

import { DanhMucSanPhamGiong } from 'src/app/shared/DanhMucSanPhamGiong.model';
import { DanhMucSanPhamGiongService } from 'src/app/shared/DanhMucSanPhamGiong.service';
import { DanhMucNguyenVatLieu } from 'src/app/shared/DanhMucNguyenVatLieu.model';
import { DanhMucNguyenVatLieuService } from 'src/app/shared/DanhMucNguyenVatLieu.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { ThanhVienCoQuanQuanLyDetailComponent } from '../thanh-vien-co-quan-quan-ly-detail/thanh-vien-co-quan-quan-ly-detail.component';
import { DanhMucSanPhamGiongDetailComponent } from '../danh-muc-san-pham-giong-detail/danh-muc-san-pham-giong-detail.component';

import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';
import { ToChucDetailComponent } from '../to-chuc-detail/to-chuc-detail.component';

@Component({
  selector: 'app-tu-van',
  templateUrl: './tu-van.component.html',
  styleUrls: ['./tu-van.component.css']
})
export class TuVanComponent implements OnInit {

  ThanhVienChuyenGiaDataSource: MatTableDataSource<any>;
  @ViewChild('ThanhVienChuyenGiaSort') ThanhVienChuyenGiaSort: MatSort;
  @ViewChild('ThanhVienChuyenGiaPaginator') ThanhVienChuyenGiaPaginator: MatPaginator;

  @ViewChild('DanhMucSanPhamGiongSort') DanhMucSanPhamGiongSort: MatSort;
  @ViewChild('DanhMucSanPhamGiongPaginator') DanhMucSanPhamGiongPaginator: MatPaginator;

  @ViewChild('DanhMucNguyenVatLieuSort') DanhMucNguyenVatLieuSort: MatSort;
  @ViewChild('DanhMucNguyenVatLieuPaginator') DanhMucNguyenVatLieuPaginator: MatPaginator;

  ToChucErrorDataSource: MatTableDataSource<any>;
  @ViewChild('ToChucErrorSort') ToChucErrorSort: MatSort;
  @ViewChild('ToChucErrorPaginator') ToChucErrorPaginator: MatPaginator;

  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  DanhMucTinhThanhID: number = environment.InitializationNumber;
  DanhMucQuanHuyenID: number = environment.InitializationNumber;
  DanhMucXaPhuongID: number = environment.InitializationNumber;
  DanhMucNguyenVatLieuPhanLoaiID: number = environment.InitializationNumber;
  IsChoPhep: boolean = true;
  IsCam: boolean = false;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,
    public DanhMucSanPhamGiongService: DanhMucSanPhamGiongService,
    public DanhMucNguyenVatLieuService: DanhMucNguyenVatLieuService,

    public ThanhVienService: ThanhVienService,
    
    public ToChucService: ToChucService,
  ) { }

  ngOnInit(): void {
    
  }

  ToChucErrorSearch() {
    if (this.ToChucService.SearchString.length > 0) {
      this.ToChucErrorDataSource.filter = this.ToChucService.SearchString.toLowerCase();
    }
    else {
      this.ToChucErrorGetToList();
    }
  }

  ToChucErrorGetToList() {
    this.isShowLoading = true;
    this.ToChucService.GetByErrorToListAsync().subscribe(
      res => {
        this.ToChucService.list0001 = (res as ToChuc[]);
        this.ToChucErrorDataSource = new MatTableDataSource(this.ToChucService.list0001);
        this.ToChucErrorDataSource.sort = this.ToChucErrorSort;
        this.ToChucErrorDataSource.paginator = this.ToChucErrorPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucAdd(ID: any) {
    this.ToChucService.baseParameter.ID = ID;
    this.ToChucService.GetByIDAsync().subscribe(
      res => {
        this.ToChucService.formData = res as ToChuc;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ToChucDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
      },
      err => {
      }
    );
  }

  DanhMucSanPhamGiongSearch() {
    this.isShowLoading = true;
    this.DanhMucSanPhamGiongService.GetBySearchStringToListAsync().subscribe(
      res => {
        this.DanhMucSanPhamGiongService.list = (res as DanhMucSanPhamGiong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));                
        this.DanhMucSanPhamGiongService.DataSource = new MatTableDataSource(this.DanhMucSanPhamGiongService.list);
        this.DanhMucSanPhamGiongService.DataSource.sort = this.DanhMucSanPhamGiongSort;
        this.DanhMucSanPhamGiongService.DataSource.paginator = this.DanhMucSanPhamGiongPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  } 
  DanhMucSanPhamGiongDelete(element: DanhMucSanPhamGiong) {
    this.DanhMucSanPhamGiongService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucSanPhamGiongService.ComponentDeleteAll(this.DanhMucSanPhamGiongSort, this.DanhMucSanPhamGiongPaginator));
  }
  DanhMucSanPhamGiongAdd(ID: any) {
    this.DanhMucSanPhamGiongService.baseParameter.ID = ID;
    this.DanhMucSanPhamGiongService.GetByIDAsync().subscribe(
      res => {
        this.DanhMucSanPhamGiongService.formData = res as DanhMucSanPhamGiong;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(DanhMucSanPhamGiongDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.DanhMucSanPhamGiongSearch();
        });
      },
      err => {
      }
    );
  }

  DanhMucNguyenVatLieuGetToList() {
    this.isShowLoading = true;
    this.DanhMucNguyenVatLieuService.baseParameter.ParentID = this.DanhMucNguyenVatLieuPhanLoaiID;
    if (this.IsChoPhep == true) {
      this.DanhMucNguyenVatLieuService.baseParameter.Active = true;
    }
    if (this.IsCam == true) {
      this.DanhMucNguyenVatLieuService.baseParameter.Active = false;
    }
    this.DanhMucNguyenVatLieuService.baseParameter.SearchString = this.DanhMucNguyenVatLieuService.SearchString;    
    this.DanhMucNguyenVatLieuService.GetBySearchStringToListAsync().subscribe(
      res => {
        this.DanhMucNguyenVatLieuService.list = (res as DanhMucNguyenVatLieu[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));        
        for (let i = 0; i < this.DanhMucNguyenVatLieuService.list.length; i++) {
          if (this.DanhMucNguyenVatLieuService.list[i].Active == true) {
            this.DanhMucNguyenVatLieuService.list[i].IsChoPhep = true;
          }
          if (this.DanhMucNguyenVatLieuService.list[i].Active == false) {
            this.DanhMucNguyenVatLieuService.list[i].IsCam = true;
          }
        }        
        this.DanhMucNguyenVatLieuService.DataSource = new MatTableDataSource(this.DanhMucNguyenVatLieuService.list);
        this.DanhMucNguyenVatLieuService.DataSource.sort = this.DanhMucNguyenVatLieuSort;
        this.DanhMucNguyenVatLieuService.DataSource.paginator = this.DanhMucNguyenVatLieuPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucNguyenVatLieuSearch() {
    this.DanhMucNguyenVatLieuGetToList();
  }
  DanhMucNguyenVatLieuSave(element: DanhMucNguyenVatLieu) {
    if (element.IsChoPhep == true) {
      element.Active = true;
    }
    if (element.IsCam == true) {
      element.Active = false;
    }
    this.DanhMucNguyenVatLieuService.formData = element;
    this.DanhMucNguyenVatLieuService.SaveAsync().subscribe(
      res => {
        this.DanhMucNguyenVatLieuSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  DanhMucNguyenVatLieuDelete(element: DanhMucNguyenVatLieu) {
    if (confirm(environment.DeleteConfirm)) {
      this.DanhMucNguyenVatLieuService.baseParameter.ID = element.ID;
      this.DanhMucNguyenVatLieuService.RemoveAsync().subscribe(
        res => {
          this.DanhMucNguyenVatLieuSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
  onDanhMucNguyenVatLieuIsChoPhepChange(element: DanhMucNguyenVatLieu) {
    element.IsCam = !element.IsChoPhep;
    if (element.IsChoPhep == true) {
      element.Active = true;
    }
  }
  onDanhMucNguyenVatLieuIsCamChange(element: DanhMucNguyenVatLieu) {
    element.IsChoPhep = !element.IsCam;
    if (element.IsCam == true) {
      element.Active = false;
    }
  }
  DanhMucNguyenVatLieuExportExcel() {
    this.isShowLoading = true;
    this.DownloadService.baseParameter.ParentID = this.DanhMucNguyenVatLieuPhanLoaiID;
    if (this.IsChoPhep == true) {
      this.DownloadService.baseParameter.Active = true;
    }
    if (this.IsCam == true) {
      this.DownloadService.baseParameter.Active = false;
    }
    this.DownloadService.ExportDanhMucNguyenVatLieuToExcelAsync().subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucNguyenVatLieuExportHTML() {
    this.isShowLoading = true;
    this.DownloadService.baseParameter.ParentID = this.DanhMucNguyenVatLieuPhanLoaiID;
    if (this.IsChoPhep == true) {
      this.DownloadService.baseParameter.Active = true;
    }
    if (this.IsCam == true) {
      this.DownloadService.baseParameter.Active = false;
    }
    this.DownloadService.ExportDanhMucNguyenVatLieuToHTMLAsync().subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ChuyenGiaAdd(ID: any) {
    this.ThanhVienService.baseParameter.ID = ID;
    this.ThanhVienService.GetByIDAsync().subscribe(
      res => {
        this.ThanhVienService.formData = res as ThanhVien;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ThanhVienCoQuanQuanLyDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.ChuyenGiaSearch();
        });
      },
      err => {
      }
    );
  }

  ChuyenGiaSearch() {
    this.ChuyenGiaGetToList();
  }
  ChuyenGiaGetToList() {
    this.isShowLoading = true;
    this.ThanhVienService.baseParameter.ParentID = environment.ThanhVienChuyenGiaID;
    this.ThanhVienService.baseParameter.SearchString = this.ThanhVienService.SearchString;
    this.ThanhVienService.baseParameter.DanhMucTinhThanhID = this.DanhMucTinhThanhID;
    this.ThanhVienService.baseParameter.DanhMucQuanHuyenID = environment.InitializationNumber;
    this.ThanhVienService.baseParameter.DanhMucXaPhuongID = environment.InitializationNumber;
    this.ThanhVienService.GetByParentID_SearchString_DanhMucTinhThanhID_DanhMucQuanHuyenID_DanhMucXaPhuongIDToListAsync().subscribe(
      res => {
        this.ThanhVienService.listChuyenGia = (res as ThanhVien[]);
        this.ThanhVienChuyenGiaDataSource = new MatTableDataSource(this.ThanhVienService.listChuyenGia);
        this.ThanhVienChuyenGiaDataSource.sort = this.ThanhVienChuyenGiaSort;
        this.ThanhVienChuyenGiaDataSource.paginator = this.ThanhVienChuyenGiaPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  DanhMucTinhThanhGetToList() {
    this.isShowLoading = true;
    this.DanhMucTinhThanhService.baseParameter.Active = true;
    this.DanhMucTinhThanhService.GetSQLByThanhVienIDAndActiveToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.list = (res as DanhMucTinhThanh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucTinhThanhService.list001 = this.DanhMucTinhThanhService.list;
        if (this.DanhMucTinhThanhService.list) {
          if (this.DanhMucTinhThanhService.list.length) {
            this.DanhMucTinhThanhID = this.DanhMucTinhThanhService.list[0].ID;
          }
        }        
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucQuanHuyenGetToList() {
    this.isShowLoading = true;
    this.DanhMucQuanHuyenService.baseParameter.ParentID = this.DanhMucTinhThanhID;
    this.DanhMucQuanHuyenService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucQuanHuyenService.list = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucQuanHuyenService.list001 = this.DanhMucQuanHuyenService.list;
        this.DanhMucXaPhuongGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucXaPhuongGetToList() {
    this.isShowLoading = true;
    this.DanhMucXaPhuongService.baseParameter.ParentID = this.DanhMucQuanHuyenID;
    this.DanhMucXaPhuongService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucXaPhuongService.list = (res as DanhMucXaPhuong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucXaPhuongService.list001 = this.DanhMucXaPhuongService.list;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  DanhMucTinhThanhFilter(searchString: string) {
    this.DanhMucTinhThanhService.Filter(searchString);
  }
  DanhMucQuanHuyenFilter(searchString: string) {
    this.DanhMucQuanHuyenService.Filter(searchString);
  }
  DanhMucXaPhuongFilter(searchString: string) {
    this.DanhMucXaPhuongService.Filter(searchString);
  }
}
