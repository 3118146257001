<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-bar-chart fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Hợp tác xã</h2>
            <h6 class="m-0 text-dark fw-light">
                Báo cáo thống kê
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-Report0002" data-bs-toggle="tab" href="#Report0002"
                                    role="tab" aria-controls="Report0002" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Khảo sát</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-Report0003" data-bs-toggle="tab" href="#Report0003"
                                    role="tab" aria-controls="Report0003" aria-selected="false">
                                    <span class="badge bg-danger" style="font-size: 16px;">Tọa độ</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-Report0004" data-bs-toggle="tab" href="#Report0004"
                                    role="tab" aria-controls="Report0004" aria-selected="false">
                                    <span class="badge bg-success" style="font-size: 16px;">Diện
                                        tích</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-Report0006" data-bs-toggle="tab" href="#Report0006"
                                    role="tab" aria-controls="Report0006" aria-selected="false">
                                    <span class="badge bg-warning" style="font-size: 16px;">Xuống giống</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade" id="Report0002" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="row gx-4">
                                        <div class="col-lg-3 col-sm-12 col-12">
                                            <label class="form-label">Tỉnh thành</label>
                                            <mat-select class="form-control" name="DanhMucTinhThanhID"
                                                (selectionChange)="DanhMucQuanHuyenGetToList()"
                                                [(ngModel)]="DanhMucTinhThanhID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucTinhThanhFilter($event.target.value)">
                                                <mat-option [value]="0">
                                                    Tất cả
                                                </mat-option>
                                                <mat-option *ngFor="let item of DanhMucTinhThanhService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-2 col-sm-12 col-12">
                                            <label class="form-label">Quận huyện</label>
                                            <mat-select class="form-control" name="DanhMucQuanHuyenID"
                                                (selectionChange)="DanhMucXaPhuongGetToList()"
                                                [(ngModel)]="DanhMucQuanHuyenID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucQuanHuyenFilter($event.target.value)">
                                                <mat-option [value]="0">
                                                    Tất cả
                                                </mat-option>
                                                <mat-option *ngFor="let item of DanhMucQuanHuyenService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-2 col-sm-12 col-12">
                                            <label class="form-label">Xã phường</label>
                                            <mat-select class="form-control" name="DanhMucXaPhuongID"
                                                [(ngModel)]="DanhMucXaPhuongID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucXaPhuongFilter($event.target.value)">
                                                <mat-option [value]="0">
                                                    Tất cả
                                                </mat-option>
                                                <mat-option *ngFor="let item of DanhMucXaPhuongService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-3 col-sm-12 col-12">
                                            <label class="form-label">.</label>
                                            <input [(ngModel)]="ReportService.SearchString" placeholder="Tìm ..."
                                                class="form-control" (keyup.enter)="Report0002Search()">
                                        </div>
                                        <div class="col-lg-2 col-sm-12 col-12">
                                            <label class="form-label">.</label>
                                            <div>
                                                <a class="btn btn-success" title="Tìm" (click)="Report0002Search()"><i
                                                        class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                                <!-- <a class="btn btn-success" title="Download excel"
                                                    (click)="Report0002ExportExcel()"><i
                                                        class="bi bi-cloud-download"></i></a>&nbsp;&nbsp;&nbsp;
                                                <a class="btn btn-success" title="Báo cáo"
                                                    (click)="Report0002ExportHTML()"><i
                                                        class="bi bi-printer"></i></a>&nbsp;&nbsp;&nbsp; -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card mb-4 card-info">
                                        <div class="card-header">
                                            <h5 class="card-title text-white">Tham gia đề án và Khảo sát năng lực sản
                                                xuất
                                                <span *ngIf="ReportService.list0002">({{ReportService.list0002.length}}
                                                    items)</span>
                                            </h5>
                                        </div>
                                        <div class="card-body">
                                            <div class="table-outer">
                                                <div class="table-responsive">
                                                    <table mat-table matSort #ReportSort0002="matSort"
                                                        [dataSource]="ReportDataSource0002"
                                                        class="table table-striped m-0">
                                                        <ng-container matColumnDef="STT">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                STT
                                                            </th>
                                                            <td mat-cell *matCellDef="let element; index as i;"
                                                                style="text-align: center;">
                                                                <div
                                                                    *ngIf="!element.DanhMucXaPhuongName.includes('Tổng cộng')">
                                                                    {{ReportPaginator0002.pageSize *
                                                                    ReportPaginator0002.pageIndex + i + 1}}</div>
                                                                <div
                                                                    *ngIf="element.DanhMucXaPhuongName.includes('Tổng cộng')">
                                                                    <b>{{ReportPaginator0002.pageSize *
                                                                        ReportPaginator0002.pageIndex + i + 1}}</b>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DanhMucTinhThanhName">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tỉnh
                                                                thành
                                                            </th>
                                                            <td mat-cell *matCellDef="let element">
                                                                <div
                                                                    *ngIf="!element.DanhMucXaPhuongName.includes('Tổng cộng')">
                                                                    {{element.DanhMucTinhThanhName}}</div>
                                                                <div
                                                                    *ngIf="element.DanhMucXaPhuongName.includes('Tổng cộng')">
                                                                    <b>{{element.DanhMucTinhThanhName}}</b>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DanhMucQuanHuyenName">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Quận
                                                                huyện
                                                            </th>
                                                            <td mat-cell *matCellDef="let element">
                                                                <div
                                                                    *ngIf="!element.DanhMucXaPhuongName.includes('Tổng cộng')">
                                                                    {{element.DanhMucQuanHuyenName}}</div>
                                                                <div
                                                                    *ngIf="element.DanhMucXaPhuongName.includes('Tổng cộng')">
                                                                    <b>{{element.DanhMucQuanHuyenName}}</b>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DanhMucXaPhuongName">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Xã
                                                                phường
                                                            </th>
                                                            <td mat-cell *matCellDef="let element">
                                                                <div
                                                                    *ngIf="!element.DanhMucXaPhuongName.includes('Tổng cộng')">
                                                                    {{element.DanhMucXaPhuongName}}</div>
                                                                <div
                                                                    *ngIf="element.DanhMucXaPhuongName.includes('Tổng cộng')">
                                                                    <b>{{element.DanhMucXaPhuongName}}</b>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <!-- <ng-container matColumnDef="SoLuongTongHop">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tổng số
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                <div
                                                                    *ngIf="!element.DanhMucXaPhuongName.includes('Tổng cộng')">
                                                                    {{element.SoLuongTongHop}}</div>
                                                                <div
                                                                    *ngIf="element.DanhMucXaPhuongName.includes('Tổng cộng')">
                                                                    <b>{{element.SoLuongTongHop}}</b>
                                                                </div>
                                                            </td>
                                                        </ng-container> -->
                                                        <ng-container matColumnDef="SoLuongThamGia">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tham
                                                                gia
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                <div
                                                                    *ngIf="!element.DanhMucXaPhuongName.includes('Tổng cộng')">
                                                                    {{element.SoLuongThamGia}}</div>
                                                                <div
                                                                    *ngIf="element.DanhMucXaPhuongName.includes('Tổng cộng')">
                                                                    <b>{{element.SoLuongThamGia}}</b>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="SoLuongKhaoSat">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Khảo
                                                                sát
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                <div
                                                                    *ngIf="!element.DanhMucXaPhuongName.includes('Tổng cộng')">
                                                                    {{element.SoLuongKhaoSat}}</div>
                                                                <div
                                                                    *ngIf="element.DanhMucXaPhuongName.includes('Tổng cộng')">
                                                                    <b>{{element.SoLuongKhaoSat}}</b>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="TyLeHoanThanh">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Hoàn
                                                                thành
                                                                (%)</th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                <div
                                                                    *ngIf="!element.DanhMucXaPhuongName.includes('Tổng cộng')">
                                                                    {{element.TyLeHoanThanh}} %</div>
                                                                <div
                                                                    *ngIf="element.DanhMucXaPhuongName.includes('Tổng cộng')">
                                                                    <b>{{element.TyLeHoanThanh}} %</b>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <tr mat-header-row
                                                            *matHeaderRowDef="ReportService.displayColumns0001">
                                                        </tr>
                                                        <tr mat-row
                                                            *matRowDef="let row; columns: ReportService.displayColumns0001;">
                                                        </tr>
                                                    </table>
                                                    <mat-paginator #ReportPaginator0002="matPaginator"
                                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                                        [showFirstLastButtons]></mat-paginator>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="Report0003" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="row gx-4">
                                        <div class="col-lg-3 col-sm-12 col-12">
                                            <label class="form-label">Tỉnh thành</label>
                                            <mat-select class="form-control" name="DanhMucTinhThanhID"
                                                (selectionChange)="DanhMucQuanHuyenGetToList()"
                                                [(ngModel)]="DanhMucTinhThanhID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucTinhThanhFilter($event.target.value)">
                                                <mat-option [value]="0">
                                                    Tất cả
                                                </mat-option>
                                                <mat-option *ngFor="let item of DanhMucTinhThanhService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-2 col-sm-12 col-12">
                                            <label class="form-label">Quận huyện</label>
                                            <mat-select class="form-control" name="DanhMucQuanHuyenID"
                                                (selectionChange)="DanhMucXaPhuongGetToList()"
                                                [(ngModel)]="DanhMucQuanHuyenID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucQuanHuyenFilter($event.target.value)">
                                                <mat-option [value]="0">
                                                    Tất cả
                                                </mat-option>
                                                <mat-option *ngFor="let item of DanhMucQuanHuyenService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-2 col-sm-12 col-12">
                                            <label class="form-label">Xã phường</label>
                                            <mat-select class="form-control" name="DanhMucXaPhuongID"
                                                [(ngModel)]="DanhMucXaPhuongID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucXaPhuongFilter($event.target.value)">
                                                <mat-option [value]="0">
                                                    Tất cả
                                                </mat-option>
                                                <mat-option *ngFor="let item of DanhMucXaPhuongService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-2 col-sm-12 col-12">
                                            <label class="form-label">Trạng thái</label>
                                            <select class="form-select" name="SortOrder" [(ngModel)]="SortOrder">
                                                <option [value]="0">
                                                    Tất cả</option>
                                                <option [value]="1">
                                                    Có tọa độ</option>
                                                <option [value]="2">
                                                    Chưa có tọa độ</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-2 col-sm-12 col-12">
                                            <label class="form-label">.</label>
                                            <div>
                                                <a class="btn btn-success" title="Tìm" (click)="Report0003Search()"><i
                                                        class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                                <!-- <a class="btn btn-success" title="Download excel"
                                                    (click)="Report0003ExportExcel()"><i
                                                        class="bi bi-cloud-download"></i></a>&nbsp;&nbsp;&nbsp;
                                                <a class="btn btn-success" title="Báo cáo"
                                                    (click)="Report0003ExportHTML()"><i
                                                        class="bi bi-printer"></i></a>&nbsp;&nbsp;&nbsp; -->
                                            </div>

                                        </div>
                                    </div>
                                    <div class="card mb-4 card-danger">
                                        <div class="card-header">
                                            <h5 class="card-title text-white">Tọa độ Trụ sở và Vùng nguyên liệu
                                                <span *ngIf="ReportService.list0003">({{ReportService.list0003.length}}
                                                    items)</span>
                                            </h5>
                                        </div>
                                        <div class="card-body">
                                            <div class="table-outer">
                                                <div class="table-responsive">
                                                    <table mat-table matSort #ReportSort0003="matSort"
                                                        [dataSource]="ReportDataSource0003"
                                                        class="table table-striped m-0">
                                                        <ng-container matColumnDef="STT">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                STT
                                                            </th>
                                                            <td mat-cell *matCellDef="let element; index as i;"
                                                                style="text-align: center;">
                                                                {{ReportPaginator0003.pageSize *
                                                                ReportPaginator0003.pageIndex + i + 1}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DanhMucTinhThanhName">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tỉnh
                                                                thành
                                                            </th>
                                                            <td mat-cell *matCellDef="let element">

                                                                {{element.DanhMucTinhThanhName}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DanhMucQuanHuyenName">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Quận
                                                                huyện
                                                            </th>
                                                            <td mat-cell *matCellDef="let element">

                                                                {{element.DanhMucQuanHuyenName}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DanhMucXaPhuongName">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Xã
                                                                phường
                                                            </th>
                                                            <td mat-cell *matCellDef="let element">
                                                                {{element.DanhMucXaPhuongName}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="Name">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Hợp
                                                                tác xã
                                                            </th>
                                                            <td mat-cell *matCellDef="let element">
                                                                <a style="cursor: pointer;" title="{{element.Name}}"
                                                                    class="link-primary"
                                                                    (click)="ToChucAdd(element.ParentID)"><b>{{element.Name}}</b></a>
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="SoLuongTongHop">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Trụ sở
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.SoLuongTongHop}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="SoLuongThamGia">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vùng
                                                                nguyên liệu
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">

                                                                {{element.SoLuongThamGia}}
                                                            </td>
                                                        </ng-container>
                                                        <tr mat-header-row
                                                            *matHeaderRowDef="ReportService.displayColumns0003">
                                                        </tr>
                                                        <tr mat-row
                                                            *matRowDef="let row; columns: ReportService.displayColumns0003;">
                                                        </tr>
                                                    </table>
                                                    <mat-paginator #ReportPaginator0003="matPaginator"
                                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                                        [showFirstLastButtons]></mat-paginator>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="Report0004" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="row gx-4">
                                        <div class="col-lg-10 col-sm-12 col-12">
                                            <input [(ngModel)]="ReportService.SearchString" placeholder="Tìm ..."
                                                class="form-control" (keyup.enter)="Report0004Search()">
                                        </div>
                                        <div class="col-lg-2 col-sm-12 col-12">
                                            <div>
                                                <a class="btn btn-success" title="Tìm" (click)="Report0004Search()"><i
                                                        class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                                <!-- <a class="btn btn-success" title="Download excel"
                                                    (click)="Report0004ExportExcel()"><i
                                                        class="bi bi-cloud-download"></i></a>&nbsp;&nbsp;&nbsp;
                                                <a class="btn btn-success" title="Báo cáo"
                                                    (click)="Report0004ExportHTML()"><i
                                                        class="bi bi-printer"></i></a>&nbsp;&nbsp;&nbsp; -->
                                            </div>

                                        </div>
                                    </div>
                                    <div class="card mb-4 card-success">
                                        <div class="card-header">
                                            <h5 class="card-title text-white">Số lượng + Diện tích Tham gia và Khảo sát
                                                <span *ngIf="ReportService.list0004">({{ReportService.list0004.length}}
                                                    items)</span>
                                            </h5>
                                        </div>
                                        <div class="card-body">
                                            <div class="table-outer">
                                                <div class="table-responsive">
                                                    <table mat-table matSort #ReportSort0004="matSort"
                                                        [dataSource]="ReportDataSource0004"
                                                        class="table table-striped m-0">
                                                        <ng-container matColumnDef="STT">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                                class="sticky-col" style="background-color: black;">
                                                                <span style="color: #ffffff;">Tỉnh thành</span>
                                                            </th>
                                                            <td mat-cell *matCellDef="let element index as i;"
                                                                class="sticky-col">
                                                                <b>{{ReportPaginator0004.pageSize
                                                                    *
                                                                    ReportPaginator0004.pageIndex + i + 1}} |
                                                                    {{element.DanhMucTinhThanhName}}</b>
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="SoLuongThamGia">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>HTX
                                                                Tham
                                                                gia
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.SoLuongThamGia}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichThamGia001">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Diện
                                                                tích Tham gia lũy kế
                                                                đến 2024 (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichThamGia001 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichThamGia002">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Đến
                                                                2025 (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichThamGia002 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichThamGia003">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>đến
                                                                2026 (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichThamGia003 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichThamGia004">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>đến
                                                                2027 (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichThamGia004 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichThamGia005">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>đến
                                                                2028 (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichThamGia005 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichThamGia006">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>đến
                                                                2029 (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichThamGia006 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichThamGia007">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>đến
                                                                2030 (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichThamGia007 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="SoLuongKhaoSat">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>HTX đã
                                                                Khảo sát
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.SoLuongKhaoSat}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichKhaoSat001">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Diện
                                                                tích Khảo sát lũy kế
                                                                đến 2024 (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichKhaoSat001 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichKhaoSat002">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>đến
                                                                2025 (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichKhaoSat002 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichKhaoSat003">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>đến
                                                                2026 (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichKhaoSat003 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichKhaoSat004">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>đến
                                                                2027 (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichKhaoSat004 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichKhaoSat005">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>đến
                                                                2028 (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichKhaoSat005 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichKhaoSat006">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>đến
                                                                2029 (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichKhaoSat006 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichKhaoSat007">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>đến
                                                                2030 (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichKhaoSat007 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="TyLeHoanThanhSoLuong">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Số
                                                                lượng hoàn thành (%)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.TyLeHoanThanhSoLuong |
                                                                number:
                                                                '1.0-0'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="TyLeHoanThanhDienTich">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Diện
                                                                tích hoàn thành (%)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.TyLeHoanThanhDienTich |
                                                                number:
                                                                '1.0-0'}}
                                                            </td>
                                                        </ng-container>
                                                        <tr mat-header-row
                                                            *matHeaderRowDef="ReportService.displayColumns0004">
                                                        </tr>
                                                        <tr mat-row
                                                            *matRowDef="let row; columns: ReportService.displayColumns0004;">
                                                        </tr>
                                                    </table>
                                                    <mat-paginator #ReportPaginator0004="matPaginator"
                                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                                        [showFirstLastButtons]></mat-paginator>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade show active" id="Report0006" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="row gx-4">
                                        <div class="col-lg-3 col-sm-12 col-12">
                                            <label class="form-label">Tỉnh thành</label>
                                            <mat-select class="form-control" name="DanhMucTinhThanhID"
                                                (selectionChange)="DanhMucQuanHuyenGetToList()"
                                                [(ngModel)]="DanhMucTinhThanhID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucTinhThanhFilter($event.target.value)">
                                                <mat-option [value]="0">
                                                    Tất cả
                                                </mat-option>
                                                <mat-option *ngFor="let item of DanhMucTinhThanhService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-2 col-sm-12 col-12">
                                            <label class="form-label">Quận huyện</label>
                                            <mat-select class="form-control" name="DanhMucQuanHuyenID"
                                                (selectionChange)="DanhMucXaPhuongGetToList()"
                                                [(ngModel)]="DanhMucQuanHuyenID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucQuanHuyenFilter($event.target.value)">
                                                <mat-option [value]="0">
                                                    Tất cả
                                                </mat-option>
                                                <mat-option *ngFor="let item of DanhMucQuanHuyenService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-2 col-sm-12 col-12">
                                            <label class="form-label">Xã phường</label>
                                            <mat-select class="form-control" name="DanhMucXaPhuongID"
                                                [(ngModel)]="DanhMucXaPhuongID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucXaPhuongFilter($event.target.value)">
                                                <mat-option [value]="0">
                                                    Tất cả
                                                </mat-option>
                                                <mat-option *ngFor="let item of DanhMucXaPhuongService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-2 col-sm-12 col-12">
                                            <label class="form-label">Năm khảo sát</label>
                                            <mat-select class="form-control" name="year"
                                                (selectionChange)="Report0005Search()" [(ngModel)]="year">
                                                <mat-option [value]="2024">
                                                    2024
                                                </mat-option>
                                                <mat-option [value]="2025">
                                                    2025
                                                </mat-option>
                                                <mat-option [value]="2026">
                                                    2026
                                                </mat-option>
                                                <mat-option [value]="2027">
                                                    2027
                                                </mat-option>
                                                <mat-option [value]="2028">
                                                    2028
                                                </mat-option>
                                                <mat-option [value]="2029">
                                                    2029
                                                </mat-option>
                                                <mat-option [value]="2030">
                                                    2030
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-1 col-sm-12 col-12">
                                            <label class="form-label">.</label>
                                            <input [(ngModel)]="ReportService.SearchString" placeholder="Tìm ..."
                                                class="form-control" (keyup.enter)="Report0006Search()">
                                        </div>
                                        <div class="col-lg-2 col-sm-12 col-12">
                                            <label class="form-label">.</label>
                                            <div>
                                                <a class="btn btn-success" title="Tìm" (click)="Report0006Search()"><i
                                                        class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                                <!-- <a class="btn btn-success" title="Download excel"
                                                    (click)="Report0006ExportExcel()"><i
                                                        class="bi bi-cloud-download"></i></a>&nbsp;&nbsp;&nbsp;
                                                <a class="btn btn-success" title="Báo cáo"
                                                    (click)="Report0006ExportHTML()"><i
                                                        class="bi bi-printer"></i></a>&nbsp;&nbsp;&nbsp; -->
                                            </div>

                                        </div>
                                    </div>
                                    <div class="card mb-4 card-danger">
                                        <div class="card-header">
                                            <h5 class="card-title text-white">Diện tích xuống giống theo tháng
                                                <span *ngIf="ReportService.list0006">({{ReportService.list0006.length}}
                                                    items)</span>
                                            </h5>
                                        </div>
                                        <div class="card-body">
                                            <div class="table-outer">
                                                <div class="table-responsive">
                                                    <table mat-table matSort #ReportSort0006="matSort"
                                                        [dataSource]="ReportDataSource0006"
                                                        class="table table-striped m-0">
                                                        <ng-container matColumnDef="STT">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                                class="sticky-col" style="background-color: black;">
                                                                <span style="color: #ffffff;">Tỉnh thành</span>
                                                            </th>
                                                            <td mat-cell *matCellDef="let element index as i;"
                                                                class="sticky-col">
                                                                <div
                                                                    *ngIf="!element.DanhMucXaPhuongName.includes('Tổng cộng')">
                                                                    {{ReportPaginator0006.pageSize
                                                                    *
                                                                    ReportPaginator0006.pageIndex + i + 1}} |
                                                                    {{element.DanhMucTinhThanhName}} |
                                                                    {{element.DanhMucQuanHuyenName}} |
                                                                    {{element.DanhMucXaPhuongName}}</div>
                                                                <div
                                                                    *ngIf="element.DanhMucXaPhuongName.includes('Tổng cộng')">
                                                                    <b>{{ReportPaginator0006.pageSize
                                                                        *
                                                                        ReportPaginator0006.pageIndex + i + 1}} |
                                                                        {{element.DanhMucTinhThanhName}} |
                                                                        {{element.DanhMucQuanHuyenName}} |
                                                                        {{element.DanhMucXaPhuongName}}</b>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="SoLuongThamGia">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>HTX
                                                                Tham
                                                                gia
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.SoLuongThamGia}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichThamGia">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Diện
                                                                tích Tham gia (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichThamGia |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="SoLuongKhaoSat">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>HTX đã
                                                                Khảo sát
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.SoLuongKhaoSat}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichKhaoSat001">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tháng
                                                                01 (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichKhaoSat001 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichKhaoSat002">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tháng
                                                                02 (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichKhaoSat002 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichKhaoSat003">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tháng
                                                                03 (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichKhaoSat003 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichKhaoSat013">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Quý 01
                                                                (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichKhaoSat013 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichKhaoSat004">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tháng
                                                                04 (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichKhaoSat004 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichKhaoSat005">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tháng
                                                                05 (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichKhaoSat005 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichKhaoSat006">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tháng
                                                                06 (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichKhaoSat006 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichKhaoSat014">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Quý 02
                                                                (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichKhaoSat014 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichKhaoSat007">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tháng
                                                                07 (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichKhaoSat007 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichKhaoSat008">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tháng
                                                                08 (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichKhaoSat008 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichKhaoSat009">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tháng
                                                                09 (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichKhaoSat009 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichKhaoSat015">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Quý 03
                                                                (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichKhaoSat015 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichKhaoSat010">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tháng
                                                                10 (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichKhaoSat010 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichKhaoSat011">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tháng
                                                                11 (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichKhaoSat011 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichKhaoSat012">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tháng
                                                                12 (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichKhaoSat012 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichKhaoSat016">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Quý 04
                                                                (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichKhaoSat016 |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienTichKhaoSat">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Năm
                                                                (ha)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.DienTichKhaoSat |
                                                                number:
                                                                '1.2-2'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="TyLeHoanThanhSoLuong">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Số
                                                                lượng hoàn thành (%)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.TyLeHoanThanhSoLuong |
                                                                number:
                                                                '1.0-0'}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="TyLeHoanThanhDienTich">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Diện
                                                                tích hoàn thành (%)
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"
                                                                style="text-align: right;">
                                                                {{element.TyLeHoanThanhDienTich |
                                                                number:
                                                                '1.0-0'}}
                                                            </td>
                                                        </ng-container>
                                                        <tr mat-header-row
                                                            *matHeaderRowDef="ReportService.displayColumns0006">
                                                        </tr>
                                                        <tr mat-row
                                                            *matRowDef="let row; columns: ReportService.displayColumns0006;">
                                                        </tr>
                                                    </table>
                                                    <mat-paginator #ReportPaginator0006="matPaginator"
                                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                                        [showFirstLastButtons]></mat-paginator>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-loading *ngIf="isShowLoading"></app-loading>