import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DownloadService } from 'src/app/shared/Download.service';

import * as maplibregl from 'maplibre-gl';

import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';

import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';


@Component({
  selector: 'app-map-vung-trong',
  templateUrl: './map-vung-trong.component.html',
  styleUrls: ['./map-vung-trong.component.css']
})
export class MapVungTrongComponent implements OnInit, AfterViewInit, OnDestroy {

  isShowLoading: boolean = false;
  isShowSearch: boolean = false;
  domainName = environment.DomainDestination;
  domainURL = environment.DomainURL;

  map: maplibregl.Map | undefined;

  @ViewChild('map')
  private mapContainer!: ElementRef<HTMLElement>;

  constructor(

    public DownloadService: DownloadService,

    public DanhMucTinhThanhService: DanhMucTinhThanhService,


    public ToChucService: ToChucService,

  ) { }

  ngOnInit(): void {

  }

  SetIsShowSearch() {
    this.isShowSearch = !this.isShowSearch;
  }

  rotateCamera(timestamp) {
    if (this.map) {
      this.map.rotateTo((timestamp / 100) % 360, { duration: 0 });
      requestAnimationFrame(this.rotateCamera);
    }
  }

  ngAfterViewInit() {
    this.DanhMucTinhThanhGetToList();
    this.MapLoad();
  }

  ngOnDestroy() {
    this.map?.remove();
  }

  MapInitialization(longitude, latitude) {

    let zoom = environment.MapZoom;
    if (this.ToChucService.baseParameter.DanhMucTinhThanhID > 0) {
      zoom = 10;
    }
    if (this.ToChucService.baseParameter.SearchString.length > 0) {
      zoom = 12;
    }
    if ((latitude > 90) || (latitude == 0)) {
      latitude = environment.Latitude;
      longitude = environment.Longitude;
    }
    this.map = new maplibregl.Map({
      container: this.mapContainer.nativeElement,
      style: 'https://api.maptiler.com/maps/hybrid/style.json?key=' + environment.MaptilerAPIKey,
      center: [longitude, latitude],
      zoom: zoom,
      pitch: 45,
    });

    this.map.addControl(
      new maplibregl.NavigationControl({
        visualizePitch: true,
        showZoom: true,
        showCompass: true
      })
    );
    let mapSub = this.map;
    this.map.on('load', () => {
      this.map = mapSub;
      this.map.addSource("HoangSa", {
        "type": "image",
        "url": environment.DomainURL + "assets/image/HoangSa01.png",
        "coordinates": [
          [111.09665858054495, 17.432475898867523],
          [113.11720985517763, 17.38420482529338],
          [112.79285037220984, 15.643938718432054],
          [110.88537855035554, 15.672592116966598],
        ]
      });
      this.map.addLayer({
        "id": "HoangSa",
        "source": "HoangSa",
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

      this.map.addSource("TruongSa", {
        "type": "image",
        "url": environment.DomainURL + "assets/image/TruongSa01.png",
        "coordinates": [
          [112.32373278444106, 12.236103169381323],
          [117.4620551483049, 11.606334626304161],
          [115.59654957671216, 7.357025445897818],
          [110.62186805246108, 7.811210355974268],


        ]
      });
      this.map.addLayer({
        "id": "TruongSa",
        "source": "TruongSa",
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

    });
  }

  MapLoad() {

    this.isShowLoading = true;
    this.ToChucService.baseParameter.Active = true;
    if (this.ToChucService.baseParameter.DanhMucTinhThanhID == null) {
      this.ToChucService.baseParameter.DanhMucTinhThanhID = environment.DanhMucTinhThanhID;
    }
    if (this.ToChucService.baseParameter.DanhMucTinhThanhID == 0) {
      this.ToChucService.baseParameter.DanhMucTinhThanhID = environment.DanhMucTinhThanhID;
    }
    if (this.ToChucService.baseParameter.SearchString == null) {
      this.ToChucService.baseParameter.SearchString = environment.InitializationString;
    }

    this.ToChucService.GetSQLByToChucToaDoByDanhMucTinhThanhID_SearchStringToListAsync().subscribe(
      res => {
        this.ToChucService.list = (res as ToChuc[]);
        let latitude = environment.Latitude;
        let longitude = environment.Longitude;
        if (this.ToChucService.list) {
          if (this.ToChucService.list.length > 0) {
            if (this.ToChucService.list.length > 0) {
              latitude = Number(this.ToChucService.list[0].ViDo);
              longitude = Number(this.ToChucService.list[0].KinhDo);
            }
          }
        }
        this.MapInitialization(longitude, latitude);
        this.ToChucService.GetSQLByToChucToaDoByDanhMucTinhThanhID_SearchStringToListAsync().subscribe(
          res => {
            this.ToChucService.list = (res as ToChuc[]);
            if (this.ToChucService.list) {
              if (this.ToChucService.list.length > 0) {
                let listCode = [];
                let code = '';
                for (let i = 0; i < this.ToChucService.list.length; i++) {
                  if (code != this.ToChucService.list[i].Code) {
                    code = this.ToChucService.list[i].Code;
                    listCode.push(code);
                  }
                }
                for (let i = 0; i < listCode.length; i++) {
                  code = listCode[i];
                  let listToChucToaDoPolygon = [];
                  let listPolygon = [];
                  let listToChucToaDo = this.ToChucService.list.filter((item) => item.Code == code);
                  for (let j = 0; j < listToChucToaDo.length; j++) {
                    let latitudeSub1 = Number(listToChucToaDo[j].ViDo);
                    let longitudeSub1 = Number(listToChucToaDo[j].KinhDo);
                    listToChucToaDoPolygon.push([longitudeSub1, latitudeSub1]);
                  }

                  if (listToChucToaDoPolygon.length > 0) {
                    listPolygon.push(listToChucToaDoPolygon);
                    let toChuc = listToChucToaDo[0];
                    let layerID = 'Layer_' + toChuc.DanhMucNgonNguID;
                    let sourceID = 'Source_' + toChuc.DanhMucNgonNguID;

                    this.map.addSource(sourceID, {
                      'type': 'geojson',
                      'data': {
                        'type': 'Feature',
                        'properties': {
                          "name": toChuc.Name,
                          "address": toChuc.DanhMucXaPhuongName + ', ' + toChuc.DanhMucQuanHuyenName + ', ' + toChuc.DanhMucTinhThanhName,
                        },
                        'geometry': {
                          'type': 'Polygon',
                          'coordinates': listPolygon,
                        }
                      }
                    });
                    let color = this.DownloadService.GetRandomColor(listToChucToaDoPolygon.length);
                    this.map.addLayer({
                      'id': layerID,
                      'type': 'fill',
                      'source': sourceID,
                      'paint': {
                        'fill-color': color,
                        'fill-opacity': 0.5,
                        'fill-outline-color': color,
                      }
                    });

                    let truSo = "Chưa có trụ sở";
                    if (toChuc.IsTruSo) {
                      truSo = "Có trụ sở";
                    }
                    let dapUngDuAn = "";
                    if (toChuc.IsDapUngDuAn) {
                      dapUngDuAn = "Đáp ứng tiêu chí";
                    }

                    let popupContent = "<div style='opacity: 0.8; background-color: transparent;'>";
                    popupContent = popupContent + "<a class='link-primary form-label' style='cursor: pointer; text-align: center;' onclick='OpenWindowByToChuc(" + toChuc.ID + ")'><h3>" + toChuc.Name + "</h3></a>";
                    popupContent = popupContent + '<div>Trụ sở: <b>' + truSo + '</b></div>';
                    popupContent = popupContent + '<div>Địa chỉ: <b>' + toChuc.DanhMucXaPhuongName + ', ' + toChuc.DanhMucQuanHuyenName + ', ' + toChuc.DanhMucTinhThanhName + '</b></div>';
                    popupContent = popupContent + '<div>Giám đốc: <b>' + toChuc.Display + '</b></div>';
                    popupContent = popupContent + '<div>Điện thoại: <b>' + toChuc.DienThoai + '</b></div>';
                    popupContent = popupContent + '<div>Ngành nghề: <b>' + toChuc.Description + '</b></div>';
                    popupContent = popupContent + '<div>Thành viên: <b>' + toChuc.SoLuongThanhVien + '</b></div>';
                    popupContent = popupContent + '<div>Diện tích: <b>' + toChuc.DienTichSanXuatHa + ' ha</b></div>';
                    popupContent = popupContent + '<div>Đáp ứng tiêu chí: <b>' + dapUngDuAn + '</b></div>';
                    popupContent = popupContent + "</div>";

                    let mapSub = this.map;
                    this.map.on('click', layerID, function (e) {
                      this.map = mapSub;
                      let popup = new maplibregl.Popup({ offset: 25 })
                        .setLngLat(e.lngLat)
                        .setHTML(popupContent)
                        .setMaxWidth("600px")
                        .addTo(this.map);
                    });
                  }
                }

              }
            }
            this.isShowLoading = false;
          },
          err => {
            this.isShowLoading = false;
          }
        );
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucTinhThanhGetToList() {
    this.isShowLoading = true;
    this.DanhMucTinhThanhService.baseParameter.Active = true;
    this.DanhMucTinhThanhService.GetSQLByThanhVienIDAndActiveToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.list = (res as DanhMucTinhThanh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucTinhThanhService.list001 = this.DanhMucTinhThanhService.list;
        if (this.DanhMucTinhThanhService.list) {
          if (this.DanhMucTinhThanhService.list.length) {
          }
        }
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucTinhThanhFilter(searchString: string) {
    this.DanhMucTinhThanhService.Filter(searchString);
  }
}
